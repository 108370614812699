import { createSelector } from 'reselect';

import * as addonIds from '../constants/plans';

import { getSelectedAddonIds } from './addons';

export const showBackButton = createSelector(
  [getSelectedAddonIds],
  selectedAddonIds => {
    return selectedAddonIds.includes(addonIds.ESPN_PLUS_COMPONENT_ID);
  }
);
