import { connect } from 'react-redux';
import EmailError from '../components/EmailError';

import { routeReplace } from '../actions/routingActions';
import * as routes from '../constants/routes';
import { getIsUnifiedIdentityOn } from '../selectors/login';

/*
  @param errorsToEnableSwap {string[]} statuses that should enable a page swap
  @param statuses {Object.<string, boolean>} map of statuses
*/
function hasErrorToEnableSwap(errorsToEnableSwap, statuses) {
  return errorsToEnableSwap.some(el => statuses[el] === true);
}

function createConnectedEmailError(location, swapOn) {
  return connect(
    (state, ownProps) => ({
      isUnifiedIdentityOn: getIsUnifiedIdentityOn(state),
      ...state,
      ...ownProps,
    }),
    (dispatch, ownProps) => ({ dispatch, ...ownProps }),
    (stateProps, dispatchProps, ownProps) => {
      const { dispatch } = dispatchProps;
      const {
        fieldValue: { errors },
      } = ownProps;
      return {
        showSwap: errors && hasErrorToEnableSwap(swapOn, errors),
        swapForm: () => dispatch(routeReplace(location)),
        isUnifiedIdentityOn: stateProps.isUnifiedIdentityOn,
        validStatuses: swapOn,
        ...ownProps,
      };
    }
  )(EmailError);
}

export const CreateAccountEmailError = createConnectedEmailError(routes.LOGIN, [
  'existing',
  'subscribed',
]);
