import get from 'lodash/get';

import * as sprintV2 from '../api/SprintV2';
import { getConfig } from './configActions';
import { routeReplace, handleEnvironmentRedirect } from './routingActions';
import * as types from '../constants/actionTypes';
import * as routes from '../constants/routes';
import { getIsMissingPlans } from '../selectors/config';
import { getIsTMobileFeatureOn } from '../selectors/featureFlag';
import { getIsExistingUser } from '../selectors/partners';
import {
  getPayload,
  getCallbackText,
  getRoadblockText,
} from '../selectors/sprintV2';

export function handleCallbackLoaded() {
  return dispatch =>
    Promise.resolve()
      .then(() => dispatch(getSprintV2Eligibility()))
      .then(() => dispatch(getConfigAndRedirect()));
}

export function getConfigAndRedirect() {
  return (dispatch, getState) =>
    Promise.resolve()
      .then(() => dispatch(getConfig()))
      .then(
        () => {
          const roadblockRoute = getIsTMobileFeatureOn(getState())
            ? routes.TMOBILE_ROADBLOCK
            : routes.SPRINTV2_ROADBLOCK;
          // Go to roadblock page if no eligible plans are returned.
          if (getIsMissingPlans(getState())) {
            return dispatch(routeReplace(roadblockRoute));
          }

          // Go to login page for existing users.
          if (getIsExistingUser(getState())) {
            return dispatch(routeReplace(routes.LOGIN));
          }

          // Go to plan select page for new users.
          return dispatch(routeReplace(routes.PLAN_SELECT));
        },
        error => {
          // Go to error page if config call fails.
          dispatch(routeReplace(routes.ERROR));
          return Promise.reject(error);
        }
      );
}

export function handleCallbackClick() {
  return (dispatch, getState) => {
    const redirectUrl = get(getCallbackText(getState()), 'redirect', false);
    if (redirectUrl) {
      // If a redirect URL is included in the config, redirect to that URL
      dispatch(handleEnvironmentRedirect(redirectUrl));
    } else {
      // Otherwise, retry the eligibility call
      dispatch(resetSprintV2Eligibility());
      dispatch(getSprintV2Eligibility());
    }
  };
}

export function handleRoadblockClick() {
  return (dispatch, getState) => {
    const redirectUrl = get(getRoadblockText(getState()), 'redirect', false);
    if (redirectUrl) {
      dispatch(handleEnvironmentRedirect(redirectUrl));
    }
  };
}

export function getSprintV2Eligibility() {
  return (dispatch, getState) => {
    dispatch(getSprintV2EligibilityStart());
    return sprintV2.getSprintV2Eligibility(getPayload(getState())).then(
      response => dispatch(getSprintV2EligibilitySuccess(response)),
      error => {
        dispatch(getSprintV2EligibilityFailure(error));
        return Promise.reject(error);
      }
    );
  };
}

export const postLoginRoutingForSprintV2 = () => (dispatch, getState) => {
  const roadblockRoute = getIsTMobileFeatureOn(getState())
    ? routes.TMOBILE_ROADBLOCK
    : routes.SPRINTV2_ROADBLOCK;
  return dispatch(
    routeReplace(
      getIsMissingPlans(getState()) ? roadblockRoute : routes.PLAN_SELECT
    )
  );
};

export function getSprintV2EligibilityStart() {
  return {
    type: types.GET_SPRINTV2_ELIGIBILITY.START,
  };
}

export function getSprintV2EligibilitySuccess(response) {
  return {
    type: types.GET_SPRINTV2_ELIGIBILITY.SUCCESS,
    payload: response,
  };
}

export function getSprintV2EligibilityFailure(error) {
  return {
    type: types.GET_SPRINTV2_ELIGIBILITY.FAILURE,
    payload: error,
  };
}

export function resetSprintV2Eligibility() {
  return {
    type: types.GET_SPRINTV2_ELIGIBILITY.RESET,
  };
}
