import {
  ROUTE_PUSH,
  ROUTE_POP,
  ROUTE_REPLACE,
  URL_REDIRECT,
  URL_REPLACE,
} from '../constants/actionTypes';
import {
  push as routePush,
  pop as routePop,
  replace as routeReplace,
} from '../utils/flowControl';
import { redirect as urlRedirect, replace as urlReplace } from '../utils/url';

export const routingMiddleware = () => next => action => {
  const actionType = action && action.type;

  if (actionType) {
    if (actionType === ROUTE_PUSH) {
      routePush(action.route);
    } else if (actionType === ROUTE_POP) {
      routePop();
    } else if (actionType === ROUTE_REPLACE) {
      routeReplace(action.route);
    } else if (actionType === URL_REDIRECT) {
      urlRedirect(action.url, action.params);
    } else if (actionType === URL_REPLACE) {
      urlReplace(action.url);
    }
  }

  return next(action);
};
