import { date, random } from 'faker/locale/en';
import {
  FAKE_CREDITCARD,
  FAKE_PASSWORD,
  FAKE_ZIPCODE,
  FAKE_NAME,
} from './dataConstants';

export const getRandomNumber = max => {
  return random.number(max);
};

export const getRandomDate = (inputDate, min) => {
  inputDate.setFullYear(inputDate.getFullYear() - min);
  const randomDate = date.past(50, `${inputDate}`);
  return {
    month: randomDate.getMonth(),
    date: randomDate.getDate(),
    year: randomDate.getFullYear(),
  };
};

export const getRandomUser = () => {
  const email = `fastpass.${
    process.env.NODE_ENV
  }.${new Date().getTime()}@hulu.com`;
  return {
    email,
    name: FAKE_NAME,
    password: FAKE_PASSWORD,
    zipCode: FAKE_ZIPCODE,
  };
};

export const getRandomCreditCard = () => {
  const futureDate = date.future();
  const month = futureDate.getMonth() + 1;
  const year = futureDate
    .getFullYear()
    .toString()
    .substr(-2);
  const expiration = `${'0'}${month} / ${year}`.toString().substr(-7);
  return {
    number: FAKE_CREDITCARD,
    expiration,
    cvc: (random.number(10000) + 10000).toString().substring(1),
    zipCode: '90404',
  };
};
