import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { SPACE } from '../constants/keyboardKeys';

class Checkbox extends Component {
  constructor(props) {
    super(props);

    this.keyPressHandler = this.keyPressHandler.bind(this);
  }

  keyPressHandler(e) {
    if (e.key === SPACE) {
      e.preventDefault();
      e.stopPropagation();
      this.props.onChange();
    } else {
      this.props.onKeyPress(e);
    }
  }

  render() {
    const cname = this.props.checked ? 'checkbox checked' : 'checkbox';
    return (
      <div
        id={this.props.id}
        className="input__checkbox"
        onClick={this.props.onChange}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        onKeyPress={this.keyPressHandler}
        tabIndex="0"
      >
        <div className="checkbox-container">
          <span className={cname} />
        </div>
        <div
          className="checkbox-text"
          aria-label={this.props.label || this.props.text}
        >
          {this.props.text || this.props.children}
        </div>
      </div>
    );
  }
}

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  id: PropTypes.string,
  text: PropTypes.string.isRequired,
  label: PropTypes.string,
  children: PropTypes.node,
};

export default Checkbox;
