import config from '../constants/paypal';

function paypal() {
  if (!window.paypal) {
    throw new Error('window.paypal has not been initialized.');
  }

  return window.paypal;
}

/**
 * Helper method for starting a paypal flow.
 */
export function startFlow(token) {
  paypal().checkout.startFlow(token);
}

/**
 * Helper method for closing a paypal flow.
 */
export function closeFlow() {
  paypal().checkout.closeFlow();
}

/**
 * Helper method for beginning asynchronous
 * AJAX control.
 */
export function initXO() {
  paypal().checkout.initXO();
}

/**
 * Initialize the paypal module.
 *
 * For more information on options that PayPal's checkout setup function can take,
 * see: https://developer.paypal.com/docs/classic/express-checkout/in-context/javascript_advanced_settings/
 *
 * @param {string|Node} param.container A query selector for the DOM node (or the node itself).
 * @param {boolean} param.isAsync Whether to initialize PayPal asynchronously, or immediately.
 * @param {Object} param.options Any extra options to pass to PayPal.
 * @return {Promise} a promise that completes when Paypal has finished initializing.
 */
export function init({ container, isAsync = true, ...options }) {
  const initFunc = () => {
    paypal().checkout.setup(config.merchantId, {
      environment: config.environment,
      container,
      ...options,
    });
  };

  let promise;
  if (isAsync) {
    promise = new Promise(resolve => {
      window.paypalCheckoutReady = () => {
        initFunc();
        resolve();
      };
    });
  } else {
    initFunc();
    promise = Promise.resolve();
  }

  return promise;
}

export function loadSDK() {
  ((d, s, id) => {
    if (d.getElementById(id)) {
      return;
    }

    const sdk = d.createElement(s);
    sdk.id = id;
    sdk.src = '//www.paypalobjects.com/api/checkout.js';
    d.body.insertBefore(sdk, null);
  })(document, 'script', 'paypal-jssdk');
}
