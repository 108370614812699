export const populateField = (id, value) => {
  const element = document.getElementById(id);
  if (!element || element.disabled || element.value === value) return;
  element.focus();
  element.value = value;
  element.blur();
};

export const populateDropdown = (
  parentClass,
  desktopClass,
  mobileClass,
  value
) => {
  const option = document.querySelector(`${parentClass} [value="${value}"]`);
  const selector =
    document.querySelector(`${parentClass} ${desktopClass}`) ||
    document.querySelector(`${parentClass} ${mobileClass}`);

  if (!selector) return;

  if (selector.nodeName === 'SELECT') {
    if (!option || selector.value === value) return;
    selector.value = option.value;
    option.click();
  } else {
    if (!option || option.innerHTML === selector.innerHTML) return;
    option.click();
    selector.click();
  }
};

const delay = ms => new Promise(res => setTimeout(res, ms));

export const populateEdnaField = async (id, value, wait = 50) => {
  const element = document.getElementById(id);
  if (!element || element.disabled || element.value === value) return;

  // If wait time is specified, delay execution accordingly
  if (wait) {
    await delay(wait);
  }

  // Retrieve the current unchanged value
  const unchangedVal = element.value;

  const evt = new Event('input', { bubbles: true });
  // Simulate the input change event
  evt.simulated = true;

  element.value = value;

  // Force the input change event in React 16
  // eslint-disable-next-line no-underscore-dangle
  const tracker = element._valueTracker;
  if (tracker) {
    tracker.setValue(unchangedVal);
  }

  element.dispatchEvent(evt);
};

export const populateEdnaDropdown = (id, value) => {
  // Find the corresponding label for this dropdown
  const label = document.getElementById(`${id}-label`);

  if (!label) return;

  // The dropdown options will be displayed when the label is clicked
  label.click();

  // Wait 50ms for the options to be fully rendered
  setTimeout(() => {
    // Choose the option corresponding to the provided value
    const option = document.getElementById(`${id}-option-${value}`);
    if (option) {
      option.click();
    }
  }, 50);
};
