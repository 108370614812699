export const MAX_RESTAPI_FIELD_LENGTH = 254;

export const REQUIRED = val => val && val.length <= MAX_RESTAPI_FIELD_LENGTH;
export const NAME = val => val && val.trim();
export const CVC = val => val && /^(?:\d{3,4})$/.test(val);
export const ZIP = val => val && val.length === 5 && /^[0-9\b]+$/.test(val);
export const PASSWORD = val => val && val.trim().length > 5;
export const CHECKBOX = val => val;
export const EMAIL = val =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);

export const USER_FIELDS_ERRORS = [
  'email-error',
  'password-error',
  'firstName-error',
  'birthdayMonth-error',
  'birthdayDay-error',
  'birthdayYear-error',
  'gender-error',
];
export const BILLING_FIELDS_ERRORS = [
  'creditCard-error',
  'expiry-error',
  'cvc-error',
  'zip-error',
];

/**
 * Returns true for a valid cardholder name, otherwise false.
 * The goal is to alert user if they accidentally enter a CC # instead of their name.
 *
 * @param value - This is the user input for the name on card field.
 */
export function validateNameOnCard(value) {
  const fifteenOrMore = value?.length >= 15;
  const containsNumberOrSymbol = /[\d!#$-&'()*+,:;<=>?@^|]/.test(value);
  const containsLetters = /[A-Za-z]/.test(value); // if the input is shorter than a cc number or contains letters,
  // we can assume it is a name
  if (!fifteenOrMore || containsLetters) {
    return true;
  } // otherwise, if the input is 15+ characters and does not have letters,
  //  and does have numbers and symbols,
  //  we can assume that they entered a cc number on accident.
  return !containsNumberOrSymbol;
}
