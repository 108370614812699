import { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';

import { evaluateBreakpoint } from '../utils/breakpoints';

const SIXTY_FPS = 16; // (in ms) the rate of requestAnimationFrame

export function useWindowSize() {
  const getSize = () => {
    const width = window.innerWidth || undefined;
    const height = window.innerHeight || undefined;
    return {
      width,
      height,
      breakpoint: evaluateBreakpoint(width),
    };
  };

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    function onResize() {
      setWindowSize(getSize());
    }

    const handleResize = throttle(onResize, SIXTY_FPS);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}
