import { createSelector } from 'reselect';
import get from 'lodash/get';
import camelCase from 'lodash/camelCase';
import { getVerizonViaDssText } from './siteconfig';
import { SWITCH_ELIGIBILITY_REASON } from '../constants/bundlePartnerSwitchEligibilityStatuses';
import { getIsActiveSubscriber, getIsLoggedIn } from './user';
import { getIsVerizonDssFlow } from './flow';

export const getBundlePartnerSwitchEligibility = state =>
  get(state, 'bundlePartnerSwitchEligibility');

export const getIsLoading = createSelector(
  [getBundlePartnerSwitchEligibility],
  bundlePartnerSwitchEligibility => {
    return get(bundlePartnerSwitchEligibility, 'response.loading');
  }
);

export const getShouldCheckSwitchEligibility = createSelector(
  [getIsLoggedIn, getIsActiveSubscriber, getIsVerizonDssFlow],
  (isLoggedIn, isActiveSubscriber, isVerizonViaDssFlow) =>
    isLoggedIn && isActiveSubscriber && isVerizonViaDssFlow
);

export const getEligibilityReason = createSelector(
  [getBundlePartnerSwitchEligibility],
  eligibility => {
    const reasonArray = get(eligibility, 'response.payload.reason', []);
    if (reasonArray.includes(SWITCH_ELIGIBILITY_REASON.THIRD_PARTY_NON_DSS)) {
      return SWITCH_ELIGIBILITY_REASON.THIRD_PARTY_NON_DSS;
    }
    return reasonArray[0];
  }
);

export const getBundlePartnerSwitchEligibilityTextForReason = createSelector(
  [getEligibilityReason, state => path => getVerizonViaDssText(state, path)],
  (eligibilityReason, getBundlePartnerSwitchTextByPath) => {
    return (
      (eligibilityReason &&
        getBundlePartnerSwitchTextByPath(
          `roadblock.eligibility.${camelCase(eligibilityReason)}`
        )) ||
      getBundlePartnerSwitchTextByPath(`roadblock.error`)
    );
  }
);
