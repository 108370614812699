import { createReducer } from 'redux-starter-kit';

import { GET_SUPERBUNDLE_ELIGIBILITY } from '../constants/actionTypes';

export const initialState = {
  eligibility: null,
  error: null,
  loading: false,
};

export default createReducer(initialState, {
  [GET_SUPERBUNDLE_ELIGIBILITY.START]: () => ({
    eligibility: null,
    loading: true,
    error: null,
  }),
  [GET_SUPERBUNDLE_ELIGIBILITY.SUCCESS]: (_, { payload }) => ({
    eligibility: payload,
    loading: false,
    error: null,
  }),
  [GET_SUPERBUNDLE_ELIGIBILITY.FAILURE]: (_, { payload }) => ({
    eligibility: null,
    loading: false,
    error: payload,
  }),
});
