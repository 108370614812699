import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import first from 'lodash/first';
import noop from 'lodash/noop';

import Loading from '../components/Loading';
import MarkdownRoadblock from '../components/MarkdownRoadblock';
import { getExternalIdentityVerificationResult } from '../selectors/flow';
import {
  hasIdentityVerificationError,
  getIdentityRoadblock,
} from '../selectors/identity';
import { getPlans } from '../selectors/plans';
import { isStudentPlan } from '../selectors/plan';
import * as routes from '../constants/routes';
import { VERIFICATION_RESULT_SUCCESS } from '../constants/identity';
import { getIsLoggedIn } from '../selectors/user';
import { updateSelectedPlan } from '../actions/planSelectActions';
import { routeReplace, urlRedirect } from '../actions/routingActions';
import { handleIdentityVerification } from '../actions/identityActions';

export const IdentityVerificationPage = ({
  isLoggedIn,
  handleUnauthorizedUsers,
  plan,
  selectPlan,
  verifyIdentity,
  error,
  roadblock,
  onRoadblockClick,
  verificationResult,
}) => {
  useEffect(() => {
    if (error) return;

    if (isStudentPlan(plan)) {
      selectPlan(plan);
    }

    if (isLoggedIn) {
      verifyIdentity(verificationResult);
    } else {
      handleUnauthorizedUsers();
    }
  }, []);

  if (error) {
    return (
      <MarkdownRoadblock
        {...roadblock}
        handleClick={() => onRoadblockClick(roadblock.redirectUrl)}
      />
    );
  }

  return <Loading />;
};

IdentityVerificationPage.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  handleUnauthorizedUsers: PropTypes.func.isRequired,
  plan: PropTypes.object.isRequired,
  selectPlan: PropTypes.func.isRequired,
  verifyIdentity: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  roadblock: PropTypes.object.isRequired,
  onRoadblockClick: PropTypes.func.isRequired,
  verificationResult: PropTypes.string,
};

const mapStateToProps = state => ({
  isLoggedIn: getIsLoggedIn(state),
  error: hasIdentityVerificationError(state),
  plan: first(getPlans(state)),
  roadblock: getIdentityRoadblock(state),
  verificationResult: getExternalIdentityVerificationResult(state),
});

const mapDispatchToProps = dispatch => ({
  selectPlan: plan => dispatch(updateSelectedPlan(plan)),
  handleUnauthorizedUsers: () => dispatch(routeReplace(routes.LOGIN)),
  verifyIdentity: async verificationResult => {
    try {
      if (verificationResult === VERIFICATION_RESULT_SUCCESS) {
        await dispatch(handleIdentityVerification());
        dispatch(routeReplace(routes.BILLING_INFO));
      } else {
        dispatch(urlRedirect(routes.GO_ONE_HULU));
      }
    } catch (error) {
      // Error stored in redux store will be reflected via the `error` prop and render a roadblock on this page.
      noop(error);
    }
  },
  onRoadblockClick: redirectUrl => dispatch(urlRedirect(redirectUrl)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IdentityVerificationPage);
