import {
  CLEAR_LOCATION_QUERY,
  SAVE_INITIAL_LOCATION,
  REPLACE_LOCATION_QUERY,
} from '../constants/actionTypes';
import { setCartAbandonmentCookie } from '../utils/cartAbandonmentUtils';
import { urlRedirect } from './routingActions';
import * as routes from '../constants/routes';

export function saveInitialLocation(location) {
  return {
    type: SAVE_INITIAL_LOCATION,
    location,
  };
}

export function clearLocationQuery() {
  return {
    type: CLEAR_LOCATION_QUERY,
  };
}

export function replaceLocationQuery(query) {
  return {
    type: REPLACE_LOCATION_QUERY,
    query,
  };
}

/**
 * Safely redirects a user to the default Plan Select page by clearing any
 * state that would prevent them to do so.
 */
export function redirectToPlanSelect() {
  return async (dispatch, getState) => {
    // Clear selected plan in CAE by replacing value with user name only.
    setCartAbandonmentCookie(getState(), true);

    // Hard redirect to Plan Select to prompt a fresh backend call with plans.
    dispatch(urlRedirect(routes.PLAN_SELECT));
  };
}
