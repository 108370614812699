import { YOKOZUNA_API } from '../constants/apis';
import request from './request';

export function validateAccount(validateRequest) {
  return request(`${YOKOZUNA_API}accounts/validate`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(validateRequest),
  });
}

export default validateAccount;
