import request from './request';
import YOKOZUNA_API from '../constants/apis';

export function createUser(userRequest) {
  return request(`${YOKOZUNA_API}users`, {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userRequest),
  });
}

export default createUser;
