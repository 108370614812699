import { QueryClientProvider, UnifiedLogin } from '@hulu/web-login-ui';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import Loading from '../components/Loading';
import { LoginAppRouter } from '../utils/buildAppRouter';
import { metricsManager } from '../metrics/MetricsManager';
import { getUnifiedFooterConfig } from '../utils/getUnifiedFooterConfig';
import {
  getIsHuluClientSignupAgeLimitFeatureEnabled,
  getIsOneTrustScriptEnabled,
} from '../selectors/featureFlag';
import { getEmail } from '../selectors/user';
import { getIsBundlePartnerFlow } from '../selectors/flow';
import { ACCOUNT_INFO } from '../constants/routes';

const LoginPage = ({
  onLoaded,
  onLoginCallback,
  shouldRedirectToIDMLogin,
  shouldSkip,
  shouldEnableReviewAndAcceptPage,
}) => {
  useEffect(() => {
    onLoaded();
  }, []);

  const history = useHistory();

  // Indicate if the flag for the OneTrust script is enabled
  const isOneTrustScriptEnabled = useSelector(getIsOneTrustScriptEnabled);

  // Indicate if the flag for Hulu Client Singup Age limit is enabled
  const isHuluClientSignupAgeLimitFeatureEnabled = useSelector(
    getIsHuluClientSignupAgeLimitFeatureEnabled
  );

  const isActivationFlow = useSelector(getIsBundlePartnerFlow);
  // The email field would not be editable when the user is in the activation flow
  const isEditable = !isActivationFlow;

  // Email stored within user node
  const userEmail = useSelector(getEmail);

  const onRedirectToCreateAccount = useCallback(() => {
    history.push(ACCOUNT_INFO);
  }, []);

  // Show loading splash if the page will redirect away.
  if (shouldRedirectToIDMLogin || shouldSkip) {
    return <Loading />;
  }

  // Obtain the environment variable and pass it to the `UnifiedCreateAccount` component.
  // This approach is inspired by the integration with the `WebLogin`.
  // For reference:
  // https://github.prod.hulu.com/SUMO/sufo-redux/blob/develop/src/app/pages/LoginPage.js#L35-L41
  const env = {
    development: 'dev',
    staging: 'staging',
    dogfood: 'staging',
    smoke: 'smoke',
    production: 'prod',
  }[process.env.NODE_ENV];

  // Show the unified login UI/UX (the "Edna" login flow) if the feature is enabled.
  const config = {
    disableCreateAccount: false,
    env,
    isEditable,
    onRedirectToCreateAccount,
    router: LoginAppRouter().build(),
    shouldEnableMinorBlock: isHuluClientSignupAgeLimitFeatureEnabled,
    trackEvent: metricsManager.trackEvent,
    enableReviewAndAcceptPage: shouldEnableReviewAndAcceptPage,
    userEmail,
  };

  return (
    <div className="web-login-ui-page">
      <QueryClientProvider>
        <UnifiedLogin
          config={config}
          footerConfig={getUnifiedFooterConfig(isOneTrustScriptEnabled)}
          onLoggedIn={onLoginCallback}
        />
      </QueryClientProvider>
    </div>
  );
};

LoginPage.propTypes = {
  onLoaded: PropTypes.func.isRequired,
  onLoginCallback: PropTypes.func.isRequired,
  shouldRedirectToIDMLogin: PropTypes.bool.isRequired,
  shouldSkip: PropTypes.bool.isRequired,
  shouldEnableReviewAndAcceptPage: PropTypes.bool.isRequired,
};

export default LoginPage;
