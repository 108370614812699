import React from 'react';
import PropTypes from 'prop-types';

export default class ActionError extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { hasAction, action } = this.props;
    if (hasAction) {
      action();
    }
  }

  render() {
    return (
      <div
        onClick={this.handleClick}
        className={this.props.hasAction ? 'errors errors--action' : 'errors'}
      >
        {this.props.children}
      </div>
    );
  }
}

ActionError.propTypes = {
  action: PropTypes.func.isRequired,
  hasAction: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
