import React from 'react';

import Plan from './Plan';
import '../styles/valuePropBullets.scss';

/**
 * This component will set plan props unique to experiment HULU-14131.
 *
 * planClass: class name for experiment styling
 * description: If bulletedDescription prop is undefined, use default plan description.
 */
const ValuePropsBulletsPlan = props => {
  const { bulletedDescription, description } = props;

  return (
    <Plan
      {...props}
      planClass="value-props-bullets-plan"
      description={bulletedDescription || description}
    />
  );
};

ValuePropsBulletsPlan.propTypes = Plan.propTypes;

export { ValuePropsBulletsPlan };
