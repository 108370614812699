import { parse } from 'query-string';
import get from 'lodash/get';

import {
  GET_SPRINTV2_ELIGIBILITY,
  GET_SPOTIFY_ELIGIBILITY,
  SET_IS_EXISTING_USER,
  SAVE_INITIAL_LOCATION,
  GET_PARTNER_OFFER_CONTEXT,
} from '../constants/actionTypes';
import { PARTNERSHIP } from '../constants/partners';
import { PARTNER_LANDING } from '../constants/routes';

export const initialState = {
  sprintV2Eligibility: {
    response: null,
    error: null,
    loading: false,
  },
  spotifyEligibility: {
    response: null,
    error: null,
    loading: false,
  },
  partnerOffer: {
    id: null,
    key: null,
    response: {
      context: null,
      loading: false,
      error: null,
    },
  },
};

function partner(state = initialState, action) {
  switch (action.type) {
    case GET_SPRINTV2_ELIGIBILITY.START:
      return {
        ...state,
        sprintV2Eligibility: { ...state.sprintV2Eligibility, loading: true },
      };
    case GET_SPRINTV2_ELIGIBILITY.SUCCESS:
      return {
        ...state,
        sprintV2Eligibility: {
          response: action.payload,
          error: null,
          loading: false,
        },
      };
    case GET_SPRINTV2_ELIGIBILITY.FAILURE:
      return {
        ...state,
        sprintV2Eligibility: {
          response: null,
          error: action.payload,
          loading: false,
        },
      };
    case GET_SPRINTV2_ELIGIBILITY.RESET:
      return {
        ...state,
        sprintV2Eligibility: { response: null, error: null, loading: false },
      };
    case GET_SPOTIFY_ELIGIBILITY.START:
      return {
        ...state,
        spotifyEligibility: { ...state.spotifyEligibility, loading: true },
      };
    case GET_SPOTIFY_ELIGIBILITY.SUCCESS:
      return {
        ...state,
        spotifyEligibility: {
          response: action.payload,
          error: null,
          loading: false,
        },
      };
    case GET_SPOTIFY_ELIGIBILITY.FAILURE:
      return {
        ...state,
        spotifyEligibility: {
          response: null,
          error: action.payload,
          loading: false,
        },
      };
    case GET_SPOTIFY_ELIGIBILITY.RESET:
      return {
        ...state,
        spotifyEligibility: { response: null, error: null, loading: false },
      };
    case SET_IS_EXISTING_USER:
      return { ...state, isExistingUser: action.payload };
    case SAVE_INITIAL_LOCATION: {
      const pathname = get(action, ['location', 'pathname'], '');
      const query = parse(get(action, ['location', 'search'], ''));
      return {
        ...state,
        partnerOffer: {
          ...state.partnerOffer,

          id: query[PARTNERSHIP.OFFER_ID_PARAM] || state.partnerOffer.id,
          key: pathname.includes(PARTNER_LANDING)
            ? pathname
                .split('/')
                .pop()
                .toLowerCase()
            : state.partnerOffer.key,
        },
      };
    }
    case GET_PARTNER_OFFER_CONTEXT.START:
      return {
        ...state,
        partnerOffer: {
          ...state.partnerOffer,
          response: {
            context: null,
            loading: true,
            error: null,
          },
        },
      };
    case GET_PARTNER_OFFER_CONTEXT.SUCCESS:
      return {
        ...state,
        partnerOffer: {
          ...state.partnerOffer,
          response: {
            context: action.payload,
            loading: false,
            error: null,
          },
        },
      };
    case GET_PARTNER_OFFER_CONTEXT.FAILURE:
      return {
        ...state,
        partnerOffer: {
          ...state.partnerOffer,
          response: {
            context: null,
            loading: false,
            error: action.payload,
          },
        },
      };
    default:
      return state;
  }
}

export default partner;
