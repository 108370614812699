import { connect } from 'react-redux';

import {
  handleBillingPageLoaded,
  handleBillingPageTabSelect,
  handleBillingPageSubmit,
} from '../actions/billingActions';
import track from '../metrics/track';
import BillingInfoPage from '../pages/BillingInfoPage';
import {
  getIsBillingPageLoading,
  getBillingCtaButtonText,
  getIsSkipBillingFlow,
  getBillingTabIndex,
} from '../selectors/billingPage';
import {
  getIsGoogleAndroid,
  getIsUnifiedActivationFlow,
} from '../selectors/flow';
import {
  getDisplayPaymentFields,
  getVermontPrepaidConsentText,
} from '../selectors/ledger';
import { getControlText } from '../selectors/siteconfig';
import {
  canSubmitBillingInfo,
  getCreateSubscriberInProgress,
} from '../selectors/subscription';
import { getSelectedPlan } from '../selectors/user';
import { getIsIneligibleDiscount } from '../selectors/warning';
import { isCuriosityIncluded } from '../selectors/plan';

const mapStateToProps = state => ({
  tabIndex: getBillingTabIndex(state),
  isLoading: getIsBillingPageLoading(state),
  canSubmit: canSubmitBillingInfo(state),
  createSubscriberInProgress: getCreateSubscriberInProgress(state),
  isCuriosityIncluded: isCuriosityIncluded(getSelectedPlan(state)),
  isIneligibleDiscount: getIsIneligibleDiscount(state),
  displayPaymentFields: getDisplayPaymentFields(state),
  onlyCCSupported: getIsGoogleAndroid(state),
  buttonText: getBillingCtaButtonText(state),
  liveConsent: getControlText(state, 'live_consent'),
  isSkipBillingFlow: getIsSkipBillingFlow(state),
  vermontConsent: getVermontPrepaidConsentText(state),
  isUnifiedActivationFlow: getIsUnifiedActivationFlow(state),
});

const mapDispatchToProps = dispatch => ({
  onLoaded: () => dispatch(handleBillingPageLoaded()),
  onTabSelect: index => dispatch(handleBillingPageTabSelect(index)),
  onSubmit: () => dispatch(handleBillingPageSubmit()),
});

export default track(
  connect(mapStateToProps, mapDispatchToProps)(BillingInfoPage)
);
