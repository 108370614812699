import { actions } from 'react-redux-form';
import { connect } from 'react-redux';
import get from 'lodash/get';
import CreditCardField from '../components/CreditCardField';

export default connect(
  state => ({
    creditCardNumber: get(state, 'forms.payment.creditCard.value', ''),
  }),
  dispatch => ({
    onValueChanged: (name, value) =>
      dispatch(actions.change(name || 'payment.creditCard', value)),
  })
)(CreditCardField);
