import { connect } from 'react-redux';
import { getShouldLogin } from '../selectors/login';
import {
  getBundlesForSelectedPlan,
  getBasePlanForSelectedBundle,
  getHasUpsellPage,
} from '../selectors/plans';
import { getSelectedPlan } from '../selectors/user';
import BundleSelectPage from '../pages/BundleSelectPage';
import { updateSelectedPlan } from '../actions/planSelectActions';
import { routePush } from '../actions/routingActions';
import track from '../metrics/track';

const mapStateToProps = state => ({
  availableBundles: getBundlesForSelectedPlan(state),
  availableBasePlan: getBasePlanForSelectedBundle(state),
  shouldLogin: getShouldLogin(state),
  selectedPlan: getSelectedPlan(state),
  hasUpsellPage: getHasUpsellPage(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  gotToPage: route => dispatch(routePush(route)),
  updatePlan: plan => dispatch(updateSelectedPlan(plan)),
});

export default track(
  connect(mapStateToProps, mapDispatchToProps)(BundleSelectPage)
);
