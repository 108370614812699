import { getConfig } from './configActions';
import { routeReplace } from './routingActions';
import { getSiteConfig } from './siteConfigActions';
import { refreshSessionCookie } from '../api/Login';
import { getCookie, HULU_DT, HULU_SESSION } from '../utils/cookieUtils';
import { getFeatureFlags } from './featureFlagActions';
import { saveInitialLocation } from './urlActions';
import { BOOTSTRAP } from '../constants/actionTypes';
import {
  ACCOUNT_INFO,
  ERROR,
  GO_REACQ,
  LOGIN,
  PLAN_SELECT,
  SPRINT_LANDING,
  SPRINTV2_LANDING,
  TMOBILE,
  TMOBILE_LANDING,
} from '../constants/routes';
import { updateSelectedPlan, selectDefaultPlanTab } from './planSelectActions';
import { getIsTMobileFeatureOn } from '../selectors/featureFlag';
import {
  getFlowPathname,
  getFromParam,
  getIsInitPage,
  getIsPartnerFlow,
  getIsPartnerLandingPage,
  getIsPlanBuilderFlow,
  getIsUnifiedActivationPath,
  getIsUnifiedActivationFlow,
} from '../selectors/flow';
import { getShouldLogin } from '../selectors/login';
import { getPlans } from '../selectors/plans';
import { getIsLoggedIn } from '../selectors/user';
import { checkCartAbandonment } from './cartAbandonmentActions';
import { shouldSkipPlanSelectForCartAbandonment } from '../utils/cartAbandonmentUtils';
import * as MetricsEvents from '../metrics/metricsEvents';
import { checkPlanBuilder } from './planBuilderActions';
import { logout } from './serverActions';

export function triggerReload(location = window.location) {
  return dispatch => {
    dispatch(saveInitialLocation(location));
    return dispatch(runBootstrap());
  };
}

const fetchSessionCookie = () => {
  const deviceToken = getCookie(HULU_DT, true);
  const sessionToken = getCookie(HULU_SESSION, true);
  if (deviceToken && !sessionToken) {
    // if the user has a _hulu_dt cookie or a device token without _hulu_session
    // then the user can get a new session cookie from their device token
    // we want to make this call before config call so we can mark the user as logged in
    return refreshSessionCookie();
  }
  return Promise.resolve();
};

export function runBootstrap() {
  return dispatch =>
    Promise.resolve()
      .then(() => dispatch(runBootstrapStart()))
      .then(() => fetchSessionCookie())
      .then(() =>
        Promise.all([
          dispatch(getFeatureFlags()),
          dispatch(getSiteConfig()),
          dispatch(handleBootstrapConfigCall()),
        ])
      )
      .then(() => dispatch(selectDefaultPlanTab()))
      .then(() => dispatch(checkCartAbandonment()))
      .then(() => dispatch(checkPlanBuilder()))
      .then(() => dispatch(handleBootstrapRouting()))
      .then(
        () => dispatch(runBootstrapSuccess()),
        error => {
          dispatch(routeReplace(ERROR));
          return dispatch(runBootstrapFailure(error));
        }
      );
}

/**
 * Only skip the config call during bootstrapping if we are on a partner landing page.
 */
export function handleBootstrapConfigCall() {
  return (dispatch, getState) => {
    // Skip the config call if the user is in a legacy partner flow
    if (getIsPartnerLandingPage(getFlowPathname(getState()))) {
      return Promise.resolve();
    }

    // Logout before making the call if the user is in the Unified Activation flow.
    // This mirrors the behavior of Disney+ and discards legacy eligibility responses.
    const shouldLogout =
      getIsLoggedIn(getState()) && getIsUnifiedActivationPath(getState());

    return Promise.resolve()
      .then(() => (shouldLogout ? dispatch(logout()) : Promise.resolve()))
      .then(() => dispatch(getConfig()));
  };
}

export function handleBootstrapRouting() {
  return (dispatch, getState, { trackEvent }) => {
    const flowPathname = getFlowPathname(getState());

    if (getFromParam(getState())) {
      trackEvent(MetricsEvents.FROM_PARAM_LANDING);
    }

    if (
      !getIsPartnerLandingPage(flowPathname) &&
      !getIsUnifiedActivationPath(getState()) &&
      !getIsPlanBuilderFlow(getState())
    ) {
      trackEvent(MetricsEvents.SUBSCRIPTION_START);
    }

    if (getShouldLogin(getState()) || flowPathname === GO_REACQ) {
      // Select a plan if the user is in the Activation flow.
      // This is necessary if the user enters an email without an existing account in the login flow
      // and gets redirected to account creation again.
      // Otherwise, the Create Account step would send the user back to the Plan Select page.
      const isUnifiedActivationFlow = getIsUnifiedActivationFlow(getState());

      if (isUnifiedActivationFlow) {
        // Select any standalone plan, it will be discarded when redirected to CUP, but
        // we need something selected in order to not get kicked out of Account Creation.
        const planToSelect = getPlans(getState()).find(
          plan => !plan.isDisneySuperBundle
        );

        dispatch(updateSelectedPlan(planToSelect));
      }

      return dispatch(routeReplace(LOGIN));
    }

    if (shouldSkipPlanSelectForCartAbandonment(getState())) {
      return dispatch(routeReplace(ACCOUNT_INFO));
    }

    if (!getIsPartnerFlow(getState()) && getIsInitPage(getState())) {
      return dispatch(routeReplace(PLAN_SELECT));
    }

    // Replace Sprint/T-Mobile landing page route based on if T-Mobile FF is on or off
    if (getIsTMobileFeatureOn(getState())) {
      switch (flowPathname) {
        case SPRINT_LANDING:
          return dispatch(routeReplace(TMOBILE));
        case SPRINTV2_LANDING:
          return dispatch(routeReplace(TMOBILE_LANDING));
        default:
          break;
      }
    } else {
      switch (flowPathname) {
        case TMOBILE:
          return dispatch(routeReplace(SPRINT_LANDING));
        case TMOBILE_LANDING:
          return dispatch(routeReplace(SPRINTV2_LANDING));
        default:
          break;
      }
    }

    return dispatch(routeReplace(flowPathname));
  };
}

export function runBootstrapStart() {
  return {
    type: BOOTSTRAP.START,
  };
}

export function runBootstrapSuccess() {
  return {
    type: BOOTSTRAP.SUCCESS,
  };
}

export function runBootstrapFailure(error) {
  return {
    type: BOOTSTRAP.FAILURE,
    payload: error,
  };
}
