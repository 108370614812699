import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { preventFocus } from '../utils/a11y';

const PlanGroupTabs = ({ tabOptions, onTabSelect, planTabIndex }) => (
  <div className="plan-group__tablist" data-testid="plan-group-tablist">
    {tabOptions.map(({ index, text }) => (
      <button
        aria-label={`${text} Tab`}
        className={classNames(
          'plan-group__tab',
          planTabIndex === index && 'active-tab'
        )}
        data-testid="plan-group-tab"
        key={index}
        onClick={() => onTabSelect(index)}
        onMouseDown={preventFocus}
        type="button"
      >
        {text}
      </button>
    ))}
  </div>
);

PlanGroupTabs.propTypes = {
  onTabSelect: PropTypes.func.isRequired,
  planTabIndex: PropTypes.string.isRequired,
  tabOptions: PropTypes.array.isRequired,
};

export default PlanGroupTabs;
