import { YOKOZUNA_API } from '../constants/apis';
import {
  DPLUS_TEXT_KEY_ROOT,
  DPLUS_PPV_TEXT_KEY_ROOT,
  EPLUS_TEXT_KEY_ROOT,
  FRONT_END_PAYMENTS_KEY_ROOT,
  MAINTENANCE_PAGE_ROOT,
  PARTNER_TEXT_KEY_ROOT,
  REDIRECTS_ROOT,
  TEXT_CONTROLS_KEY,
  VERIZON_VIA_DSS_TEXT_KEY_ROOT,
  MAX_BUNDLE_TEXT_KEY_ROOT,
} from '../constants/siteConfigKeys';
import request from './request';
import { stringify } from '../utils/querystring';

/**
 * Performs a network call to siteconfig service and returns data.
 *
 * @param {string} partner - if set, gets additional data for partnerships text
 */
export function getSiteConfiguration(partner) {
  const isProd =
    process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'smoke';
  const metricsTrackerEnv = isProd ? 'production' : 'development';
  const nodes = [
    `client_metrics_config.${metricsTrackerEnv}`,
    'sufo_plans',
    TEXT_CONTROLS_KEY,
    FRONT_END_PAYMENTS_KEY_ROOT,
    DPLUS_TEXT_KEY_ROOT,
    DPLUS_PPV_TEXT_KEY_ROOT,
    EPLUS_TEXT_KEY_ROOT,
    MAX_BUNDLE_TEXT_KEY_ROOT,
    VERIZON_VIA_DSS_TEXT_KEY_ROOT,
    MAINTENANCE_PAGE_ROOT,
    REDIRECTS_ROOT,
  ];
  if (partner) {
    nodes.push(`${PARTNER_TEXT_KEY_ROOT}_default`);
    nodes.push(`${PARTNER_TEXT_KEY_ROOT}_${partner}`);
  }
  return request(`${YOKOZUNA_API}siteconfig?${stringify({ key: nodes })}`, {
    method: 'GET',
    credentials: 'include',
  });
}
