import { connect } from 'react-redux';

import { getConfig } from '../actions/configActions';
import {
  handleSuccessfulLogin,
  loggedInRoutingLogic,
} from '../actions/loginPageActions';
import { urlRedirect } from '../actions/routingActions';
import { loginSuccess } from '../actions/serverActions';
import { getCurrentHostUrl } from '../actions/identityActions';

import { url } from '../../../config/env';
import * as routes from '../constants/routes';
import * as MetricsEvents from '../metrics/metricsEvents';
import track from '../metrics/track';
import LoginPage from '../pages/LoginPage';

import {
  getIsBundlePartnerFlow,
  getIsReacqFlow,
  getPartnerFlow,
} from '../selectors/flow';
import { getIsHiSwitchFlow } from '../selectors/siteconfig';
import {
  getIsUnifiedIdentityOn,
  getShouldEnableReviewAndAcceptPage,
  getShouldRedirectToIDMLogin,
} from '../selectors/login';
import { getIsUpdatedGenderOptionOn } from '../selectors/featureFlag';

function mapStateToProps(state) {
  const { user, payment } = state;
  return {
    user,
    payment,
    partnerFlow: getPartnerFlow(state),
    isHiSwitchFlow: getIsHiSwitchFlow(state),
    isReacqFlow: getIsReacqFlow(state),
    disableEmail: getIsBundlePartnerFlow(state),
    shouldRedirectToIDMLogin: getShouldRedirectToIDMLogin(state),
    shouldUseUpdatedGenderOptions: getIsUpdatedGenderOptionOn(state),
    shouldUseWebLoginComponent: getIsUnifiedIdentityOn(state),
    shouldEnableReviewAndAcceptPage: getShouldEnableReviewAndAcceptPage(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mergeProps(
  {
    user,
    partnerFlow,
    isReacqFlow,
    disableEmail,
    shouldRedirectToIDMLogin,
    shouldUseUpdatedGenderOptions,
    shouldUseWebLoginComponent,
    shouldEnableReviewAndAcceptPage,
  },
  { dispatch, goToAccountInfo, ...dispatchProps },
  ownProps
) {
  return {
    ...ownProps,
    user,
    disableEmail,
    isReacqFlow,
    partnerFlow,
    shouldRedirectToIDMLogin,
    shouldUseUpdatedGenderOptions,
    shouldUseWebLoginComponent,
    shouldEnableReviewAndAcceptPage,
    shouldSkip: user.loggedIn || false,
    onLoginCallback: () => {
      dispatch(loginSuccess());
      dispatch(getConfig())
        .then(updatedConfig => {
          dispatch(handleSuccessfulLogin(updatedConfig, user));
        })
        .catch(error => {
          throw new Error(`Failed to log in user: ${error}`);
        });
    },
    onLoaded: () => {
      if (user.loggedIn) {
        return dispatch(loggedInRoutingLogic(user));
      }

      /**
       * Check if IDM Login feature is on and Bundle Partner Activation and PPV flows are false
       * If Bundle Partner Activation flow is true, it must use WST login page
       * PPV must also use WST login page
       *
       * Currently, we use either of the 2 ways to log in a user:
       * - Using a Login component by the IDM team,
       * - Redirecting to an external Login page hosted by the IDM team.

       * The long-term goal is to always use the IDM Login component after our
       * dependencies are ready.
       * A flow chart for the current logic can be found below:
       * @see {@link https://lucid.app/lucidchart/709191f5-e3ea-4950-80f8-715cca31cd27/edit?beaconFlowId=84DAD4E027E247CB&invitationId=inv_5c49ec06-1a6f-41af-a1c7-51d766eeff9d&page=0_0#}
       */
      if (shouldRedirectToIDMLogin) {
        const returnUrl = `${getCurrentHostUrl()}${routes.LOGIN}`;
        return dispatch(
          urlRedirect(`${url.hoth.host}${url.hoth.login}`, { next: returnUrl })
        );
      }
      return ownProps.trackEvent(MetricsEvents.SUBSCRIPTION_STEP_START, {
        title: 'SUF - Login',
        pageId: 'login',
      });
    },
    ...dispatchProps,
  };
}

export default track(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(LoginPage)
);
