import * as types from '../constants/actionTypes';
import * as api from '../api/Ledger';
import { getLedgerRequest, getV2LedgerRequest } from '../selectors/ledger';
import { getTermsModalButtonText } from '../selectors/siteconfig';
import { hasAssignment } from '../selectors/config';
import { getSelectedPlan } from '../selectors/user';
import { getPlanOptions } from '../selectors/plans';
import {
  getIsSimplifiedLedgerEnabled,
  isFeatureEnabled,
} from '../selectors/featureFlag';
import { getIsIneligiblePromotion } from '../selectors/warning';
import {
  showDefaultModal,
  showLedgerEndpointFailureModal,
} from './modalActions';
import { removeAddon, removeAddonBundle } from './addonActions';
import { routePush } from './routingActions';
import { reselectPlan, selectPlan } from './serverActions';
import { PLAN_SELECT } from '../constants/routes';
import { BILLING as events } from '../metrics/metricsEvents';
import { PAGE_IDS } from '../metrics/tealium';
import { CAT_357 } from '../constants/experiments';

// Ledger API actions
export function getLedger() {
  return (dispatch, getState) => {
    dispatch(resetLedger());
    dispatch(getLedgerStart());

    // TODO WEB-9655: Productionalize -- Remove flag logic and replace all v1 ledger calls with v2 ledger calls
    const shouldShowSimplifiedLedger = getIsSimplifiedLedgerEnabled(getState());

    const currSelectedPlan = getSelectedPlan(getState());
    if (
      isFeatureEnabled(getState(), 'prepaid') &&
      hasAssignment(getState(), CAT_357.PREPAID_ON_BILLING) &&
      getPlanOptions(getState(), currSelectedPlan).length > 1
    ) {
      return Promise.all(
        getPlanOptions(getState(), currSelectedPlan).map(planOption => {
          if (!getIsIneligiblePromotion(getState())) {
            dispatch(selectPlan(planOption, { persistWarnings: true }));
            return shouldShowSimplifiedLedger
              ? api.getV2Ledger(getV2LedgerRequest(getState()))
              : api.getLedger(getLedgerRequest(getState()));
          }

          // The plan options that comes back with getPlanOptions() for a new user
          // is always going to have the free trial. So in the case that the user is not
          // eligible for the free trial promotion anymore, getPlanOptions() will not return
          // plans with no free trial, but currSelectedPlan is the plan returned from
          // createSubscriberFailure, and it is the plan previously selected by user but
          // with no free trial.
          if (planOption.subIdentifier === currSelectedPlan.subIdentifier) {
            dispatch(selectPlan(currSelectedPlan, { persistWarnings: true }));
            return shouldShowSimplifiedLedger
              ? api.getV2Ledger(getV2LedgerRequest(getState()))
              : api.getLedger(getLedgerRequest(getState()));
          }

          return null;
        })
      )
        .then(
          response => dispatch(getLedgerSuccess(response)),
          error => {
            dispatch(getLedgerFailure(error));
            dispatch(showLedgerEndpointFailureModal());
            return Promise.reject(error);
          }
        )
        .then(() =>
          dispatch(selectPlan(currSelectedPlan, { persistWarnings: true }))
        );
    }

    return shouldShowSimplifiedLedger
      ? api.getV2Ledger(getV2LedgerRequest(getState())).then(
          response => dispatch(getLedgerSuccess(response)),
          error => {
            dispatch(getLedgerFailure(error));
            dispatch(showLedgerEndpointFailureModal());
            return Promise.reject(error);
          }
        )
      : api.getLedger(getLedgerRequest(getState())).then(
          response => dispatch(getLedgerSuccess(response)),
          error => {
            dispatch(getLedgerFailure(error));
            dispatch(showLedgerEndpointFailureModal());
            return Promise.reject(error);
          }
        );
  };
}

export function getLedgerSuccess(payload) {
  return {
    type: types.GET_LEDGER.SUCCESS,
    payload,
  };
}

export function getLedgerStart() {
  return {
    type: types.GET_LEDGER.START,
  };
}

export function getLedgerFailure(error) {
  return {
    type: types.GET_LEDGER.FAILURE,
    payload: error,
  };
}

export function resetLedger() {
  return {
    type: types.GET_LEDGER.RESET,
  };
}

// Ledger UI actions
export const LEDGER_UI_ACTIONS = {
  showLedgerTermsModal,
  changePlan,
  deselectAddonBundle,
  deselectAddon,
};

function showLedgerTermsModal(name, body) {
  return (dispatch, getState, { trackEvent }) => {
    trackEvent(events.DETAILS_CLICK, { name, pageId: PAGE_IDS.BILLING });
    dispatch(showDefaultModal(name, body, getTermsModalButtonText(getState())));
  };
}

export function changePlan() {
  return (dispatch, getState, { trackEvent }) => {
    trackEvent(events.CHANGE_PLAN, { pageId: PAGE_IDS.BILLING });
    dispatch(reselectPlan());
    dispatch(routePush(PLAN_SELECT));
  };
}

export function deselectAddonBundle(id) {
  return (dispatch, getState, { trackEvent }) => {
    trackEvent(events.REMOVE_ADDON_BUNDLE, { id, pageId: PAGE_IDS.BILLING });
    dispatch(removeAddonBundle(id));
    dispatch(getLedger());
  };
}

export function deselectAddon(id) {
  return (dispatch, getState, { trackEvent }) => {
    trackEvent(events.REMOVE_ADDON, { id, pageId: PAGE_IDS.BILLING });
    dispatch(removeAddon(id));
    dispatch(getLedger());
  };
}
