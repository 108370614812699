import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import smoothscroll from 'smoothscroll-polyfill';
import {
  isPseudo2PBundle,
  isSashDisneyBundle,
  isTrioBasic,
} from '../selectors/plan';
import {
  getIsBundlePlansTab,
  getShowSashBundleAsDefault,
} from '../selectors/plans';
import { getPlanIdentifierQueryParam } from '../selectors/flow';

require('../styles/scrollingCarousel.scss');

const getSlideClasses = (activeIndex, index, key) =>
  classNames(
    'carousel__slide',
    `carousel__slide--${key}`,
    index === activeIndex && 'carousel__slide--active'
  );

// Consts to handle selection on scroll
const PIXELS_PER_SLIDE = 142;
const CAROUSEL_PADDING = 16;
const CAROUSEL_SCROLL_TIMEOUT = 70;

class ScrollingCarousel extends Component {
  constructor() {
    super();
    this.state = {
      activeIndex: 0,
      spacerWidth: 0,
    };
    this.isScrolling = null;
    this.isCardClicked = false;
    this.scrollContainer = React.createRef();

    this.updateSpacerWidthOnResize = this.updateSpacerWidthOnResize.bind(this);
  }

  componentDidMount() {
    // Initiates smooth scroll polyfill
    // Needed because 'scroll-behavior' CSS property is not supported on mobile devices.
    smoothscroll.polyfill();

    window.addEventListener('resize', this.updateSpacerWidthOnResize);

    // If in mobile view and Plan tab index is set to show bundle tab,
    // default selected card to be ADS_HULU_DISNEY_ESPN bundle
    const {
      isBundlePlansTab,
      queryPlanIdentifier,
      showSashBundleAsDefault,
    } = this.props;

    let newActiveIndex;
    const spacerWidth = this.getSpacerWidth();

    // Check if experiment HULU-13632 is active or showing bundle tab
    const showSashBundle = showSashBundleAsDefault || isBundlePlansTab;

    if (queryPlanIdentifier) {
      newActiveIndex = this.getQueryPlanIdentifierIndex();

      // Set to index position of target offer
      // After state updated, scroll to index position
      this.setState({ activeIndex: newActiveIndex, spacerWidth }, () => {
        this.scrollToCard(newActiveIndex);
      });
    } else if (showSashBundle) {
      // Get the index for ADS_HULU_DISNEY_ESPN
      newActiveIndex = this.getSashBundleIndex();

      // Set to index position for SASH bundle
      // After state updated, scroll to index position
      this.setState({ activeIndex: newActiveIndex, spacerWidth }, () => {
        this.scrollToCard(newActiveIndex);
      });
    } else {
      this.updateSpacerWidthOnResize();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSpacerWidthOnResize);
  }

  onSlideClick(index) {
    this.isCardClicked = true;
    this.setState({ activeIndex: index });
    this.scrollToCard(index);
  }

  getCarouselSlides() {
    const { activeIndex } = this.state;
    const carouselActiveKey = get(
      this.plans[activeIndex],
      'props.plan.identifier',
      ''
    );
    const slides = this.plans.map((plan, index) => {
      const planKey = get(plan, 'props.plan.identifier', '');
      return (
        planKey && (
          <div
            key={planKey}
            className={getSlideClasses(activeIndex, index, planKey)}
            onClick={() => this.onSlideClick(index)}
          >
            {React.cloneElement(plan, {
              carouselActiveKey,
            })}
          </div>
        )
      );
    });
    slides.push(
      <div
        key="spacer"
        className="carousel__spacer"
        style={{ width: `${this.state.spacerWidth}px` }}
      />
    );
    return slides;
  }

  getPlanDetails() {
    const { children } = this.props;
    const { activeIndex } = this.state;
    const details = children[activeIndex];

    return details
      ? React.cloneElement(details, { shouldShowDetails: true })
      : null;
  }

  getScrolledActiveIndex() {
    const scrollContainer = this.scrollContainer.current;
    const firstCardScrollRange = PIXELS_PER_SLIDE / 2 + CAROUSEL_PADDING;
    // This prevents issues with negative scroll values because of iOs bounce effect
    const scrollLeft =
      scrollContainer.scrollLeft < 0 ? 0 : scrollContainer.scrollLeft;
    let activeIndex = Math.ceil(
      (scrollLeft - firstCardScrollRange) / PIXELS_PER_SLIDE
    );
    // This prevents issues with higher scroll values because of iOs bounce effect
    if (activeIndex > this.plans.length - 1)
      activeIndex = this.plans.length - 1;
    return activeIndex;
  }

  setActiveIndex(activeIndex) {
    this.setState({ activeIndex });
  }

  getSpacerWidth() {
    const scrollContainer = this.scrollContainer.current;

    let spacerWidth = 0;
    if (
      scrollContainer &&
      scrollContainer.scrollWidth - this.state.spacerWidth >
        scrollContainer.clientWidth
    ) {
      spacerWidth =
        scrollContainer.clientWidth - PIXELS_PER_SLIDE - CAROUSEL_PADDING;
    }

    return spacerWidth;
  }

  getSashBundleIndex() {
    return this.plans.findIndex(plan => {
      return isSashDisneyBundle(plan.props.plan.identifier);
    });
  }

  getDuoBasicPlanIndex() {
    return this.plans.findIndex(plan => {
      return isPseudo2PBundle(plan.props.plan.identifier);
    });
  }

  getTrioBasicPlanIndex() {
    return this.plans.findIndex(plan => {
      return isTrioBasic(plan.props.plan.identifier);
    });
  }

  getQueryPlanIdentifierIndex() {
    return this.plans.findIndex(
      plan => this.props.queryPlanIdentifier === plan.props.plan.identifier
    );
  }

  updateSpacerWidthOnResize() {
    const spacerWidth = this.getSpacerWidth();
    this.setState({ spacerWidth });
  }

  scrollToCard(cardIndex) {
    const scrollContainer = this.scrollContainer.current;
    if (scrollContainer && scrollContainer.scroll) {
      const to = PIXELS_PER_SLIDE * cardIndex;
      scrollContainer.scroll({ top: 0, left: to, behavior: 'smooth' });
    }
  }

  handleOnScroll() {
    // Prevents selecting cards on scroll when plan was clicked
    if (!this.isCardClicked) {
      this.setActiveIndex(this.getScrolledActiveIndex());
    }
    // Clears timeout throughout the scroll so it is only executed once
    clearTimeout(this.isScrolling);
    // Timeout to scroll to selected the card only when scrolling ends
    this.isScrolling = setTimeout(() => {
      if (this.isCardClicked) {
        this.isCardClicked = false;
      } else {
        this.scrollToCard(this.getScrolledActiveIndex());
      }
    }, CAROUSEL_SCROLL_TIMEOUT);
  }

  render() {
    // Performance optimization, set to be used by instance methods.
    this.plans = React.Children.toArray(this.props.children);
    return (
      <section>
        <div
          className="scrolling carousel"
          ref={this.scrollContainer}
          onScroll={this.handleOnScroll.bind(this)}
          data-testid="scrolling-carousel-container"
        >
          <div className="carousel__slides">{this.getCarouselSlides()}</div>
        </div>
        <div className="carousel__details">{this.getPlanDetails()}</div>
      </section>
    );
  }
}

ScrollingCarousel.propTypes = {
  children: PropTypes.node.isRequired,
  isBundlePlansTab: PropTypes.bool,
  queryPlanIdentifier: PropTypes.string,
  showSashBundleAsDefault: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => ({
  isBundlePlansTab: getIsBundlePlansTab(state),
  queryPlanIdentifier: getPlanIdentifierQueryParam(state),
  showSashBundleAsDefault: getShowSashBundleAsDefault(state, props),
});

export default connect(mapStateToProps)(ScrollingCarousel);
