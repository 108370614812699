import * as types from '../constants/actionTypes';

export const initialState = {
  error: null,
  loading: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.GET_SITE_CONFIG.START: {
      return { ...state, loading: true };
    }
    case types.GET_SITE_CONFIG.SUCCESS: {
      return {
        ...state,
        ...action.payload,
        error: null,
        loading: false,
      };
    }
    case types.GET_SITE_CONFIG.FAILURE: {
      return { ...state, error: action.payload, loading: false };
    }
    case types.GET_SITE_CONFIG.RESET: {
      return { ...state, error: null, loading: false };
    }
    default: {
      return state;
    }
  }
}
