import { createSelector } from 'reselect';
import get from 'lodash/get';

export const getIsIneligiblePromotion = state =>
  get(state, 'warning.isIneligiblePromotion', false);
export const getIsIneligibleDiscount = state =>
  get(state, 'warning.isIneligibleDiscount', false);
export const getIsIneligibleTrialDiscount = state =>
  get(state, 'warning.isIneligibleTrialDiscount', false);

export const getIsIneligible = createSelector(
  [getIsIneligiblePromotion, getIsIneligibleDiscount],
  (isIneligiblePromotion, isIneligibleDiscount) =>
    isIneligiblePromotion || isIneligibleDiscount
);
