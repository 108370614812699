import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-redux-form';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import classNames from 'classnames';

import SuperBundleLogo from '../components/SuperBundleLogo';
import BillingLegalTerms from '../components/BillingLegalTerms';
import CheckBoxField from '../components/CheckBoxField';
import FeatureFlag from '../components/FeatureFlag';
import Loading from '../components/Loading';
import Panel from '../components/Panel';
import PaymentZipCode from '../components/PaymentZipCode';
import {
  CHECKBOX as validateCheckbox,
  BILLING_FIELDS_ERRORS,
} from '../constants/validators';
import CreditCardFormContainer from '../containers/CreditCardFormContainer';
import LedgerContainer from '../containers/LedgerContainer';
import { getVenmoInstance } from '../utils/venmo';
import { deleteCartAbandonmentCookie } from '../utils/cartAbandonmentUtils';

require('../styles/billing.scss');
require('../styles/form.scss');

const BillingInfoPage = ({
  isLoading,
  onLoaded,
  isSkipBillingFlow,
  buttonText,
  onTabSelect,
  onSubmit,
  canSubmit,
  createSubscriberInProgress,
  isCuriosityIncluded,
  isIneligibleDiscount,
  liveConsent,
  displayPaymentFields,
  onlyCCSupported,
  tabIndex,
  vermontConsent,
  isUnifiedActivationFlow,
}) => {
  const [venmoInstance, setVenmoInstance] = useState(null);

  useEffect(() => {
    onLoaded();
    getVenmoInstance().then(instance => {
      setVenmoInstance(instance);
    });
  }, []);

  if (isLoading || isUnifiedActivationFlow) {
    return <Loading />;
  }

  // If we're in a skip billing flow and the page isn't loading, we shouldn't render the billing form
  if (isSkipBillingFlow) {
    // remove cart abandonment cookie since we are skipping billing
    deleteCartAbandonmentCookie();
    return null;
  }

  const venmoSupported = venmoInstance && venmoInstance.isBrowserSupported();

  return (
    <>
      <SuperBundleLogo />
      <Form
        model="payment"
        onSubmit={onSubmit}
        className="form billing"
        method="POST"
      >
        <LedgerContainer />
        <Panel className="large">
          <div className="payments__container">
            {displayPaymentFields && (
              <Tabs selectedIndex={tabIndex} onSelect={onTabSelect}>
                <TabList className="payments__tablist">
                  <Tab
                    className={classNames(
                      'payments__tab',
                      'cc',
                      onlyCCSupported && 'payments__tab-single'
                    )}
                  >
                    Credit Card
                  </Tab>
                  {!onlyCCSupported && (
                    <>
                      <Tab className="payments__tab paypal">PayPal</Tab>
                      {venmoSupported && (
                        <FeatureFlag feature="venmo">
                          <Tab className="payments__tab venmo">Venmo</Tab>
                        </FeatureFlag>
                      )}
                    </>
                  )}
                </TabList>
                <TabPanel>
                  <CreditCardFormContainer />
                </TabPanel>
                {!onlyCCSupported && (
                  <>
                    <TabPanel>
                      <div className="payment_method" />
                    </TabPanel>
                    {venmoSupported && (
                      <FeatureFlag feature="venmo">
                        <TabPanel>
                          <div className="payment_method">
                            <PaymentZipCode />
                          </div>
                        </TabPanel>
                      </FeatureFlag>
                    )}
                  </>
                )}
              </Tabs>
            )}
            <BillingLegalTerms />
          </div>
        </Panel>
        <div className="consent__container">
          {isIneligibleDiscount && (
            <CheckBoxField
              model="hasCheckedIneligibleDiscountConsent"
              id="hasCheckedIneligibleDiscountConsent"
              text="I understand the discounted offer has expired, and I'm signing up for the selected plan above."
              validators={{ required: validateCheckbox }}
            />
          )}
          {isCuriosityIncluded && (
            <CheckBoxField
              model="hasCheckedRecordingConsent"
              id="hasCheckedRecordingConsent"
              text={liveConsent}
              validators={{ required: validateCheckbox }}
            />
          )}
          {vermontConsent && (
            <CheckBoxField
              model="hasCheckedVermontPrepaidConsent"
              id="hasCheckedVermontPrepaidConsent"
              text={vermontConsent}
              validators={{ required: validateCheckbox }}
            />
          )}
        </div>
        <button
          className={classNames('button', !canSubmit && 'disabled')}
          type="submit"
          disabled={!canSubmit}
          aria-disabled={!canSubmit}
          aria-describedby={BILLING_FIELDS_ERRORS.join(' ')}
        >
          {buttonText}
          {createSubscriberInProgress && <span className="spinner" />}
        </button>
      </Form>
    </>
  );
};

BillingInfoPage.defaultProps = {
  buttonText: 'SUBMIT',
};

BillingInfoPage.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onLoaded: PropTypes.func.isRequired,
  onTabSelect: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  onlyCCSupported: PropTypes.bool.isRequired,
  isIneligibleDiscount: PropTypes.bool.isRequired,
  displayPaymentFields: PropTypes.bool.isRequired,
  isCuriosityIncluded: PropTypes.bool.isRequired,
  canSubmit: PropTypes.bool.isRequired,
  createSubscriberInProgress: PropTypes.bool,
  liveConsent: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  tabIndex: PropTypes.number.isRequired,
  isSkipBillingFlow: PropTypes.bool,
  vermontConsent: PropTypes.string.isRequired,
  isUnifiedActivationFlow: PropTypes.bool,
};

export default BillingInfoPage;
