import get from 'lodash/get';
import { createSelector } from 'reselect';
import { SPOTIFY } from '../constants/partners';
import { ESPN_PLUS_COMPONENT_ID } from '../constants/plans';
import { hasSelectedAddon } from './addons';
import {
  getIs2PBundleFlow,
  getIsBundlePartnerFlow,
  getPartnerFlow,
  getIsMaxBundleFlow,
} from './flow';
import {
  getControlText,
  getDPlusPpvText,
  getDPlusText,
  getEPlusText,
  getIsHiSwitchFlow,
  getMaxBundleText,
  getSiteConfig,
} from './siteconfig';
import { getIsSwitchBillingEligible, getIsUserAgeRestricted } from './user';

const getPartnerRoadblockTextKey = partnerFlow => {
  switch (partnerFlow) {
    case SPOTIFY.FLOW:
      return 'spotify_roadblock_text';
    default:
      return '';
  }
};

export const getPartnerRoadblockText = createSelector(
  [getSiteConfig, getPartnerFlow, getIsSwitchBillingEligible],
  (siteConfig, partnerFlow, isEligible) => {
    const textKey = getPartnerRoadblockTextKey(partnerFlow);
    const eligibleKey = isEligible ? 'eligible' : 'ineligible';
    return get(siteConfig, [textKey, eligibleKey], {});
  }
);

export const getPartnerRoadblockDeclineUrl = state => {
  switch (getPartnerFlow(state)) {
    case SPOTIFY.FLOW:
      return getIsSwitchBillingEligible(state)
        ? SPOTIFY.MANAGE_HULU_URL
        : SPOTIFY.HOME_URL;
    default:
      return null;
  }
};

/**
 * A selector for retrieving the correct SiteConfig path for age restriction.
 *
 * @param {Object} state - the redux state tree.
 *
 * @return {string[]} path - the path to locate the correct SiteConfig node.
 */
export const getAgeConfigPath = state => {
  const basePath = ['roadblock', 'age'];
  const hasEspnAddon = hasSelectedAddon(state, ESPN_PLUS_COMPONENT_ID);
  if (hasEspnAddon || getIsHiSwitchFlow(state)) {
    return basePath;
  }
  // The `sufo_dplus` and `sufo_max` nodes in site-config for age have an extra
  // nested level for activation vs hisuf messaging. Need to treat differently.
  const bundlePartnerPath = getIsBundlePartnerFlow(state)
    ? 'activation'
    : 'hisuf';
  return [...basePath, bundlePartnerPath];
};

/**
 * A selector for retrieving the correct roadblock text.
 *
 * @param {Object} state - the redux state tree.
 * @param {string|string[]} path - the text path.
 *
 * @return {Object} SiteConfig node of text to display.
 */
export const getRoadblockConfig = (state, path) => {
  const hasEspnAddon = hasSelectedAddon(state, ESPN_PLUS_COMPONENT_ID);
  const is2PBundleFlow = getIs2PBundleFlow(state);
  const isUserAgeRestricted = getIsUserAgeRestricted(state);
  const isMaxBundleFlow = getIsMaxBundleFlow(state);
  const isHiSwitchFlow = getIsHiSwitchFlow(state);

  if (hasEspnAddon) {
    return getEPlusText(state, path);
  }

  if (isMaxBundleFlow) {
    return getMaxBundleText(state, path);
  }

  if (is2PBundleFlow && !isUserAgeRestricted) {
    return getControlText(state, 'suf_2p_bundle_flow.roadblock.ineligible');
  }

  if (isHiSwitchFlow) {
    return getDPlusPpvText(state, path);
  }

  return getDPlusText(state, path);
};
