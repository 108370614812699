import React from 'react';
import '../styles/productBanner.scss';
import PropTypes from 'prop-types';
import { preventFocus } from '../utils/a11y';

const MoreDetails = ({ text, url }) => (
  <div className="plan__more-details__container">
    {url ? (
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className="plan__more-details"
        data-testid="more-details--banner"
      >
        {text}
      </a>
    ) : (
      <p className="plan__more-details__text">{text}</p>
    )}
  </div>
);

MoreDetails.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string,
};

const ProductBanner = ({
  offer,
  onClick,
  openModal,
  isPriceIncreaseEnabled,
  showBundleTerms,
}) => {
  const {
    alt_text: altText,
    logo,
    more_details: moreDetails,
    disclaimer,
    name,
    tagline,
  } = offer;

  const showDisclaimer = isPriceIncreaseEnabled && disclaimer;

  return (
    <div className="product-banner" data-testid="product-banner">
      <div className="product-banner__logos">
        <img src={`/static/images/${logo}`} alt={altText} />
      </div>
      <div className="product-banner__details">
        <h3 className="product-banner__details-tagline">{tagline}</h3>
        <p>{`${name}${showDisclaimer ? '*' : ''}`}</p>
        <MoreDetails {...moreDetails} />
        {showDisclaimer && (
          <p className="product-banner__details">{disclaimer}</p>
        )}
      </div>
      <div className="product-banner__cta">
        <div className="plan__cta">
          <button
            className="button"
            aria-label="Select Product Banner Plan"
            type="button"
            data-testid="select-button--banner"
            onClick={onClick}
          >
            Select
          </button>
        </div>
        <button
          type="button"
          className="plan__terms"
          onMouseDown={preventFocus}
          onClick={openModal}
          data-testid="terms-apply--banner"
        >
          {showBundleTerms ? 'Bundle Terms Apply' : 'Terms Apply'}
        </button>
      </div>
    </div>
  );
};

ProductBanner.propTypes = {
  isPriceIncreaseEnabled: PropTypes.bool,
  offer: PropTypes.shape({
    alt_text: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    more_details: PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string,
    }).isRequired,
    disclaimer: PropTypes.string,
    name: PropTypes.string.isRequired,
    tagline: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  showBundleTerms: PropTypes.bool.isRequired,
};

export default ProductBanner;
