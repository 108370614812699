import { ACCOUNT_CREATION_SCREENS } from './accountCreation';

export const ROOT = '/';
export const PLAN_SELECT = '/plans';
export const ADDON_SELECT = '/addons';
export const GO = '/go';
export const PLAN_OPTIONS = '/plan-options';
export const ACCOUNT_INFO = '/account';

// Listed below are the primary route and its subroutes that are necessary for completing the 3-step account creation process
export const ACCOUNT_CREATION = '/account/:view';
export const ACCOUNT_CREATION_VIEWS = Object.keys(ACCOUNT_CREATION_SCREENS);
export const ACCOUNT_CREATION_PATTERN = `(?<=^/account/)(${ACCOUNT_CREATION_VIEWS.join(
  '|'
)})$`;

export const LOGIN = '/login';
export const BILLING_INFO = '/billing';
export const PAYPAL_CALLBACK = '/paypal';
export const ERROR = '/error';
export const AGE_ROADBLOCK = '/age-roadblock';

// Unified Activation Route
export const ACTIVATE = '/activate';

// Partner routes
export const PARTNER_LANDING = '/partner-landing';
export const PARTNER_ELIGIBILITY = '/partner-eligibility';
export const PARTNER_ROADBLOCK = '/partner-roadblock';

// Sprint-specific routes
export const SPRINT_LANDING = '/sprint';

// SprintV2-specific routes
export const SPRINTV2_LANDING = '/sprint-landing';
export const SPRINTV2_CALLBACK = '/sprintv2-callback';
export const SPRINTV2_ROADBLOCK = '/sprintv2-roadblock';

// TMobile routes (to replace sprint and sprintV2)
export const TMOBILE = '/t-mobile';
export const TMOBILE_LANDING = '/t-mobile-landing';
export const TMOBILE_CALLBACK = '/t-mobile-callback';
export const TMOBILE_ROADBLOCK = '/t-mobile-roadblock';

// Spotify-specific routes
export const SPOTIFY_LANDING = '/spotify';
export const SPOTIFY_CALLBACK = '/spotify-callback';
export const SPOTIFY_PARTNER_CONFIRM = '/partner-confirm';

// Bundle Partner (DSS) routes
export const BUNDLE_PARTNER_ACTIVATE = '/bundles/activate';
export const BUNDLE_SELECT = '/bundles';
export const BUNDLE_PARTNER_SWITCH_ELIGIBILITY = '/bundles/switch';
export const SUPERBUNDLE_ROADBLOCK = '/superbundle-roadblock';

// Go routes
export const GO_SVOD = '/go/on-demand';
export const GO_ONE_HULU = '/go/one-hulu';
export const GO_LIVE_ONLY = '/go/live-only';
export const GO_SASH = '/go/sash';
export const GO_NOAH = '/go/noah';
export const GO_CURIOSITY = '/go/live';
export const GO_REACQ = '/go/reacq';

// Identity routes
export const IDENTITY_VERIFICATION = '/identity/verify';

export const INIT_PAGES = [
  ROOT,
  GO_SVOD,
  GO_ONE_HULU,
  GO_LIVE_ONLY,
  GO_SASH,
  GO_NOAH,
  GO_CURIOSITY,
  GO,
];

/**
 * We should skip the config call on these pages as the config call will be made
 * after an eligibility check (with the eligibility info passed into the config
 * call).
 *
 * @type {string[]}
 */
export const PARTNER_LANDING_PAGES = [
  PARTNER_LANDING,
  SPRINT_LANDING,
  SPRINTV2_LANDING,
  SPOTIFY_LANDING,
  BUNDLE_PARTNER_ACTIVATE,
  TMOBILE,
  TMOBILE_LANDING,
];

/**
 * Users that come to the SUF with this query parameter present in the URL will
 * be shown the "Bundle & Save" tab active by default.
 * @type {string}
 */
export const TAB_QUERY_PARAM = 'tab';
export const TAB_QUERY_BUNDLES_VALUE = 'bundles';

export const IDENTIFIER_QUERY_PARAM = 'identifier';
