import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Control, actions } from 'react-redux-form';
import Checkbox from './Checkbox';

class CheckBoxField extends Component {
  constructor() {
    super();

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(model) {
    return dispatch => {
      dispatch(actions.toggle(model));
    };
  }

  render() {
    return (
      <div className="fieldset">
        <Control.checkbox
          id={this.props.id}
          disabled={this.props.disabled}
          model={`.${this.props.model}`}
          value={this.props.value}
          validators={this.props.validators}
          text={this.props.text}
          label={this.props.label}
          component={Checkbox}
          changeAction={this.handleChange}
        >
          {this.props.children}
        </Control.checkbox>
      </div>
    );
  }
}

CheckBoxField.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  model: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  label: PropTypes.string,
  children: PropTypes.node,
  validators: PropTypes.object,
};

export default CheckBoxField;
