import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

require('../styles/markdownRoadblock.scss');

const MarkdownRoadblock = ({
  markdown,
  buttonText,
  handleClick,
  showBackButton,
  handleBack,
  footer,
  onLoaded,
}) => {
  useEffect(() => {
    if (onLoaded) onLoaded();
  }, []);

  return (
    <div className="markdown-roadblock">
      <ReactMarkdown
        source={markdown}
        className="markdown-roadblock__markdown"
        linkTarget="_blank"
      />
      {buttonText && handleClick && (
        <button
          className="button markdown-roadblock__button"
          type="button"
          onClick={handleClick}
        >
          {buttonText}
        </button>
      )}
      {showBackButton && (
        <button
          className="button markdown-roadblock-back__button"
          type="button"
          onClick={handleBack}
        >
          Back
        </button>
      )}
      {footer && (
        <ReactMarkdown
          source={footer}
          className="markdown-roadblock__footer"
          linkTarget="_blank"
        />
      )}
    </div>
  );
};

MarkdownRoadblock.propTypes = {
  markdown: PropTypes.string.isRequired,
  footer: PropTypes.string,
  buttonText: PropTypes.string,
  handleClick: PropTypes.func,
  onLoaded: PropTypes.func,
  showBackButton: PropTypes.bool,
  handleBack: PropTypes.func,
};

export default MarkdownRoadblock;
