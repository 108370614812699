import React from 'react';
import { Switch, Route } from 'react-router-dom';

import * as routes from './constants/routes';
import AddonSelectScreen from './containers/AddonSelectScreen';
import BillingInfoScreen from './containers/BillingInfoScreen';
import CreateAccountScreen from './containers/CreateAccountScreen';
import ErrorScreen from './containers/ErrorScreen';
import LoginScreen from './containers/LoginScreen';
import PaypalCallbackScreen from './containers/PaypalCallbackScreen';
import PlanSelectScreen from './containers/PlanSelectScreen';
import SpotifyCallbackScreen from './containers/SpotifyCallbackScreen';
import SpotifyLandingScreen from './containers/SpotifyLandingScreen';
import SpotifyRoadblockScreen from './containers/SpotifyRoadblockScreen';
import SprintErrorScreen from './containers/SprintErrorScreen';
import SprintV2CallbackScreen from './containers/SprintV2CallbackScreen';
import SprintV2LandingScreen from './containers/SprintV2LandingScreen';
import SprintV2RoadblockScreen from './containers/SprintV2RoadblockScreen';
import AgeRoadblockPage from './pages/AgeRoadblockPage';
import PartnerEligibilityScreen from './pages/PartnerEligibilityPage';
import PartnerLandingScreen from './pages/PartnerLandingPage';
import PartnerRoadblockPage from './pages/PartnerRoadblockPage';
import BundlePartnerActivationScreen from './pages/BundlePartnerActivationPage';
import SuperbundleRoadblockPage from './pages/SuperbundleRoadblockPage';
import BundleSelectContainer from './containers/BundleSelectContainer';
import PlanOptionsPage from './pages/PlanOptionsPage';
import BundlePartnerSwitchEligibilityPage from './pages/BundlePartnerSwitchEligibilityPage';
import IdentityVerificationPage from './pages/IdentityVerificationPage';
import UnifiedActivatePage from './containers/UnifiedActivatePageContainer';

export default function() {
  return (
    <Switch>
      <Route exact path={routes.PLAN_SELECT} component={PlanSelectScreen} />
      <Route exact path={routes.ADDON_SELECT} component={AddonSelectScreen} />
      <Route exact path={routes.PLAN_OPTIONS} component={PlanOptionsPage} />
      <Route exact path={routes.BILLING_INFO} component={BillingInfoScreen} />
      <Route
        exact
        path={routes.PAYPAL_CALLBACK}
        component={PaypalCallbackScreen}
      />
      <Route exact path={routes.SPRINT_LANDING} component={SprintErrorScreen} />
      <Route
        exact
        path={routes.SPRINTV2_LANDING}
        component={SprintV2LandingScreen}
      />
      <Route
        exact
        path={routes.SPRINTV2_CALLBACK}
        component={SprintV2CallbackScreen}
      />
      <Route
        exact
        path={routes.SPRINTV2_ROADBLOCK}
        component={SprintV2RoadblockScreen}
      />
      <Route exact path={routes.TMOBILE} component={SprintErrorScreen} />
      <Route
        exact
        path={routes.TMOBILE_LANDING}
        component={SprintV2LandingScreen}
      />
      <Route
        exact
        path={routes.TMOBILE_CALLBACK}
        component={SprintV2CallbackScreen}
      />
      <Route
        exact
        path={routes.TMOBILE_ROADBLOCK}
        component={SprintV2RoadblockScreen}
      />
      <Route
        exact
        path={routes.SPOTIFY_LANDING}
        component={SpotifyLandingScreen}
      />
      <Route
        exact
        path={routes.SPOTIFY_CALLBACK}
        component={SpotifyCallbackScreen}
      />
      <Route
        exact
        path={routes.SPOTIFY_PARTNER_CONFIRM}
        component={SpotifyRoadblockScreen}
      />
      <Route
        exact
        path={`${routes.PARTNER_LANDING}/:partner`}
        component={PartnerLandingScreen}
      />
      <Route
        exact
        path={routes.PARTNER_ELIGIBILITY}
        component={PartnerEligibilityScreen}
      />
      <Route exact path={routes.AGE_ROADBLOCK} component={AgeRoadblockPage} />
      <Route
        exact
        path={routes.PARTNER_ROADBLOCK}
        component={PartnerRoadblockPage}
      />
      <Route
        exact
        path={routes.BUNDLE_PARTNER_SWITCH_ELIGIBILITY}
        component={BundlePartnerSwitchEligibilityPage}
      />
      <Route
        exact
        path={routes.BUNDLE_PARTNER_ACTIVATE}
        component={BundlePartnerActivationScreen}
      />
      <Route
        exact
        path={routes.BUNDLE_SELECT}
        component={BundleSelectContainer}
      />
      <Route
        exact
        path={routes.SUPERBUNDLE_ROADBLOCK}
        component={SuperbundleRoadblockPage}
      />
      <Route
        exact
        path={[routes.ACCOUNT_INFO, routes.ACCOUNT_CREATION]}
        component={CreateAccountScreen}
      />

      <Route exact path={routes.ACTIVATE} component={UnifiedActivatePage} />

      <Route
        exact
        path={`${routes.LOGIN}*`}
        component={LoginScreen}
        header="Log In"
      />
      <Route
        exact
        path={routes.IDENTITY_VERIFICATION}
        component={IdentityVerificationPage}
      />
      <Route exact path={routes.ERROR} component={ErrorScreen} />
      <Route exact path="*" component={ErrorScreen} />
    </Switch>
  );
}
