/*
 * Helper methods for CheckUp specific actions
 */

/*
 * @desc
 * Add a 'cu- ' prefixed class name to an element
 * which can be used for page-object css selectors,
 * making the page-objects less fragile to changes.
 *
 * @param {string} component component name
 * @param {string} value property name, identifier, or any other value for the component
 * @return {string} sanitized checkup class name
 */

export const sanitize = (component, value) => {
  if (component && value) {
    return `cu-${component}-${value}`
      .toLowerCase()
      .replace(/_|\s+/g, '-')
      .replace(/[^a-z0-9-]/g, '');
  }
  return undefined;
};
