import { GET_LEDGER } from '../constants/actionTypes';

export const initialState = {
  ledger: null,
  error: null,
  loading: false,
};

function ledger(state = initialState, action) {
  switch (action.type) {
    case GET_LEDGER.START:
      return {
        ...state,
        loading: true,
      };
    case GET_LEDGER.SUCCESS:
      return {
        ...state,
        ledger: action.payload,
        loading: false,
      };
    case GET_LEDGER.FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_LEDGER.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export default ledger;
