import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { getShouldShowStudentDiscountLink } from '../selectors/plans';
import env from '../../../config/env';
import { STUDENT_DISCOUNT_LINK_PARAMS } from '../metrics/urlParamsMetrics';
import { getStudentDiscountText } from '../selectors/siteconfig';

import '../styles/tabsOnMobileWeb.scss';
import '../styles/studentDiscountLink.scss';

const StudentDiscountLink = ({
  linkClass,
  showDiscountLink,
  studentDiscountText,
}) =>
  // Student discount link contains instrumentation parameters
  showDiscountLink && (
    <div
      className={classNames(
        'student-discount-link',
        linkClass && `student-discount-link--${linkClass}`
      )}
    >
      <a
        href={`${env.url.site}/student?${STUDENT_DISCOUNT_LINK_PARAMS}`}
        data-testid="student-discount-link"
        data-automationid="student-discount-link"
      >
        {studentDiscountText}
      </a>
    </div>
  );

StudentDiscountLink.defaultProps = {
  linkClass: '',
  studentDiscountText: '',
};

StudentDiscountLink.propTypes = {
  linkClass: PropTypes.string,
  showDiscountLink: PropTypes.bool.isRequired,
  studentDiscountText: PropTypes.string,
};

const mapStateToProps = state => ({
  showDiscountLink: getShouldShowStudentDiscountLink(state),
  studentDiscountText: getStudentDiscountText(state),
});

export default connect(mapStateToProps)(StudentDiscountLink);
