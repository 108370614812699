import { connect } from 'react-redux';
import { urlRedirect } from '../actions/routingActions';
import MarkdownRoadblock from '../components/MarkdownRoadblock';
import { getPartnerFlowText } from '../selectors/siteconfig';
import { getPartnerRoadblockEligibility } from '../selectors/partners';

const mapStateToProps = state =>
  getPartnerFlowText(state, [
    'roadblock',
    getPartnerRoadblockEligibility(state),
  ]);

const mergeProps = ({ redirectUrl, ...stateProps }, { dispatch }) => ({
  ...stateProps,
  handleClick: () => dispatch(urlRedirect(redirectUrl)),
});

export default connect(mapStateToProps, null, mergeProps)(MarkdownRoadblock);
