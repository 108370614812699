import { createSelector } from 'reselect';
import { isFeatureEnabled } from './featureFlag';
import { getIsBundlePartnerFlow, getIsPartnerFlow } from './flow';
import { getIsHiSwitchFlow } from './siteconfig';
import { getIsActiveSubscriber, getIsExisting, getIsLoggedIn } from './user';

/**
 * The idm-login flag decides if the app uses an in-house Login component
 * or redirects to the IDM Login page hosted at auth.hulu.com.
 */
export const getIsIDMLoginFeatureOn = state =>
  isFeatureEnabled(state, 'idm-login');

/**
 * If the user does not come from a bundle partner activation (like DSS or Verizon)
 * or a HiSwitch flow, and IDM login feature flag is on, it should redirect to IDM's login page.
 * Otherwise, we must use WST login page.
 *
 * @returns {Boolean} whether or not it should redirect to IDM's Login page.
 */
export const getShouldRedirectToIDMLogin = createSelector(
  [getIsIDMLoginFeatureOn, getIsBundlePartnerFlow, getIsHiSwitchFlow],
  (isIDMLoginFeatureOn, isBundlePartnerFlow, isHiSwitchFlow) =>
    isIDMLoginFeatureOn && !isBundlePartnerFlow && !isHiSwitchFlow
);

/**
 * Determines if the Unified Identity experience should be enabled when the
 * user is prompted to Login. See HUWEB-28963 for reference.
 */
export const getIsUnifiedIdentityOn = state =>
  isFeatureEnabled(state, 'unified-identity');

/**
 * Determines if the Edna Login experience should display a separate Review and Accept page
 * after the Missing Info page.
 */
export const getShouldEnableReviewAndAcceptPage = state =>
  isFeatureEnabled(state, 'edna-login-review-and-accept');

export const getShouldLogin = createSelector(
  [getIsPartnerFlow, getIsLoggedIn, getIsExisting, getIsActiveSubscriber],
  (isPartnerFlow, isLoggedIn, isExisting, isActive) =>
    !isPartnerFlow && !isLoggedIn && (isExisting || isActive)
);
