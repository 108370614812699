import get from 'lodash/get';
import { shouldCollectMetrics } from '../utils/ageValidationUtils';
import * as MetricsEvents from './metricsEvents';
import { getDeviceType } from '../utils/deviceUtils';
import * as MetricsUtils from '../utils/metricsUtils';
import { isHuluPlatform } from '../utils/huluPlatformUtils';
import * as newrelic from '../api/NewRelic';
import { tealiumEnv } from '../../../config/env';
import {
  getAddonIdentifier,
  getAddonBundleComponentIds,
  getAddonBundleIdentifiers,
  getSelectedAddonBundleComponentIds,
  getSelectedAddonIds,
  getSelectedAddonBundleComponentIdentifiers,
  getSelectedAddonIdentifiers,
} from '../selectors/addons';
import { getStatus } from '../selectors/superbundleEligibility';
import {
  getLedgerDueTodayAmount,
  getLedgerMonthlyDueAmount,
} from '../selectors/ledger';
import { getInstrumentationFlowName } from '../selectors/flow';
import { isUserOptedIntoOneTrustConsentGroup } from '../utils/metricsUtils';

export const PAGE_IDS = {
  AGE_ROADBLOCK: 'age-roadblock',
  BILLING: 'billing',
};

export class TealiumMetricsTracker {
  constructor() {
    // Don't initialize Tealium when running unit tests
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    // Don't initialize Tealium when the user has opted out from targeted advertising
    if (!isUserOptedIntoOneTrustConsentGroup()) {
      return;
    }

    try {
      /* eslint-disable */
      (function(t, e, a, l, i, u, m) {
        if (t.utag) return;
        e = t.utag = {};
        e.e = [];
        e.view = function(a, b, c) {
          e.e.push({ a: a, b: b, c: c, d: 'view' });
        };
        e.link = function(a, b, c) {
          e.e.push({ a: a, b: b, c: c, d: 'link' });
        };
        e.track = function(a) {
          e.e.push({
            a: a.data,
            b: a.cfg ? a.cfg.cb : null,
            c: a.cfg ? a.cfg.uids : undefined,
            d: a.event,
          });
        };
      })(window);

      (function(a, b, c, d) {
        a = '//tags.tiqcdn.com/utag/hulu/main/' + tealiumEnv + '/utag.js';
        b = document;
        c = 'script';
        d = b.createElement(c);
        d.src = a;
        d.type = 'text/java' + c;
        d.async = true;
        a = b.getElementsByTagName(c)[0];
        a.parentNode.insertBefore(d, a);
      })();
      /* eslint-enable */
    } catch (err) {
      newrelic.noticeError(err);
      console.error(`Tealium failed to initialize.\n${err}`);
    }
  }

  getEventList() {
    return [
      MetricsEvents.SUBSCRIPTION_START,
      MetricsEvents.SUBSCRIPTION_STEP_START,
      MetricsEvents.SUBSCRIPTION_PLAN_SELECT,
      MetricsEvents.SUBSCRIPTION_END,
      MetricsEvents.BILLING.CHANGE_PLAN,
      MetricsEvents.BILLING.REMOVE_ADDON_BUNDLE,
      MetricsEvents.BILLING.REMOVE_ADDON,
      MetricsEvents.BILLING.DETAILS_CLICK,
      MetricsEvents.AGE_ROADBLOCK,
      MetricsEvents.SUPERBUNDLE_ELIGIBILITY_CHECK,
      MetricsEvents.BUNDLE_PARTNER_SWITCH_INELIGIBLE,
      MetricsEvents.FROM_PARAM_LANDING,
    ];
  }

  /**
   * Track an event and send it to the Tealium tracking system.
   *
   * Spec: https://wiki.hulu.com/pages/viewpage.action?pageId=62697550
   *
   * @param {string} event The name of the event to track.
   * @param {object} state The redux store's current state.
   * @param {object} options Any extra options provided by the callee.
   */
  trackEvent(eventName, state, options) {
    // Don't track event when the user has opted out from targeted advertising
    if (!isUserOptedIntoOneTrustConsentGroup()) {
      return;
    }

    // Don't track event when the user's date of birth is known and is a teen between 13 and 16 years old.
    const dateOfBirth = state?.user?.birthday;
    if (dateOfBirth && !shouldCollectMetrics(dateOfBirth)) {
      return;
    }

    const props = {
      app: 'sufo-redux',
      client_type: this.getClientType(state),
      customer_type: MetricsUtils.getCustomerType(
        state,
        eventName === MetricsEvents.SUBSCRIPTION_END
      ),
      device_category: getDeviceType().toLowerCase(),
      wholesale_bundle_id: get(
        state,
        'partner.spotifyEligibility.response.bundleId',
        'no wholesale id'
      ),
      spotify_lp_bundle_id: get(
        state,
        'flow.query.spotify_bundle_id',
        'no spotify bundle id'
      ),
    };

    const pageProps = {
      page_name: options.pageId,
      page_type: 'signup_flow',
    };

    switch (eventName) {
      case MetricsEvents.SUBSCRIPTION_START:
        Object.assign(props, pageProps, {
          flow_name: getInstrumentationFlowName(state),
          event_name: 'subscription_start',
        });
        this.sendLink(props);
        break;
      case MetricsEvents.FROM_PARAM_LANDING:
      case MetricsEvents.SUBSCRIPTION_STEP_START:
        Object.assign(props, pageProps, this.getPlanProps(state));
        this.sendEvent(props);
        break;
      case MetricsEvents.SUBSCRIPTION_PLAN_SELECT:
        Object.assign(props, this.getPlanProps(state), {
          event_name: 'sufo_plan_select',
          tealium_event: 'cart_add',
        });
        this.sendLink(props);
        break;
      case MetricsEvents.SUBSCRIPTION_END:
        Object.assign(props, this.getPlanProps(state), {
          event_name: 'signup_complete',
          order_id: options.subscriptionId,
          order_total: getLedgerDueTodayAmount(state),
          payment_type: this.getPaymentType(state),
          base_product_name: get(
            state,
            'user.selectedPlan.identifier',
            ''
          ).toLowerCase(),
          trial_duration: MetricsUtils.getTrialDuration(
            get(state, 'user.selectedPlan')
          ),
        });
        this.sendLink(props);
        break;
      case MetricsEvents.BILLING.CHANGE_PLAN: {
        Object.assign(props, pageProps, {
          event_name: 'sufo_billing_change_plan',
          product_id: [get(state, 'user.selectedPlan.id', '')],
          product_name: [
            get(state, 'user.selectedPlan.identifier', '').toLowerCase(),
          ],
          tealium_event: 'cart_remove',
        });
        this.sendLink(props);
        break;
      }
      case MetricsEvents.BILLING.REMOVE_ADDON_BUNDLE: {
        const { id } = options;
        Object.assign(props, pageProps, {
          event_name: 'sufo_remove_billing_bundle',
          product_id: getAddonBundleComponentIds(state, id),
          product_name: getAddonBundleIdentifiers(state, id).map(identifier =>
            identifier.toLowerCase()
          ),
          tealium_event: 'cart_remove',
        });
        this.sendLink(props);
        break;
      }
      case MetricsEvents.BILLING.REMOVE_ADDON: {
        const { id } = options;
        Object.assign(props, pageProps, {
          event_name: 'sufo_remove_billing_add-on',
          product_id: [id],
          product_name: [getAddonIdentifier(state, id).toLowerCase()],
          tealium_event: 'cart_remove',
        });
        this.sendLink(props);
        break;
      }
      case MetricsEvents.BILLING.DETAILS_CLICK:
        Object.assign(props, pageProps, {
          event_name: 'sufo_billing_details_click',
          product_name: options.name.toLowerCase(),
        });
        this.sendLink(props);
        break;
      case MetricsEvents.AGE_ROADBLOCK:
        Object.assign(props, pageProps, {
          event_name: 'roadblock',
          page_name: MetricsEvents.AGE_ROADBLOCK,
        });
        this.sendEvent(props);
        break;
      case MetricsEvents.SUPERBUNDLE_ELIGIBILITY_CHECK:
        Object.assign(props, pageProps, {
          event_name: 'eligibility_check',
          status: getStatus(state),
          curr_page_uri: 'app:signup:account_creation',
        });
        this.sendLink(props);
        break;
      case MetricsEvents.BUNDLE_PARTNER_SWITCH_INELIGIBLE:
        Object.assign(props, pageProps, {
          tealium_event: 'view',
          page_name: 'app:signup:ineligible',
          selected_program_id: 'NNNNN',
        });
        this.sendEvent(props);
        break;
      default:
        break;
    }
  }

  getPlanProps(state) {
    const {
      user: { selectedPlan },
    } = state;
    if (!selectedPlan || !selectedPlan.id) {
      return {};
    }

    const componentIds = [
      selectedPlan.id,
      ...getSelectedAddonBundleComponentIds(state),
      ...getSelectedAddonIds(state),
    ];

    const componentIdentifiers = [
      selectedPlan.identifier,
      ...getSelectedAddonBundleComponentIdentifiers(state),
      ...getSelectedAddonIdentifiers(state),
    ].map(identifier => identifier.toLowerCase());

    const { length } = componentIds;
    const productQuantity = Array(length).fill(1);
    const productAction = Array(length).fill('new');
    const monthlyDue = getLedgerMonthlyDueAmount(state);
    const productPrice = Array(length)
      .fill(monthlyDue)
      .fill(0, 1);
    const programId = String(
      get(selectedPlan, 'subscription.promotion.programId')
    );

    // flow name used for cart_abandonment
    const flowName = getInstrumentationFlowName(state);
    return {
      flow_name: flowName,
      product_id: componentIds,
      product_name: componentIdentifiers,
      product_quantity: productQuantity,
      product_action: productAction,
      product_price: productPrice,
      program_id: programId,
    };
  }

  getClientType(state) {
    if (isHuluPlatform()) return 'android';
    if (state.flow.client.subpartner === 'roku_web') return 'roku_web';
    return 'web';
  }

  getPaymentType(state) {
    const {
      payment: { paymentType },
    } = state;
    if (paymentType.toLowerCase() !== 'none') {
      return paymentType;
    }
    return 'promo';
  }

  sendEvent(props) {
    window.utag.view(props);
  }

  sendLink(props) {
    window.utag.link(props);
  }
}
