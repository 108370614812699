import React, { Component } from 'react';
import { MetricsManagerContext } from './MetricsManagerProvider';

/**
 * Wrap the component, and it can call track on its own properties to track the metrics.
 */
export default function track(WrappedComponent) {
  class Track extends Component {
    render() {
      const { trackEvent, flushEvents } = this.context;
      const mergedProps = {
        trackEvent,
        flushEvents,
        ...this.props,
      };
      return <WrappedComponent {...mergedProps} />;
    }
  }

  Track.contextType = MetricsManagerContext;
  return Track;
}
