import {
  ROUTE_PUSH,
  ROUTE_POP,
  ROUTE_REPLACE,
  URL_REDIRECT,
  URL_REPLACE,
} from '../constants/actionTypes';
import env from '../../../config/env';

// sends user to specified in-app route. adds new history entry.
export function routePush(route) {
  return {
    type: ROUTE_PUSH,
    route,
  };
}

// uses browser history to navigate back a page.
export function routePop() {
  return {
    type: ROUTE_POP,
  };
}

// sends user to specified in-app route. replaces current history entry.
export function routeReplace(route) {
  return {
    type: ROUTE_REPLACE,
    route,
  };
}

// performs http redirect to specified url
export function urlRedirect(url, params = {}) {
  return {
    type: URL_REDIRECT,
    url,
    params,
  };
}

// performs redirect to the specified url. current page will not be saved in history.
export function urlReplace(url) {
  return {
    type: URL_REPLACE,
    url,
  };
}

// Swaps out url placeholders and performs the redirect
export const handleEnvironmentRedirect = redirectUrl => dispatch =>
  dispatch(
    urlRedirect(
      redirectUrl
        .replace('{url.site}', env.url.site)
        .replace('{url.login}', env.url.login)
    )
  );
