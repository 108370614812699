import { YOKOZUNA_API } from '../constants/apis';
import request from './request';

export default function validateZip(zip) {
  const trimZip = zip.trim();
  return !trimZip || trimZip.length !== 5
    ? Promise.reject({ status: 0, valid: false })
    : request(`${YOKOZUNA_API}zips/${trimZip}`, {
        method: 'GET',
        credentials: 'include',
      });
}
