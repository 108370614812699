import { createReducer } from 'redux-starter-kit';
import { GET_COHORTS } from '../constants/actionTypes';

export const initialState = {
  cohorts: [],
  error: null,
  loading: false,
};

export default createReducer(initialState, {
  [GET_COHORTS.START]: state => {
    state.loading = true;
  },
  [GET_COHORTS.SUCCESS]: (state, { payload }) => {
    state.error = null;
    state.loading = false;
    state.cohorts = payload.cohorts;
    state.status = payload.status;
  },
  [GET_COHORTS.FAILURE]: (state, { payload }) => {
    state.error = payload;
    state.loading = false;
  },
});
