import * as types from '../constants/actionTypes';
import * as paymentMethods from '../constants/paymentMethods';
import { translateSavedPayment } from '../utils/prefillBillingUtils';
import { SPOTIFY } from '../constants/partners';

export const initialState = {
  paymentType: paymentMethods.CREDITCARD,
  billingTabIndex: 0,
  paypalRedirect: {},
};

function payment(state = initialState, action) {
  switch (action.type) {
    case types.PAYMENT_TAB_SELECTED:
      return {
        ...state,
        billingTabIndex: action.tabIndex,
      };
    case types.UPDATE_PAYMENT_TOKEN:
      return {
        ...state,
        paymentType: action.paymentType,
        paymentToken: action.paymentToken,
      };
    case types.SELECT_PLAN:
      if (action.plan && action.plan.paymentType === paymentMethods.NONE) {
        return {
          ...state,
          paymentType: paymentMethods.NONE,
        };
      }
      return state;
    case types.GET_SPOTIFY_ELIGIBILITY.SUCCESS:
      return {
        ...state,
        paymentType: paymentMethods.SPOTIFY,
        ...action.payload.tokens,
      };
    case types.GET_SPRINTV2_ELIGIBILITY.SUCCESS:
      return {
        ...state,
        paymentType: paymentMethods.SPRINTV2,
      };
    case types.GET_PARTNER_OFFER_CONTEXT.SUCCESS:
      return {
        ...state,
        paymentType: paymentMethods.PARTNER,
      };
    case types.GET_BUNDLE_PARTNER_PAYLOAD.SUCCESS:
      return {
        ...state,
        paymentType: paymentMethods.BUNDLE_PARTNER,
      };
    case types.GET_CONFIG.SUCCESS:
      if (action.payload.giftCode) {
        return {
          ...state,
          code: action.payload.giftCode.code,
          paymentType: paymentMethods.GIFT_CODE,
        };
      }

      if (action.payload.rpm) {
        return {
          ...state,
          token: action.payload.rpm.token,
          zip: action.payload.rpm.zip,
          paymentType: paymentMethods.RPM,
        };
      }

      if (
        action.payload.savedPayment &&
        action.payload.savedPayment.method === paymentMethods.CREDITCARD
      ) {
        return {
          ...state,
          ...translateSavedPayment(action.payload.savedPayment),
        };
      }

      return state;
    case types.REDUX_LOCALSTORAGE_INIT: {
      if (action.payload) {
        const paymentModel = {};

        // Check for restoring payment type from config
        if (action.payload.config) {
          const { user } = action.payload;
          const { config } = action.payload;
          if (config.giftCode) {
            paymentModel.paymentType = paymentMethods.GIFT_CODE;
            paymentModel.code = config.giftCode.code;
          } else if (config.rpm) {
            paymentModel.paymentType = paymentMethods.RPM;
            paymentModel.token = config.rpm.token;
            paymentModel.zip = config.rpm.zip;
          } else if (
            config.savedPayment &&
            config.savedPayment.method === paymentMethods.CREDITCARD
          ) {
            Object.assign(
              paymentModel,
              translateSavedPayment(config.savedPayment)
            );
          } else if (
            user.selectedPlan &&
            user.selectedPlan.paymentType === paymentMethods.NONE
          ) {
            paymentModel.paymentType = paymentMethods.NONE;
          }
        }

        // Check for partner flow values to restore
        if (action.payload.flow && action.payload.flow.partnerFlow) {
          const { flow } = action.payload;
          if (flow.partnerFlow === SPOTIFY.FLOW && flow.spotifyTokens) {
            paymentModel.accessToken = flow.spotifyTokens.accessToken;
            paymentModel.refreshToken = flow.spotifyTokens.refreshToken;
          }
        }

        return {
          ...state,
          ...paymentModel,
        };
      }

      return state;
    }
    case types.CLEAR_PREFILL_BILLING_INFO:
      if (state.paymentType === paymentMethods.USE_CURRENT) {
        return {
          ...initialState,
          billingTabIndex: state.billingTabIndex,
        };
      }
      return state;
    case types.SET_PAYPAL_REDIRECT_URLS:
      return {
        ...state,
        paypalRedirect: {
          successRedirectUrl: action.successRedirectUrl,
          cancelRedirectUrl: action.cancelRedirectUrl,
        },
      };
    case types.GET_VENMO_TOKEN.SUCCESS:
      return {
        ...state,
        nonce: action.payload.nonce,
      };
    default:
      break;
  }
  return state;
}

export default payment;
