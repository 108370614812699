import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './inputError.scss';

const InputError = ({ className, message }) => (
  <div className={cx('input-error', className)}>
    <img
      className="input-error__icon"
      alt="Error"
      src="/static/images/error-icon.svg"
    />
    <span className="input-error__text">{message}</span>
  </div>
);

InputError.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node.isRequired,
};

export default InputError;
