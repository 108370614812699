import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import PlanOption from './PlanOption';
import { PLAN_VALUE_PROPS } from '../constants/planText';
import { TERMS_APPLY } from '../constants/terms';
import { getPricingEvolution } from '../selectors/planOptionsPage';

const PlanOptions = ({
  isPrepaidOnBillingTreatment,
  planOptions,
  preSelectedSubIdentifier,
  isIneligiblePromotion,
  updateChosenPlan,
  openTermsApplyModal,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    preSelectedSubIdentifier
  );
  useEffect(() => {
    setSelectedOption(preSelectedSubIdentifier);
  }, [preSelectedSubIdentifier]);

  const clickHandler = planOption => {
    if (!isIneligiblePromotion) {
      setSelectedOption(planOption.subIdentifier);
      updateChosenPlan(planOption);
    }
  };

  const getPrepaidOptionsClasses = () => {
    return `prepaid__options ${
      isPrepaidOnBillingTreatment ? 'prepaid__on-billing' : ''
    }`;
  };

  return (
    <ul className={getPrepaidOptionsClasses()} data-testid="prepaid__options">
      {planOptions.map(planOption => (
        <PlanOption
          planOptionOffer={
            !isPrepaidOnBillingTreatment && !isIneligiblePromotion
              ? planOption.planOptionOffer
              : planOption.planPaymentDuration
          }
          planTrialDuration={
            !isPrepaidOnBillingTreatment ||
            isIneligiblePromotion ||
            !planOption.planTrialDuration
              ? ''
              : planOption.planTrialDuration
          }
          pricingEvolution={getPricingEvolution(planOption.displayPricing)}
          savingDetail={(plan =>
            plan.discountSavingsDetail || PLAN_VALUE_PROPS[plan.subIdentifier])(
            planOption
          )}
          key={planOption.subIdentifier}
          onClick={() => clickHandler(planOption)}
          isSelected={planOption.subIdentifier === selectedOption}
          id={planOption.subIdentifier}
          onTermsApplyClick={
            isPrepaidOnBillingTreatment &&
            !!planOption.optionOfferAdditionalTerms &&
            planOption.optionOfferAdditionalTerms.length
              ? () => {
                  openTermsApplyModal(
                    TERMS_APPLY,
                    planOption.optionOfferAdditionalTerms.join('\n\n')
                  );
                }
              : null
          }
        />
      ))}
    </ul>
  );
};

PlanOptions.propTypes = {
  isPrepaidOnBillingTreatment: PropTypes.bool.isRequired,
  planOptions: PropTypes.arrayOf(
    PropTypes.shape({
      planPaymentDuration: PropTypes.string,
      planTrialDuration: PropTypes.string,
      displayPricing: PropTypes.arrayOf(PropTypes.string).isRequired,
      discountSavingsDetail: PropTypes.string,
      subIdentifier: PropTypes.string.isRequired,
    })
  ),
  preSelectedSubIdentifier: PropTypes.string,
  updateChosenPlan: PropTypes.func.isRequired,
  openTermsApplyModal: PropTypes.func.isRequired,
  isIneligiblePromotion: PropTypes.bool,
};

export default PlanOptions;
