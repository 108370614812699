import React from 'react';
import PropTypes from 'prop-types';
import SimpleTooltip from './SimpleTooltip';

const PartnerRoadblockFooter = ({ tooltip, callout, text }) => {
  const calloutDiv = callout && <p className="roadblock__callout">{callout}</p>;
  return (
    <div>
      {[].concat(text).map((textItem, index) => (
        <p key={index}>{textItem}</p>
      ))}
      {calloutDiv}
      <p>
        If you have canceled your Hulu subscription within the last 30 days,
        please&nbsp;
        <SimpleTooltip tooltip={tooltip}>
          <span id="learn_more_link">click here</span>
        </SimpleTooltip>
      </p>
    </div>
  );
};

PartnerRoadblockFooter.propTypes = {
  tooltip: PropTypes.string.isRequired,
  callout: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};

export default PartnerRoadblockFooter;
