import union from 'lodash/union';
import * as types from '../constants/actionTypes';

export const initialState = {
  error: null,
  loaded: false,
  loading: false,
  plans: [],
  addons: [],
  addonBundles: [],
  errorMessages: [],
  messaging: {
    planSelect: {
      header: 'Select your plan',
      subheader: 'Switch plans or cancel anytime.',
      below: '',
      banner: null,
      bannerInstructions: null,
    },
    createAccount: {
      header: 'Create your account',
      subheader: 'This will be your login for all devices',
      below: '',
      banner: null,
      bannerInstructions: null,
    },
    addonSelect: {
      header: 'Select your add-ons',
      subheader: 'Switch or cancel anytime.',
      below: '',
      banner: '',
      bannerInstructions: '',
    },
  },
  assignments: [],
};

export default function config(state = initialState, action) {
  switch (action.type) {
    case types.GET_CONFIG.START: {
      return { ...state, error: null, loading: true };
    }
    case types.GET_CONFIG.SUCCESS: {
      return {
        ...state,
        ...action.payload,
        error: null,
        loaded: true,
        loading: false,
      };
    }
    case types.GET_CONFIG.FAILURE: {
      return { ...state, error: action.payload, loading: false };
    }
    case types.UPDATE_ASSIGNMENT: {
      return {
        ...state,
        assignments: union(state.assignments, action.payload.user.assignments),
      };
    }
    case types.CLEAR_LOCATION_QUERY: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
