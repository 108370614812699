import isEqual from 'lodash/isEqual';

export function filterPlans(plans, ...keys) {
  const filtered = [];
  let found;

  keys.forEach(key => {
    found = plans.find(plan => plan.identifier === key);
    return found ? filtered.push(found) : null;
  });
  return filtered;
}

export function orderPlans(plans, order) {
  if (!order || !order.length) return plans;

  const sorted = [];
  let found;

  order.forEach(key => {
    found = plans.find(plan => plan.identifier === key);
    return found ? sorted.push(found) : null;
  });

  return sorted;
}

/*
  Filters fields for subscription comparison.
*/
const filterSubscription = subscription => {
  // eslint-disable-next-line no-unused-vars
  const {
    source: { referrerUrl, ...sourceFields },
    ...subscriptionFields
  } = subscription;
  return { source: { ...sourceFields }, ...subscriptionFields };
};

/*
  Given the user's current plan and the config received on login,
  return true only if the new config has changed the plan that the user selected
*/
export function didSelectedPlanChange(currentPlan, newConfig) {
  // handle the case where use does not have a selected plan
  if (!currentPlan || !currentPlan.subIdentifier) {
    return false;
  }

  // handle the case where the new config does not contain the plan the user has
  const newPlan = newConfig.plans.find(
    el => el.subIdentifier === currentPlan.subIdentifier
  );
  if (!newPlan) {
    return true;
  }

  // if subscription node differs, then the user's selected plan has changed
  return !isEqual(
    filterSubscription(newPlan.subscription),
    filterSubscription(currentPlan.subscription)
  );
}
