import * as spotify from '../api/Spotify';
import { url } from '../../../config/env';
import { getConfig } from './configActions';
import { routePush, urlReplace, urlRedirect } from './routingActions';
import * as types from '../constants/actionTypes';
import { SPOTIFY } from '../constants/partners';
import * as routes from '../constants/routes';
import * as MetricsEvents from '../metrics/metricsEvents';
import { getResponseStatus, getIsExistingUser } from '../selectors/spotify';
import { getIsLoggedIn } from '../selectors/user';
import { getCookie } from '../utils/cookieUtils';

export function handleCallbackLoaded() {
  return (dispatch, getState, { trackEvent }) =>
    Promise.resolve()
      .then(() => dispatch(getSpotifyEligibility()))
      .then(
        () => dispatch(getConfigAndRedirect()),
        error => {
          let metricsPageTitle = '';
          const responseStatus = getResponseStatus(getState());

          if (responseStatus === SPOTIFY.RESPONSE_STATUS.ALREADY_SUBSCRIBED) {
            metricsPageTitle = 'SUF - Roadblock Spotify Existing Ineligible';
          } else if (responseStatus === SPOTIFY.RESPONSE_STATUS.SERVICE_ERROR) {
            metricsPageTitle = 'SUF - Roadblock Error Retry';
          }
          trackEvent(MetricsEvents.SUBSCRIPTION_STEP_START, {
            title: metricsPageTitle,
            pageId: 'roadblock',
          });

          // Returning a rejected promise stops the loading spinner on the callback page.
          return Promise.reject(error);
        }
      );
}

export function getConfigAndRedirect() {
  return (dispatch, getState) =>
    Promise.resolve()
      .then(() => dispatch(getConfig()))
      .then(
        () => {
          if (getIsLoggedIn(getState())) {
            return dispatch(routePush(routes.SPOTIFY_PARTNER_CONFIRM));
          }

          if (getIsExistingUser(getState())) {
            return dispatch(routePush(routes.LOGIN));
          }

          return dispatch(routePush(routes.ACCOUNT_INFO));
        },
        error => {
          // Go to error page if config call fails.
          dispatch(routePush(routes.ERROR));
          return Promise.reject(error);
        }
      );
}

export function handleCallbackClick() {
  return (dispatch, getState) => {
    const responseStatus = getResponseStatus(getState());
    if (responseStatus === SPOTIFY.RESPONSE_STATUS.ALREADY_SUBSCRIBED) {
      dispatch(urlReplace(url.login));
    } else if (responseStatus === SPOTIFY.RESPONSE_STATUS.CUSTOMER_NOT_FOUND) {
      dispatch(urlRedirect(SPOTIFY.MANAGE_HULU_URL));
    } else {
      const testCookie = getCookie('_spotify_test_uid');
      const redirectUri = `${window.location.origin}${SPOTIFY.REDIRECT_URL}${
        getIsExistingUser(getState()) ? SPOTIFY.EXISTING_USER_PARAM : ''
      }`;

      // Skips Spotify OAuth step if test mode is set.
      if (SPOTIFY.TEST_MODE_ALLOWED && testCookie) {
        const query = {
          [SPOTIFY.PARAM_CODE]: testCookie,
          [SPOTIFY.PARAM_REDIRECT]: redirectUri,
        };
        dispatch(urlRedirect(routes.SPOTIFY_CALLBACK, query));
      } else {
        const query = {
          response_type: 'code',
          client_id: SPOTIFY.CLIENT_ID,
          scope: SPOTIFY.SCOPE,
          redirect_uri: redirectUri,
        };
        dispatch(
          urlRedirect(`${SPOTIFY.API_URL}${SPOTIFY.AUTH_OPERATION}`, query)
        );
      }
    }
  };
}

// Spotify API actions
export function getSpotifyEligibility() {
  return dispatch =>
    dispatch(getSpotifyEligibilityStart()).then(
      response => dispatch(getSpotifyEligibilitySuccess(response)),
      error => {
        dispatch(getSpotifyEligibilityFailure(error));
        return Promise.reject(error);
      }
    );
}

export function getSpotifyEligibilityStart() {
  return function(dispatch, getState) {
    const {
      flow: { query },
    } = getState();
    dispatch({
      type: types.GET_SPOTIFY_ELIGIBILITY.START,
    });
    return spotify.getSpotifyEligibility(
      query.spotify_code,
      query.spotify_redirect
    );
  };
}

export function getSpotifyEligibilitySuccess(response) {
  return {
    type: types.GET_SPOTIFY_ELIGIBILITY.SUCCESS,
    payload: response,
  };
}

export function getSpotifyEligibilityFailure(error) {
  return {
    type: types.GET_SPOTIFY_ELIGIBILITY.FAILURE,
    payload: error,
  };
}

export function resetSpotifyEligibility() {
  return {
    type: types.GET_SPOTIFY_ELIGIBILITY.RESET,
  };
}

export function setSpotifyActivationStatus(activationStatus) {
  return (dispatch, getState) => {
    const {
      flow: {
        spotifyTokens: { accessToken },
      },
    } = getState();
    spotify
      .setSpotifyActivationStatus(accessToken, activationStatus)
      .catch(() => {
        // Errors are not handled for SpotifyActivationStatus
      });
  };
}
