import get from 'lodash/get';
import { connect } from 'react-redux';

import {
  getBadgeType,
  getFeatureLists,
  getPlanDiscountDescription,
  getPlanDisplayTrial,
  isCuriosityIncluded,
  getIsDisneySuperBundle,
  makeAltTrialText,
  makeDiscountText,
  getIsNOAHIncluded,
  getShouldShowLiveTVTrial,
} from '../selectors/plan';
import {
  getFeatureList,
  getPlanBadgeDescription,
  getLearnMoreRedirection,
  getPlanNames,
  getPlanMobileBadgeDescription,
  getPlanDefaultDescription,
  getPlanValuePropCopy,
  getLiveTvTrialDiscountDescription,
  getLiveTvTrialDisclaimer,
} from '../selectors/dynamicMessaging';
import { hasAssignment } from '../selectors/config';
import { CAT_321, CAT_345, HUWEB_20642 } from '../constants/experiments';
import {
  getBundlesForPlan,
  getShow3UpView,
  getHasUpsellCheckbox,
  getIsSASHFlashSale,
  getSASHFlashSaleOverrides,
  getShowBundleBadges,
  getShowSASHBundleBadge,
  getShowTabsOnMobileWeb,
  getShowValuePropBullets,
  getWindowIsDesktop,
} from '../selectors/plans';
import { getDPlusText, getNoahTooltipMessage } from '../selectors/siteconfig';
import { showPlanSelectTermsModal } from '../actions/planSelectActions';
import { getIsLiveOnlyFlow } from '../selectors/flow';
import AlternativePlanWrapper from '../components/AlternativePlanWrapper';
import {
  getDescription,
  getName,
  getModalTitle,
  siteUrlPrepended,
  getDescriptionWithPrice,
  getBulletedDescription,
} from '../utils/planUtils';
import withWindowSize from '../utils/withWindowSize';
import {
  getIsHuluFreeTrialsEnabled,
  getIsLivePromoFeatureOn,
} from '../selectors/featureFlag';

const mapStateToProps = (state, ownProps) => {
  const { plan, hideBadge } = ownProps;
  const isDesktop = getWindowIsDesktop({}, ownProps);
  const hasTrial = get(plan, 'trial.length', 0) > 0;
  const featureListConfig = getFeatureList(state);
  const featureLists = getFeatureLists(featureListConfig, plan);
  const isPriceZero = hasAssignment(state, CAT_321.ZERO);
  const isUserTypeBadge = hasAssignment(state, CAT_345.USER_TYPE_BADGE);
  const isLivePriority = hasAssignment(state, HUWEB_20642.LIVE_PRIORITY);
  const learnMorePath = get(getLearnMoreRedirection(state), [plan.id]);
  const planNames = getPlanNames(state);
  const isLiveOnly = getIsLiveOnlyFlow(state);
  const defaultName = get(planNames, [plan.id, 'default'], plan.name);
  const longName = get(planNames, [plan.id, 'long'], plan.name);
  const defaultDescription = getPlanDefaultDescription(state, plan);
  const planIncludesLive = isCuriosityIncluded(plan);
  const valuePropBullets = getPlanValuePropCopy(state, plan);
  const isDisneySuperBundle = getIsDisneySuperBundle(plan);
  const show3UpView = getShow3UpView(state);
  const isNOAHIncluded = getIsNOAHIncluded(plan);
  const isLivePromoFeatureOn = getIsLivePromoFeatureOn(state);
  const showLiveTvTrial = getShouldShowLiveTVTrial(isLivePromoFeatureOn, plan);
  const liveTvTrialDisclaimer = getLiveTvTrialDisclaimer(state);
  const liveTvTrialDiscountDescription = getLiveTvTrialDiscountDescription(
    state
  );
  const descriptionWithPrice = getDescriptionWithPrice(
    longName,
    plan,
    showLiveTvTrial,
    liveTvTrialDiscountDescription
  );
  const isHuluFreeTrialsEnabled = getIsHuluFreeTrialsEnabled(state);

  const props = {
    ...ownProps,
    ...featureLists,
    badgeDescription: hideBadge
      ? ''
      : getPlanBadgeDescription(state, plan, show3UpView),
    mobileBadgeDescription: getPlanMobileBadgeDescription(
      state,
      plan,
      show3UpView
    ),
    badgeType: getBadgeType(plan, isUserTypeBadge, isDesktop, show3UpView),
    description: getDescription(
      plan.identifier,
      defaultDescription,
      descriptionWithPrice
    ),
    dPlusTermsUrl: getDPlusText(state, 'banner.termsUrl'),
    hasBundles: selectedPlan => !!getBundlesForPlan(state, selectedPlan).length,
    hasTrial,
    isBundleOffer: getHasUpsellCheckbox(state),
    isDesktop,
    isLiveOnly,
    isLivePriority,
    isPriceZero,
    isUserTypeBadge,
    show3UpView: getShow3UpView(state),
    showSASHBundleBadge: getShowSASHBundleBadge(state, ownProps),
    showBundleBadges: getShowBundleBadges(state),
    showFlashSalePlan: getIsSASHFlashSale(state, plan),
    showTabsOnMobileWeb: getShowTabsOnMobileWeb(state),
    showValuePropBullets: getShowValuePropBullets(state),
    showLiveTvTrial,
    noahTooltipMessage: getNoahTooltipMessage(state),
    planAltDisplayTrial: makeAltTrialText(
      plan,
      isPriceZero,
      isHuluFreeTrialsEnabled
    ),
    planDisplayDiscount: makeDiscountText(plan),
    planDisplayTrial: getPlanDisplayTrial(
      plan,
      isLiveOnly,
      isHuluFreeTrialsEnabled
    ),
    name: getName(plan.identifier, defaultName, longName),
    modalTitle: getModalTitle(plan.identifier, defaultName, longName),
    defaultName,
    learnMoreUrl: siteUrlPrepended(learnMorePath),
    planDiscountDescription: getPlanDiscountDescription(plan, showLiveTvTrial),
    planIncludesLive,
    bulletedDescription: getBulletedDescription(
      plan.identifier,
      valuePropBullets,
      descriptionWithPrice
    ),
    isDisneySuperBundle,
    isNOAHIncluded,
    liveTvTrialDisclaimer,
    isHuluFreeTrialsEnabled,
  };
  const overrideProps = getSASHFlashSaleOverrides(props);

  return { ...props, ...overrideProps };
};

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const {
    modalTitle,
    dPlusTermsUrl,
    showFlashSalePlan,
    isDesktop,
    isDisneySuperBundle,
    isNOAHIncluded,
    learnMoreUrl,
    noahTooltipMessage,
    plan,
    planIncludesLive,
    show3UpView,
    showLiveTvTrial,
    liveTvTrialDisclaimer,
    showValuePropBullets,
    isHuluFreeTrialsEnabled,
  } = stateProps;
  const { disclaimer, identifier } = plan;
  const { onClick } = ownProps;

  return {
    ...stateProps,
    onPlanClick: () => onClick(plan),
    onLinkPlanClick: linkPlan => onClick(linkPlan),
    disclaimerProps: {
      disclaimer,
      dPlusTermsUrl,
      show3UpView,
      showFlashSalePlan,
      identifier,
      isDesktop,
      isDisneySuperBundle,
      isNOAHIncluded,
      learnMoreUrl,
      noahTooltipMessage,
      planIncludesLive,
      showLiveTvTrial,
      liveTvTrialDisclaimer,
      showValuePropBullets,
      isHuluFreeTrialsEnabled,
      openModal: () =>
        dispatch(
          showPlanSelectTermsModal(modalTitle, get(plan, 'additionalTerms', []))
        ),
    },
  };
};

export default withWindowSize(
  connect(mapStateToProps, null, mergeProps)(AlternativePlanWrapper)
);
