import * as newrelic from '../api/NewRelic';

const expiry = 60 * 60 * 1000; /* ms */

export default {
  setItem(key, value) {
    const data = { value, timestamp: Date.now() };
    window.sessionStorage.setItem(key, JSON.stringify(data));
  },

  getItem(key) {
    let data = null;
    try {
      data = JSON.parse(window.sessionStorage.getItem(key));
    } catch (e) {
      newrelic.noticeError('Failed to parse sessionStorage JSON.');
    }

    if (!data) {
      return null;
    }
    if (Date.now() - expiry > data.timestamp) {
      window.sessionStorage.removeItem(key);
      return null;
    }
    return data.value;
  },

  removeItem(key) {
    window.sessionStorage.removeItem(key);
  },
};
