import { getIsUnifiedActivationEnabled } from '../selectors/featureFlag';
import { getPlans } from '../selectors/plans';
import { routePush, routeReplace, urlRedirect } from './routingActions';
import { updateSelectedPlan } from './planSelectActions';
import { ACCOUNT_INFO } from '../constants/routes';
import { getQuery } from '../selectors/flow';
import ENV from '../../../config/env';
import { deleteCartAbandonmentCookie } from '../utils/cartAbandonmentUtils';

/**
 * Checks that the Unified Activation flow can be initiated, selects a random plan
 * to be able to go to Account Creation, and redirects the user there.
 */
export const handleStartUnifiedActivation = () => (dispatch, getState) => {
  if (!getIsUnifiedActivationEnabled(getState())) {
    // Redirect to page not found error if the feature flag is disabled
    return dispatch(routeReplace('/error'));
  }

  // Select any standalone plan, it will be discarded when redirected to CUP, but
  // we need something selected in order to not get kicked out of Account Creation.
  const planToSelect = getPlans(getState()).find(
    plan => !plan.isDisneySuperBundle
  );
  dispatch(updateSelectedPlan(planToSelect));

  // Redirect to Account Creation
  return dispatch(routePush(ACCOUNT_INFO));
};

/**
 * Redirects the user to CUP in the appropriate environment
 */
export const handleRedirectToUnifiedActivation = () => (dispatch, getState) => {
  const redirectUrl = `${ENV.url.cup}/activate`;
  const { providerId, token } = getQuery(getState());

  // Clear cart abandonment cookie because it's not applicable for Activations
  deleteCartAbandonmentCookie();

  // Redirect to CUP
  return dispatch(urlRedirect(redirectUrl, { providerId, token }));
};
