import React, { createRef, Component } from 'react';
import PropTypes from 'prop-types';

import { keepFocusWithin } from '../../utils/a11y';

import '../../styles/errorModal.scss';

class ModalError extends Component {
  constructor() {
    super();
    this.modalRef = createRef();
  }

  componentDidMount() {
    this.keepFocusWithin = keepFocusWithin(this.modalRef);
  }

  componentWillUnmount() {
    this.keepFocusWithin.remove();
  }

  render() {
    const {
      message,
      closeModal,
      preventClose,
      redirectText,
      redirectRoute,
    } = this.props;

    const onClickFunc = e => {
      e.stopPropagation();
    };

    return (
      <div id="error-modal" className="modal" onClick={onClickFunc}>
        <div ref={this.modalRef} role="dialog" tabIndex="-1">
          <div className="modal-header" />
          <div className="modal-body">
            {message}
            <p>
              <a href={redirectRoute} target="_blank" rel="noopener noreferrer">
                {redirectText}
              </a>
            </p>
          </div>
          <div className="modal-footer" />
          {preventClose || (
            <button
              aria-label="Close"
              className="modal-close"
              onClick={closeModal}
              type="button"
            >
              <img
                className="modal-close__icon"
                alt="Close"
                src="/static/images/close-icon.svg"
              />
            </button>
          )}
        </div>
      </div>
    );
  }
}

ModalError.propTypes = {
  message: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  preventClose: PropTypes.bool.isRequired,
  redirectText: PropTypes.string.isRequired,
  redirectRoute: PropTypes.string.isRequired,
};

export default ModalError;
