import { routeReplace, urlRedirect } from './routingActions';
import { fetchConfig } from '../api/ConfigApi';
import * as types from '../constants/actionTypes';
import {
  getIsBundlePartnerFlow,
  getIsUnifiedActivationFlow,
} from '../selectors/flow';
import { getConfigRequest } from '../selectors/config';
import { getIsLoggedInActiveSubscriber } from '../selectors/user';
import { clearStorage } from '../store';

import env from '../../../config/env';
import { CHANGE_PLAN_URL } from '../constants/misc';
import * as routes from '../constants/routes';
import { getShouldCheckSwitchEligibility } from '../selectors/bundlePartnerSwitchEligibility';
import {
  getIsHiSwitchFlow,
  getHiSwitchRedirectUrl,
} from '../selectors/siteconfig';

// Config API actions
export function getConfig(overrideRedirectionUrl) {
  return (dispatch, getState) => {
    dispatch(getConfigStart());

    return fetchConfig(getConfigRequest(getState())).then(
      response => {
        dispatch(getConfigSuccess(response));
        const {
          config: { giftCode },
          flow: { partnerFlow },
        } = getState();
        const isUnifiedActivationFlow = getIsUnifiedActivationFlow(getState());

        // If the user is already an active subscriber we usually want them to
        // finish their purchase in Account Management instead of Sign Up
        const shouldInterruptSignupFlow =
          getIsLoggedInActiveSubscriber(getState()) &&
          !partnerFlow &&
          !isUnifiedActivationFlow;

        // Handle redirection for logged-in subscribers.
        if (shouldInterruptSignupFlow) {
          clearStorage();

          if (overrideRedirectionUrl) {
            return dispatch(urlRedirect(overrideRedirectionUrl));
          }

          if (giftCode) {
            // Redirect to Gift card redemption
            return dispatch(
              urlRedirect(env.url.redeemGiftCode, { code: giftCode.code })
            );
          }

          if (getIsBundlePartnerFlow(getState())) {
            // Continue to legacy DSS Activation.
            return handleLoginForBundlePartnerFlow(getState, dispatch);
          }

          if (getIsHiSwitchFlow(getState())) {
            // Redirect to hi-switch flows if configured.
            return dispatch(urlRedirect(getHiSwitchRedirectUrl(getState())));
          }

          // Redirect to Account Management.
          return dispatch(urlRedirect(CHANGE_PLAN_URL));
        }

        return response;
      },
      error => {
        dispatch(getConfigFailure(error));
        return Promise.reject(error);
      }
    );
  };
}

/**
 * For VerizonViaDss bundle partner flows, the user should be sent to /bundles/switch
 * to check their eligibility. Non-verizon bundle flows will be directed to site.
 *
 * @param getState Function to get the state.
 * @param dispatch Dispatch function.
 */
const handleLoginForBundlePartnerFlow = (getState, dispatch) => {
  if (getShouldCheckSwitchEligibility(getState())) {
    return dispatch(routeReplace(routes.BUNDLE_PARTNER_SWITCH_ELIGIBILITY));
  }

  return dispatch(urlRedirect(env.url.site));
};

export function getConfigStart() {
  return {
    type: types.GET_CONFIG.START,
  };
}

export function getConfigSuccess(response) {
  return {
    type: types.GET_CONFIG.SUCCESS,
    payload: response,
  };
}

export function getConfigFailure(error) {
  return {
    type: types.GET_CONFIG.FAILURE,
    payload: error,
  };
}
