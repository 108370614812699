export const ERROR_CODES = {
  INELIGIBLE_PROMOTION: 'ineligible-promotion',
  INELIGIBLE_DISCOUNT: 'ineligible-discount',
  INVALID_PARTNER_OPERATION: 'invalid-partner-operation',
  AUTHENTICATION_FAILURE: 'authentication-failure',
  UNKNOWN: 'unknown',
  USER_UNAUTHORIZED: 'user-not-authorized',
  USER_EMAIL_REGISTERED: 'user-email-registered',
  USER_PASSWORD_POLICY_CHECK_FAILED: 'user-password-policy-check-failed',
  ALREADY_SUBSCRIBED: 'already-subscribed',
  CUSTOMER_NOT_FOUND: 'customer-not-found',
  INVALID_PAYMENT_INFO: 'invalid-payment-info',
  AVS_FAILURE: 'avs-failure',
  SERVICE_ERROR: 'service-error',
  AGE_RESTRICTED: 'age-restricted',
  DSS_SUBSCRIBED: 'dss-subscribed',
  DSS_REDIRECT: 'dss-redirect',
};

export const DEFAULT_ERROR = {
  code: ERROR_CODES.UNKNOWN,
  message:
    'We could not complete your request right now. Please try again later',
};
