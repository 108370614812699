import React from 'react';
import PropTypes from 'prop-types';
import { actions } from 'react-redux-form';

import * as formActions from '../actions/formActions';
import { getAccountStatus } from '../api';

/**
 * Special kind of error message for the Account page form that allows for an
 * `onClick` handler to perform some actions in order to clean up the form state
 * and redirect the user to the Log In page.
 */
export default class EmailError extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const {
      showSwap,
      swapForm,
      dispatch,
      formValue,
      model,
      modelValue,
      validStatuses,
      isUnifiedIdentityOn,
    } = this.props;
    if (showSwap) {
      swapForm();
      dispatch(actions.resetValidity(formValue.model));
      dispatch(actions.focus(model));
      dispatch(actions.setPending(model, true));
      getAccountStatus(modelValue, isUnifiedIdentityOn).then(
        response => {
          const status = response ? response.status : undefined;
          dispatch(formActions.setEmailValidity(model, status, validStatuses));
          dispatch(actions.setPending(model, false));
        },
        () => {
          dispatch(
            formActions.setEmailValidity(model, 'serviceUnavailable', [
              'available',
            ])
          );
          dispatch(actions.setPending(model, false));
        }
      );
    }
  }

  render() {
    const { showSwap, children } = this.props;

    if (showSwap) {
      return (
        <button
          className="errors errors--action"
          id="email-error"
          onClick={this.handleClick}
          type="button"
        >
          {children}
        </button>
      );
    }

    return (
      <div className="errors" id="email-error">
        {children}
      </div>
    );
  }
}

EmailError.propTypes = {
  model: PropTypes.string.isRequired,
  modelValue: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  showSwap: PropTypes.bool.isRequired,
  swapForm: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  validStatuses: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  isUnifiedIdentityOn: PropTypes.bool.isRequired,
  formValue: PropTypes.shape({
    model: PropTypes.string.isRequired,
  }).isRequired,
};
