import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import Badge from '../components/Badge';
import LedgerInfo from '../components/Ledger/LedgerInfo';
import { ITEM_TYPES as types } from '../constants/ledger';
import { findPlanById } from '../selectors/plans';
import { getAddonsById } from '../selectors/addons';
import { getPlans } from '../selectors/config';
import { BADGE_TYPES } from '../constants/badge';

const LedgerInfoWithBadge = ({
  name,
  promoBadgeText,
  promoDescriptionText,
}) => (
  <>
    {promoBadgeText && (
      <Badge badgeType={BADGE_TYPES.LIGHT_GRAY} description={promoBadgeText} />
    )}
    <LedgerInfo name={name} description={promoDescriptionText} />
  </>
);

const mapStateToProps = (state, { id, type, promotions }) => {
  const promoAvailable = !isEmpty(promotions);

  const promoEndDate = get(promotions, '[0].endDate', null);

  const formatPromoEndDate =
    promoEndDate && new Date(promoEndDate).toLocaleDateString();

  const trial =
    type === types.BASE_BUNDLE
      ? get(findPlanById(id, getPlans(state)), 'trial', {})
      : get(getAddonsById(state)[id], 'trial', {});

  const promoTrialText = () => {
    if (get(trial, 'unit') === 'week') {
      return '7-DAY FREE TRIAL';
    }
    if (get(trial, 'unit') === 'month') {
      return '30-DAY FREE TRIAL';
    }
    return '';
  };

  return {
    promoBadgeText: (promoAvailable && promoTrialText()) || '',
    promoDescriptionText:
      (promoAvailable &&
        formatPromoEndDate &&
        `You will be billed on ${formatPromoEndDate}`) ||
      '',
  };
};

LedgerInfoWithBadge.propTypes = {
  name: PropTypes.string.isRequired,
  promoBadgeText: PropTypes.string,
  promoDescriptionText: PropTypes.string,
};

export default connect(mapStateToProps)(LedgerInfoWithBadge);
