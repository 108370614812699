import { createReducer } from 'redux-starter-kit';
import { GET_FEATURE_FLAGS } from '../constants/actionTypes';

export const initialState = {
  featureFlags: null,
  error: null,
  loading: false,
};

export default createReducer(initialState, {
  [GET_FEATURE_FLAGS.START]: state => {
    state.loading = true;
  },
  [GET_FEATURE_FLAGS.SUCCESS]: (state, { payload }) => {
    state.error = null;
    state.loading = false;
    state.featureFlags = payload.featureFlags;
    state.status = payload.status;
  },
  [GET_FEATURE_FLAGS.FAILURE]: (state, { payload }) => {
    state.error = payload;
    state.loading = false;
  },
});
