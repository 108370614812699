import { createSelector } from 'reselect';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import intersection from 'lodash/intersection';

import { SPRINTV2, PARTNERSHIP } from '../constants/partners';
import { SASH } from '../constants/plans';
import { getIsBundlePartnerFlow, getPartnerFlow } from './flow';
import { getPartnerOfferContext } from './partners';
import { getControlText } from './siteconfig';
import { isCuriosityIncluded } from './plan';
import { replaceKeys } from '../utils/textUtils';
import { getPayload as getBundlePartnerPayload } from './bundlePartner';

export const getPlans = state => get(state, 'config.plans', []);
export const getIsLoaded = state => get(state, 'config.loaded', false);
export const getUser = state => get(state, 'config.user');
export const getMessaging = state => get(state, 'config.messaging');

export const getIsSashOnly = createSelector(
  getPlans,
  plans => plans.length === 1 && plans[0].identifier === SASH
);

export const getIsMissingPlans = createSelector(
  getPlans,
  plans => plans.length === 0
);

export const getIsCuriosityIncluded = createSelector(getPlans, plans =>
  plans.some(isCuriosityIncluded)
);

const PROMO_CODE_FIELDS = [
  'promotion_code',
  'partner_code',
  'vip_code',
  'human_code',
];
export const getPromotionCode = query =>
  PROMO_CODE_FIELDS.map(key => query[key]).find(value => value != null);

const getThirdPartyId = state => {
  const partnerFlow = getPartnerFlow(state);

  if (partnerFlow === SPRINTV2.FLOW) {
    return get(state, 'partner.sprintV2Eligibility.response.subscriberId');
  }

  if (partnerFlow === PARTNERSHIP.FLOW) {
    return get(getPartnerOfferContext(state), 'partnerAccountId', null);
  }

  if (getIsBundlePartnerFlow(state)) {
    return get(getBundlePartnerPayload(state), 'partnerUserId', null);
  }

  return null;
};

const getDiscountIds = createSelector(
  getPartnerOfferContext,
  partnerOfferContext => get(partnerOfferContext, 'discountIds', [])
);

const getThirdPartyProductIds = createSelector(
  getBundlePartnerPayload,
  payload => get(payload, 'products', null)
);

const getProgramId = state => {
  const partnerFlow = getPartnerFlow(state);

  if (partnerFlow === PARTNERSHIP.FLOW) {
    return get(getPartnerOfferContext(state), 'programId', null);
  }

  return get(state, 'flow.query.promo_id', null);
};

const getGiftCodeValue = state =>
  get(state, 'config.giftCode.value.value', null);

const getShowVipMessage = ({ config: { plans } }) =>
  plans.some(plan => get(plan, 'subscription.promotion.type', '') === 'vip');

const getShowInvalidCodeMessage = ({ config: { errorMessages } }) =>
  errorMessages.some(
    err =>
      err.code === 'field-error' &&
      (err.field === 'gift-code' ||
        err.field === 'promotion-code' ||
        err.field === 'device-code')
  );

export const getPlanCodeMessage = state => {
  if (getGiftCodeValue(state)) {
    return replaceKeys(
      getControlText(state, 'plan_select_page.message.gift_code', {}),
      {
        GIFT_CODE: getGiftCodeValue(state),
      }
    );
  }

  if (getShowVipMessage(state)) {
    return getControlText(state, 'plan_select_page.message.vip', {});
  }

  if (getShowInvalidCodeMessage(state)) {
    return getControlText(state, 'plan_select_page.message.invalid_code', {});
  }

  return {};
};

const DEEJAY_DEVICE_ID = '-7';

export const getConfigRequest = state => {
  const {
    flow: {
      query,
      client,
      client: { subpartner },
    },
  } = state;

  return {
    ...client,
    email: query.email,
    from: query.from,
    deviceCode: query.device_code,
    deviceId: DEEJAY_DEVICE_ID,
    promotionCode: getPromotionCode(query),
    referralCode: query.referral_code,
    referralType: query.referral_reward_type,
    giftCode: query.gift_code,
    promotionName: query.promotion_name,
    programId: getProgramId(state),
    appleTransfer: query.apple_transfer,
    rokuPac: subpartner === 'roku_web' ? query.roku_pac : null,
    thirdPartyId: getThirdPartyId(state),
    discountIds: getDiscountIds(state),
    thirdPartyProductIds: getThirdPartyProductIds(state),
  };
};

/**
 * Check if a user is assigned to one or more given treatments. If multiple
 * treatments are checked against, a user only needs to be assigned to one for
 * this check to pass.
 *
 * @param {object} state - the redux state tree.
 * @param {string|[]} developerId - one or more developer IDs to check for
 */

export const getMatchingAssignments = (state, developerId) => {
  const currentAssignments = get(state, 'config.assignments', []) || [];
  return intersection(
    currentAssignments,
    isArray(developerId) ? developerId : [developerId]
  );
};

export const hasAssignment = createSelector(
  getMatchingAssignments,
  matchingAssignments => matchingAssignments.length > 0
);
