export const SASH = 'SASH';
export const NOAH = 'NOAH';
export const LIVE = 'LIVE';
export const LIVE_ONLY = 'LIVE_ONLY';
export const NOADS_HULU_DISNEY_ESPN = 'NOADS_HULU_DISNEY_ESPN';
export const NOADS_LIVE_DISNEY_ESPN = 'NOADS_LIVE_DISNEY_ESPN';
export const ADS_HULU_DISNEY_ESPN = 'ADS_HULU_DISNEY_ESPN';
export const ADS_LIVE_DISNEY_ESPN = 'ADS_LIVE_DISNEY_ESPN';
export const PSEUDO_2P = 'PSEUDO_2P';
export const ADS_MAX_BUNDLE = 'ADS_MAX_HULU_DISNEY';
export const NOADS_MAX_BUNDLE = 'NOADS_MAX_HULU_DISNEY';
export const DUO_BASIC = 'HULU_DISNEY_ADS_2P_BUNDLE';
export const DUO_PREMIUM = '2P_BUNDLE_PREMIUM';

export const BUNDLE_PLANS = [
  NOADS_HULU_DISNEY_ESPN,
  ADS_HULU_DISNEY_ESPN,
  PSEUDO_2P,
  ADS_MAX_BUNDLE,
  NOADS_MAX_BUNDLE,
  DUO_PREMIUM,
  DUO_BASIC,
];

/**
 * Map of componentIds:
 * 1:   SASH
 * 2:   NOAH
 * 6:   CURIOSITY
 * 15:  DISNEY_PLUS
 * 16:  ESPN_PLUS
 * 28:  MAX_BUNDLE_ADS (Hulu, Disney+ Max)
 * 29:  MAX_BUNDLE_NOADS (Hulu, Disney+, Max)
 * */

export const BASE_COMPONENT_IDS = [1, 2, 6];
export const DISNEY_BUNDLE_IDS = [1, 15, 16];
export const ESPN_PLUS_COMPONENT_ID = 16;
export const DUO_BASIC_COMPONENT_ID = 25;
export const DUO_PREMIUM_COMPONENT_ID = 26;
export const MAX_BUNDLE_ADS_COMPONENT_ID = 28;
export const MAX_BUNDLE_NOADS_COMPONENT_ID = 29;

/**
 * Bundle IDs (known as Plan IDs within the sufo-redux state)
 * Can be found at: http://hype.prod.hulu.com/bundles
 * 28: Max with Ads Bundle
 * 29: Max No Ads Bundle
 */
export const MAX_BUNDLE_PLAN_IDS = [28, 29];

/**
 * Plan's tab index options
 */
export const BASIC_PLANS_TAB = 'basic';
export const BUNDLE_PLANS_TAB = 'bundle';

// List of tab options
export const TAB_OPTIONS = [
  {
    index: BASIC_PLANS_TAB,
    text: 'Base plans',
  },
  {
    index: BUNDLE_PLANS_TAB,
    text: 'Bundle / save',
  },
];

export const LIVE_BANNER_PROGRAM_ID = '600';
