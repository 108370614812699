// IDM Authenticate recaptcha ref instance
let authRecaptchaRef;

export function setAuthRecaptchaRef(ref) {
  authRecaptchaRef = ref;
}

export function getAuthRecaptchaInstance() {
  return authRecaptchaRef && authRecaptchaRef.current;
}
