import React from 'react';
import { connect } from 'react-redux';
import { urlRedirect } from '../actions/routingActions';
import SpotifyLandingFooter from '../components/SpotifyLandingFooter';
import { SPOTIFY } from '../constants/partners';
import { SPOTIFY_CALLBACK } from '../constants/routes';
import {
  SUBSCRIPTION_START,
  SUBSCRIPTION_STEP_START,
} from '../metrics/metricsEvents';
import track from '../metrics/track';
import SprintSpotifyLandingPage from '../pages/SprintSpotifyLandingPage';
import { getPartnerFlow } from '../selectors/flow';
import { getSpotifyLandingEligibilityText } from '../selectors/siteconfig';
import { getCookie } from '../utils/cookieUtils';

const mapStateToProps = state => ({
  partnerFlow: getPartnerFlow(state),
  eligibilityText: getSpotifyLandingEligibilityText(state),
});

const mapDispatchToProps = dispatch => ({
  onClick: isExistingUser => () => {
    const testCookie = getCookie('_spotify_test_uid');
    const redirectUri = `${window.location.origin}${SPOTIFY.REDIRECT_URL}${
      isExistingUser ? SPOTIFY.EXISTING_USER_PARAM : ''
    }`;

    // Skips Spotify OAuth step if test mode is set.
    if (SPOTIFY.TEST_MODE_ALLOWED && testCookie) {
      const query = {
        [SPOTIFY.PARAM_CODE]: testCookie,
        [SPOTIFY.PARAM_REDIRECT]: redirectUri,
      };
      dispatch(urlRedirect(SPOTIFY_CALLBACK, query));
    } else {
      const query = {
        response_type: 'code',
        client_id: SPOTIFY.CLIENT_ID,
        scope: SPOTIFY.SCOPE,
        redirect_uri: redirectUri,
      };
      dispatch(
        urlRedirect(`${SPOTIFY.API_URL}${SPOTIFY.AUTH_OPERATION}`, query)
      );
    }
  },
});

const mergeProps = (
  { eligibilityText, ...stateProps },
  { onClick },
  { trackEvent }
) => ({
  onLoaded: () => {
    trackEvent(SUBSCRIPTION_START);
    trackEvent(SUBSCRIPTION_STEP_START, {
      title: 'SUF - Landing Page',
      pageId: 'landing',
    });
  },

  titleText: 'Just a few more steps!',
  subTitles: [
    'First, choose an option below and let Hulu link to your Spotify account.',
    "If you're new to Hulu, you'll create an account. If you have an account, just log in to continue.",
  ],
  buttons: [
    {
      id: 'new-user-button',
      text: "I'm new to Hulu",
      onClick: onClick(false),
    },
    {
      id: 'existing-user-button',
      text: 'I have a Hulu account',
      onClick: onClick(true),
    },
  ],
  footer: <SpotifyLandingFooter eligibilityText={eligibilityText} />,
  ...stateProps,
});

export default track(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(SprintSpotifyLandingPage)
);
