import * as envVars from '../../../config/env';

export const FORWARD_PARAMS_WHITELIST = [
  'src',
  'hsrc',
  'pdv',
  'cmp',
  'promo_id',
];

export const COOKIES_DOMAIN = envVars.cookieDomain;
export const SUCCESS_PATH = '/account/addons/success';
export const HULU_DOMAIN_REGEX = /^[a-z]+\.hulu(qa)?\.com/;

/**
 * Override the Account page URL based on environment.
 * This needs to be evaluated at runtime because staging and test environments
 * use the same staging artifact, but test needs a different URL value.
 *
 * Running Locally:
 * When running the command `npm run dev:staging`, the env variable ACCOUNT_URL is
 * hard coded within the npm command. ACCOUNT_URL overrides the envVars.url.changePlan
 * variable in the `env.dev.js` file.
 */
export const CHANGE_PLAN_URL =
  window.location.host === 'signup.test.huluqa.com'
    ? 'https://develop-test.site-secure.huluqa.com/account'
    : envVars.url.changePlan;

/**
 * OneTrust is a third-party tool that powers our "Do Not Sell My Personal Information"
 * footer link for managing the user's personal information preferences.
 */
export const ONETRUST_SDK_SOURCE_URL =
  'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
export const ONETRUST_SDK_DOMAIN_SCRIPT = {
  prod: '6d45ba73-6015-4447-9ccf-e722d2959983',
  nonprod: 'c270fd7d-b546-4e84-99b0-e480304e7727',
};

export const ONETRUST_HULU_CONSENT_GROUP = 'SPD_BG';
