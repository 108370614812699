import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LearnMoreModal } from '@hulu/web-login-ui';
import ModalDefault from './ModalDefault';
import ModalError from './ModalError';
import ModalTerms from './ModalTerms';
import ModalStudent from './ModalStudent';
import * as modals from '../../constants/modals';
import { zIndexLearnMoreModal } from '../../styles/variables.scss';

import '../../styles/modal.scss';

class Modal extends Component {
  renderContent() {
    const {
      acceptText,
      className,
      closeModal,
      declineText,
      fitContent,
      footer,
      message,
      preventClose,
      redirectRoute,
      redirectText,
      submitModal,
      title,
      type,
    } = this.props;

    switch (type) {
      case modals.ERROR:
        return (
          <ModalError
            message={message}
            closeModal={closeModal}
            preventClose={preventClose}
            redirectText={redirectText}
            redirectRoute={redirectRoute}
          />
        );
      case modals.TERMS:
        return (
          <ModalTerms
            message={message}
            closeModal={closeModal}
            fitContent={!!fitContent}
          />
        );
      case modals.STUDENT:
        return (
          <ModalStudent
            title={title}
            message={message}
            closeModal={closeModal}
            submitModal={submitModal}
            acceptText={acceptText}
            declineText={declineText}
          />
        );
      case modals.DEFAULT:
        return (
          <ModalDefault
            title={title}
            message={[].concat(message)}
            closeModal={closeModal}
            className={className}
            footer={footer}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const modalContent = this.renderContent();
    const showLearnMoreModal = this.props.type === modals.LEARN_MORE_MODAL;

    return showLearnMoreModal ? (
      <LearnMoreModal
        zIndex={zIndexLearnMoreModal}
        onDismiss={this.props.closeModal}
      />
    ) : (
      modalContent && (
        <div className="overlay" onClick={this.props.closeModal}>
          {modalContent}
        </div>
      )
    );
  }
}

Modal.propTypes = {
  acceptText: PropTypes.string,
  className: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  declineText: PropTypes.string,
  fitContent: PropTypes.bool,
  footer: PropTypes.node,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  preventClose: PropTypes.bool,
  redirectRoute: PropTypes.string,
  redirectText: PropTypes.string,
  submitModal: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
};

export default Modal;
