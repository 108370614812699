import { createSelector } from 'reselect';
import difference from 'lodash/difference';
import get from 'lodash/get';
import union from 'lodash/union';
import find from 'lodash/find';

import { getSelectedPlan } from './user';

export const getAddons = state => get(state, 'config.addons', []);
export const getAddonBundles = state => get(state, 'config.addonBundles', []);

export const getSelectedAddonIds = state =>
  get(state, 'addons.selectedAddons', []);
export const getSelectedAddonBundleIds = state =>
  get(state, 'addons.selectedAddonBundles', []);

export const getAddonsById = createSelector([getAddons], addons =>
  addons.reduce((addonsById, addon) => {
    addonsById[addon.componentId] = addon;
    return addonsById;
  }, {})
);

export const getAddonIdentifier = (state, id) =>
  getAddonsById(state)[id].identifier;

export const getAddonBundlesById = createSelector(
  [getAddonBundles],
  addonBundles =>
    addonBundles.reduce((addonBundlesById, addonBundle) => {
      addonBundlesById[addonBundle.bundleId] = addonBundle;
      return addonBundlesById;
    }, {})
);

export const getAddonBundleComponentIds = (state, id) =>
  getAddonBundlesById(state)[id].componentIds;
export const getAddonBundleIdentifiers = (state, id) =>
  getAddonBundlesById(state)[id].componentIdentifiers;

export const getSelectedAddonBundles = createSelector(
  [getAddonBundlesById, getSelectedAddonBundleIds],
  (addonBundles, selectedAddonBundleIds) =>
    selectedAddonBundleIds.map(id => addonBundles[id])
);

export const getSelectedAddonBundleRequests = createSelector(
  [getSelectedAddonBundles],
  selectedAddonBundles =>
    selectedAddonBundles.map(addonBundle => addonBundle.requestObject)
);

export const getSelectedAddons = createSelector(
  [getAddonsById, getSelectedAddonIds],
  (addons, selectedAddonIds) => selectedAddonIds.map(id => addons[id])
);

export const getSelectedAddonIdentifiers = createSelector(
  [getAddonsById, getSelectedAddonIds],
  (addons, selectedAddonIds) =>
    selectedAddonIds.map(id => addons[id].identifier)
);

export const getSelectedAddonRequests = createSelector(
  [getSelectedAddons],
  selectedAddons => selectedAddons.map(addon => addon.requestObject)
);

export const getSelectedAddonBundleComponentIds = createSelector(
  [getSelectedAddonBundles],
  selectedAddonBundles =>
    selectedAddonBundles.reduce(
      (componentIds, addonBundle) =>
        componentIds.concat(addonBundle.componentIds),
      []
    )
);

export const getSelectedAddonBundleComponentIdentifiers = createSelector(
  [getSelectedAddonBundles],
  selectedAddonBundles =>
    selectedAddonBundles.reduce(
      (componentIds, addonBundle) =>
        componentIds.concat(addonBundle.componentIdentifiers),
      []
    )
);

export const getAggregateSelectedAddonIdentifiers = createSelector(
  [getSelectedAddonIdentifiers, getSelectedAddonBundleComponentIdentifiers],
  (addonIdentifiers, addonBundleAddonIdentifiers) =>
    union(addonIdentifiers, addonBundleAddonIdentifiers)
);

export const getAllSelectedAddonRequests = createSelector(
  [getSelectedAddonRequests, getSelectedAddonBundleRequests],
  (addonRequests, addonBundleRequests) => ({
    addons: addonRequests,
    addonBundles: addonBundleRequests,
  })
);

export const wasOfferedAddonsOrAddonBundles = createSelector(
  [getAddons, getAddonBundles],
  (addons, addonBundles) => addons.length > 0 || addonBundles.length > 0
);

export const isBuyingAddonsOrAddonBundles = createSelector(
  [getSelectedAddonIds, getSelectedAddonBundleIds],
  (selectedAddonsIds, selectedAddonBundleIds) =>
    selectedAddonsIds.length > 0 || selectedAddonBundleIds.length > 0
);

/**
 * Returns true if the `requiredComponentIds` are all included in the `planComponentIds` array.
 * lodash/difference returns an empty array if all elements in subset are inside set.
 */
const planHasRequiredComponentIds = (
  planComponentIds,
  requiredComponentIds = []
) => !difference(requiredComponentIds, planComponentIds).length;

/**
 * Filters the addons by checking if requiredComponentIds is a subset of the planComponentIds.
 */
export const getAddonIdsCompatibleWithPlan = createSelector(
  [getAddons, getSelectedPlan],
  (addons, { componentIds }) =>
    addons
      .filter(addon =>
        planHasRequiredComponentIds(componentIds, addon.requiredComponentIds)
      )
      .map(addon => addon.componentId)
);

/**
 * Filters the addon bundles by checking if requiredComponentIds is a subset of the
 * the plan's componentIds
 * In case there's an addon bundle that have some componentIds in their requiredComponentId,
 * we'll check for the requiredComponentIds as a subset of the concat between
 * the plan's and the addon's componentIds.
 */
export const getAddonBundleIdsCompatibleWithPlan = createSelector(
  [getAddonBundles, getSelectedPlan],
  (addonBundles, { componentIds }) => {
    const bundleComponentIds = addonBundles
      .filter(
        addon =>
          addon.componentIds &&
          addon.componentIds.some(id => addon.requiredComponentIds.includes(id))
      )
      .map(addon => addon.componentIds)
      .reduce((arr, id) => arr.concat(id), []);
    const combinedComponentIds =
      bundleComponentIds && componentIds
        ? bundleComponentIds.concat(
            componentIds.filter(planId => !bundleComponentIds.includes(planId))
          )
        : componentIds;
    return addonBundles
      .filter(addonBundle =>
        planHasRequiredComponentIds(
          combinedComponentIds,
          addonBundle.requiredComponentIds
        )
      )
      .map(addonBundle => addonBundle.bundleId);
  }
);

export const hasSelectedAddon = (state, componentId) =>
  getSelectedAddonIds(state).includes(componentId);

export const getExternalProductIdByComponentId = (state, componentId) => {
  return find(
    getSelectedAddons(state),
    addon => addon.componentId === componentId
  )?.externalProductId;
};
