import { COOKIES_DOMAIN } from '../constants/misc';
import { getNewGuid } from './browserUtils';

const GUID = 'guid';
const HULU_PLUS_CMP = '_hulu_plus_cmp';
const HULU_KIDS_PROFILE = '_hulu_is_p_kids';
const HULU_PGID = '_hulu_pgid';
const HULU_PID = '_hulu_pid';
const HULU_SESSION_STATUS = '_hsstat';
const HULU_UID = '_hulu_uid';
const HULU_WEB_SUB = '_hulu_web_sub_visited';
export const HULU_DT = '_hulu_dt';
export const HULU_SESSION = '_hulu_session';
export const CART_ABANDONMENT = '_hulu_cart_abandonment';
export const CART_ABANDONMENT_TTL = 30; // days

export const ONETRUST_CONSENT = 'OptanonConsent';

const SUBSCRIBER_PGID = 2;

const tryDecode = val => {
  try {
    return decodeURIComponent(val);
  } catch (e) {
    return val;
  }
};

const parseCookie = cookie => {
  if (typeof cookie !== 'string') {
    throw new TypeError('argument cookie must be a string');
  }

  const obj = {};
  const pairs = cookie.split(/; */);

  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i];
    let eqIdx = pair.indexOf('=');

    // skip things that don't look like key=value
    if (eqIdx < 0) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const key = pair.substr(0, eqIdx).trim();
    let val = pair.substr(++eqIdx, pair.length).trim();

    // quoted values
    if (val[0] === '"') {
      val = val.slice(1, -1);
    }

    if (undefined === obj[key]) {
      obj[key] = tryDecode(val);
    }
  }

  return obj;
};

const getCookie = (name, nullIfCookieNotSupported) => {
  if (typeof document === 'undefined') {
    if (nullIfCookieNotSupported) {
      return null;
    }
    throw Error('');
  }
  const cookies = parseCookie(document.cookie);
  return cookies && cookies[name];
};

const setCookie = (name, value, days, domain) => {
  if (typeof document.cookie === 'undefined') {
    return;
  }

  let expires = new Date();
  if (typeof days === 'undefined') {
    expires.setMonth(expires.getMonth() + 1);
  } else if (days === 0) {
    expires = null;
  } else {
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  }

  const encode = encodeURIComponent || escape;
  let encodedValue = `${encode(name)}=${encode(value)}`;

  if (encode === escape) {
    encodedValue = encodedValue.replace(/\+/g, '%2B');
  }

  if (expires) {
    encodedValue += `; expires=${expires.toUTCString()}`;
  }
  encodedValue += '; path=/';

  if (domain !== null && domain !== undefined) {
    encodedValue += `; domain=${domain}`;
  } else {
    document.cookie = `${encode(
      name
    )}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
    encodedValue += `; domain=${COOKIES_DOMAIN}`;
  }

  document.cookie = encodedValue;
};

const getCMPId = () => {
  const value = getCookie('_hulu_plus_cmp');
  return value ? parseInt(value, 10) : '';
};

const removeCookie = (name, domain = COOKIES_DOMAIN) => {
  const encode = encodeURIComponent || escape;
  document.cookie = `${encode(
    name
  )}=; path=/; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

const getGuid = () => {
  let guid = getCookie(GUID, true);

  if (guid === undefined) {
    guid = getNewGuid();
    setCookie(GUID, guid, 3650, COOKIES_DOMAIN);
  }

  return guid;
};
const getHuluPlusCmp = () => getCookie(HULU_PLUS_CMP, true);
const getHuluSessionStatus = () => getCookie(HULU_SESSION_STATUS, true);
const getPgId = () => {
  if (getUserId() === undefined) return 1;
  const value = getCookie(HULU_PGID, true);
  return value ? parseInt(value, 10) : 1;
};
const getProfileId = () => getCookie(HULU_PID, true);

const getSessionReferrer = () => {
  let referrer = getCookie('_session_referrer');
  if (!referrer) {
    referrer = document.referrer
      .replace('http://', '')
      .replace('https://', '')
      .substr(0, 200);
    // cookie expires in 2h according to hudis code
    setCookie('_session_referrer', referrer, 0.08333);
  }
  return referrer;
};

const getUserId = () => getCookie(HULU_UID, true);

const isProfileKids = () => getCookie(HULU_KIDS_PROFILE, true);
// eslint-disable-next-line no-bitwise
const isSubscriber = () => Boolean(SUBSCRIBER_PGID & getPgId());

const setHuluSessionStatus = (value, days) =>
  setCookie(HULU_SESSION_STATUS, value, days, COOKIES_DOMAIN);

const getWebSubCookie = () => getCookie(HULU_WEB_SUB, true);

const didVisitWebSub = () => getWebSubCookie() === 'true';

const clearWebSubCookie = () => removeCookie(HULU_WEB_SUB);

export {
  clearWebSubCookie,
  didVisitWebSub,
  getCookie,
  getCMPId,
  getGuid,
  getHuluPlusCmp,
  getHuluSessionStatus,
  getProfileId,
  getSessionReferrer,
  getUserId,
  isProfileKids,
  isSubscriber,
  setCookie,
  removeCookie,
  setHuluSessionStatus,
};
