import { connect } from 'react-redux';

import {
  handleCallbackLoaded as onLoaded,
  handleCallbackClick as handleClick,
} from '../actions/sprintV2Actions';
import SprintV2RoadblockPage from '../pages/SprintV2RoadblockPage';
import {
  getCallbackPageIsLoading,
  getCallbackText,
} from '../selectors/sprintV2';

const mapStateToProps = state => ({
  isLoading: getCallbackPageIsLoading(state),
  text: getCallbackText(state),
});

export default connect(mapStateToProps, { onLoaded, handleClick })(
  SprintV2RoadblockPage
);
