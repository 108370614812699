import xor from 'lodash/xor';
import without from 'lodash/without';

import * as types from '../constants/actionTypes';

export const initialState = {
  selectedAddons: [],
  selectedAddonBundles: [],
  removedAddons: [],
  removedAddonBundles: [],
};

function addons(state = initialState, action) {
  switch (action.type) {
    case types.SET_ADDONS:
      return {
        ...state,
        selectedAddons: without(action.addons, ...state.removedAddons),
      };
    case types.TOGGLE_ADDON:
      return {
        ...state,
        selectedAddons: xor(state.selectedAddons, [action.addonId]),
      };
    case types.REMOVE_ADDON:
      return {
        ...state,
        selectedAddons: without(state.selectedAddons, action.addonId),
        removedAddons: [...state.removedAddons, action.addonId],
      };
    case types.SET_ADDON_BUNDLES:
      return {
        ...state,
        selectedAddonBundles: without(
          action.addonBundles,
          ...state.removedAddonBundles
        ),
      };
    case types.TOGGLE_ADDON_BUNDLE:
      return {
        ...state,
        selectedAddonBundles: xor(state.selectedAddonBundles, [
          action.addonBundleId,
        ]),
      };
    case types.REMOVE_ADDON_BUNDLE:
      return {
        ...state,
        selectedAddonBundles: without(
          state.selectedAddonBundles,
          action.addonBundleId
        ),
        removedAddonBundles: [
          ...state.removedAddonBundles,
          action.addonBundleId,
        ],
      };
    default:
      return state;
  }
}

export default addons;
