import * as subscriber from '../api/CreateSubscriber';
import * as account from '../api/ValidateAccount';
import * as types from '../constants/actionTypes';
import {
  getCreateSubscriberRequest,
  getValidateAccountRequest,
} from '../selectors/subscription';

export function createSubscriber() {
  return (dispatch, getState) => {
    dispatch(resetCreateSubscriber());
    dispatch(createSubscriberStart());

    return subscriber
      .createSubscriber(getCreateSubscriberRequest(getState()))
      .then(
        response => {
          dispatch(createSubscriberSuccess(response));
          return response;
        },
        error => {
          dispatch(createSubscriberFailure(error));
          return Promise.reject(error);
        }
      );
  };
}

export function createSubscriberStart() {
  return {
    type: types.CREATE_SUBSCRIBER.START,
  };
}

export function createSubscriberSuccess(response) {
  return {
    type: types.CREATE_SUBSCRIBER.SUCCESS,
    payload: response,
  };
}

export function createSubscriberFailure(error) {
  return {
    type: types.CREATE_SUBSCRIBER.FAILURE,
    payload: error,
  };
}

export function resetCreateSubscriber() {
  return {
    type: types.CREATE_SUBSCRIBER.RESET,
  };
}

export function validateAccount() {
  return (dispatch, getState) =>
    account.validateAccount(getValidateAccountRequest(getState()));
}
