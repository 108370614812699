import { connect } from 'react-redux';
import { removeAddonDueToRoadblock } from '../actions/ageRoadblockActions';
import { routeReplace, urlRedirect } from '../actions/routingActions';
import { clearLocationQuery } from '../actions/urlActions';
import MarkdownRoadblock from '../components/MarkdownRoadblock';
import { hasSelectedAddon } from '../selectors/addons';
import * as MetricsEvents from '../metrics/metricsEvents';
import track from '../metrics/track';
import { ESPN_PLUS_COMPONENT_ID, BASIC_PLANS_TAB } from '../constants/plans';
import { BILLING_INFO, GO_ONE_HULU, PLAN_SELECT } from '../constants/routes';
import { showBackButton } from '../selectors/ageRoadblockPage';
import { getAgeConfigPath, getRoadblockConfig } from '../selectors/roadblock';
import { getIsSuperbundleFlow } from '../selectors/user';
import { setSelectedPlanTab } from '../actions/planSelectActions';
import { setCartAbandonmentCookie } from '../utils/cartAbandonmentUtils';
import { getIs2PBundleFlow } from '../selectors/flow';
import { logout } from '../actions/serverActions';

const mapStateToProps = state => ({
  hasEspnAddon: hasSelectedAddon(state, ESPN_PLUS_COMPONENT_ID),
  isSuperbundleFlow: getIsSuperbundleFlow(state),
  ...state,
});

const mergeProps = (
  { hasEspnAddon, isSuperbundleFlow, ...stateProps },
  { dispatch },
  { trackEvent }
) => {
  const path = getAgeConfigPath(stateProps);
  const roadblock = getRoadblockConfig(stateProps, path);
  const is2PBundleFlow = getIs2PBundleFlow(stateProps);
  return {
    ...stateProps,
    ...roadblock,
    handleClick: () => {
      if (hasEspnAddon) {
        dispatch(removeAddonDueToRoadblock(ESPN_PLUS_COMPONENT_ID));
        dispatch(routeReplace(BILLING_INFO));
      } else {
        if (isSuperbundleFlow) {
          dispatch(clearLocationQuery());
        }
        // Check if it's 2P Bundle flow to redirect to Go One Hulu and trigger a reload
        if (is2PBundleFlow) {
          dispatch(urlRedirect(GO_ONE_HULU));
        } else {
          // Set to show Basic Plans tab before redirecting to Plan Select
          dispatch(setSelectedPlanTab(BASIC_PLANS_TAB));
          dispatch(routeReplace(PLAN_SELECT));
        }
      }
    },
    showBackButton: showBackButton(stateProps),
    handleBack: () => dispatch(logout),
    onLoaded: () => {
      // Overwrites Cart Abandonment cookie with only the user name and no plan
      setCartAbandonmentCookie(stateProps, true);

      if (hasEspnAddon) {
        trackEvent(MetricsEvents.SUBSCRIPTION_STEP_START, {
          title: 'SUF - Roadblock ESPN+ Age Ineligible',
        });
      } else {
        trackEvent(MetricsEvents.SUBSCRIPTION_STEP_START, {
          title: 'SUF - Roadblock Disney Age Ineligible',
        });
      }
    },
  };
};

export default track(
  connect(mapStateToProps, null, mergeProps)(MarkdownRoadblock)
);
