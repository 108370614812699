import get from 'lodash/get';
import { createSelector } from 'reselect';

import * as statuses from '../constants/superbundleEligibilityStatuses';
import { isUserDSSRedirect, isUserDSSSubscribed } from '../utils/dssUtils';
import { getClient } from './flow';
import { getSubscriptionNode } from './subscription';
import { getUser, getSelectedPlan } from './user';
import {
  ELIGIBILITY_STATUS_FEATURE_FLAG,
  isFeatureEnabled,
} from './featureFlag';
import { getExternalProductIdByComponentId, hasSelectedAddon } from './addons';
import { ESPN_PLUS_COMPONENT_ID } from '../constants/plans';

/**
 * Returns a boolean to say that the Eligibility Status feature is turned on, so
 * the new eligibility-status endpoint should be used.
 * @param state
 * @return Boolean
 */
function shouldUseNewEndpoint(state) {
  return isFeatureEnabled(state, ELIGIBILITY_STATUS_FEATURE_FLAG);
}

/**
 * Returns an object with the endpoint name and the endpoint body which should be used to check eligibility.
 * If the Endpoint Status feature is turned on, the eligibility-status endpoint is used. Otherwise, the dss-eligibility
 * endpoint is used.
 * Corresponding methods are called to build the endpoint body for each.
 * @param state
 * @return {{eligibilityRequest: ({client: *, subscription: *}|{product: *, email: *}), eligibilityEndpoint: (string)}}
 */
export const getEligibilityEndpoint = state => {
  const useNewEndpoint = shouldUseNewEndpoint(state);

  return {
    eligibilityEndpoint: useNewEndpoint
      ? 'eligibility-status'
      : 'dss-eligibility',
    eligibilityRequest: useNewEndpoint
      ? getEligibilityStatusRequest(state)
      : getDSSEligibilityRequest(state),
  };
};

const hasESPNAddonSelected = state =>
  hasSelectedAddon(state, ESPN_PLUS_COMPONENT_ID);
const getESPNAddonComponentId = state =>
  getExternalProductIdByComponentId(state, ESPN_PLUS_COMPONENT_ID);

/* Legacy endpoint for checking a users eligibility to signup for a Disney bundle.
 * This method is used if the 'good-soup' flag is off.
 * If ESPN Addon is selected, return ESPN componentId as the request product
 */
export const getDSSEligibilityRequest = createSelector(
  getUser,
  getSelectedPlan,
  hasESPNAddonSelected,
  getESPNAddonComponentId,
  ({ email }, { externalProductId }, hasESPNAddon, espnCompId) => {
    const product = hasESPNAddon ? espnCompId : externalProductId;

    return {
      email,
      product,
    };
  }
);

/* New endpoint to check a users eligibility to signup for a Disney bundle.
 * (Pulls eligibility rules from Ballyhoo instead of DSS)
 * This method is used if 'good-soup' feature flag is on.
 */
export const getEligibilityStatusRequest = createSelector(
  [getClient, getSubscriptionNode],
  (client, subscription) => ({
    client,
    subscription,
  })
);

const getSuperbundleResponse = state =>
  get(state, 'superbundleEligibility.eligibility.status', '').toLowerCase();
const getSuperbundleError = state => get(state, 'superbundleEligibility.error');
const getCreateSubscriberErrors = state =>
  get(state, 'subscription.createSubscriber.error.errors');

export const getStatus = createSelector(
  [getSuperbundleResponse, getSuperbundleError, getCreateSubscriberErrors],
  (response, error, createSubscriberErrors) => {
    if (error) {
      return statuses.ERROR;
    }
    if (isUserDSSRedirect(createSubscriberErrors)) {
      return statuses.REDIRECT;
    }
    if (isUserDSSSubscribed(createSubscriberErrors)) {
      return statuses.SUBSCRIBED;
    }
    return (
      Object.values(statuses).find(status => status === response) ||
      statuses.INELIGIBLE
    );
  }
);
