import { GET_VENMO_TOKEN } from '../constants/actionTypes';
import * as paymentMethods from '../constants/paymentMethods';
import { getPaymentType, getVenmoPaymentNode } from '../selectors/subscription';
import { getVenmoInstance } from '../utils/venmo';

// Venmo API actions
export function handleVenmoPayment() {
  return (dispatch, getState) => {
    if (
      getPaymentType(getState()) === paymentMethods.VENMO &&
      !getVenmoPaymentNode(getState()).token
    ) {
      return dispatch(getVenmoToken());
    }
    return Promise.resolve();
  };
}

export function getVenmoToken() {
  return dispatch =>
    getVenmoInstance()
      .then(venmoInstance => venmoInstance.tokenize())
      .then(
        response => dispatch(getVenmoTokenSuccess(response)),
        error => {
          dispatch(getVenmoTokenFailure(error));
          return Promise.reject(error);
        }
      );
}

export function getVenmoTokenSuccess(response) {
  return {
    type: GET_VENMO_TOKEN.SUCCESS,
    payload: response,
  };
}

export function getVenmoTokenFailure(error) {
  return {
    type: GET_VENMO_TOKEN.FAILURE,
    payload: error,
  };
}
