import { YOKOZUNA_API } from '../constants/apis';
import request from './request';

export const getVenmoClientToken = () =>
  request(`${YOKOZUNA_API}client-tokens/venmo`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  }).then(response => response.clientToken);

export const getPaypalClientToken = paypalTokenRequest =>
  request(`${YOKOZUNA_API}client-tokens/paypal`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(paypalTokenRequest),
  }).then(response => response.clientToken);
