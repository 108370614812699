import qs from 'querystring';

export { parse, escape, unescape } from 'querystring';

/**
 * Almost exactly like querystring's stringify method, but
 * this will remove all instances of null or undefined from
 * the object from the generated querystring.
 *
 * @param {Object} obj the Object to stringify
 * @param [...args] Any other params to querystring's stringify
 * @return {String} the encoded object (like a=3&b=4&c=foo).
 */
export function stringify(obj, ...args) {
  const query = {};
  Object.keys(obj).forEach(key => {
    if (obj[key] != null) {
      query[key] = obj[key];
    }
  });

  return qs.stringify(query, ...args);
}

export default { ...qs, stringify };
