import React, { Component } from 'react';
import PropTypes from 'prop-types';

require('../styles/panel.scss');

class Panel extends Component {
  render() {
    const { children, className, id, testid } = this.props;

    return (
      <div className={`panel ${className}`} id={id} data-testid={testid}>
        {children}
      </div>
    );
  }
}

Panel.defaultProps = {
  className: '',
};

Panel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  testid: PropTypes.string,
};

export default Panel;
