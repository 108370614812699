import some from 'lodash/some';
import { ERROR_CODES } from '../constants/errorCodes';

const isUserDSSSubscribed = errors =>
  some(errors, { code: ERROR_CODES.DSS_SUBSCRIBED });

const isUserDSSRedirect = errors =>
  some(errors, { code: ERROR_CODES.DSS_REDIRECT });

export { isUserDSSSubscribed, isUserDSSRedirect };
