import React from 'react';

import Plan from './Plan';
import { isSashDisneyBundle } from '../selectors/plan';

import '../styles/sashBundleBadgePlan.scss';

export const ADS_BUNDLE_BADGE_COPY = 'Best Value • Save 25%';

const BundleBadgesPlan = props => {
  const { identifier } = props.plan;
  const badgeDescription = isSashDisneyBundle(identifier)
    ? ADS_BUNDLE_BADGE_COPY
    : '';

  return (
    <Plan
      {...props}
      planClass="sash-bundle-badge-plan"
      badgeDescription={badgeDescription}
    />
  );
};

BundleBadgesPlan.propTypes = Plan.propTypes;

export default BundleBadgesPlan;
