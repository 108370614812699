import React, { createRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { actions } from 'react-redux-form';
import {
  ARROW_DOWN,
  ARROW_UP,
  ENTER,
  ESCAPE,
  SPACE,
  TAB,
} from '../constants/keyboardKeys';
import { isMobile } from '../utils/deviceUtils';
import { getFormFieldValidity } from '../selectors/form';

export class SelectField extends React.Component {
  constructor(props) {
    super(props);
    this.optionListFieldRef = createRef();
    this.updateFormModel = this.updateFormModel.bind(this);
    this.toggleShowOptions = this.toggleShowOptions.bind(this);
    this.mouseDownHandler = this.mouseDownHandler.bind(this);
    this.mouseUpHandler = this.mouseUpHandler.bind(this);
    this.keyPressHandler = this.keyPressHandler.bind(this);
    this.keyDownHandler = this.keyDownHandler.bind(this);
    this.blurHandler = this.blurHandler.bind(this);
    this.pageClick = this.pageClick.bind(this);
    this.renderFancyOptions = this.renderFancyOptions.bind(this);
    this.dispatchChange = this.dispatchChange.bind(this);

    this.activeQuery = '';
    this.mouseIsDownOnDropdown = false;
    this.state = {
      showOptions: false,
      selectedOptionIndex: this.props.options.findIndex(
        el => this.props.value === el.value
      ),
    };
  }

  componentDidMount() {
    window.addEventListener('mousedown', this.pageClick);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.value !== nextProps.value ||
      this.state.selectedOptionIndex !== nextState.selectedOptionIndex ||
      this.state.showOptions !== nextState.showOptions
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.selectedOptionDom) {
      this.scrollIntoViewIfNeeded(this.selectedOptionDom);
    }
    if (this.activeQuery && prevState.showOptions && !this.state.showOptions) {
      this.activeQuery = '';
    }

    // 3-29 short-circuit an infinite validation loop by manually triggering the even that causes validation
    if (
      (!prevProps.value || prevProps.value.length === 0) &&
      this.props.value &&
      this.props.value.length > 0
    ) {
      this.props.onBlur();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.pageClick);
  }

  dispatchChange(name, value) {
    this.props.dispatch(actions.change(name, value));
  }

  mouseDownHandler() {
    this.mouseIsDownOnDropdown = true;
  }

  mouseUpHandler() {
    this.mouseIsDownOnDropdown = false;
  }

  pageClick() {
    if (this.mouseIsDownOnDropdown) {
      return;
    }

    if (this.state.showOptions) {
      this.toggleShowOptions();
    }
  }

  blurHandler() {
    if (this.state.showOptions && this.optionListFieldRef.current) {
      return;
    }
    this.props.dispatch(actions.blur(this.props.name));
    this.activeQuery = '';
  }

  // KeyDown handler is for control keys like arrows or tab and enter.
  keyDownHandler(e) {
    switch (e.key) {
      case ARROW_DOWN: {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ showOptions: true });
        const nextIndex =
          this.props.options.findIndex(el => this.props.value === el.value) + 1;
        if (nextIndex < this.props.options.length && nextIndex >= 0) {
          this.dispatchChange(
            this.props.name,
            this.props.options[nextIndex].value
          );
          this.setState({ selectedOptionIndex: nextIndex });
          document.getElementById(`${this.props.id}-menu`).focus();
        }
        break;
      }
      case ARROW_UP: {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ showOptions: true });
        const prevIndex =
          this.props.options.findIndex(el => this.props.value === el.value) - 1;
        if (prevIndex < this.props.options.length && prevIndex >= 0) {
          this.dispatchChange(
            this.props.name,
            this.props.options[prevIndex].value
          );
          this.setState({ selectedOptionIndex: prevIndex });
          document.getElementById(`${this.props.id}-menu`).focus();
        }
        break;
      }
      case ENTER:
      case ESCAPE:
        this.setState({ showOptions: false });
        break;
      case SPACE:
        this.props.dispatch(actions.focus(this.props.name));
        this.setState({ showOptions: true });
        break;
      case TAB:
        if (this.state.showOptions) {
          this.toggleShowOptions();
        }
        break;
      default:
        break;
    }
  }

  // Handling keypresses to do keyboard shortcuts (i.e. pick an option by typing directly)
  keyPressHandler(e) {
    // Any other single-character keypress to be interpreted as shortcut
    if (e.key.length === 1) {
      this.activeQuery += e.key.toUpperCase();
      // search specifically against the start of each option
      let nextIndex = this.props.options.findIndex(
        el =>
          el.text
            .toString()
            .substr(0, this.activeQuery.length)
            .toUpperCase() === this.activeQuery
      );
      if (nextIndex === -1) {
        // otherwise search the entire string
        nextIndex = this.props.options.findIndex(el =>
          el.text
            .toString()
            .toUpperCase()
            .includes(this.activeQuery)
        );
      }
      if (nextIndex > -1) {
        this.dispatchChange(
          this.props.name,
          this.props.options[nextIndex].value
        );
        this.setState({ selectedOptionIndex: nextIndex });
      } else {
        this.activeQuery = '';
      }
    }
  }

  // Click callback for each individual dropdown option.
  updateFormModel(e) {
    if (e && e.target) {
      this.setState({
        selectedOptionIndex: this.props.options.findIndex(
          el => e.target.getAttribute('value') === el.value.toString()
        ),
      });
      this.toggleShowOptions();
      this.dispatchChange(this.props.name, e.target.getAttribute('value'));
    }
  }

  // Simple helper function to ensure the element is blurs and focuses properly.
  toggleShowOptions() {
    if (this.state.showOptions) {
      this.props.dispatch(actions.blur(this.props.name));
    } else {
      this.props.dispatch(actions.focus(this.props.name));
    }
    this.setState(({ showOptions }) => ({ showOptions: !showOptions }));
  }

  /**
   * Given a DOM node, it will make sure its parent element has scrolled that node into view. It will attempt
   * to center the element.
   *
   * scrollIntoViewIfNeeded has specification: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoViewIfNeeded
   * This specific polyfill is adapted from: https://gist.github.com/hsablonniere/2581101
   */
  scrollIntoViewIfNeeded(element) {
    const parent = element.parentNode;
    const parentComputedStyle = window.getComputedStyle(parent, null);
    const parentBorderTopWidth = parseInt(
      parentComputedStyle.getPropertyValue('border-top-width'),
      10
    );
    const parentBorderLeftWidth = parseInt(
      parentComputedStyle.getPropertyValue('border-left-width'),
      10
    );
    const overTop = element.offsetTop - parent.offsetTop < parent.scrollTop;
    const overBottom =
      element.offsetTop -
        parent.offsetTop +
        element.clientHeight -
        parentBorderTopWidth >
      parent.scrollTop + parent.clientHeight;
    const overLeft = element.offsetLeft - parent.offsetLeft < parent.scrollLeft;
    const overRight =
      element.offsetLeft -
        parent.offsetLeft +
        element.clientWidth -
        parentBorderLeftWidth >
      parent.scrollLeft + parent.clientWidth;

    if (overTop || overBottom) {
      parent.scrollTop =
        element.offsetTop -
        parent.offsetTop -
        parent.clientHeight / 2 -
        parentBorderTopWidth +
        element.clientHeight / 2 +
        parent.offsetTop;
    }

    if (overLeft || overRight) {
      parent.scrollLeft =
        element.offsetLeft -
        parent.offsetLeft -
        parent.clientWidth / 2 -
        parentBorderLeftWidth +
        element.clientWidth / 2 +
        parent.offsetLeft;
    }
  }

  /**
   * Helper function which will render the dropdown options.
   * Ensures the currently selected option is highlighted.
   */
  renderFancyOptions() {
    const result = [];
    this.props.options.forEach((el, index) => {
      if (this.state.selectedOptionIndex === index) {
        result.push(
          <li
            id={`${this.props.id}-item-${index}`}
            data-testid={`${this.props.id}-item-${index}`}
            role="option"
            aria-selected="true"
            className="active"
            ref={opt => {
              this.selectedOptionDom = opt;
            }}
            key={index}
            value={el.value}
          >
            {el.text}
          </li>
        );
      } else {
        result.push(
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <li
            id={`${this.props.id}-item-${index}`}
            data-testid={`${this.props.id}-item-${index}`}
            role="option"
            aria-selected="false"
            onClick={this.updateFormModel}
            key={index}
            value={el.value}
          >
            {el.text}
          </li>
        );
      }
    });
    return result;
  }

  render() {
    const activeValue = this.props.value
      ? this.props.options.find(el => el.value === this.props.value).text
      : this.props.placeholder;
    const optionsClassName = this.state.showOptions
      ? 'options'
      : 'options hidden';
    const selectedItemClassName =
      activeValue === this.props.placeholder
        ? 'selector-selected input__text placeholder'
        : 'selector-selected input__text';
    if (!isMobile()) {
      if (this.props.disabled) {
        return (
          <div
            className="selector-container disabled"
            data-testid="selector-container"
          >
            <div className={selectedItemClassName}>{activeValue}</div>
          </div>
        );
      }

      return (
        <div
          className="selector-container"
          data-testid="selector-container"
          onMouseDown={this.mouseDownHandler}
          onMouseUp={this.mouseUpHandler}
          onKeyDown={this.keyDownHandler}
          onKeyPress={this.keyPressHandler}
          onBlur={this.blurHandler}
        >
          <div
            className="selector-button"
            tabIndex="0"
            aria-haspopup="listbox"
            data-toggle="true"
            aria-describedby={`${this.props.id}-error`}
            aria-controls={`${this.props.id}-menu`}
            aria-expanded={this.state.showOptions}
            aria-invalid={!this.props.valid}
          >
            <div
              className={selectedItemClassName}
              onClick={this.toggleShowOptions}
              aria-live="polite"
            >
              {activeValue}
            </div>
          </div>
          <ul
            role="listbox"
            className={optionsClassName}
            id={`${this.props.id}-menu`}
            tabIndex="0"
            aria-activedescendant={`${this.props.id}-item-${this.state.selectedOptionIndex}`}
            ref={this.optionListFieldRef}
          >
            {this.renderFancyOptions()}
          </ul>
        </div>
      );
    }
    return (
      <select
        className="input__text input__select"
        onBlur={this.props.onBlur}
        onChange={this.props.onChange}
        onFocus={this.props.onFocus}
        onKeyPress={this.props.onKeyPress}
        value={this.props.value}
        disabled={this.props.disabled}
        tabIndex="0"
        aria-describedby={`${this.props.id}-error`}
        aria-invalid={!this.props.valid}
      >
        <option value="">{this.props.placeholder}</option>
        {this.props.options.map((el, index) => (
          <option value={el.value} key={index} onClick={this.updateFormModel}>
            {el.text}
          </option>
        ))}
      </select>
    );
  }
}

SelectField.defaultProps = {
  value: '',
  disabled: false,
  valid: false,
};

SelectField.propTypes = {
  dispatch: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string]).isRequired,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  valid: PropTypes.bool,
};

const mapStateToProps = (state, props) => ({
  valid: getFormFieldValidity(state, props.name),
});

export default connect(mapStateToProps)(SelectField);
