import React from 'react';
import PropTypes from 'prop-types';

import LedgerActionItem from './LedgerActionItem';

const LedgerActionItems = ({ actions }) => {
  /**
   * Filtering action items to only display visible ones
   * @param {boolean} shouldShow should be different from false
   */
  const visibleItems = actions.filter(action => action.shouldShow !== false);

  return (
    <div className="ledger__action-items">
      {visibleItems.map(({ action, text }, index) => {
        return <LedgerActionItem key={index} text={text} action={action} />;
      })}
    </div>
  );
};

LedgerActionItems.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape(LedgerActionItem.PropTypes))
    .isRequired,
};

export default LedgerActionItems;
