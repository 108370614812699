export const SWITCH_ELIGIBILITY = Object.freeze({
  SWITCH: 'SWITCH',
  ROADBLOCK: 'ROADBLOCK',
  MERGE: 'MERGE',
  ALREADY_ACTIVATED: 'ALREADY_ACTIVATED',
});

export const SWITCH_ELIGIBILITY_REASON = Object.freeze({
  THIRD_PARTY_NON_DSS: 'THIRD_PARTY_NON_DSS',
  DSS: 'DSS',
  LIVE: 'LIVE',
  PREPAID: 'PREPAID',
  DISNEY_BUNDLE: 'DISNEY_BUNDLE',
});
