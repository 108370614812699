import React from 'react';
import PropTypes from 'prop-types';

const Subtitle = ({ children }) => (
  <div className="roadblock__subtitle">{children}</div>
);

Subtitle.propTypes = {
  children: PropTypes.node,
};

export default Subtitle;
