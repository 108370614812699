import { PLAN_BUILDER_FLOW } from '../constants/metrics';
import { getIsPlanBuilderFlow } from '../selectors/flow';
import { setInstrumentationFlowName } from './instrumentationFlowActions';

export const checkPlanBuilder = () => {
  return async (dispatch, getState) => {
    const state = getState();

    const isPlanBuilderFlow = getIsPlanBuilderFlow(state);

    if (!isPlanBuilderFlow) {
      return;
    }

    dispatch(setInstrumentationFlowName(PLAN_BUILDER_FLOW));
  };
};
