import React from 'react';
import PropTypes from 'prop-types';

const Title = ({ children }) => (
  <div className="roadblock__title">{children}</div>
);

Title.propTypes = {
  children: PropTypes.node,
};

export default Title;
