import React from 'react';
import PropTypes from 'prop-types';

const SpotifyRoadblockFooter = ({ body, helpLink, helpText, footer }) => (
  <div>
    <p>
      {body}&nbsp;
      <a href={helpLink} target="_blank">
        {helpText}
      </a>
      .
    </p>
    <p>{footer}</p>
  </div>
);

SpotifyRoadblockFooter.propTypes = {
  body: PropTypes.string.isRequired,
  helpLink: PropTypes.string.isRequired,
  helpText: PropTypes.string.isRequired,
  footer: PropTypes.string.isRequired,
};

export default SpotifyRoadblockFooter;
