import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PlanSelectMessage extends Component {
  render() {
    const { header, subheader } = this.props;

    if (!header && !subheader) {
      return null;
    }

    return (
      <div className="plan__message">
        <div className="plan__message-header">{header}</div>
        <div className="plan__message-subheader">{subheader}</div>
      </div>
    );
  }
}

PlanSelectMessage.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
};

export default PlanSelectMessage;
