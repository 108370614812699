import {
  SET_USER_DATA,
  USER_CREATION_RECAPTCHA,
} from '../constants/actionTypes';

export const setUserCreationRecaptchaToken = token => ({
  type: USER_CREATION_RECAPTCHA,
  token,
});

export const setUserData = payload => ({
  type: SET_USER_DATA,
  payload,
});
