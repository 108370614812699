import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

require('../styles/securitySymbol.scss');

const imagesUrls = {
  circle: '/static/images/security-symbol-with-circle.svg',
  nocircle: '/static/images/security-symbol-no-circle.svg',
};

const SecuritySymbol = ({ className, imgType, text }) => (
  <div className={classNames('security-symbol', className)}>
    <img
      className="security-symbol__img"
      alt="Secure Lock"
      src={imagesUrls[imgType]}
    />
    <span className="security-symbol__text">{text}</span>
  </div>
);

SecuritySymbol.propTypes = {
  className: PropTypes.string,
  imgType: PropTypes.oneOf(['circle', 'nocircle']),
  text: PropTypes.string,
};

SecuritySymbol.defaultProps = {
  imgType: 'circle',
  text: 'Secure Checkout',
};

export default SecuritySymbol;
