import { YOKOZUNA_V3_API, YOKOZUNA_V4_API } from '../constants/apis';
import request from './request';

// TODO WEB-9655: Remove
function getLedger(ledgerRequest) {
  return request(`${YOKOZUNA_V3_API}subscriptions/ledger`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ledgerRequest),
  });
}

function getV2Ledger(ledgerRequest) {
  return request(`${YOKOZUNA_V4_API}subscriptions/ledger`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ledgerRequest),
  });
}

export { getLedger, getV2Ledger };
