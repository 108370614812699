import get from 'lodash/get';
import { createSelector } from 'reselect';

import { ERROR_CODES } from '../constants/errorCodes';
import { PARTNERSHIP } from '../constants/partners';
import { getIsUserHuluBilled } from './user';

export const getIsExistingUser = state =>
  get(state, 'partner.isExistingUser', false);

export const getPartnerOffer = state => get(state, 'partner.partnerOffer');

export const getPartnerOfferKey = createSelector(
  [getPartnerOffer],
  partnerOffer => get(partnerOffer, 'key')
);

export const getPartnerOfferId = createSelector(getPartnerOffer, partnerOffer =>
  get(partnerOffer, 'id')
);

export const getPartnerOfferContext = createSelector(
  getPartnerOffer,
  partnerOffer => get(partnerOffer, 'response.context', {})
);

export const getPartnerOfferError = createSelector(
  getPartnerOffer,
  partnerOffer => {
    const errorCode = get(partnerOffer, 'response.error.errors[0].code');
    const { OFFER_ERRORS } = PARTNERSHIP;
    switch (errorCode) {
      case ERROR_CODES.INELIGIBLE_PROMOTION:
        return OFFER_ERRORS.INVALID_OFFER;
      case ERROR_CODES.ALREADY_SUBSCRIBED:
        return OFFER_ERRORS.ALREADY_REDEEMED;
      case ERROR_CODES.SERVICE_ERROR:
      case ERROR_CODES.UNKNOWN:
        return OFFER_ERRORS.RETRY;
      default:
        // This will allow us to render a spinner on the eligibility page when
        // there isn't an error.
        return null;
    }
  }
);

const getIsMissingPlans = state => state.config.plans.length === 0;

export const getPartnerRoadblockEligibility = createSelector(
  [getIsMissingPlans, getIsUserHuluBilled],
  (isMissingPlans, isUserHuluBilled) => {
    if (!isMissingPlans) {
      return null;
    }

    if (isUserHuluBilled) {
      return PARTNERSHIP.SWITCH_ELIGIBILITY.HULU_INELIGIBLE;
    }

    return PARTNERSHIP.SWITCH_ELIGIBILITY.THIRD_PARTY_INELIGIBLE;
  }
);
