import * as types from '../constants/actionTypes';
import request from '../api/request';
import { YOKOZUNA_API } from '../constants/apis';

// Feature flag API actions
export function getFeatureFlags() {
  return async dispatch => {
    dispatch(getFeatureFlagsStart());
    try {
      const featureFlagResponse = await request(
        `${YOKOZUNA_API}feature-flags`,
        {
          method: 'GET',
          credentials: 'include',
        }
      );
      dispatch(getFeatureFlagsSuccess(featureFlagResponse));
      return featureFlagResponse;
    } catch (error) {
      dispatch(getFeatureFlagsFailure(error));
      throw error;
    }
  };
}

export function getFeatureFlagsStart() {
  return {
    type: types.GET_FEATURE_FLAGS.START,
  };
}

export function getFeatureFlagsSuccess(response) {
  return {
    type: types.GET_FEATURE_FLAGS.SUCCESS,
    payload: response,
  };
}

export function getFeatureFlagsFailure(error) {
  return {
    type: types.GET_FEATURE_FLAGS.FAILURE,
    payload: error,
  };
}
