// used to generate heimdall_hub_id strings for page_impression HIT
// https://wiki.disneystreaming.com/display/HHITs/P0+Instrumentation

import * as routes from './routes';

// https://wiki.disneystreaming.com/display/HHITs/Non-Heimdall+Page+Types+and+URIs
const HUB_ID_PLAN_SELECT = 'app:signup:plan_select';
const HUB_ID_PLAN_OPTIONS = 'app:signup:plan_options';
const HUB_ID_ACCOUNT_CREATION = 'app:signup:account_creation';
const HUB_ID_BILLING_INFO = 'app:signup:billing_info';

export const HEIMDALL_HUB_MAPPINGS = {
  [routes.GO]: HUB_ID_PLAN_SELECT,
  [routes.ROOT]: HUB_ID_PLAN_SELECT,
  [routes.PLAN_SELECT]: HUB_ID_PLAN_SELECT,
  [routes.PLAN_OPTIONS]: HUB_ID_PLAN_OPTIONS,
  [routes.GO_ONE_HULU]: HUB_ID_PLAN_SELECT,
  [routes.GO_LIVE_ONLY]: HUB_ID_PLAN_SELECT,
  [routes.GO_CURIOSITY]: HUB_ID_PLAN_SELECT,
  [routes.ACCOUNT_INFO]: HUB_ID_ACCOUNT_CREATION,
  [routes.BILLING_INFO]: HUB_ID_BILLING_INFO,
};
