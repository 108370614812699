import { connect } from 'react-redux';

import { routeReplace } from '../actions/routingActions';
import * as routes from '../constants/routes';
import * as MetricsEvents from '../metrics/metricsEvents';
import track from '../metrics/track';
import CreateAccountForm from '../pages/CreateAccountPage';
import { getIsAmazonEchoShowClient } from '../selectors/flow';
import {
  getDisplayBillingZipOnAccountPage,
  getIsLedgerLoading,
} from '../selectors/ledger';
import {
  getIsPrepaidForSelectedPlan,
  getUserCreationPasswordCheckError,
} from '../selectors/user';
import { getMissingUserFields } from '../utils/userUtils';
import { isCuriosityIncluded, isStudentPlan } from '../selectors/plan';
import {
  getIsRokuWeb,
  getDisableEmail,
  getIsUserRequestLoading,
  getUserCreateFeature,
  getShouldDisableAccountStatusCheck,
  getShouldShowEdnaCreateAccount,
} from '../selectors/accountPage';
import {
  handleCreateAccountSubmit,
  handleRedirectToBilling,
} from '../actions/accountActions';
import { hasAssignment } from '../selectors/config';
import { CAT_357 } from '../constants/experiments';
import { getPlanOptions } from '../selectors/plans';
import {
  getIsTWDCPrivacyPolicyFeatureOn,
  getIsUpdatedGenderOptionOn,
  isFeatureEnabled,
} from '../selectors/featureFlag';
import { getIsUnifiedIdentityOn } from '../selectors/login';
import { showStudentModal } from '../actions/modalActions';
import { checkIfVerifiedStudent } from '../actions/identityActions';

function mapStateToProps(state, ownProps) {
  return { ...state, ...ownProps };
}

function mapDispatchToProps(dispatch, ownProps) {
  return { dispatch, ...ownProps };
}

function mergeProps(state, dispatchProps, ownProps) {
  const { dispatch } = dispatchProps;
  const {
    user,
    flow,
    misc: { lockedOut },
    user: { selectedPlan, loggedIn, accountSubmitted },
    flow: { partnerFlow },
    match: { path },
  } = state;
  const missingUserFields = getMissingUserFields(state);
  const hasCompletedUserFieldsForSelectedPlan = missingUserFields.length === 0;
  const displayBillingZip = getDisplayBillingZipOnAccountPage(state);
  const isPrepaidForSelectedPlan = getIsPrepaidForSelectedPlan(state);
  const displayPlaceHolder = getIsAmazonEchoShowClient(state);
  const userCreationDisabledOrIsLoggedIn =
    !getUserCreateFeature(state) || !!user.loggedIn;

  const shouldSkipUserCreation =
    userCreationDisabledOrIsLoggedIn &&
    hasCompletedUserFieldsForSelectedPlan &&
    !displayBillingZip;

  const isUserRequestLoading = getIsUserRequestLoading(state);
  const isInPrepaidOnBillingTreatment = hasAssignment(
    state,
    CAT_357.PREPAID_ON_BILLING
  );
  const planOptions = getPlanOptions(state, selectedPlan);
  const isPrepaidFeatureOn = isFeatureEnabled(state, 'prepaid');
  const isGrecaptchaFeatureOn = isFeatureEnabled(state, 'grecaptcha');
  const isTWDCPrivacyPolicyFeatureOn = getIsTWDCPrivacyPolicyFeatureOn(state);
  const shouldDisableAccountStatusCheck = getShouldDisableAccountStatusCheck(
    state
  );

  return {
    user,
    missingUserFields,
    lockedOut,
    dispatch,
    isUserRequestLoading,
    isLoading: getIsLedgerLoading(state), // CAT-42: show loader while fetching ledger data
    location: flow,
    path,
    accountValidated: loggedIn || accountSubmitted,
    onLoaded: async () => {
      if (!selectedPlan) {
        dispatch(routeReplace(routes.PLAN_SELECT));
      } else if (shouldSkipUserCreation) {
        if (isStudentPlan(selectedPlan)) {
          let isVerifiedStudent;
          try {
            isVerifiedStudent = await dispatch(checkIfVerifiedStudent());
          } catch (error) {
            // Show roadblock
            dispatch(routeReplace(routes.IDENTITY_VERIFICATION));
            return;
          }
          if (isVerifiedStudent) {
            dispatch(routeReplace(routes.BILLING_INFO));
          } else {
            dispatch(showStudentModal());
          }
        } else {
          dispatch(handleRedirectToBilling());
        }
      } else if (!getShouldShowEdnaCreateAccount(state)) {
        // Do not send the instrumentation events when the user is in an Edna Create Account flow,
        // because it's handled by the feature component from @hulu/web-login-ui.
        ownProps.trackEvent(MetricsEvents.SUBSCRIPTION_STEP_START, {
          title: 'SUF - Account Info',
          pageId: 'account',
        });
      }
    },
    canSubmit:
      state.forms.user.$form.valid &&
      !isUserRequestLoading &&
      (!state.forms.user.$form.pending || state.misc.reselectingPlan),
    onSubmit: () => {
      dispatch(handleCreateAccountSubmit());
    },
    isRokuWeb: getIsRokuWeb(state),
    disableEmail: getDisableEmail(state),
    isCuriosityIncluded: isCuriosityIncluded(selectedPlan),
    shouldDisplayLegalZip:
      isInPrepaidOnBillingTreatment &&
      isPrepaidFeatureOn &&
      planOptions.length > 1,
    partnerFlow,
    displayBillingZip,
    isPrepaidForSelectedPlan,
    displayPlaceHolder,
    shouldShowEdnaCreateAccount: getShouldShowEdnaCreateAccount(state),
    isGrecaptchaFeatureOn,
    isTWDCPrivacyPolicyFeatureOn,
    shouldDisableAccountStatusCheck,
    showUnifiedIdentity: getIsUnifiedIdentityOn(state),
    showUpdatedGenderOptions: getIsUpdatedGenderOptionOn(state),
    swapLoginForm: () => dispatch(routeReplace(routes.LOGIN)),
    userCreationPasswordCheckError: getUserCreationPasswordCheckError(state),
  };
}

export default track(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(CreateAccountForm)
);
