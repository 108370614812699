import { createSelector } from 'reselect';
import get from 'lodash/get';
import { stringify } from '../utils/querystring';

import env from '../../../config/env';
import { getFromParam } from './flow';
import {
  getIsIneligibleDiscount,
  getIsIneligiblePromotion,
  getIsIneligibleTrialDiscount,
} from './warning';
import { getIsActiveSubscriber } from './user';
import {
  DPLUS_PPV_TEXT_KEY_ROOT,
  DPLUS_TEXT_KEY_ROOT,
  EPLUS_TEXT_KEY_ROOT,
  FRONT_END_PAYMENTS_KEY_ROOT,
  MAX_BUNDLE_TEXT_KEY_ROOT,
  PARTNER_TEXT_KEY_ROOT,
  REDIRECTS_ROOT,
  TEXT_CONTROLS_KEY,
  VERIZON_VIA_DSS_TEXT_KEY_ROOT,
} from '../constants/siteConfigKeys';

export const getSiteConfig = state =>
  get(state, 'siteConfig["sufo_controls.text"]', null);

export const getTextConfig = createSelector([getSiteConfig], config => ({
  config,
}));

const getIneligibleHeaderText = createSelector([getSiteConfig], siteConfig =>
  get(siteConfig, 'ineligible_text.header', null)
);

const getIneligibleSubheaderText = createSelector([getSiteConfig], siteConfig =>
  get(siteConfig, 'ineligible_text.subheader.default', null)
);

export const getTermsModalButtonText = createSelector(
  [getSiteConfig],
  siteConfig => get(siteConfig, 'terms_modal_button_text', null)
);

export const getStudentDiscountText = createSelector(
  [getSiteConfig],
  siteConfig => get(siteConfig, 'plan_select_page.student_discount.text', null)
);

export const getLiveTvLinkText = createSelector([getSiteConfig], siteConfig =>
  get(siteConfig, 'plan_select_page.live_tv_link.text', null)
);

export const getIneligibleErrorText = createSelector(
  [
    getIsIneligiblePromotion,
    getIsIneligibleDiscount,
    getIsIneligibleTrialDiscount,
    getIneligibleHeaderText,
    getIneligibleSubheaderText,
  ],
  (
    isIneligiblePromotion,
    isIneligibleDiscount,
    isIneligibleTrialDiscount,
    headerText,
    subheaderText
  ) => {
    if (isIneligiblePromotion) {
      return {
        header: headerText.ineligible_promotion,
        subheader: subheaderText,
      };
    }

    if (isIneligibleDiscount) {
      return {
        header: headerText.ineligible_discount,
        subheader: subheaderText,
      };
    }

    if (isIneligibleTrialDiscount) {
      return {
        header: headerText.trial_discount_ineligible,
        subheader: subheaderText,
      };
    }

    return {};
  }
);

export const getSprintV2Config = createSelector(getSiteConfig, siteConfig =>
  get(siteConfig, 'sprintV2')
);

export const getTMobileConfig = createSelector(getSiteConfig, siteConfig =>
  get(siteConfig, 'tmobile')
);

export const getSpotifyLandingEligibilityText = createSelector(
  [getSiteConfig],
  siteConfig =>
    get(siteConfig, 'spotify_landing_text.eligibility_requirements', null)
);

export const shouldAdoptNewFeature = (state, path) => {
  const weight = getConfigValue(state, FRONT_END_PAYMENTS_KEY_ROOT, path, 0);
  return weight <= 1 && weight >= Math.random();
};

export const getPaymentErrors = createSelector(getSiteConfig, siteConfig =>
  get(siteConfig, 'payment_errors')
);

export const getInsufficientFundsMessage = createSelector(
  getPaymentErrors,
  paymentErrors => get(paymentErrors, 'insufficient_funds')
);

export const getInvalidZipMessage = createSelector(
  getPaymentErrors,
  paymentErrors => get(paymentErrors, 'invalid_zip')
);

export const getNoahTooltipMessage = createSelector(getSiteConfig, siteConfig =>
  get(siteConfig, 'noahTooltip')
);

export const getEnabledUpsellPageFlows = createSelector(
  getSiteConfig,
  siteConfig => get(siteConfig, 'plan_upsell_page.flows', [])
);

export const getValuePropBulletsCopy = createSelector(
  getSiteConfig,
  siteConfig => get(siteConfig, 'hulu_14131_exp_copy', {})
);

export const getLiveTvTrialCopy = createSelector(getSiteConfig, siteConfig =>
  get(siteConfig, 'live_tv_trial_copy', {})
);

/**
 * Get the config value for a given path, or the config node itself if the path isn't included.
 *
 * @param {object} state - the redux state tree.
 * @param {object} state.siteConfig - site-config.
 * @param {string} key - the key to lookup in site-config.
 * @param {string|[]} [path] - the path to lookup the value for.
 * @param {object} defaultValue - the default value if path does not resolve
 */
export const getConfigValue = ({ siteConfig }, key, path, defaultValue) => {
  const config = get(siteConfig, key, {});
  return path ? get(config, path, defaultValue) : config;
};

/**
 * A selector for retrieving sufo_controls text (our main site-config node)
 *
 * @param {Object} state - the redux state tree.
 * @param {string|string[]} path - the control text path.
 * @param {object=} defaultValue - the default value if path does not resolve
 */
export const getControlText = (state, path, defaultValue) =>
  getConfigValue(state, TEXT_CONTROLS_KEY, path, defaultValue);

/**
 * A selector for retrieving partner flow text
 *
 * @param {Object} state - the redux state tree.
 * @param {string|string[]} path - the text path.
 */
export const getPartnerFlowText = (state, path) =>
  getConfigValue(state, PARTNER_TEXT_KEY_ROOT, path);

/**
 * A selector for retrieving dplus flow text
 *
 * @param {Object} state - the redux state tree.
 * @param {string|string[]} path - the text path.
 */
export const getDPlusText = (state, path) =>
  getConfigValue(state, DPLUS_TEXT_KEY_ROOT, path);

/**
 * A selector for retrieving dplus ppv flow text
 *
 * @param {Object} state - the redux state tree.
 * @param {string|string[]} path - the text path.
 */
export const getDPlusPpvText = (state, path) =>
  getConfigValue(state, DPLUS_PPV_TEXT_KEY_ROOT, path);

/**
 * A selector for retrieving eplus flow text
 *
 * @param {Object} state - the redux state tree.
 * @param {string|string[]} path - the text path.
 */
export const getEPlusText = (state, path) =>
  getConfigValue(state, EPLUS_TEXT_KEY_ROOT, path);

/**
 * A selector for retrieving verizon via dss flow text
 *
 * @param {Object} state - the redux state tree.
 * @param {string|string[]} path - the text path.
 */
export const getVerizonViaDssText = (state, path) =>
  getConfigValue(state, VERIZON_VIA_DSS_TEXT_KEY_ROOT, path);

/**
 * A selector for retrieving Max Bundle flow text
 *
 * @param {Object} state - the redux state tree.
 * @param {string|string[]} path - the text path.
 */
export const getMaxBundleText = (state, path) =>
  getConfigValue(state, MAX_BUNDLE_TEXT_KEY_ROOT, path);

/**
 * A selector to determine if the flow is configured to redirect to the 2 step
 * hi-switch flow.
 *
 * @param state - the redux state
 *
 * @returns {Boolean} True if the from param matches site-config has been
 *   configured for hi-switch.
 */
export const getIsHiSwitchFlow = state => {
  const ppvRedirects = getConfigValue(
    state,
    REDIRECTS_ROOT,
    'hiswitch-from-params',
    []
  );
  return ppvRedirects.includes(getFromParam(state));
};

/**
 * A selector to determine if the flow is Pay Per View.
 *
 * Currently implementation is that all hi-switch from parameters are Pay Per
 * View. This may change if hi-switch is supported for non-PPV flows.
 *
 * @param state - the redux state
 *
 * @return {Boolean} True if the from param is a PPV from-param
 */
export const getIsPPVFlow = state => getIsHiSwitchFlow(state);

/**
 * A selector to determine if the flow should redirect to web-sub when going to
 * the billing page. Flows found in this selector should always be redirected
 * to web-sub.
 *
 * @param state - the redux state
 * @returns {Boolean} - true if the flow's from param is included in site-config's web-sub from params array
 */
export const getIsRedirectToWebsubFlow = state => {
  const fromParams = getConfigValue(
    state,
    REDIRECTS_ROOT,
    'websub-from-params',
    []
  );
  return fromParams.includes(getFromParam(state));
};

/**
 * A selector to get the hi-switch redirect URL.
 *
 * @param state - the redux state.
 *
 * @return {string} path - the hi-switch path to redirect to with appended from
 *   params.
 */
export const getHiSwitchRedirectUrl = state => {
  const params = {
    from: getFromParam(state),
  };

  //  The 'ppv=true' param indicates that the user is a new subscriber, and determines
  //  the copy shown by WAM. This can be removed when new subscribers can complete the PPV
  //  signup without being redirected.
  if (!getIsActiveSubscriber(state)) {
    params.ppv = true;
  }
  return `${env.url.login}/addons/confirm?${stringify(params)}`;
};
