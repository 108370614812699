import React, { createRef, Component } from 'react';
import PropTypes from 'prop-types';

import SheerIDLogo from '../SheerIDLogo';
import { preventFocus, keepFocusWithin } from '../../utils/a11y';

class ModalStudent extends Component {
  constructor() {
    super();

    this.state = {
      isRedirecting: false,
    };

    this.modalRef = createRef();
    this.onEscapeClose = this.onEscapeClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    document.body.classList.add('modal-open');
    document.addEventListener('keydown', this.onEscapeClose);
    this.keepFocusWithin = keepFocusWithin(this.modalRef);
  }

  componentWillUnmount() {
    this.keepFocusWithin.remove();
    document.removeEventListener('keydown', this.onEscapeClose);
    document.body.classList.remove('modal-open');
  }

  onEscapeClose(e) {
    if (e.which === 27) {
      this.props.closeModal();
    }
  }

  handleSubmit() {
    this.setState({
      isRedirecting: true,
    });
    this.props.submitModal();
  }

  render() {
    const { closeModal, title, message, acceptText, declineText } = this.props;
    const { isRedirecting } = this.state;

    return (
      <div
        ref={this.modalRef}
        className="modal-padding"
        role="dialog"
        aria-modal="true"
        aria-labelledby="student-modal-header"
      >
        <div
          id="student-modal"
          data-testid="student-modal"
          className="modal"
          onClick={e => e.stopPropagation()}
        >
          <div className="modal-header" id="student-modal-header">
            <div>
              <SheerIDLogo />
            </div>
            {title}
          </div>
          <div className="modal-body">
            <p>{message}</p>
          </div>
          <button
            data-testid="student-modal-accept"
            className="button modal-cta"
            onClick={this.handleSubmit}
            type="button"
            disabled={isRedirecting}
          >
            {isRedirecting ? (
              <span className="spinner spinner--in-button" />
            ) : (
              acceptText
            )}
          </button>
          <button
            data-testid="student-modal-decline"
            className="button button--inverse modal-cta modal-decline"
            onClick={closeModal}
            disabled={isRedirecting}
            type="button"
          >
            {declineText}
          </button>
          <button
            aria-label="Close"
            className="modal-close"
            onClick={closeModal}
            onMouseDown={preventFocus}
            type="button"
          >
            <img
              className="modal-close__icon"
              alt="Close"
              src="/static/images/close-icon.svg"
            />
          </button>
        </div>
      </div>
    );
  }
}

ModalStudent.propTypes = {
  title: PropTypes.node.isRequired,
  message: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  submitModal: PropTypes.func.isRequired,
  acceptText: PropTypes.string.isRequired,
  declineText: PropTypes.string.isRequired,
};

export default ModalStudent;
