import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';

import { getPartnerFlowText } from '../selectors/siteconfig';
import {
  handlePartnerLandingClick,
  handlePartnerLandingLoaded,
} from '../actions/partnerActions';

export class PartnerLandingPage extends Component {
  componentDidMount() {
    this.props.onLoaded();
  }

  render() {
    const {
      title,
      body,
      newUserButton,
      existingUserButton,
      handleClick,
      disclaimer,
      termsMarkdown,
    } = this.props;

    return (
      <div className="partner-web">
        <h1 className="text__intro">{title}</h1>
        <div className="text__subtitle">
          <div>{body}</div>
        </div>
        <button
          id="new-user-button"
          className="button button--partner"
          onClick={() => handleClick(false)}
          type="button"
        >
          {newUserButton}
        </button>
        <button
          id="existing-user-button"
          className="button button--partner button--inverse"
          onClick={() => handleClick(true)}
          type="button"
        >
          {existingUserButton}
        </button>
        <div className="disclaimer disclaimer__partner">{disclaimer}</div>
        <ReactMarkdown
          source={termsMarkdown}
          className="terms terms__partner"
          linkTarget="_blank"
        />
      </div>
    );
  }
}

PartnerLandingPage.propTypes = {
  onLoaded: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  newUserButton: PropTypes.string.isRequired,
  existingUserButton: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  disclaimer: PropTypes.string.isRequired,
  termsMarkdown: PropTypes.string.isRequired,
};

export default connect(state => getPartnerFlowText(state, 'landing'), {
  onLoaded: handlePartnerLandingLoaded,
  handleClick: handlePartnerLandingClick,
})(PartnerLandingPage);
