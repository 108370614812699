import { YOKOZUNA_API } from '../constants/apis';
import request from './request';
import { stringify } from '../utils/querystring';

export function getSpotifyEligibility(spotifyCode, spotifyRedirect) {
  const query = {
    'spotify-code': spotifyCode,
    'spotify-redirect': spotifyRedirect,
  };

  return request(
    `${YOKOZUNA_API}partners/spotify/eligibility?${stringify(query)}`,
    {
      method: 'GET',
      credentials: 'include',
    }
  );
}

export function setSpotifyActivationStatus(accessToken, activationStatus) {
  const options = {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      accessToken,
      activationStatus,
    }),
  };

  return request(`${YOKOZUNA_API}activations/spotify-status`, options);
}
