import React from 'react';
import PropTypes from 'prop-types';

const BreakdownItem = ({ name, price, promotions }) => (
  <li className="breakdown__item">
    <div className="breakdown__name">{name}</div>
    <div className="breakdown__price">{price}</div>
    {promotions &&
      promotions.map((promotion, index) => (
        <div key={index} className="breakdown__promotion">
          {promotion}
        </div>
      ))}
  </li>
);

BreakdownItem.propTypes = {
  name: PropTypes.string,
  price: PropTypes.string,
  promotions: PropTypes.arrayOf(PropTypes.string),
};

export default BreakdownItem;
