export const isHuluPlatform = () => !!window.huluPlatform;

export const completeSignupOnPlatform = (email, password) => {
  let json;
  if (email && password) {
    json = { email, password };
  } else {
    json = { token: getExternalUserToken() };
  }
  window.huluPlatform.onSignupCompleted(JSON.stringify(json));
};

export const getExternalUserToken = () => window.huluPlatform.getUserToken();
