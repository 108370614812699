import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SimpleTooltip from './SimpleTooltip';
import LedgerTerm from './LedgerTerm';
import { getLedgerTerms } from '../selectors/ledger';
import { getBillingDisclaimer } from '../selectors/dynamicMessaging';
import { getBillingCtaButtonText } from '../selectors/billingPage';
import { BOLD_BILLING_LEGAL_TERMS } from '../constants/ledger';

const formatStyle = (content, isBold) =>
  isBold ? <span className="bold">{content}</span> : content;

export const BillingLegalTerms = ({
  legalTerms,
  switchBillingDisclaimer,
  ctaText,
}) => {
  if (!legalTerms) return null;

  // Replace variables and inject {{ctaText}}
  const terms = legalTerms.map(({ key, value, ...term }) => (
    <Fragment key={key}>
      {formatStyle(
        <LedgerTerm {...term} value={value.replace('{{ctaText}}', ctaText)} />,
        BOLD_BILLING_LEGAL_TERMS.includes(key)
      )}
    </Fragment>
  ));

  if (switchBillingDisclaimer) {
    const { disclaimer, tooltip, link } = switchBillingDisclaimer;
    terms.push([
      '\n\n',
      disclaimer,
      <SimpleTooltip key={`tooltip-${link}`} tooltip={tooltip}>
        {link}
      </SimpleTooltip>,
    ]);
  }

  return <div className="billing-terms">{terms}</div>;
};

BillingLegalTerms.propTypes = {
  legalTerms: PropTypes.arrayOf(PropTypes.shape(LedgerTerm.propTypes)),
  switchBillingDisclaimer: PropTypes.shape({
    disclaimer: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }),
  ctaText: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  legalTerms: getLedgerTerms(state),
  switchBillingDisclaimer: getBillingDisclaimer(state),
  ctaText: getBillingCtaButtonText(state),
});

export default connect(mapStateToProps, null)(BillingLegalTerms);
