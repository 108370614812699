import React from 'react';
import { connect } from 'react-redux';
import SprintSpotifyRoadblockPage from '../pages/SprintSpotifyRoadblockPage';
import PartnerRoadblockFooter from '../components/PartnerRoadblockFooter';
import SpotifyRoadblockFooter from '../components/SpotifyRoadblockFooter';
import { setSpotifyActivationStatus } from '../actions/spotifyActions';
import { routeReplace, urlRedirect } from '../actions/routingActions';
import { SPOTIFY } from '../constants/partners';
import * as routes from '../constants/routes';
import { CHANGE_PLAN_URL } from '../constants/misc';
import track from '../metrics/track';
import * as MetricsEvents from '../metrics/metricsEvents';
import { getIsExisting, getIsSwitchBillingEligible } from '../selectors/user';
import {
  getPartnerRoadblockText,
  getPartnerRoadblockDeclineUrl,
} from '../selectors/roadblock';

function mapStateToProps(state) {
  const {
    flow: { partnerFlow },
  } = state;
  const isUserEligible = getIsSwitchBillingEligible(state);
  // A user is considered cancelled if they have a status of "existing"
  const isCancelled = getIsExisting(state);
  const roadblockText = Object.assign({}, getPartnerRoadblockText(state));
  const declineRedirectUrl = getPartnerRoadblockDeclineUrl(state);

  if (!isUserEligible) {
    if (partnerFlow === SPOTIFY.FLOW) {
      const {
        body,
        help_link: helpLink,
        help_text: helpText,
        footer,
      } = roadblockText;

      roadblockText.footer = (
        <SpotifyRoadblockFooter
          body={body}
          helpLink={helpLink}
          helpText={helpText}
          footer={footer}
        />
      );
    }
  } else if (roadblockText.tooltip) {
    roadblockText.footer = (
      <PartnerRoadblockFooter
        tooltip={roadblockText.tooltip}
        callout={roadblockText.callout}
        text={roadblockText.footer}
      />
    );
  }

  return {
    isUserEligible,
    isCancelled,
    partnerFlow,
    declineRedirectUrl,
    roadblockText,
  };
}

function mergeProps(state, dispatchProps, ownProps) {
  const {
    isUserEligible,
    isCancelled,
    partnerFlow,
    declineRedirectUrl,
    roadblockText,
  } = state;
  const { dispatch } = dispatchProps;
  const { trackEvent } = ownProps;

  const onAccept = () => {
    if (isUserEligible) {
      dispatch(routeReplace(routes.ACCOUNT_INFO));
    } else {
      dispatch(urlRedirect(CHANGE_PLAN_URL));
    }
  };

  const onDecline = () => {
    dispatch(urlRedirect(declineRedirectUrl));
  };

  const onLoaded = () => {
    if (partnerFlow && isCancelled) {
      dispatch(routeReplace(routes.ACCOUNT_INFO));
    } else {
      const metricsPageTitle = isUserEligible
        ? 'SUF - Hulu Eligible'
        : 'SUF - Hulu Ineligible';
      if (partnerFlow === SPOTIFY.FLOW) {
        const activationStatus = isUserEligible
          ? SPOTIFY.STATUS.PENDING_ACTIVE
          : SPOTIFY.STATUS.INELIGIBLE;
        dispatch(setSpotifyActivationStatus(activationStatus));
      }
      trackEvent(MetricsEvents.SUBSCRIPTION_STEP_START, {
        title: metricsPageTitle,
        pageId: 'switch',
      });
    }
  };

  return {
    onAccept,
    onDecline,
    onLoaded,
    roadblockText,
  };
}

export default track(
  connect(mapStateToProps, null, mergeProps)(SprintSpotifyRoadblockPage)
);
