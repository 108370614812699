/**
 * Selectors for inserting messaging for pages.
 * See `PageMessaging` in constants/proptypes for more info.
 */

import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import size from 'lodash/size';
import { createSelector } from 'reselect';
import React from 'react';
import { SPRINTV2, PARTNERSHIP } from '../constants/partners';
import * as ROUTES from '../constants/routes';
import { getPlans, hasAssignment } from './config';
import {
  getPathname,
  getPartnerFlow,
  getIsReacqFlow,
  getIsPartnerFlow,
  getIsVerizonDssFlow,
} from './flow';
import { getDisplayPaymentFields } from './ledger';
import {
  getPartnerFlowText,
  getControlText,
  getSiteConfig,
  getVerizonViaDssText,
  getIsPPVFlow,
  getValuePropBulletsCopy,
  getLiveTvTrialCopy,
} from './siteconfig';
import { getSelectedPlan, getIsActiveSubscriber } from './user';
import { getIsSkipBillingFlow } from './billingPage';
import {
  CAT_43,
  CAT_345,
  CAT_335,
  CAT_336,
  HULU_13632,
} from '../constants/experiments';
import {
  SASH,
  NOAH,
  LIVE,
  PSEUDO_2P,
  ADS_HULU_DISNEY_ESPN,
  NOADS_HULU_DISNEY_ESPN,
  DUO_PREMIUM,
  DUO_BASIC,
} from '../constants/plans';
import { isNoAdsDisneyBundle, isSashDisneyBundle, isStudentPlan } from './plan';
import { showDefaultModal } from '../actions/modalActions';
import ShowStudentTermsButton from '../components/ShowStudentTermsButton';
import { getShowBundleBadges, getShowValuePropBullets } from './plans';
import {
  getSprintBillingDisclaimerText,
  getSprintBillingSubheader,
  getSprintPlanSelectDisclaimer,
  getSprintPlanSelectHeader,
} from './sprintV2';
import { getIs3StepAccountCreationEnabled } from './featureFlag';

export const getMessagingNode = state => get(state, 'config.messaging', {});

// PLAN SELECT MESSAGING
export const getPlanSelectMessaging = state => {
  const type =
    getSelectedPlan(state) || getIsActiveSubscriber(state)
      ? 'previous_selection'
      : 'no_selection';
  switch (getPartnerFlow(state)) {
    case SPRINTV2.FLOW:
      return getSprintPlanSelectHeader(state, type);
    case PARTNERSHIP.FLOW:
      return getPartnerFlowText(
        state,
        ['planSelect', 'headers', camelCase(type)],
        {}
      );
    default:
      // If the user is in the experiment HULU-14131, show experiment's subheader copy value/
      // Regardless of experiment assignment, show header from upstream or show default value.
      if (getShowValuePropBullets(state)) {
        return {
          header: get(
            getMessagingNode(state),
            ['planSelect', 'header'],
            'Choose Your Plan'
          ),
          subheader: getHulu14131PageSubtitle(state),
        };
      }

      return get(getMessagingNode(state), 'planSelect', {
        header: 'Choose Your Plan',
        subheader: 'Switch plans or cancel anytime.',
      });
  }
};

export const getPlanSelectDisclaimer = state => {
  const type = size(getPlans(state)) === 1 ? 'one_plan' : 'three_plans';
  switch (getPartnerFlow(state)) {
    case SPRINTV2.FLOW:
      return getSprintPlanSelectDisclaimer(state, type);
    case PARTNERSHIP.FLOW:
      return getPartnerFlowText(state, [
        'planSelect',
        'disclaimers',
        camelCase(type),
      ]);
    default:
      return null;
  }
};

// Get plan description list from site-config
const getPlanDescriptions = state =>
  getControlText(state, 'plan_description', {});

/**
 * Use plan description from upstream unless there is an
 * override defined in site-config.
 * @param {object} state The redux state tree
 * @param {object} plan The plan object and its properties
 * @returns The selected plan description
 */
export const getPlanDefaultDescription = (state, plan) => {
  const planDescriptions = getPlanDescriptions(state);
  return get(planDescriptions, [plan.id], plan.description);
};

export const getLearnMoreRedirection = state =>
  getControlText(state, 'learn_more', {});

export const getPlanNames = state => getControlText(state, 'plan_names', {});

// Get plan discount badge text from site-config
export const getPlanDiscountBadge = state =>
  getControlText(state, 'plan_discount_badge', {});

/**
 * If plan is SASH Bundle return plan badge text from upstream.
 * Remove badge description for Live Bundle plans.
 * In 3Up Flow send badge text for centered card (Trio Basic)
 * Otherwise, use getBadgeDescription to determine the required text.
 *
 * @param {object} state The redux state tree
 * @param {object} plan The plan object and its properties
 * @returns The selected text for the plan badge description
 */
export const getPlanBadgeDescription = (state, plan, show3UpView) => {
  const isPseudo2P = plan.identifier === PSEUDO_2P;
  const isTrioBasic = plan.identifier === ADS_HULU_DISNEY_ESPN;
  const isTrioPremium = plan.identifier === NOADS_HULU_DISNEY_ESPN;

  if (
    (isSashDisneyBundle(plan.identifier) && !show3UpView) ||
    (isTrioPremium && !show3UpView)
  ) {
    return plan.badge;
  }

  if (plan.includesLive && plan.isDisneySuperBundle) {
    return undefined;
  }

  let badgeDescription = getBadgeDescription(state, plan);

  if (hasAssignment(state, CAT_345.USER_TYPE_BADGE)) {
    if (plan.identifier === NOAH) badgeDescription = 'FOR THE BINGERS';
    if (plan.identifier === LIVE) badgeDescription = 'FOR CORD-CUTTERS';
  }

  if (show3UpView && !hasAssignment(state, HULU_13632.BADGES)) {
    if (isPseudo2P || isTrioPremium) badgeDescription = '';
    if (isTrioBasic) return plan.badge;
  }
  return badgeDescription;
};

/**
 * Get the text for plan badge description on mobile view.
 * If undefined, mobile badge description will match desktop badge.
 *
 * If the plan is SASH Bundle and the bundle badge experiment is not enabled,
 * return badge description. Otherwise, return undefined.
 * @param {object} state The redux state tree
 * @param {object} plan The plan object and its properties
 * @returns The selected text for the plan badge description on mobile view.
 */
export const getPlanMobileBadgeDescription = (state, plan, show3UpView) => {
  let mobileBadge = '';
  const bundlePlans = [
    PSEUDO_2P,
    ADS_HULU_DISNEY_ESPN,
    NOADS_HULU_DISNEY_ESPN,
    DUO_BASIC,
    DUO_PREMIUM,
  ];

  const showBundleBadgesExp = getShowBundleBadges(state);
  const planIdentifier = plan.identifier;

  if (
    bundlePlans.includes(planIdentifier) &&
    show3UpView &&
    !showBundleBadgesExp
  ) {
    mobileBadge = 'Bundle & Save';
  } else {
    mobileBadge =
      (isSashDisneyBundle(planIdentifier) ||
        isNoAdsDisneyBundle(planIdentifier)) &&
      !showBundleBadgesExp
        ? getBadgeDescription(state, plan)
        : undefined;
  }
  return mobileBadge;
};

/**
 * Check if there's a discount value in plan to use the description from siteconfig
 * In case there's no discount value, or no value in siteconfig
 * use the value from our upstreams
 * @param {object} state The redux store's current state
 * @param {object} plan Plan object and all its properties
 * @returns The text use for badge description
 */
const getBadgeDescription = (state, plan) =>
  get(plan, 'pricing[0].discount')
    ? get(getPlanDiscountBadge(state), [plan.id], plan.badge)
    : plan.badge;

export const getFeatureList = state =>
  getControlText(state, 'feature_list', {});

// ADDON SELECT MESSAGING
const getAddonSelectMessaging = createSelector(
  [getMessagingNode],
  messagingNode =>
    get(messagingNode, 'addonSelect', {
      header: 'Select your Add-ons',
      subheader: 'Switch or cancel anytime',
      banner: 'Big promo look over here at this thing',
      bannerInstructions: 'And as an added bonus, look at this other thing',
    })
);

const hasCustomerValueSubHeaderTreatment = state =>
  hasAssignment(state, CAT_335.SUBHEADER);

// PLAN OPTIONS PAGE MESSAGING
const getPlanOptionsPageHeader = state => {
  return getControlText(state, 'plan_options_page.header.default', [
    'Select Annual Billing',
    'and Save',
  ]);
};

export const getPlanOptionsPageSubheader = createSelector(
  [getSelectedPlan, getSiteConfig],
  (selectedPlan, siteConfigControlText) => {
    const subheader = get(
      siteConfigControlText,
      'plan_options_page.subheader.default',
      'Offer applies only to our %PLAN% plan.'
    );
    return selectedPlan
      ? subheader.replace(
          '%PLAN%',
          selectedPlan.identifier === SASH
            ? `${selectedPlan.name} (ad-supported)`
            : selectedPlan.name
        )
      : '';
  }
);

const getPlanOptionSelectMessaging = createSelector(
  [getPlanOptionsPageHeader, getPlanOptionsPageSubheader],
  (header, subheader) => ({ header, subheader })
);

// ACCOUNT INFO MESSAGING
// CAT-335: Shows customer value sub-header copy for Account creation page
const getAccountInfoMessaging = createSelector(
  [
    getMessagingNode,
    hasCustomerValueSubHeaderTreatment,
    getIsVerizonDssFlow,
    getSelectedPlan,
    state => path => getVerizonViaDssText(state, path),
    state => path => getControlText(state, path),
  ],
  (
    messagingNode,
    showCustomerValueSubHeader,
    isVerizonDssFlow,
    selectedPlan,
    getVerizonViaDssTextByPath,
    getControlTextByPath
  ) => {
    const messaging = get(messagingNode, 'createAccount', {
      header: 'Create Your Account',
    });

    if (showCustomerValueSubHeader) {
      messaging.subheader =
        'Share some info about yourself and we’ll do our best to recommend ' +
        'shows and movies that you might enjoy. It also helps keep your account ' +
        'safe so we know it’s really you signing up. You’ll use your email and ' +
        'password to sign into your Hulu account.';
    } else if (isVerizonDssFlow) {
      // Show a special sub-header if in Verizon via DSS activation flow
      messaging.subheader = getVerizonViaDssTextByPath(
        'messaging.createAccount.subheader'
      );
    } else if (isStudentPlan(selectedPlan)) {
      const modalMessage = getControlTextByPath(
        'student.account_page.terms_apply'
      );
      const modalTitle = getControlTextByPath(
        'student.account_page.terms_apply_title'
      );

      messaging.subheader = getControlTextByPath(
        'student.account_page.subheader'
      );
      messaging.getSubheaderRenderers = dispatch => ({
        link: props => (
          <ShowStudentTermsButton
            {...props}
            onClick={() => dispatch(showDefaultModal(modalTitle, modalMessage))}
          />
        ),
      });
    }

    return messaging;
  }
);

// BILLING INFO MESSAGING
export const getBillingHeader = state => {
  const headerType = getDisplayPaymentFields(state) ? 'default' : 'no_payment';
  return getControlText(state, ['billing_page', 'header', headerType], '');
};

// CAT-336: Gets Billing sub-header for experiment
const getExperimentalBillingSubheader = state => {
  if (get(state, 'user.selectedPlan.trial', null)) {
    const promotions = get(
      state,
      'ledger.ledger.subscription.plan.promotions',
      []
    );
    const ledgerDescription = promotions.length
      ? promotions[0].displayValue
      : '';
    // Extracting the date string from the ledger description
    const displayDate = ledgerDescription.match(
      /([1-9]|1[0-2])\/([1-9]|1\d|2\d|3[01])\/\d{2}/g
    );
    return displayDate
      ? `Enjoy your free trial, your first billing date will be on ${displayDate[0]}. You can cancel anytime.`
      : null;
  }
  return "You'll be billed today and you can cancel anytime.";
};

export const getBillingSubheader = state => {
  const partnerFlow = getPartnerFlow(state);
  if (partnerFlow === PARTNERSHIP.FLOW) {
    return getPartnerFlowText(state, 'billingInfo.subheader');
  }
  if (partnerFlow === SPRINTV2.FLOW) {
    return getSprintBillingSubheader(state);
  }
  if (getIsPPVFlow(state)) {
    return getControlText(state, 'billing_page.subheader.ppv');
  }
  if (hasAssignment(state, CAT_336.SUBHEADER)) {
    return getExperimentalBillingSubheader(state);
  }
  return getControlText(state, 'billing_page.subheader.default', null);
};

export const hasSecuritySymbolTreatment = state => {
  return (
    hasAssignment(state, CAT_43.TREATMENT1) && getDisplayPaymentFields(state)
  );
};

const getBillingInfoMessaging = createSelector(
  [
    getIsSkipBillingFlow,
    getBillingHeader,
    getBillingSubheader,
    hasSecuritySymbolTreatment,
  ],
  (isSkipBillingFlow, billingHeader, billingSubheader, showSecuritySymbol) => {
    // Don't show messaging if we're in a skip billing flow.
    if (isSkipBillingFlow) {
      return {};
    }

    return {
      header: billingHeader || 'Add Your Billing Info',
      subheader: billingSubheader,
      showSecuritySymbol,
    };
  }
);

export const getBillingDisclaimer = state => {
  if (!getIsActiveSubscriber(state)) {
    return undefined;
  }
  switch (getPartnerFlow(state)) {
    case PARTNERSHIP.FLOW:
      return getPartnerFlowText(state, 'billingInfo.disclaimer');
    case SPRINTV2.FLOW:
      return getSprintBillingDisclaimerText(state);
    default:
      return undefined;
  }
};

const getLoginPageMessaging = state => {
  if (getIsPartnerFlow(state)) {
    return { header: null };
  }

  return {
    header: getIsReacqFlow(state)
      ? getControlText(state, 'login_page.reacq.header')
      : 'Log In',
  };
};

export const getMessagingForPage = (state, props) => {
  const route = getPathname(state, props);

  const is3StepAccountCreationEnabled = getIs3StepAccountCreationEnabled(state);
  // const accountCreationRoutePattern = new RegExp(
  //   ROUTES.ACCOUNT_CREATION_PATTERN,
  //   'g'
  // );
  // const showAccountInfoMessaging =
  //   is3StepAccountCreationEnabled && accountCreationRoutePattern.test(route);

  // If the feature is turned on and the requested route matches the pattern for a 3-step account creation,
  // then return the account info messaging as it appears on single account creation flow.
  if (is3StepAccountCreationEnabled) {
    return getAccountInfoMessaging(state);
  }

  switch (route) {
    case ROUTES.PLAN_SELECT:
      return getPlanSelectMessaging(state);
    case ROUTES.ADDON_SELECT:
      return getAddonSelectMessaging(state);
    case ROUTES.PLAN_OPTIONS:
      return getPlanOptionSelectMessaging(state);
    case ROUTES.ACCOUNT_INFO:
      return getAccountInfoMessaging(state);
    case ROUTES.BILLING_INFO:
      return getBillingInfoMessaging(state);
    case ROUTES.LOGIN:
      return getLoginPageMessaging(state);
    default:
      return {};
  }
};

/**
 * Returns the site config page subtitle value for the experiment
 * HULU-14131. If undefined, returns default copy.
 */
const getHulu14131PageSubtitle = state => {
  return get(
    getValuePropBulletsCopy(state),
    'page_subtitle',
    'Switch plans or cancel anytime.'
  );
};

/**
 * Parses the Site Config hulu_14131_exp_copy object and
 * returns the list of value propositions for a given plan.
 *
 * bullet_messages - an object with id's and copy values for each value prop
 * plan_bullet_lists - an object where each plan has a list it's the value prop ids
 *
 * @returns {Array} List of copy values to display for the plan.
 */
export const getPlanValuePropCopy = (state, plan) => {
  const copy = getValuePropBulletsCopy(state);
  const bulletMessages = get(copy, 'bullet_messages', {});
  const planBulletsIdList = get(copy.plan_bullet_lists, [plan.id], []);

  return planBulletsIdList.map(id => bulletMessages[id]);
};

/**
 * Returns the discount disclaimer for Live Tv Trial
 * from the object within sufo_controls.text
 */
export const getLiveTvTrialDisclaimer = state =>
  get(getLiveTvTrialCopy(state), 'plan_discount_disclaimer');

/**
 * Returns the discount description copy for Live Tv Trial
 * from the object within sufo_controls.text
 */
export const getLiveTvTrialDiscountDescription = state =>
  get(getLiveTvTrialCopy(state), 'plan_discount_description');
