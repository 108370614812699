import { getDate } from './dateUtils';
import { ERROR_CODES } from '../constants/errorCodes';

const ELIGIBLE_AGE = 18;
const AGE_PERMISSION_REQUIRED = 13;

const ELIGIBLE_AGE_FOR_ADVERTISING = 17;

const shouldLockOut = birthday => {
  const date = getDate(birthday);
  const current = new Date();
  const permissionRequiredAgeDate = new Date(
    current.getFullYear() - AGE_PERMISSION_REQUIRED,
    current.getMonth(),
    current.getDate()
  );
  return date > permissionRequiredAgeDate;
};

const isParentalConsentNeeded = birthday => {
  const date = getDate(birthday);
  const current = new Date();
  const eligibleAgeDate = new Date(
    current.getFullYear() - ELIGIBLE_AGE,
    current.getMonth(),
    current.getDate()
  );
  const permissionRequiredAgeDate = new Date(
    current.getFullYear() - AGE_PERMISSION_REQUIRED,
    current.getMonth(),
    current.getDate()
  );
  return date <= permissionRequiredAgeDate && date >= eligibleAgeDate;
};

/**
 * Return if some tracker should be enabled to collect metrics given the user's date of birth.
 * One example of such trackers is Tealium.
 *
 * Metrics should not be collected if the user is a teen in a known age range.
 */
const shouldCollectMetrics = birthday => {
  const dateOfBirth = getDate(birthday);
  const currentDate = new Date();

  const advertisingEligibleAgeDate = new Date(
    currentDate.getFullYear() - ELIGIBLE_AGE_FOR_ADVERTISING,
    currentDate.getMonth(),
    currentDate.getDate()
  );

  return dateOfBirth <= advertisingEligibleAgeDate;
};

const isUserAgeRestricted = errors =>
  errors.some(error => error.code === ERROR_CODES.AGE_RESTRICTED);

export {
  shouldLockOut,
  isParentalConsentNeeded,
  isUserAgeRestricted,
  shouldCollectMetrics,
};
