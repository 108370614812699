var merge = require('lodash/merge');

if (process.env.NODE_ENV === 'development') {
  module.exports = merge(
    {},
    require('./env.prod.js'),
    require('./env.staging.js'),
    require('./env.dev.js')
  );
} else if (process.env.NODE_ENV === 'dogfood') {
  module.exports = merge(
    {},
    require('./env.prod.js'),
    require('./env.staging.js'),
    require('./env.dogfood.js')
  );
} else if (
  process.env.NODE_ENV === 'staging' ||
  process.env.NODE_ENV === 'test'
) {
  module.exports = merge(
    {},
    require('./env.prod.js'),
    require('./env.staging.js')
  );
} else if (process.env.NODE_ENV === 'smoke') {
  module.exports = merge(
    {},
    require('./env.prod.js'),
    require('./env.smoke.js')
  );
} else {
  // PROD is default
  module.exports = require('./env.prod.js');
}
