import React from 'react';
import PropTypes from 'prop-types';
import BreakdownItem from './BreakdownItem';

const LedgerBreakdown = ({ breakdown }) => (
  <ul className="ledger__breakdown">
    {breakdown.map((item, index) => (
      <BreakdownItem key={index} {...item} />
    ))}
  </ul>
);

LedgerBreakdown.propTypes = {
  breakdown: PropTypes.arrayOf(PropTypes.shape(BreakdownItem.PropTypes))
    .isRequired,
};

export default LedgerBreakdown;
