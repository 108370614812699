import browserHistory from '../browserHistory';
import * as MetricsEvents from './metricsEvents';
import {
  isAndroidMetricsTrackerAvailable,
  AndroidMetricsTracker,
} from './androidMetricsTracker';
import { TealiumMetricsTracker } from './tealium';
import * as newRelic from '../api/NewRelic';
import { SiteMetricsTracker } from './siteMetricsTracker';

class MetricsManager {
  constructor() {
    this.trackers = [];
    this.trackerMap = {};
    this.isInitialized = false;
    this.firstImpressionTimer = Date.now();

    this.register = this.register.bind(this);
    this.trackEvent = this.trackEvent.bind(this);
    this.initialize = this.initialize.bind(this);
    this.setStore = this.setStore.bind(this);
    this.historyListener = this.historyListener.bind(this);
    this.flushEvents = this.flushEvents.bind(this);
  }

  /*
   * MetricsManager should be initialized once per session, and before calling
   * any of its functions. Please remember to setStore() (see below)
   * Subsequent calls to initialize are no-ops.
   */
  initialize(siteConfig) {
    if (this.isInitialized) return;
    const state = this.store.getState();
    this.trackers.forEach(tracker => {
      if (typeof tracker.initialize === 'function') {
        tracker.initialize(siteConfig, state);
      }
    });
    browserHistory.listen(this.historyListener);
    this.isInitialized = true;
  }

  /*
   * The store must be set before initializing.
   */
  setStore(store) {
    this.store = store;
  }

  /*
   * MetricsManager will listen to all browser history changes and can call trackEvent()
   * based on logic here.
   */
  historyListener(e) {
    if (e && e.pathname) {
      // Do not send the page_impression events when the user is in an Edna Create Account flow,
      // because it's handled by the feature component from @hulu/web-login-ui.
      if (e.pathname.startsWith('/account')) {
        return;
      }

      const currPath = e.pathname;
      const pageImpressionOptions = {
        pathname: currPath,
        hit_version: '1.2.3',
        is_first_impression: !!this.firstImpressionTimer,
        duration: this.firstImpressionTimer
          ? Date.now() - this.firstImpressionTimer
          : null,
      };
      this.trackEvent(MetricsEvents.PAGE_IMPRESSION, pageImpressionOptions);
      this.firstImpressionTimer = null;
    }
  }

  register(tracker) {
    if (!(typeof tracker.getEventList === 'function')) {
      throw new Error("The tracker doesn't implement getEventList function.");
    }
    if (!(typeof tracker.trackEvent === 'function')) {
      throw new Error("The tracker doesn't implement trackEvent function.");
    }
    const eventList = tracker.getEventList();
    if (!eventList || !Array.isArray(eventList)) {
      return;
    }
    eventList.forEach(event => {
      const list = this.trackerMap[event] || [];
      list.push(tracker);
      this.trackerMap[event] = list;
    });
    this.trackers.push(tracker);
  }

  trackEvent(eventName, options = {}) {
    // shouldn't trigger any metrics event before or in loading screen.
    if (!this.isInitialized && this.store) {
      // there is a bug in redux-localstorage, REDUX_LOCALSTORAGE_INIT won't be caught in the middleware.
      const state = this.store.getState();
      if (!state.siteConfig) {
        this.initialize(state.siteConfig);
      }
    }
    if (!this.isInitialized || !this.store) {
      // ignore any event before metrics manager is initialized
      // i.e. refresh on billing page, billing form will be re-rendered before getting to load screen
      // this SUBSCRIPTION_STEP_START event will be redundant.
      return;
    }
    const events = Array.isArray(eventName) ? eventName : [eventName];
    events.forEach(e => {
      const list = this.trackerMap[e];
      if (!list) {
        return;
      }
      const state = this.store.getState();
      list.forEach(tracker => {
        try {
          tracker.trackEvent(e, state, options);
        } catch (err) {
          newRelic.noticeError(err);
        }
      });
    });
  }

  /**
   * Ideally, this method is used to allow all of the underlying trackers
   * to flush all of their metrics and return a promise that only resolves
   * when the metrics have been flushed.
   *
   * Unfortunately, in practice none of the trackers actually know when they've
   * finished emitting these metrics.  So instead, some of these trackers just
   * return a Promise that simply resolves after a short setTimeout delay.
   */
  flushEvents() {
    const promises = this.trackers
      .filter(tracker => typeof tracker.flush === 'function')
      .map(tracker => tracker.flush());

    return Promise.all(promises);
  }
}

const createMetricsManager = function() {
  const metricsManager = new MetricsManager();

  if (isAndroidMetricsTrackerAvailable()) {
    metricsManager.register(new AndroidMetricsTracker());
  } else {
    metricsManager.register(new SiteMetricsTracker());
  }

  metricsManager.register(new TealiumMetricsTracker());

  return metricsManager;
};

const metricsManager = createMetricsManager();

export { metricsManager };
