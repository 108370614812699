import { actions } from 'react-redux-form';
import { datadogRum } from '@datadog/browser-rum';

import * as api from '../api';
import { getConfig } from './configActions';
import * as types from '../constants/actionTypes';
import { ERROR_CODES } from '../constants/errorCodes';
import { isFeatureEnabled } from '../selectors/featureFlag';
import { getIsPartnerFlow } from '../selectors/flow';
import { getAuthRecaptchaInstance } from '../utils/recaptcha';

export function login(fetchConfigAfterLogin, overrideRedirectionUrl) {
  return function(dispatch, getState) {
    const {
      user: { loggedIn, email, password },
      misc: {
        loginCsrfTokens: { password: token },
      },
    } = getState();
    if (!password) {
      if (loggedIn) {
        // Logged in user without a stored password should just refresh cookies
        return api.refreshCookies();
      }
      // Logged out user without a stored password is an invalid case
      throw new Error('Attempted to log in user when password is null');
    }

    // Logged in or logged out user with a stored password should call authentication

    // Get the auth recaptcha class instance
    const authRecaptchaInstance = getAuthRecaptchaInstance();

    const isGrecaptchaAuthFeatureOn = isFeatureEnabled(
      getState(),
      'grecaptcha-auth'
    );

    // Define an empty value for the recaptcha token.
    // A recaptcha token is not required if the auth recaptcha feature is not enabled.
    let recaptchaPromise = Promise.resolve();

    // If the auth recaptcha feature is enabled,
    // then get a new recaptcha token for the login call.
    const shouldGetNewRecaptchaToken =
      isGrecaptchaAuthFeatureOn && authRecaptchaInstance;

    if (shouldGetNewRecaptchaToken) {
      // Execute the recaptcha challenge to get a token
      datadogRum.addAction('recaptcha_async_executed');
      recaptchaPromise = authRecaptchaInstance.executeAsync();
    }

    return recaptchaPromise
      .catch(e => {
        datadogRum.addAction('recaptcha_async_rejected');
        return Promise.reject(e);
      })
      .then(recaptchaToken => {
        if (shouldGetNewRecaptchaToken)
          datadogRum.addAction('recaptcha_async_resolved');
        return api.login(email, password, token, recaptchaToken);
      })
      .then(() => {
        dispatch(loginSuccess());
        return fetchConfigAfterLogin
          ? dispatch(getConfig(overrideRedirectionUrl))
          : Promise.resolve();
      })
      .finally(() => {
        // Reset the challenge each time
        authRecaptchaInstance.reset();
      });
  };
}

/**
 * Calls the Logout API and clears the user data from the Redux store
 */
export function logout() {
  return dispatch => api.logout().then(() => dispatch(logoutSuccess()));
}

function processPaymentErrors(
  error,
  plan,
  addons,
  addonBundles,
  user,
  redirectRoute = null
) {
  return (dispatch, getState) =>
    dispatch({
      type: types.PROCESS_PAYMENT_ERRORS,
      preventClose:
        error.code === ERROR_CODES.INVALID_PARTNER_OPERATION &&
        getIsPartnerFlow(getState()),
      redirectRoute,
      error,
      plan,
      addons: addons && addons.map(addon => addon.requestObject),
      addonBundles:
        addonBundles &&
        addonBundles.map(addonBundle => addonBundle.requestObject),
      user,
    });
}

export function processErrors(
  error,
  plan,
  addons,
  addonBundles,
  user,
  redirectRoute = null
) {
  return dispatch => {
    dispatch(
      processPaymentErrors(
        error,
        plan,
        addons,
        addonBundles,
        user,
        redirectRoute
      )
    );
    if (error.code === ERROR_CODES.AVS_FAILURE) {
      dispatch(actions.setValidity('payment.zip', { invalid: false }));
    }
    return Promise.resolve();
  };
}

export function setPaymentToken(paymentType, paymentToken) {
  return {
    type: types.UPDATE_PAYMENT_TOKEN,
    paymentType,
    paymentToken,
  };
}

export function clearPrefillBillingInfo() {
  return {
    type: types.CLEAR_PREFILL_BILLING_INFO,
  };
}

export function selectPlan(plan, options = {}) {
  return {
    type: types.SELECT_PLAN,
    plan,
    ...options,
  };
}

export function reselectPlan() {
  return {
    type: types.RESELECT_PLAN,
  };
}

export function loginSuccess() {
  return {
    type: types.LOGIN_SUCCESS,
  };
}

export function logoutSuccess() {
  return {
    type: types.LOGOUT_SUCCESS,
  };
}
