import React from 'react';
import PropTypes from 'prop-types';

import { url } from '../../../config/env';

const HULU_LOGO_DARK = '/static/images/hulu-dark.svg';

const PageTopNav = ({ logo, isLoggedIn, onLoginButtonClick }) => {
  /**
   * Prevent href navigation before dispatching onClick action.
   * @param {React.SyntheticEvent} event
   */
  const redirectToLogin = event => {
    event.preventDefault();
    onLoginButtonClick();
  };

  return (
    <header className="page__header">
      <div className="page__header--inner">
        <div className="logo">
          <div aria-label="Hulu" role="img" className="logo--inner">
            <img
              className="logo__image"
              src={logo || HULU_LOGO_DARK}
              alt="Hulu"
              role="presentation"
            />
          </div>
        </div>
        {!isLoggedIn && (
          <div className="login">
            <a
              className="login__button"
              href={url.login}
              onClick={redirectToLogin}
            >
              Log In
            </a>
          </div>
        )}
      </div>
    </header>
  );
};

PageTopNav.propTypes = {
  logo: PropTypes.string,
  isLoggedIn: PropTypes.bool.isRequired,
  onLoginButtonClick: PropTypes.func.isRequired,
};

export default PageTopNav;
