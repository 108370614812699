import * as newrelic from './NewRelic';
import { HTTP_STATUS } from '../constants/httpStatus';

import {
  isHuluPlatform,
  getExternalUserToken,
} from '../utils/huluPlatformUtils';

/**
 * A simple wrapper around fetch that also handles response.
 * @param {...any} args - Same as the arguments that get passed to fetch.
 * @see fetch
 */
export default function request(url, options) {
  if (isHuluPlatform()) {
    const userToken = getExternalUserToken();
    if (userToken) {
      options.headers = {
        ...options.headers,
        'X-Hulu-User-Token': userToken,
      };
    }
  }
  return fetch(url, options)
    .catch(res => {
      newrelic.noticeError(res);
      return Promise.reject({ status: 0, reason: res });
    })
    .then(res => {
      // Skip JSON parsing if the response status is 204 (No Content).
      if (res.status === HTTP_STATUS.NO_CONTENT) {
        return { status: res.status };
      }

      return res.json().then(json => {
        const response = { status: res.status, ...json };
        return res.ok ? response : Promise.reject(response);
      });
    });
}
