export const ERROR_TEXT = Object.freeze({
  REQUIRED: 'Required',
  REQUIRED_ZIP: 'Please enter your zip',
  REQUIRED_EMAIL: 'Please enter your email address',
  REQUIRED_NAME: 'Please enter your name',
  REQUIRED_GENDER: 'Please select your gender',
  VALID_DATE: 'Please enter a valid date',
  VALID_EMAIL: 'Please enter a valid email address',
  VALID_CODE: 'Please enter a valid code',
  VALID_ZIP: 'Please enter a valid zip',
  VALID_ZIP_CODE: 'Please enter a valid zip code',
  VALID_PASSWORD: 'Passwords must be six or more characters',
  HOME_ZIP: 'Please enter your home zip',
  ACCOUNT_EXISTS: 'You already have an account. Click here to log in',
  CANNOT_VERIFY_EMAIL: "We can't verify your email now",
  CHECK_CREDITCARD: 'Please check your credit card number',
  LIVETV_UNAVAILABLE:
    'Live TV is only available in the 50 United States and the District of Columbia. Choose a different plan.',
});

export const ERROR_SCREEN_DEFAULT_TEXT = 'Page Not Found';
