import React from 'react';

import { GENDER, MIN_AGE } from '../helpers/dataConstants';
import {
  getRandomUser,
  getRandomDate,
  getRandomNumber,
} from '../helpers/dataGenerator';
import {
  populateEdnaField,
  populateEdnaDropdown,
} from '../helpers/testingUtils';

export const FastPassAccount = () => {
  const getRandomData = () => {
    return {
      user: getRandomUser(),
      date: getRandomDate(new Date(), MIN_AGE),
      gender: getRandomNumber(GENDER.length - 1),
    };
  };

  /**
   * Formats the date object into mm/dd/yyyy.
   * @param {Object} date - The date object containing the month, date, year properties.
   * @returns {string} A string representing the date in mm/dd/yyyy format.
   */
  const formatDate = date => {
    const { year } = date;

    // As the starting index for months is 0, to get the correct month value, we could increment date.month by 1
    let month = (1 + date.month).toString();
    if (month.length < 2) {
      month = `0${month}`;
    }

    let day = date.date.toString();
    if (day.length < 2) {
      day = `0${day}`;
    }

    return `${month}/${day}/${year}`;
  };

  const handleOnClick = async () => {
    const { user, date, gender } = getRandomData();

    await populateEdnaField('email-field', user.email);
    await populateEdnaField('password', user.password);
    await populateEdnaField('name', user.name);
    await populateEdnaField('birthdate', formatDate(date));
    await populateEdnaField('zip', user.zipCode);
    await populateEdnaField('billingZip', user.zipCode);
    await populateEdnaField('legalZip', user.zipCode);

    populateEdnaDropdown('gender-field', gender);

    try {
      document.querySelector("[type='submit']").focus();
    } catch (e) {
      // e
    }
  };

  return (
    <div className="testing-dashboard__account">
      <button
        className="image-button"
        data-testid="fastpass-account-button"
        type="button"
        onClick={handleOnClick}
      >
        <img
          alt="Fast Pass Icon"
          src="/static/images/testing-dashboard-icon.svg"
        />
      </button>
    </div>
  );
};
