const DAYS_IN_MONTHS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getDate = date => {
  const year = parseInt(date.year || '0', 10);
  const month = parseInt(date.month || '1', 10) - 1;
  const day = parseInt(date.day || '1', 10);
  return new Date(year, month, day);
};

export const parseDate = dateStr => {
  const date = new Date(dateStr);
  return {
    year: date.getUTCFullYear().toString(),
    month: (date.getUTCMonth() + 1).toString(),
    day: date.getUTCDate().toString(),
  };
};

const isLeapYear = year =>
  year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);

export const isValidDate = dateObj => {
  if (dateObj == null || Object.keys(dateObj).length !== 3) {
    return false;
  }
  const month = parseInt(dateObj.month, 10) - 1;
  const day = parseInt(dateObj.day, 10);
  const year = parseInt(dateObj.year, 10);

  const date = new Date(year, month - 1, day);

  if (date.getTime() > Date.now() || !MONTHS[month]) {
    return false;
  }

  if (month === 1) {
    // month starts at 0 so 1 is february
    return isLeapYear(year) ? day <= 29 : day <= 28;
  }

  return day <= DAYS_IN_MONTHS[month];
};

export const isValidDateString = date =>
  new Date(date) instanceof Date && !isNaN(new Date(date));

/**
 * Format a date string. Example: 2019-01-01T00:00:00-04:00 will become 1/1/19
 * If the date passed in does not contain a timezone, default to UTC timezone.
 * @param date - date string to be formated
 */
export const formatDateString = date => {
  const options = {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
  };
  if (date.split('T').length === 1) {
    options.timeZone = 'UTC';
  }
  return new Date(date).toLocaleDateString('en-US', options);
};
