import React from 'react';
import PropTypes from 'prop-types';

require('../styles/page.scss');

const ShowStudentTermsButton = ({ children, onClick }) => (
  <button
    type="button"
    className="link-button"
    data-testid="show-student-terms-button"
    onClick={onClick}
  >
    {children}
  </button>
);

ShowStudentTermsButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
};

export default ShowStudentTermsButton;
