import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { routePush } from '../actions/routingActions';
import { login, processErrors } from '../actions/serverActions';
import { refreshCookies } from '../api';
import { noticeError } from '../api/NewRelic';
import { DEFAULT_ERROR } from '../constants/errorCodes';
import * as routes from '../constants/routes';
import track from '../metrics/track';
import * as MetricsEvents from '../metrics/metricsEvents';
import PaypalCallbackPage from '../pages/PaypalCallbackPage';
import { handleSuccessfulSignupRedirect } from '../utils/url';
import { isUserAgeRestricted } from '../utils/ageValidationUtils';
import { createSubscriber } from '../actions/subscriberActions';
import { deleteCartAbandonmentCookie } from '../utils/cartAbandonmentUtils';
import {
  getIsHiSwitchFlow,
  getHiSwitchRedirectUrl,
} from '../selectors/siteconfig';

function mapStateToProps(state) {
  const {
    flow: { query },
  } = state;

  return {
    isHiSwitchFlow: getIsHiSwitchFlow(state),
    hiSwitchRedirectUrl: getHiSwitchRedirectUrl(state),
    query,
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const handleSubscription = () => {
    const { query, isHiSwitchFlow, hiSwitchRedirectUrl } = stateProps;
    const { dispatch } = dispatchProps;
    const { trackEvent, flushEvents } = ownProps;

    if (!query || !query.token) {
      throw new Error("No token provided.  Can't complete subscription.");
    }
    dispatch(createSubscriber()).then(
      response => {
        trackEvent(MetricsEvents.SUBSCRIPTION_END, { ...response });

        // Login the user once the subscription is created
        const loginPromise = dispatch(login(false)).then(() => {
          trackEvent(MetricsEvents.USER_LOGIN, {
            auth_method: MetricsEvents.AUTH_METHOD.NEW_SUBSCRIPTION,
          });
        });

        const refreshCookiesPromise = refreshCookies();
        const metricsDelayPromise = flushEvents();
        Promise.all([loginPromise, refreshCookiesPromise, metricsDelayPromise])
          .catch(err => {
            // Refresh cookie call failed. Log the error and proceed with redirect anyways
            noticeError(
              err || 'Refresh cookie call failed at the end of Paypal signup.'
            );
          })
          .then(() => {
            deleteCartAbandonmentCookie();
            const redirectUrl = isHiSwitchFlow
              ? hiSwitchRedirectUrl
              : response.redirectionUrl;
            handleSuccessfulSignupRedirect(redirectUrl);
          });
      },
      errorResponse => {
        const error = { ...DEFAULT_ERROR };
        if (!isEmpty(errorResponse.errors)) {
          if (isUserAgeRestricted(errorResponse.errors)) {
            dispatch(routePush(routes.AGE_ROADBLOCK));
            return; // eslint-disable-line
          }
          Object.assign(error, errorResponse.errors[0]);
        }
        trackEvent(MetricsEvents.SUBSCRIPTION_PAYMENT_ERROR, { error });
        dispatch(
          processErrors(
            error,
            errorResponse.plan,
            errorResponse.addons,
            errorResponse.addonBundles,
            errorResponse.user
          )
        );
        dispatch(routePush(routes.BILLING_INFO));
      }
    );
  };

  return {
    ...stateProps,
    handleSubscription,
  };
}

export default track(
  connect(mapStateToProps, null, mergeProps)(PaypalCallbackPage)
);
