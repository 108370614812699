import get from 'lodash/get';
import { routePush, routeReplace, urlRedirect } from './routingActions';
import { getMissingUserFields } from '../utils/userUtils';
import { SPOTIFY, SPRINTV2, PARTNERSHIP } from '../constants/partners';
import { postLoginRoutingForIdentityVerification } from './identityActions';
import { postLoginRoutingForSprintV2 } from './sprintV2Actions';
import { postConfigRoutingForPartnership } from './partnerActions';
import { handleRedirectToBilling } from './accountActions';
import * as routes from '../constants/routes';
import {
  getIsHuluSuperbundleFlow,
  getPartnerFlow,
  getIsUnifiedActivationFlow,
} from '../selectors/flow';
import {
  getDisplayBillingZipOnAccountPage,
  getShouldRedirectToAccount,
} from '../selectors/ledger';
import { requiresVerifiedIdentity } from '../selectors/identity';
import * as MetricsEvents from '../metrics/metricsEvents';
import { checkTrialDiscountEligibility } from './eligibilityActions';
import { didSelectedPlanChange } from '../utils/planSelectUtils';
import { CHANGE_PLAN_URL } from '../constants/misc';

/**
 * Handles routing logic for after a successful user login.
 * @param updatedUserNode - the most recent user node, which should be obtained from config endpoint
 */
export const loggedInRoutingLogic = updatedUserNode => (dispatch, getState) => {
  const partnerFlow = getPartnerFlow(getState());
  const displayBillingZipOnAccountPage = getDisplayBillingZipOnAccountPage(
    getState()
  );
  if (updatedUserNode) {
    if (updatedUserNode.status === 'subscribed' && partnerFlow) {
      if (partnerFlow === SPOTIFY.FLOW) {
        return dispatch(routeReplace(routes.SPOTIFY_CALLBACK));
      }

      if (partnerFlow === SPRINTV2.FLOW) {
        return dispatch(postLoginRoutingForSprintV2());
      }

      if (partnerFlow === PARTNERSHIP.FLOW) {
        return dispatch(postConfigRoutingForPartnership());
      }

      throw new Error(`unknown partner flow on login: ${partnerFlow}`);
    } else if (
      getMissingUserFields(getState(), updatedUserNode).length === 0 &&
      !displayBillingZipOnAccountPage
    ) {
      if (requiresVerifiedIdentity(getState())) {
        return dispatch(postLoginRoutingForIdentityVerification());
      }
      if (getShouldRedirectToAccount(getState())) {
        return dispatch(urlRedirect(CHANGE_PLAN_URL));
      }
      return dispatch(handleRedirectToBilling());
    }
  }
  return dispatch(routePush(routes.ACCOUNT_INFO));
};

export const handleSuccessfulLogin = (updatedConfig, user) => (
  dispatch,
  getState,
  { trackEvent }
) => {
  trackEvent(MetricsEvents.USER_LOGIN, {
    auth_method: MetricsEvents.AUTH_METHOD.USERNAME_PASSWORD,
  });
  dispatch(checkTrialDiscountEligibility(updatedConfig));
  if (
    getIsHuluSuperbundleFlow(getState()) &&
    updatedConfig.plans.length === 0
  ) {
    return dispatch(routePush(routes.AGE_ROADBLOCK));
  }
  if (
    get(updatedConfig, 'user.status', '') !== 'subscribed' &&
    (!user.selectedPlan ||
      didSelectedPlanChange(user.selectedPlan, updatedConfig)) &&
    !getIsUnifiedActivationFlow(getState())
  ) {
    return dispatch(routePush(routes.PLAN_SELECT));
  }
  return dispatch(loggedInRoutingLogic(updatedConfig.user));
};
