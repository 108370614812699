import { createSelector } from 'reselect';
import get from 'lodash/get';

import * as paymentMethods from '../constants/paymentMethods';
import { getAllSelectedAddonRequests } from './addons';
import { getClient, getQuery } from './flow';
import { getPartnerOfferId } from './partners';
import { getIsIneligible } from './warning';
import { getToken as getBundlePartnerToken } from './bundlePartner';
import { getUserNode } from './user';

const getUser = state => state.user;
const getPayment = state => state.payment;
const getSprintV2Eligibility = state =>
  get(state, 'partner.sprintV2Eligibility.response', null);
const getSpotifyEligibility = state =>
  get(state, 'partner.spotifyEligibility.response', null);
const getCreateSubscriberLoading = state =>
  state.subscription.createSubscriber.loading;
const getCreateSubscriberSuccess = state =>
  state.subscription.createSubscriber.response != null;
const getIsBillingFormValid = state =>
  get(state, 'forms.payment.$form.valid', false);

export const getSubscription = state =>
  get(state, 'user.selectedPlan.subscription', null);
export const getPaymentType = state => state.payment.paymentType;

export const getCreateSubscriberInProgress = createSelector(
  [getCreateSubscriberLoading, getCreateSubscriberSuccess],
  (createSubscriberLoading, createSubscriberSuccess) =>
    createSubscriberLoading || createSubscriberSuccess
);

// Checks whether the payment method requires retrieving a token to allow for billing info submission.
export const canSubmitPayment = ({ payment: { paymentType, paymentToken } }) =>
  !(paymentType === paymentMethods.PAYPAL && paymentToken);

export const canSubmitBillingInfo = state =>
  getIsBillingFormValid(state) &&
  !getCreateSubscriberInProgress(state) &&
  canSubmitPayment(state);

export const isBuyingLivePlan = state =>
  get(state, 'user.selectedPlan.includesLive', false);

export const getSubscriptionNode = createSelector(
  [getSubscription, getAllSelectedAddonRequests, getIsIneligible],
  (subscription, addonRequests, isIneligible) =>
    isIneligible
      ? subscription
      : {
          ...subscription,
          ...addonRequests,
        }
);

// Payment node selectors for the various payment types.
export const getCreditCardPaymentNode = createSelector(
  [getPayment],
  ({ ownerFullName, cvc: cvv, zip, creditCard, expiry }) => {
    const paymentNode = {
      cvv,
      ownerFullName,
      method: paymentMethods.CREDITCARD,
      zip,
    };
    if (expiry) {
      const [month, year] = expiry.replace(/\s/g, '').split('/');
      const fourDigitYear = year.length === 2 ? `20${year}` : year;
      paymentNode.expiration = `${fourDigitYear}-${month}`;
    }
    if (creditCard) {
      paymentNode.number = creditCard.replace(/\s/g, '');
    }
    return paymentNode;
  }
);

export const getCurrentPaymentNode = createSelector([getPayment], ({ cvc }) => {
  return {
    cvv: cvc,
    method: paymentMethods.USE_CURRENT,
  };
});

export const getVenmoPaymentNode = createSelector(
  [getPayment],
  ({ nonce, zip }) => ({
    method: paymentMethods.VENMO,
    token: nonce,
    zip,
  })
);

export const getPaypalPaymentNode = createSelector(
  [getPayment, getQuery],
  ({ zip }, { token }) => ({
    method: paymentMethods.PAYPAL,
    zip,
    token,
  })
);

export const getGiftCodePaymentNode = createSelector(
  [getPayment, getUser],
  ({ code, zip }, { zip: userZip, billingZip }) => ({
    method: paymentMethods.GIFT_CODE,
    code,
    zip: zip || userZip || billingZip,
  })
);

export const getRpmPaymentNode = createSelector(
  [getPayment],
  ({ token, zip }) => ({
    method: paymentMethods.RPM,
    token,
    zip,
  })
);

export const getSpotifyPaymentNode = createSelector(
  [getPayment, getSpotifyEligibility],
  ({ accessToken: token, refreshToken }, spotifyEligibility) => {
    const paymentNode = {
      method: paymentMethods.SPOTIFY,
      token,
      refreshToken,
    };
    if (spotifyEligibility) {
      paymentNode.bundleId = spotifyEligibility.bundleId;
    }
    return paymentNode;
  }
);

export const getSprintV2PaymentNode = createSelector(
  [getSprintV2Eligibility],
  sprintV2Eligibility => {
    const paymentNode = {
      method: paymentMethods.SPRINTV2,
    };
    if (sprintV2Eligibility) {
      paymentNode.sprintV2SubId = sprintV2Eligibility.subscriberId;
    }
    return paymentNode;
  }
);

export const getPartnerPaymentNode = createSelector(
  getPartnerOfferId,
  partnerOfferId => ({
    method: paymentMethods.PARTNER,
    token: partnerOfferId,
  })
);

export const getBundlePartnerPaymentNode = createSelector(
  getBundlePartnerToken,
  token => ({
    method: paymentMethods.BUNDLE_PARTNER,
    token,
  })
);

export const getNoPaymentNode = createSelector(
  [getPayment, getUser],
  ({ zip }, { zip: userZip, billingZip }) => ({
    method: paymentMethods.NONE,
    zip: zip || userZip || billingZip,
  })
);

export const getEmptyPaymentNode = createSelector(
  [getPayment],
  ({ paymentType }) => ({
    method: paymentType,
  })
);

export const getPaymentNode = state => {
  switch (state.payment.paymentType) {
    case paymentMethods.CREDITCARD:
      return getCreditCardPaymentNode(state);
    case paymentMethods.VENMO:
      return getVenmoPaymentNode(state);
    case paymentMethods.PAYPAL:
      return getPaypalPaymentNode(state);
    case paymentMethods.GIFT_CODE:
      return getGiftCodePaymentNode(state);
    case paymentMethods.RPM:
      return getRpmPaymentNode(state);
    case paymentMethods.SPOTIFY:
      return getSpotifyPaymentNode(state);
    case paymentMethods.SPRINTV2:
      return getSprintV2PaymentNode(state);
    case paymentMethods.PARTNER:
      return getPartnerPaymentNode(state);
    case paymentMethods.BUNDLE_PARTNER:
      return getBundlePartnerPaymentNode(state);
    case paymentMethods.NONE:
      return getNoPaymentNode(state);
    case paymentMethods.USE_CURRENT:
      return getCurrentPaymentNode(state);
    default:
      return getEmptyPaymentNode(state);
  }
};

export const getSubscriptionRequest = createSelector(
  [getUserNode, getSubscriptionNode, getClient],
  (user, subscription, client) => ({
    user,
    subscription,
    client,
  })
);

export const getCreateSubscriberRequest = createSelector(
  [getSubscriptionRequest, getPaymentNode],
  (subscriptionRequest, payment) => ({
    ...subscriptionRequest,
    payment,
  })
);

export const getValidateAccountRequest = createSelector(
  [getUser, getClient],
  (user, client) => ({
    bundleIdentifier: get(user, 'selectedPlan.identifier', null),
    bundleProgramId: get(
      user,
      'selectedPlan.subscription.promotion.programId',
      null
    ),
    componentIds: get(user, 'selectedPlan.componentIds', null),
    componentIdentifiers: get(user, 'selectedPlan.componentIdentifiers', null),
    device: client.device || client.subpartner,
    email: user.email,
    homeZip: user.zip,
    partner: client.partner,
  })
);
