import { createSelector } from 'reselect';
import get from 'lodash/get';
import has from 'lodash/has';
import { matchPath } from 'react-router-dom';

import { HULU_DOMAIN_REGEX } from '../constants/misc';
import {
  SPOTIFY,
  SPRINTV2,
  AMAZON,
  GOOGLE_ANDROID,
  BUNDLE_PARTNER,
} from '../constants/partners';
import {
  GO_LIVE_ONLY,
  GO_REACQ,
  INIT_PAGES,
  PARTNER_LANDING_PAGES,
  TAB_QUERY_PARAM,
  PAYPAL_CALLBACK,
  BUNDLE_PARTNER_ACTIVATE,
  IDENTIFIER_QUERY_PARAM,
  ACTIVATE,
} from '../constants/routes';
import {
  VERIFICATION_ID_PARAM,
  VERIFICATION_RESULT_PARAM,
} from '../constants/identity';
import { isMobile } from '../utils/deviceUtils';
import {
  getIsTMobileFeatureOn,
  getIsUnifiedActivationEnabled,
} from './featureFlag';
import { getControlText } from './siteconfig';
import { getIsSuperbundleFlow, getSelectedPlan } from './user';
import { getPayload } from './bundlePartner';
import { MAX_BUNDLE_PLAN_IDS } from '../constants/plans';

/*
  This function formats incorrectly typed T-Mobile paths.
  Ex. signup.hulu.com/tmobile -> signup.hulu.com/t-mobile
*/
const formatTmobilePath = (flowPathName = '') => {
  const T_MOBILE = 'tmobile';
  const pathName = flowPathName.toLowerCase();
  return pathName.includes(T_MOBILE)
    ? pathName.replace(T_MOBILE, 't-mobile')
    : pathName;
};

export const getSearch = state => state.flow.search;
const getRedirectUrl = state => get(state, 'flow.query.redirectUrl', '');

export const getPathname = (state, ownProps) =>
  get(ownProps, 'location.pathname', '');
export const getFlowPathname = state => formatTmobilePath(state.flow.pathname);
export const getQuery = state => state.flow.query;
export const getClient = state => state.flow.client;
export const getClientPartner = state => state.flow.client.partner;
export const getClientSubpartner = state => state.flow.client.subpartner;
export const getStartPathname = state => state.flow.startPathname;
export const getIsPartnerFlow = state => !!state.flow.partnerFlow;
export const getInstrumentationFlowName = state =>
  state.flow.instrumentationFlowName;
export const getIsSpotifyPartnerFlow = state =>
  state.flow.partnerFlow === SPOTIFY.FLOW;
export const getIsSprintV2PartnerFlow = state =>
  state.flow.partnerFlow === SPRINTV2.FLOW;
export const getIsAmazonEchoShowClient = state =>
  state.flow.client.partner.toLowerCase() === AMAZON.PARTNER &&
  state.flow.client.subpartner.toLowerCase() ===
    AMAZON.SUBPARTNER.AMAZON_ECHO_SHOW;
export const getPartnerFlow = state => state.flow.partnerFlow;
export const getIsVIPFlow = state => has(state, 'flow.query.vip_code', false);
export const getIsPaypalCallback = state =>
  state.flow.pathname === PAYPAL_CALLBACK;

export const hasExternalIdentityVerification = state =>
  has(state, `flow.query.${VERIFICATION_RESULT_PARAM}`) &&
  has(state, `flow.query.${VERIFICATION_ID_PARAM}`);
export const getExternalIdentityVerificationId = state =>
  get(state, `flow.query.${VERIFICATION_ID_PARAM}`, false);
export const getExternalIdentityVerificationResult = state =>
  get(state, `flow.query.${VERIFICATION_RESULT_PARAM}`, 'failure');
export const getTabQueryParam = state =>
  get(state, `flow.query.${TAB_QUERY_PARAM}`, undefined);
export const getPlanIdentifierQueryParam = state =>
  get(state, `flow.query.${IDENTIFIER_QUERY_PARAM}`);

export const getFromParam = state => get(state, `flow.query.from`);
export const getPromoIDParam = state => get(state, `flow.query.promo_id`);
export const getReferrerParam = state => get(state, 'flow.query.referrer');

export const getIsInitPage = createSelector([getFlowPathname], pathname =>
  INIT_PAGES.includes(pathname)
);

export const getIsRokuPacFlow = createSelector(
  [getSearch],
  search => search.indexOf('=roku_web') > -1 && search.indexOf('roku_pac=') > -1
);

export const getIsGoogleAndroid = createSelector(
  [getClientPartner, getClientSubpartner],
  (partner, subpartner) =>
    partner === GOOGLE_ANDROID.PARTNER &&
    subpartner === GOOGLE_ANDROID.SUBPARTNER
);

export const getIsVerizonDssFlow = createSelector(
  [getClientPartner],
  partner => partner?.toLowerCase() === BUNDLE_PARTNER.PARTNER.VERIZON_VIA_DSS
);

// This selector checks that the redirect URL is a Hulu domain and returns if so
// and null otherwise.
export const getRedirectUrlQueryParam = createSelector(
  [getIsAmazonEchoShowClient, getRedirectUrl],
  (isAmazonEchoShowClient, redirectUrl) => {
    try {
      const decodedRedirect = decodeURIComponent(redirectUrl);
      const redirectHostname =
        decodedRedirect && new URL(decodedRedirect).hostname;
      return isAmazonEchoShowClient && redirectHostname.match(HULU_DOMAIN_REGEX)
        ? decodedRedirect
        : null;
    } catch (error) {
      return null;
    }
  }
);

export const getIsPartnerLandingPage = pathname =>
  matchPath(pathname, { path: PARTNER_LANDING_PAGES }) !== null;

/**
 * True if the user is currently at the /activate route, or if they started the flow there
 */
export const getIsUnifiedActivationPath = createSelector(
  [getStartPathname, getFlowPathname],
  (startPathname, pathname) =>
    startPathname === ACTIVATE || pathname === ACTIVATE
);

/**
 * True if the user is in the Unified Activation flow, and its feature flag is enabled
 */
export const getIsUnifiedActivationFlow = createSelector(
  [getIsUnifiedActivationEnabled, getIsUnifiedActivationPath],
  (isUnifiedActivationEnabled, isUnifiedActivationPath) =>
    isUnifiedActivationEnabled && isUnifiedActivationPath
);

/**
 * Returns a CSS class to be added to the root page level HTML element based on what flow the user is on.
 *
 * @param {boolean} partnerFlow - The name of the partner flow if applicable.
 * @param {string} pathname - The current route where the user is at.
 * @param {boolean} isTMobileFeatureOn - T-Mobile feature flag.
 * @param {boolean} isUnifiedActivationFlow - True if the user is in the Unified Activation flow.
 * @returns {string} The calculated CSS class.
 */
export const getPartnerClass = createSelector(
  [
    getPartnerFlow,
    getPathname,
    getIsTMobileFeatureOn,
    getIsUnifiedActivationFlow,
  ],
  (partnerFlow, pathname, isTMobileFeatureOn, isUnifiedActivationFlow) => {
    if (isUnifiedActivationFlow) {
      return 'unified-activation-flow';
    }

    if (partnerFlow) {
      const landingPageClass = getIsPartnerLandingPage(pathname)
        ? ' landing'
        : '';
      const isTMobileFlow = isTMobileFeatureOn && partnerFlow === SPRINTV2.FLOW;
      const tMobileClass = isTMobileFlow ? ' t-mobile' : '';
      return `partner-flow ${partnerFlow.toLowerCase()}-flow${landingPageClass}${tMobileClass}`;
    }

    return '';
  }
);

export const getVipClass = createSelector([getIsVIPFlow], isVIPFlow => {
  return isVIPFlow ? 'vip-flow' : '';
});

export const getFooterClass = createSelector(
  [getIsRokuPacFlow],
  isRokuPacFlow => (isRokuPacFlow && isMobile() ? 'footer mobile' : 'footer')
);

export const getIsReacqFlow = state => getStartPathname(state) === GO_REACQ;

export const getIsLiveOnlyFlow = state =>
  getStartPathname(state) === GO_LIVE_ONLY;

/**
 * Check if the user is in a DSS-activation flow where the user purchased the bundle from DSS,
 * or DSS via a partner, Ex. Verizon.
 *
 * We're in a bundle partner flow if the user started on the activate page or
 * there a bundle partner payload.
 *
 * @param {Object} state the redux state tree.
 * @returns {Boolean} whether or not the flow is a bundle partner flow.
 */
export const getIsBundlePartnerFlow = state => {
  const startPathname = getStartPathname(state);
  const matchOptions = { path: BUNDLE_PARTNER_ACTIVATE };
  const isActivatePage = matchPath(startPathname, matchOptions) !== null;
  return isActivatePage || getPayload(state) !== null;
};

/**
 * Check if the user is in a superbundle flow where the user is purchasing the bundle from Hulu.
 *
 * In this flow, we need to call the DSS eligibility endpoint to make sure the user is eligible
 * before he/she is allowed to purchase this bundle.
 *
 * @param {Object} state the redux state tree.
 * @returns {Boolean} whether or not the flow is a superbundle flow where the user is purchasing the bundle from Hulu.
 */
export const getIsHuluSuperbundleFlow = createSelector(
  [getIsSuperbundleFlow, getIsBundlePartnerFlow],
  (isSuperbundleFlow, isBundlePartnerFlow) =>
    isSuperbundleFlow && !isBundlePartnerFlow
);

export const getIs2PBundleFlow = state => {
  const disneyProgramsIDs = getControlText(
    state,
    'suf_2p_bundle_flow.disneyProgramsIDs',
    []
  );
  const selectedPlan = getSelectedPlan(state);

  return disneyProgramsIDs.includes(
    selectedPlan.subscription.promotion.programId
  );
};

export const getIsPlanBuilderFlow = createSelector(
  [getReferrerParam],
  referrer => referrer === 'plan-builder'
);

export const getIsMaxBundleFlow = state => {
  const selectedPlan = getSelectedPlan(state);

  return MAX_BUNDLE_PLAN_IDS.includes(selectedPlan.subscription?.planId);
};
