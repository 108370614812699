import * as React from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import Loading from '../components/Loading';

const UnifiedActivatePage = ({ onLoaded }) => {
  useEffect(() => {
    onLoaded();
  }, []);

  return <Loading />;
};

UnifiedActivatePage.propTypes = {
  onLoaded: PropTypes.func.isRequired,
};

export default UnifiedActivatePage;
