import React from 'react';
import PropTypes from 'prop-types';
import SimpleTooltip from './SimpleTooltip';

const NoahDisclaimerLink = ({ disclaimer, isInteractive, tooltipMessage }) => {
  return (
    <div className="noah-disclaimer">
      {tooltipMessage}&nbsp;
      <SimpleTooltip
        tabIndex={isInteractive ? 0 : -1}
        tooltip={disclaimer}
        tooltipLabel="Learn more pop-up"
      >
        <img
          src="/static/images/info-icon.svg"
          alt="Learn More"
          className="noah-disclaimer__tooltip-icon"
        />
      </SimpleTooltip>
    </div>
  );
};

NoahDisclaimerLink.propTypes = {
  disclaimer: PropTypes.string,
  isInteractive: PropTypes.bool,
  tooltipMessage: PropTypes.string.isRequired,
};

export default NoahDisclaimerLink;
