import * as envVars from '../../../config/env';

export const YOKOZUNA_API = envVars.url.yokozuna;
export const YOKOZUNA_V3_API = envVars.url.yokozuna_v3;
export const YOKOZUNA_V4_API = envVars.url.yokozuna_v4;
export const HOTH_API = envVars.url.hoth.host;
export const SITE_API = envVars.url.site;
export const LOGOUT_API = envVars.url.logoutApi;

export default YOKOZUNA_API;
