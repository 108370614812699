import React from 'react';

const helpCenterLink = (
  <a href="https://help.hulu.com/article/hulu-disney-bundle-signup">
    Hulu Help Center
  </a>
);

const LedgerDuoBundleDisclaimer = () => {
  return (
    <div className="ledger__duo-bundle-disclaimer">
      Already an ESPN+ Subscriber? Visit the {helpCenterLink} for more
      information on existing subscriptions and related billing and cancelation
      issues.
    </div>
  );
};

export default LedgerDuoBundleDisclaimer;
