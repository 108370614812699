import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Experiment, Treatment } from '../components/Experiment';
import { PlanPropTypes } from '../components/Plan';
import Panel from '../components/Panel';
import { CAT_277_V3 } from '../constants/experiments';
import * as routes from '../constants/routes';
import { routeReplace, urlReplace } from '../actions/routingActions';

require('../styles/bundles.scss');

export const BundleSelectPage = props => {
  const {
    availableBundles,
    availableBasePlan,
    gotToPage,
    hasUpsellPage,
    shouldLogin,
    selectedPlan,
    updatePlan,
    dispatch,
  } = props;

  useEffect(() => {
    if (!hasUpsellPage) {
      dispatch(routeReplace(routes.PLAN_SELECT));
    } else if (!selectedPlan) {
      dispatch(urlReplace(routes.GO_ONE_HULU));
    } else if (
      !availableBundles.length === 0 &&
      selectedPlan.id !== availableBasePlan.id
    ) {
      dispatch(routeReplace(routes.ACCOUNT_INFO));
    }
  }, []);

  const selectBundle = () => {
    // already selected super bundle
    if (selectedPlan.isDisneySuperBundle) {
      return next();
    }
    // potentially we have other bundles available besides disney - ever?
    // let's make sure we're selecting the first disney bundle we find - should be only one..
    const selectedBundle = availableBundles.find(
      value => value.isDisneySuperBundle
    );
    updatePlan(selectedBundle);
    return next();
  };

  const selectBase = () => {
    // already selected base plan
    if (selectedPlan.id === availableBasePlan.id) {
      return next();
    }

    // coming from account page and super bundle was previously selected
    updatePlan(availableBasePlan);
    return next();
  };

  const next = () => {
    gotToPage(shouldLogin ? routes.LOGIN : routes.ACCOUNT_INFO);
  };

  const getHeaderForTreatment1 = () => {
    const headerMap = {
      1: 'Bundle with Disney+ and ESPN+ and save over 25%',
      2: 'Bundle with Disney+ and ESPN+ and save over 20%',
      3: 'Bundle with Disney+ and ESPN+ and save over 5%',
    };

    return headerMap[selectedPlan.id];
  };

  const getBadgeForTreatment2 = () => {
    const badgeMap = {
      1: 'BUNDLE & SAVE OVER 25%',
      2: 'BUNDLE & SAVE OVER 20%',
      3: 'BUNDLE & SAVE OVER 5%',
    };

    return badgeMap[selectedPlan.id];
  };

  if (!selectedPlan) return null;

  return (
    <div className="experiment-bundles">
      <Panel className="experiment-bundles__panel">
        <div className="experiment-bundles__badge">
          <img
            src="//assetshuluimcom-a.akamaihd.net/h3o/account/addons/price_tag.svg"
            alt="Bundle and save logo"
            className="experiment-bundles__badge-icon"
          />

          <Experiment>
            <Treatment developerId={CAT_277_V3.TREATMENT1}>
              BUNDLE & SAVE
            </Treatment>
            <Treatment developerId={CAT_277_V3.TREATMENT2}>
              {getBadgeForTreatment2()}
            </Treatment>
          </Experiment>
        </div>
        <img
          className="experiment-bundles__logo"
          src="/static/images/disney-hulu-espn-bundle-banner-logo.svg"
          alt="Hulu, Disney Plus and ESPN Plus logos"
        />
        <div className="experiment-bundles__title">
          <h2>
            <Experiment>
              <Treatment developerId={CAT_277_V3.TREATMENT1}>
                {getHeaderForTreatment1()}
              </Treatment>
              <Treatment developerId={CAT_277_V3.TREATMENT2}>
                Bundle with Disney+ and ESPN+ for only $8/month more
              </Treatment>
            </Experiment>
          </h2>
        </div>
        <ol className="experiment-bundles__value-props">
          <li>
            Access the entire Hulu streaming library
            {selectedPlan.id !== 2 ? ' (ad-supported)' : ''} with hit shows,
            movies, Hulu Originals, and more
          </li>
          <li>
            Endless entertainment from Disney, Star Wars, Marvel, Pixar, and Nat
            Geo with Disney+, ad-free
          </li>
          <li>Live sports and exclusive originals with ESPN+</li>
          <li>Watch on your TV, laptop, phone, or tablet</li>
          {selectedPlan.id === 3 ? (
            <li>Live TV with 65+ top channels</li>
          ) : null}
        </ol>
        <div className="experiment-bundles__legal">
          No free trial if subscribing to the bundle.
        </div>
      </Panel>
      <div className="experiment-bundles__actions">
        <button
          className="button button--inverse"
          aria-label="Skip the bundle and continue with current selection."
          type="button"
          onClick={selectBase}
        >
          NOT NOW
        </button>
        <button
          className="button"
          aria-label={`Add bundle ${availableBasePlan &&
            availableBasePlan.name} with Disney+ and ESPN+ for $7 per month more`}
          type="button"
          onClick={selectBundle}
        >
          GET BUNDLE
        </button>
      </div>
    </div>
  );
};

BundleSelectPage.propTypes = {
  availableBundles: PropTypes.arrayOf(PlanPropTypes).isRequired,
  availableBasePlan: PlanPropTypes.isRequired,
  hasUpsellPage: PropTypes.bool.isRequired,
  selectedPlan: PlanPropTypes,
  shouldLogin: PropTypes.bool.isRequired,
  updatePlan: PropTypes.func.isRequired,
  gotToPage: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default BundleSelectPage;
