import { YOKOZUNA_API } from '../constants/apis';
import request from './request';

export function createSubscriber(subscriptionRequest) {
  return request(`${YOKOZUNA_API}subscriptions`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(subscriptionRequest),
  });
}

export default createSubscriber;
