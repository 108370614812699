export const GA_PAGEVIEW_FOLDER = '/sufo';

export const PLAN_TYPES = {
  HISUF: 'hisuf',
  SPRINTV2: 'sprintv2',
  SPOTIFY: 'spotify',
  DISNEY_ACTIVATION: 'disney_activation',
  SVOD: 'svod',
  ONE_HULU: 'one_hulu',
  LIVE_ONLY: 'live_only',
  SASH_ONLY: 'sash_only',
  NOAH_ONLY: 'noah_only',
  CURIOSITY: 'curiosity',
  DEFAULT: 'default',
};

export const SUBSCRIPTION_ERRORS = {
  INSUFFICIENT_FUNDS: 'insufficient_funds',
  PAYMENT_FAILURE_BAD_DETAILS: 'payment_failure_bad_details',
  APPLICATION_ERROR: 'application_error',
  INVALID_ZIPCODE: 'invalid_zipcode',
};

export const HIT_PAYMENT_METHODS = {
  CREDIT_CARD: 'credit_card',
  PAYPAL: 'paypal',
  VENMO: 'venmo',
  RPM: 'roku',
  GIFTCARD: 'giftcard',
  SPOTIFY: 'spotify',
  SPRINT: 'sprint',
  VIP: 'vip',
};

export const RETURNING_CUSTOMER_FLOW = 'suf_web_returning_customer';

export const PLAN_BUILDER_FLOW = 'suf_web_plan_builder';
