import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../components/Panel';

class ErrorPage extends React.Component {
  render() {
    return (
      <Panel className="large equal-padding">
        <h1>{this.props.errorText}</h1>
      </Panel>
    );
  }
}

ErrorPage.propTypes = {
  errorText: PropTypes.string.isRequired,
};

export default ErrorPage;
