import { createSelector } from 'reselect';
import get from 'lodash/get';

import { ERROR_CODES } from '../constants/errorCodes';
import { SPRINTV2 } from '../constants/partners';
import {
  getIsLoaded as getIsConfigLoaded,
  getUser,
  getIsMissingPlans,
} from './config';
import { getIsTMobileFeatureOn } from './featureFlag';
import {
  getControlText,
  getSprintV2Config,
  getTMobileConfig,
} from './siteconfig';

export const getEligibility = state =>
  get(state, 'partner.sprintV2Eligibility');
export const getPayload = state => get(state, 'flow.query.request');

export const getLoading = createSelector(getEligibility, eligibility =>
  get(eligibility, 'loading', false)
);

export const getError = createSelector(getEligibility, eligibility =>
  get(eligibility, 'error')
);

export const getErrorStatus = createSelector(getError, error =>
  get(error, 'status')
);

export const getErrorCode = createSelector(getError, error =>
  get(error, 'errors[0].code')
);

export const getCallbackPageIsLoading = state =>
  getLoading(state) || (!getIsConfigLoaded(state) && !getError(state));

export const getEligibilityStatus = createSelector(
  [getErrorStatus, getErrorCode],
  (errorStatus, errorCode) => {
    const { ELIGIBILITY_STATUS } = SPRINTV2;

    // Handle ineligibility cases from the eligibility endpoint
    if (errorStatus) {
      if (errorStatus === 400) {
        return ELIGIBILITY_STATUS.BAD_REQUEST;
      }

      if (errorStatus === 403) {
        if (errorCode === ERROR_CODES.ALREADY_SUBSCRIBED) {
          return ELIGIBILITY_STATUS.ALREADY_SUBSCRIBED;
        }

        if (errorCode === ERROR_CODES.INELIGIBLE_PROMOTION) {
          return ELIGIBILITY_STATUS.BAD_REQUEST;
        }
      }
      return ELIGIBILITY_STATUS.SERVICE_ERROR;
    }

    // User is eligible and will be redirected to plan select
    return null;
  }
);

export const getSwitchBillingEligibilityStatus = createSelector(
  [getUser, getIsMissingPlans],
  (user, isMissingPlans) => {
    const { ELIGIBILITY_STATUS } = SPRINTV2;

    // Handle existing user switch billing eligibility
    if (isMissingPlans) {
      const customerType = get(user, 'customerType');
      switch (customerType) {
        case 'HULU':
          return ELIGIBILITY_STATUS.HULU_INELIGIBLE;
        case 'SPRINT':
          return ELIGIBILITY_STATUS.ALREADY_SUBSCRIBED;
        default:
          return ELIGIBILITY_STATUS.THIRD_PARTY_INELIGIBLE;
      }
    }

    // User is eligible
    return null;
  }
);

export const getCallbackText = createSelector(
  [
    getSprintV2Config,
    getTMobileConfig,
    getIsTMobileFeatureOn,
    getEligibilityStatus,
  ],
  (sprintV2Config, tmobileConfig, isTmobileFeatureOn, status) => {
    return isTmobileFeatureOn
      ? get(tmobileConfig, ['eligibility', status], {})
      : get(sprintV2Config, ['eligibility', status], {});
  }
);

export const getRoadblockText = createSelector(
  [
    getSprintV2Config,
    getTMobileConfig,
    getIsTMobileFeatureOn,
    getSwitchBillingEligibilityStatus,
  ],
  (sprintV2Config, tmobileConfig, isTmobileFeatureOn, status) => {
    return isTmobileFeatureOn
      ? get(tmobileConfig, ['eligibility', status], {})
      : get(sprintV2Config, ['eligibility', status], {});
  }
);

export const getSubscriberId = createSelector(getEligibility, eligibility =>
  get(eligibility, 'response.subscriberId')
);

export const getSprintBillingDisclaimerText = state => {
  if (getIsTMobileFeatureOn(state)) {
    return getControlText(state, [
      'tmobile',
      'billing_page',
      'disclaimer_text',
    ]);
  }

  return getControlText(state, 'sprint_billing_disclaimer_text');
};

export const getSprintBillingSubheader = state => {
  if (getIsTMobileFeatureOn(state)) {
    return getControlText(state, ['tmobile', 'billing_page', 'subheader']);
  }

  return getControlText(state, 'billing_page.subheader.sprint');
};

export const getSprintPlanSelectDisclaimer = (state, type) => {
  if (getIsTMobileFeatureOn(state)) {
    return getControlText(state, [
      'tmobile',
      'plan_select',
      'disclaimers',
      type,
    ]);
  }

  return getControlText(state, [
    'sprintV2',
    'plan_select',
    'disclaimers',
    type,
  ]);
};

export const getSprintPlanSelectHeader = (state, type) => {
  if (getIsTMobileFeatureOn(state)) {
    return getControlText(state, ['tmobile', 'plan_select', 'headers', type]);
  }

  return getControlText(state, ['sprintV2', 'plan_select', 'headers', type]);
};

export const getSprintErrorText = state => {
  if (getIsTMobileFeatureOn(state)) {
    return getControlText(state, ['tmobile', 'error_text']);
  }

  return getControlText(state, 'sprint_error_text');
};
