import browserHistory from '../browserHistory';

function getNewLocation(options) {
  const location =
    typeof options === 'string' ? { pathname: options } : { ...options };
  location.query = {};
  return location;
}

/**
 * Given a location, pass that location to browserHistory#push,
 * but preserve the current query parameters.
 *
 * @param {Object|String} options The exact same options that are
 *        passed to `browserHistory#push`.
 */
export function push(options) {
  browserHistory.push(getNewLocation(options));
  window.scrollTo(0, 0);
}

/**
 * Navigate back in the browser history.
 */
export function pop() {
  browserHistory.goBack();
  window.scrollTo(0, 0);
}

/**
 * Given a location, pass that location to browserHistory#replace,
 * but preserve the current query parameters.
 *
 * @param {Object|String} options The exact same options that are
 *        passed to `browserHistory#replace`.
 */
export function replace(options) {
  browserHistory.replace(getNewLocation(options));
  window.scrollTo(0, 0);
}
