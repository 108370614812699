import { ABOUT_ADS_LINK } from '../constants/footer';
import { url } from '../../../config/env';

export const getUnifiedFooterConfig = shouldEnableOneTrustScript => {
  const showOneTrustOptOutModal = event => {
    event.preventDefault();

    const activeGroups = window?.OnetrustActiveGroups || '';
    // Split by comma and filter out empty values
    const currentGroupCount = activeGroups.split(',').filter(group => group)
      .length;

    if (window && window.OneTrust) {
      window.OneTrust.OnConsentChanged(consentEvent => {
        const newGroupCount = consentEvent?.detail?.length;
        // After the user saves the personal information preferences,
        // trigger a page reload if the active group count changed.
        if (currentGroupCount !== newGroupCount && window.location) {
          window.location.reload();
        }
      });
      // Show the "Opt Out of Sale/Sharing" modal pop-up provided by OneTrust SDK,
      // rather than opening the link on the footer.
      window.OneTrust.ToggleInfoDisplay();
    }
  };

  const doNotSellMyInfoFooterLink = shouldEnableOneTrustScript
    ? url.doNotSellMyInfoTWDC
    : `${url.site}/do-not-sell-my-info`;

  const doNotSellMyInfoFooterText = shouldEnableOneTrustScript
    ? 'Do Not Sell or Share My Personal Information'
    : 'Do Not Sell My Personal Information';

  const privacyRightsFooterText = shouldEnableOneTrustScript
    ? 'Your US State Privacy Rights'
    : 'Your California Privacy Rights';

  const onDoNotSellMyInfoFooterClicked = shouldEnableOneTrustScript
    ? showOneTrustOptOutModal
    : undefined;

  return [
    {
      href: ABOUT_ADS_LINK,
      key: 'ads',
      target: '_blank',
      text: 'About Ads',
      rel: 'noopener',
    },
    {
      href: `${url.site}/subscriber_agreement`,
      key: 'subscriber',
      target: '_blank',
      text: 'Subscriber Agreement',
      rel: 'noopener',
    },
    {
      href: `${url.site}/privacy`,
      key: 'privacy',
      target: '_blank',
      text: 'Privacy Policy',
      rel: 'noopener',
    },
    {
      href: doNotSellMyInfoFooterLink,
      key: 'do-not-sell-my-info',
      onClick: onDoNotSellMyInfoFooterClicked,
      target: '_blank',
      text: doNotSellMyInfoFooterText,
      rel: 'noopener',
    },
    {
      href: `${url.site}/ca-privacy-rights`,
      key: 'ca-privacy-rights',
      target: '_blank',
      text: privacyRightsFooterText,
      rel: 'noopener',
    },
    {
      text: `© ${new Date().getFullYear()} Hulu, LLC`,
    },
  ];
};
