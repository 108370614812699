import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getWarningText } from '../../selectors/identity';
import { getControlText } from '../../selectors/siteconfig';
import InputError from '../InputError';

/**
 * Displays the copy for the Unified Identity warning message shown in the Email
 * form field when the user already has an account created in one of the unified
 * DS services. Adds styling and grabs the copy from site-config.
 */
const EmailWarning = ({ loginCopy, warningCopy }) => (
  <InputError
    className="unified-identity"
    message={
      <>
        {warningCopy} <strong>{loginCopy}</strong>
      </>
    }
  />
);

EmailWarning.propTypes = {
  loginCopy: PropTypes.string.isRequired,
  warningCopy: PropTypes.string.isRequired,
};

const UnifiedIdentityEmailWarning = connect(state => ({
  loginCopy: getControlText(state, 'account.existing_email_login'),
  warningCopy: getWarningText(state),
}))(EmailWarning);

export { UnifiedIdentityEmailWarning };
