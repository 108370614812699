import * as types from '../constants/actionTypes';
import * as api from '../api/Identity';

// Cohorts API actions
export function getCohorts(cohorts = []) {
  return async dispatch => {
    dispatch(getCohortsStart());
    try {
      const response = await api.getCohorts(cohorts);
      dispatch(getCohortsSuccess(response.cohorts));
      return response.cohorts;
    } catch (error) {
      dispatch(getCohortsFailure(error));
      throw error;
    }
  };
}

export function getCohortsStart() {
  return {
    type: types.GET_COHORTS.START,
  };
}

export function getCohortsSuccess(response) {
  return {
    type: types.GET_COHORTS.SUCCESS,
    payload: response,
  };
}

export function getCohortsFailure(error) {
  return {
    type: types.GET_COHORTS.FAILURE,
    payload: error,
  };
}
