import { parse } from 'query-string';
import has from 'lodash/has';
import { matchPath } from 'react-router-dom';

import { SAVE_INITIAL_LOCATION } from '../constants/actionTypes';
import { PARTNERSHIP, SPRINTV2 } from '../constants/partners';
import {
  INIT_PAGES,
  PARTNER_LANDING,
  SPRINTV2_LANDING,
  SPOTIFY_CALLBACK,
  TMOBILE_LANDING,
} from '../constants/routes';
import { getIsPartnerLandingPage } from '../selectors/flow';

/**
 * Determines if the reducer should reset the redux state.
 *
 * @param {Object} action The redux action
 */
export const shouldResetState = ({
  type,
  location: { pathname, search } = {},
}) => {
  const query = parse(search);

  // Don't reset state if the action type is not SAVE_INITIAL_LOCATION
  if (type !== SAVE_INITIAL_LOCATION) {
    return false;
  }

  // Don't reset state when reloading a partner landing page with a missing offer ID
  if (
    matchPath(pathname, { path: PARTNER_LANDING }) &&
    !has(query, PARTNERSHIP.OFFER_ID_PARAM)
  ) {
    return false;
  }

  // Don't reset state when reloading the Sprint landing page with a missing payload
  if (
    matchPath(pathname, { path: [SPRINTV2_LANDING, TMOBILE_LANDING] }) &&
    !has(query, SPRINTV2.PAYLOAD_PARAM)
  ) {
    return false;
  }

  // Determine if the state should be reset based on the user's route
  return (
    getIsPartnerLandingPage(pathname) ||
    matchPath(pathname, {
      path: [...INIT_PAGES, SPOTIFY_CALLBACK],
      exact: true,
    }) !== null
  );
};
