import * as types from '../constants/actionTypes';
import {
  getAddonBundleIdsCompatibleWithPlan,
  getAddonIdsCompatibleWithPlan,
} from '../selectors/addons';

export function setAddons(addons) {
  return {
    type: types.SET_ADDONS,
    addons,
  };
}

export function toggleAddon(addonId) {
  return {
    type: types.TOGGLE_ADDON,
    addonId,
  };
}

export function removeAddon(addonId) {
  return {
    type: types.REMOVE_ADDON,
    addonId,
  };
}

export function setAddonBundles(addonBundles) {
  return {
    type: types.SET_ADDON_BUNDLES,
    addonBundles,
  };
}

export function toggleAddonBundle(addonBundleId) {
  return {
    type: types.TOGGLE_ADDON_BUNDLE,
    addonBundleId,
  };
}

export function removeAddonBundle(addonBundleId) {
  return {
    type: types.REMOVE_ADDON_BUNDLE,
    addonBundleId,
  };
}

export function selectAddons() {
  return (dispatch, getState) => {
    dispatch(setAddons(getAddonIdsCompatibleWithPlan(getState())));
  };
}

export function selectAddonBundles() {
  return (dispatch, getState) => {
    dispatch(setAddonBundles(getAddonBundleIdsCompatibleWithPlan(getState())));
  };
}
