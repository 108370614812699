import { didProgramChange } from '../selectors/program';
import { SET_INELIGIBLE_TRIAL_DISCOUNT } from '../constants/actionTypes';

export const setTrialDiscountIneligible = isIneligible => ({
  type: SET_INELIGIBLE_TRIAL_DISCOUNT,
  payload: isIneligible,
});

export const checkTrialDiscountEligibility = newConfig => (
  dispatch,
  getState
) => {
  if (didProgramChange(getState(), newConfig)) {
    dispatch(setTrialDiscountIneligible(true));
  }
};
