import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { isFeatureEnabled } from '../selectors/featureFlag';

const FeatureFlag = ({ featureEnabled, children }) =>
  featureEnabled ? children : null;

FeatureFlag.propTypes = {
  featureEnabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state, { feature }) => ({
  featureEnabled: isFeatureEnabled(state, feature),
});

const ConnectedFeatureFlag = connect(mapStateToProps)(FeatureFlag);

ConnectedFeatureFlag.propTypes = {
  feature: PropTypes.string.isRequired,
};

export default ConnectedFeatureFlag;
