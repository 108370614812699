import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { url } from '../../../config/env';

class SprintSpotifyLandingPage extends Component {
  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    if (this.props.onLoaded) {
      this.props.onLoaded();
    }
  }

  render() {
    const {
      titleText,
      subTitles,
      buttons,
      disclaimer,
      footer,
      isTWDCPrivacyPolicyFeatureOn,
    } = this.props;
    const privacyUrl = isTWDCPrivacyPolicyFeatureOn
      ? url.privacyHtmlTWDC
      : url.privacyHtml;
    const subTitleNode = subTitles && (
      <div className="text__subtitle">
        {[].concat(subTitles).map((text, i) => (
          <div key={i}>{text}</div>
        ))}
      </div>
    );
    const termsLink = (
      <a className="terms-link" href={url.tosHtml} target="_blank">
        Subscriber Agreement
      </a>
    );

    const privacyLink = (
      <a className="privacy-link" href={privacyUrl} target="_blank">
        Privacy Policy
      </a>
    );
    const disclaimerNode = disclaimer && (
      <div className="disclaimer disclaimer__partner">*{disclaimer}</div>
    );
    const buttonsNode =
      buttons &&
      buttons.map((button, i) => (
        <button
          key={button.id}
          id={button.id}
          className={`button button--partner${
            i === 0 ? '' : ' button--inverse'
          }`}
          onClick={button.onClick}
          type="button"
        >
          {button.text}
        </button>
      ));
    const termsNode = buttons && (
      <div className="terms terms__partner">
        By clicking above you agree to the Hulu {termsLink} and {privacyLink}.
      </div>
    );

    return (
      <div className="partner-web">
        <h1 className="text__intro">{titleText}</h1>
        {subTitleNode}
        {buttonsNode}
        {disclaimerNode}
        {termsNode}
        {footer}
      </div>
    );
  }
}

SprintSpotifyLandingPage.propTypes = {
  titleText: PropTypes.string.isRequired,
  subTitles: PropTypes.arrayOf(PropTypes.string),
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  disclaimer: PropTypes.string,
  footer: PropTypes.element,
  onLoaded: PropTypes.func,
  isTWDCPrivacyPolicyFeatureOn: PropTypes.bool,
};

export default SprintSpotifyLandingPage;
