import React from 'react';
import PropTypes from 'prop-types';

const LedgerError = ({ header, subheader }) =>
  header || subheader ? (
    <div className="ledger__ineligible-text">
      <div className="ledger__ineligible-text--header">{header}</div>
      <div className="ledger__ineligible-text--subheader">{subheader}</div>
    </div>
  ) : null;

LedgerError.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
};

export default LedgerError;
