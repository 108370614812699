import { CART_ABANDONMENT, getCookie } from '../utils/cookieUtils';
import {
  isFeatureEnabled,
  CART_ABANDONMENT_PLAN_SKIP_FEATURE_FLAG,
} from '../selectors/featureFlag';
import { getBundlesForPlan } from '../selectors/plans';
import { getIsBundleChecked } from '../selectors/user';
import { selectPlan } from './serverActions';
import { selectAddonBundles, selectAddons } from './addonActions';
import { getPartnerFlow, getSearch, getStartPathname } from '../selectors/flow';
import {
  setCartAbandonmentCookie,
  getCartAbandonmentCookie,
  getPreviouslySelectedPlanInCartAbandonment,
} from '../utils/cartAbandonmentUtils';
import { RETURNING_CUSTOMER_FLOW } from '../constants/metrics';
import { setInstrumentationFlowName } from './instrumentationFlowActions';

/**
 * Checks to see if user is returning to the same flow as when the Cart
 * Abandonment cookie was set.
 */
const isSameReturnFlow = state => {
  const cartAbandonmentCookie = getCartAbandonmentCookie();

  return (
    getPartnerFlow(state) === cartAbandonmentCookie.partnerFlow &&
    getStartPathname(state) === cartAbandonmentCookie.startPathname &&
    getSearch(state) === cartAbandonmentCookie.search
  );
};

export const checkCartAbandonment = () => {
  return (dispatch, getState) => {
    const isCartAbandonmentEnabled = isFeatureEnabled(
      getState(),
      CART_ABANDONMENT_PLAN_SKIP_FEATURE_FLAG
    );
    // We only want to update the user's selected plan if all are true:
    // - the cart abandonment feature flag is on
    // - the plan still exists
    // - the return flow is the same
    if (isSameReturnFlow(getState()) && isCartAbandonmentEnabled) {
      const previouslySelectedPlan = getPreviouslySelectedPlanInCartAbandonment(
        getState()
      );

      if (previouslySelectedPlan) {
        const bundle =
          getBundlesForPlan(getState(), previouslySelectedPlan)[0] ||
          previouslySelectedPlan;
        let selectedPlan = previouslySelectedPlan;

        if (getIsBundleChecked(getState(), previouslySelectedPlan) && bundle) {
          selectedPlan = bundle;
        }
        // update the instrumentation flow_name for tealium and hits events
        dispatch(setInstrumentationFlowName(RETURNING_CUSTOMER_FLOW));
        return Promise.all([
          dispatch(selectPlan(selectedPlan)),
          dispatch(selectAddons()),
          dispatch(selectAddonBundles()),
        ]).catch(() => Promise.resolve());
      }
    }
    return Promise.resolve();
  };
};

export const updateCartAbandonmentCookie = state => {
  if (getCookie(CART_ABANDONMENT, true)) {
    setCartAbandonmentCookie(state);
  }
};
