import { removeAddon } from './addonActions';
import { USER_INTERACTION } from '../metrics/metricsEvents';

export function removeAddonDueToRoadblock(id) {
  return (dispatch, getState, { trackEvent }) => {
    trackEvent(USER_INTERACTION, {
      action_specifier: 'signup:remove_espn_addon',
    });
    dispatch(removeAddon(id));
  };
}
