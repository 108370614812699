import url from 'url';
import { CAT_113 } from '../constants/experiments';
import { FORWARD_PARAMS_WHITELIST, SUCCESS_PATH } from '../constants/misc';
import { store, clearStorage } from '../store';
import { stringify } from './querystring';
import {
  wasOfferedAddonsOrAddonBundles,
  isBuyingAddonsOrAddonBundles,
} from '../selectors/addons';
import { hasAssignment } from '../selectors/config';
import { isBuyingLivePlan } from '../selectors/subscription';

import config from '../../../config/env';

export function replacePath(newPath) {
  // create a copy of the location, so we don't
  // actually change the page.
  const uri = { ...window.location };
  uri.pathname = newPath;
  return url.format(uri);
}

export function replaceUrlPath(urlStr, newPath) {
  const urlObj = url.parse(urlStr);
  urlObj.pathname = newPath;
  return url.format(urlObj);
}

export function appendParam(urlStr, key, value) {
  return `${urlStr}${urlStr.split('?')[1] ? '&' : '?'}${encodeURI(
    key
  )}=${encodeURI(value)}`;
}

export function removeParam(urlStr, param) {
  const [base, ...queryString] = urlStr.split('?');

  if (queryString.length > 0) {
    const prefix = `${encodeURIComponent(param)}=`;
    const parts = queryString.join('?').split('&');
    const queryParams = parts.filter(part => !part.startsWith(prefix));

    return `${base}?${queryParams.join('&')}`;
  }

  return urlStr;
}

export function appendForwardParams(urlStr) {
  const {
    flow: { query },
  } = store.getState();

  let u = urlStr;
  Object.keys(query).forEach(key => {
    if (FORWARD_PARAMS_WHITELIST.indexOf(key) !== -1) {
      u = appendParam(u, key, query[key]);
    }
  });
  return u;
}

export function redirect(originalUrl, params = {}) {
  // eslint-disable-next-line no-underscore-dangle
  const location = (config.__hack__overrideDomains || [])
    .filter(({ regex }) => regex.test(originalUrl))
    .slice(0, 1)
    .reduce(
      (memo, { regex, domain }) => memo.replace(regex, domain),
      originalUrl
    );

  const query =
    params && Object.keys(params).length ? stringify(params) : undefined;

  if (query && location.includes('?')) {
    window.location = `${location}&${query}`;
  } else if (query) {
    window.location = `${location}?${query}`;
  } else {
    window.location = location;
  }
}

export function replace(replaceUrl) {
  window.location.replace(replaceUrl);
}

/**
 * Special logic to determine if we need to skip to the success page
 */
export function shouldSkipToSuccessPage() {
  /**
   * For HISUF, given that addons/addonbundles were offered, if the user
   * purchased live and did not purchase addons, we should NOT skip to the
   * success page
   */
  const state = store.getState();
  return (
    hasAssignment(state, [CAT_113.TREATMENT1, CAT_113.TREATMENT2]) ||
    (wasOfferedAddonsOrAddonBundles(state) &&
      (!isBuyingLivePlan(state) || isBuyingAddonsOrAddonBundles(state)))
  );
}

/**
 * Handle redirecting the user after a successful signup. If the user was
 * offered add-ons or add-on bundles, we should redirect to the success page.
 *
 * @param {string} redirectUrl - The post sign up redirect URL.
 */
export function handleSuccessfulSignupRedirect(redirectUrl) {
  /**
   * Clear redux state from local storage prior to redirecting the user out of
   * the signup flow
   */
  clearStorage();

  /**
   * We're intentionally bypassing the routing middleware to prevent the redux
   * store from being resynced with local storage
   */
  redirect(
    appendForwardParams(
      shouldSkipToSuccessPage()
        ? replaceUrlPath(redirectUrl, SUCCESS_PATH)
        : redirectUrl
    )
  );
}
