import get from 'lodash/get';
import { createSelector } from 'reselect';
import { getEmail, getSelectedPlan } from './user';
import { getExternalIdentityVerificationId } from './flow';
import { isStudentPlan } from './plan';
import { HTTP_STATUS } from '../constants/httpStatus';
import { getControlText } from './siteconfig';

export const getIsIdentityVerified = state => state.identity.verified;
export const hasIdentityVerificationError = state =>
  !!state.identity.error || !!state.cohorts.error;

/*
 * Determine if a plan requires a verified identity, such as student status.
 * This criteria can be expanded to require identity proof for a larger
 * variety of users.
 */
export const requiresVerifiedIdentity = state =>
  isStudentPlan(getSelectedPlan(state)) && !getIsIdentityVerified(state);

/*
 * Build an identity verification request for the /v2/verifications endpoint.
 */
export const getIdentityVerificationRequest = (state, cohort) => ({
  verificationId: getExternalIdentityVerificationId(state),
  segmentName: cohort,
});

/*
 * Determine if identity verification page should display retry error page.
 * It should if the /verification error code is 500.
 */
export const getShouldRetryVerification = state =>
  get(state.identity.error, 'status', '') === HTTP_STATUS.INTERNAL_SERVER_ERROR;

/*
 * Should retrieve the retry roadblock copy if a retry error is encountered.
 * Should retrieve the ineligible roadblock copy otherwise.
 */
export const getIdentityRoadblock = state =>
  getShouldRetryVerification(state)
    ? getControlText(state, 'student.roadblock.retry')
    : getControlText(state, 'student.roadblock.ineligible');

const getEmailWarningCopy = state =>
  getControlText(state, 'account.existing_email_warning');

export const getWarningText = createSelector(
  [getEmail, getEmailWarningCopy],
  (email, warningCopy) => warningCopy.replace('%EMAIL%', email)
);
