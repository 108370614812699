import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Control, Errors } from 'react-redux-form';
import SelectField from './SelectField';
import InfoTooltip from './InfoTooltip';

class GenderField extends Component {
  render() {
    const {
      dispatch,
      model,
      validators,
      messages,
      placeholder,
      options,
      tooltip,
      displayName,
    } = this.props;
    return (
      <div className="fieldset">
        <div className="label">
          <label className="label__input">{displayName}</label>
          <InfoTooltip
            tooltip={tooltip}
            className="gender"
            id="gender-tooltip"
          />
        </div>
        <div className="field gender" data-testid={model}>
          <Errors
            className="has-error"
            model={`.${model}`}
            show={{ touched: true, focus: false }}
          />
          <Control.select
            model={`.${model}`}
            validators={validators}
            validateOn="blur"
            controlProps={{
              dispatch,
              placeholder,
              options,
            }}
            component={SelectField}
            mapProps={{
              id: 'gender',
            }}
          />
          <Errors
            className="errors"
            model={`.${model}`}
            show={{ touched: true, focus: false }}
            messages={messages}
            component={props => (
              <span id="gender-error" data-testid="gender-error">
                {props.children}
              </span>
            )}
          />
        </div>
      </div>
    );
  }
}

GenderField.propTypes = {
  model: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  messages: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string]).isRequired,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  placeholder: PropTypes.string.isRequired,
  tooltip: PropTypes.element.isRequired,
  validators: PropTypes.object,
};

export default GenderField;
