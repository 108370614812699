import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TextField from '../TextField';
import * as validators from '../../constants/validators';
import InputError from '../InputError';
import { ERROR_TEXT } from '../../constants/errorText';
import BirthdayField from '../BirthdayField';
import GenderField from '../GenderField';
import { getIsUpdatedGenderOptionOn } from '../../selectors/featureFlag';
import {
  GENDER_FIELD_OPTIONS,
  UPDATED_GENDER_FIELD_OPTIONS,
} from '../../constants/genderOptions';
import { ZipCodeFields } from './ZipCodeFields';

export const MissingInfo = ({
  displayBillingZip,
  disableNameField,
  displayPlaceHolder,
  includesLive,
  isPrepaid,
}) => {
  const isUpdatedGenderOptionOn = useSelector(getIsUpdatedGenderOptionOn);
  const dispatch = useDispatch();

  const placeholder = useMemo(() => {
    return displayPlaceHolder ? 'Name' : null;
  }, [displayPlaceHolder]);

  const genderOptions = useMemo(
    () =>
      isUpdatedGenderOptionOn
        ? UPDATED_GENDER_FIELD_OPTIONS
        : GENDER_FIELD_OPTIONS,
    [isUpdatedGenderOptionOn]
  );

  return (
    <>
      <TextField
        displayName="NAME"
        placeHolder={placeholder}
        form="user"
        model="firstName"
        validators={{
          invalid: validators.NAME,
        }}
        messages={{
          invalid: <InputError message={ERROR_TEXT.REQUIRED_NAME} />,
        }}
        disabled={disableNameField}
        maxLength={String(validators.MAX_RESTAPI_FIELD_LENGTH)}
        key="namefield"
      />
      <BirthdayField
        displayName="BIRTHDATE"
        dispatch={dispatch}
        model="birthday"
        tooltip={
          <p>
            We&#8217;ll use this information to create a more personalized Hulu
            experience for you based on your age.
          </p>
        }
        validators={{
          required: validators.REQUIRED,
        }}
        key="birthdayfield"
      />
      <ZipCodeFields
        displayBillingZip={displayBillingZip}
        includesLive={includesLive}
        isPrepaid={isPrepaid}
      />
      <GenderField
        displayName="GENDER"
        dispatch={dispatch}
        model="gender"
        placeholder="Select"
        options={genderOptions}
        tooltip={
          <p>
            We&#8217;ll use this information to create a more personalized Hulu
            experience for you based on your gender.
          </p>
        }
        validators={{
          required: validators.REQUIRED,
        }}
        messages={{
          required: <InputError message={ERROR_TEXT.REQUIRED_GENDER} />,
        }}
        key="genderfield"
      />
    </>
  );
};

MissingInfo.propTypes = {
  displayBillingZip: PropTypes.bool,
  displayPlaceHolder: PropTypes.bool,
  disableNameField: PropTypes.bool,
  includesLive: PropTypes.bool,
  isPrepaid: PropTypes.bool,
};

MissingInfo.defaultProps = {
  displayBillingZip: false,
  displayPlaceHolder: false,
  disableNameField: false,
  includesLive: false,
  isPrepaid: false,
};
