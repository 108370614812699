import React from 'react';

import Plan from './Plan';
import '../styles/tabsOnMobileWeb.scss';

const TabsOnMobileWebPlan = props => {
  return (
    <Plan
      {...props}
      badgeDescription={
        props.plan.disneySuperBundle ? '' : props.badgeDescription
      }
    />
  );
};

TabsOnMobileWebPlan.propTypes = Plan.propTypes;

export { TabsOnMobileWebPlan };
