import React from 'react';
import PropTypes from 'prop-types';
import LedgerDuoBundleDisclaimer from './LedgerDuoBundleDisclaimer';

const LedgerInfo = ({ name, description, showDuoBundleDisclaimer }) => {
  return (
    <div className="ledger__info">
      <h3 className="ledger__name">{name}</h3>
      {description && <div className="ledger__description">{description}</div>}
      {showDuoBundleDisclaimer && <LedgerDuoBundleDisclaimer />}
    </div>
  );
};

LedgerInfo.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  showDuoBundleDisclaimer: PropTypes.bool,
};

export default LedgerInfo;
