import { createAction } from 'redux-starter-kit';

import request from '../api/request';
import { GET_SUPERBUNDLE_ELIGIBILITY } from '../constants/actionTypes';
import { YOKOZUNA_API } from '../constants/apis';
import { getEligibilityEndpoint } from '../selectors/superbundleEligibility';

// Superbundle eligibility actions
export const getSuperbundleEligibilityStart = createAction(
  GET_SUPERBUNDLE_ELIGIBILITY.START
);
export const getSuperbundleEligibilitySuccess = createAction(
  GET_SUPERBUNDLE_ELIGIBILITY.SUCCESS
);
export const getSuperbundleEligibilityFailure = createAction(
  GET_SUPERBUNDLE_ELIGIBILITY.FAILURE
);

export function getSuperbundleEligibility() {
  return async (dispatch, getState) => {
    dispatch(getSuperbundleEligibilityStart());
    try {
      const {
        eligibilityEndpoint,
        eligibilityRequest,
      } = getEligibilityEndpoint(getState());

      const response = await request(
        `${YOKOZUNA_API}accounts/${eligibilityEndpoint}`,
        {
          credentials: 'include',
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(eligibilityRequest),
        }
      );
      return dispatch(getSuperbundleEligibilitySuccess(response));
    } catch (error) {
      return dispatch(getSuperbundleEligibilityFailure(error));
    }
  };
}
