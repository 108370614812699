import React from 'react';
import PropTypes from 'prop-types';

import Panel from '../Panel';
import Title from './Title';
import Subtitle from './Subtitle';
import Link from './Link';
import Button from './Button';

require('../../styles/form.scss');
require('../../styles/roadblock.scss');

const Roadblock = ({ children }) => (
  <Panel className="large equal-padding">
    <div className="roadblock">{children}</div>
  </Panel>
);

// Subcomponents
Roadblock.Title = Title;
Roadblock.Subtitle = Subtitle;
Roadblock.Link = Link;
Roadblock.Button = Button;

Roadblock.propTypes = {
  children: PropTypes.node,
};

export default Roadblock;
