import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Badge extends Component {
  render() {
    const { description, badgeType, mobileDescription } = this.props;

    const hasBothBadges = description && mobileDescription;
    const onlyMobile = !description && mobileDescription;
    const onlyDesktop = description && !mobileDescription;

    const getBadgeContent = () => {
      if (hasBothBadges)
        return buildBadgeContent(description, mobileDescription);
      if (onlyMobile) return mobileDescription;
      if (onlyDesktop) return description;
      return '';
    };

    const badgeContent = getBadgeContent();

    return (
      <div className="plan__badge">
        {description && (
          <span className={`plan__badge-type--${badgeType}`}>
            {badgeContent}
          </span>
        )}
        {onlyMobile && badgeType === 'light-gray' && (
          <span
            className={`plan__badge-type--${badgeType} plan__badge--mobile`}
          >
            {badgeContent}
          </span>
        )}
      </div>
    );
  }
}

const buildBadgeContent = (description, mobileDescription) => {
  return (
    <>
      <div className="plan__badge--desktop">{description}</div>
      <div className="plan__badge--mobile">{mobileDescription}</div>
    </>
  );
};

Badge.defaultProps = {
  mobileDescription: '',
};

Badge.propTypes = {
  badgeType: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  mobileDescription: PropTypes.string,
};

export default Badge;
