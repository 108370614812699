/**
 * A safe wrapper around NewRelic's JavaScript API.  If NewRelic has not been initialized, calls will be
 * dropped.  If the configuration has not been set up for new relic on this environment, it will be dropped.
 * Each method is documented according to New Relic's documentation about it.
 *
 * @see https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-apis/report-data-events-browser-agent-api
 */

import * as env from '../../../config/env';
import '../vendor/newrelic.min';

let shouldCallNewRelic = false;
export function init() {
  if (shouldCallNewRelic) {
    return;
  }

  shouldCallNewRelic = true;
  if (env.newRelicConfig) {
    window.NREUM.info = env.newRelicConfig;
  } else {
    console.warn(
      'NewRelic configuration not supplied, application will not be monitored.'
    );
    window.NREUM = { noop: true };
    shouldCallNewRelic = false;
  }
}

/**
 * Report a PageAction event with your user-defined name and attributes to New Relic Insights.
 * This can be used to track any event that is not already tracked automatically by the Browser agent,
 * such as clicking a subscribe button or accessing a tutorial. It can also be used for route changes
 * in single-page or other AJAX-heavy applications.
 *
 * This API call sends an event called PageAction to Insights, along with several default attributes,
 * for you to query in Insights. PageAction events are sent to Insights every 10 seconds, with a maximum
 * of 20 events per 10-second harvest cycle, per browser. After the 20-event limit is reached, additional
 * events are not captured. For detailed information, see Inserting custom events via the JavaScript Browser API.
 *
 * @param {String} name - Name or category of the action. Reports to Insights as the actionName attribute.
 * @param {Object} attributes - Object with page attributes.  The key will report to Insights as its own PageAction
 *        attribute with the specified values.
 */
export function addPageAction(name, attributes) {
  if (!shouldCallNewRelic) {
    return undefined;
  }

  return window.newrelic.addPageAction(name, attributes);
}

/**
 * If a browser session trace currently is in progress, this adds an object with a user-defined name,
 * start time, and other optional fields noted below. If a trace is not already in progress, making this
 * call will not cause a trace to be taken.
 *
 * Custom events within session traces can provide context for other user actions, errors, and default events
 * within the trace. This event will appear in the browser session trace details.
 *
 * @param {Object} obj - Object of parameters to add to the trace.
 * @param {String} obj.name - The name of the event.
 * @param {Number} obj.start - Time in ms since epoch.
 * @param {Number} [obj.end] - Time in ms since epoch. Defaults to same as start resulting in trace
 *            object with a duration of zero.
 * @param {String} [obj.origin] - Origin of the event.
 * @param {String} [obj.type] - Type of event.
 */
export function addToTrace(obj) {
  if (!shouldCallNewRelic) {
    return undefined;
  }

  return window.newrelic.addToTrace(obj);
}

/**
 * This call does two things:
 * - Adds a finished event to the current browser session trace, if one is in progress.
 * - Submits a PageAction event with the name finished to New Relic Insights.
 *
 * You can only call this once per page load, and it does not change the other page load timing
 * metrics recorded for the load. The purpose of this API call is to record an additional time point
 * where the page is finished according to your custom criteria, either before or after the page
 * load event (for example, pages with a lot of components that load asynchronously).
 *
 * @param {Number} [time] - Optional. Defaults to the current time of the call.
 */
export function finished(time) {
  if (!shouldCallNewRelic) {
    return undefined;
  }

  return window.newrelic.finished(time);
}

/**
 * Use this call to notice or log your app's handled or other miscellaneous errors. This is useful when you have
 * caught and handled an error, but you still want to identify it without disrupting your app's operation.
 *
 * You can also use the API to notice errors that otherwise would be reported without any detail, such as errors
 * that happen during the script initialization or in an inline event handler. The errors will appear in the
 * JavaScript errors page along with other errors New Relic normally detects.
 *
 * @param {Error} error - Provide a meaningful error message that you can use when analyzing data
 *      on the JavaScript errors page.
 */
export function noticeError(error) {
  if (!shouldCallNewRelic) {
    return undefined;
  }

  return window.newrelic.noticeError(error);
}

/**
 * Adds a user-defined attribute name and value to subsequent events on the page. Make this call before the
 * window load event fires (when that data is transmitted) in order for the attributes to be included in
 * the PageView event. Once an attribute is set, it will be recorded with all PageAction events until the page
 * is reloaded or the attribute is manually unset (PageAction events require an Insights Pro Trial or
 * Insights Pro Annual subscription).
 *
 * If you are using SPA monitoring with a compatible agent version, attributes set with this call will
 * also be included in BrowserInteraction events. However, attributes set using the SPA API will
 * take precedence over these attributes.
 *
 * @param {String} name - Name of the attribute. Appears as column in the PageView event. It will also appear
 *      as a column in the PageAction event if you are using it.
 * @param {String|Number} value of the attribute. Appears as the value in the named attribute column in the
 *      PageView event. It will appear as a column in the PageAction event if you are using it. Custom
 *      attribute values cannot be complex objects, only simple types such as strings and numbers.
 */
export function setCustomAttribute(name, value) {
  if (!shouldCallNewRelic) {
    return undefined;
  }

  return window.newrelic.setCustomAttribute(name, value);
}

/**
 * The newrelic.setErrorHandler call allows for selective ignoring of known errors. It takes a single error
 * handler function, which will be called for each error the agent captures. If the handler returns true,
 * the error will NOT be recorded by New Relic, otherwise it will be processed normally.
 *
 * @param {errorHandler} callback - The callback for handling errors.
 */
export function setErrorHandler(callback) {
  if (!shouldCallNewRelic) {
    return undefined;
  }

  return window.newrelic.setErrorHandler(callback);
}

/**
 * Customized page names can help you more effectively group your page views if your URL structure does not provide
 * useful groupings or if New Relic Browser doesn't capture the part of the URL where routing information is stored.
 * The provided page view name will be used in New Relic Browser and in New Relic Insights.
 *
 * To use a customized page view name instead of the page URL, format the name as a slash-delimited string. Make this
 * call before the load event fires in order for it to appear correctly.
 *
 * @param {String} name - Name of the page you want to use when viewing it in New Relic Browser or Insights.
 * @param {String} [host] - Optional. Default is http://custom.transaction.  To further group these custom transactions,
 *        provide a custom host. Otherwise, the page views will be assigned the default domain custom.transaction.
 *        Segments within the name must be explicitly added to the Whitelist segments in your URL whitelist settings
 *        if they do not already appear. Commonly, you would want to set host to your site's domain URI.
 *
 */
export function setPageViewName(name, host) {
  if (!shouldCallNewRelic) {
    return undefined;
  }

  return window.newrelic.setPageViewName(name, host);
}

/**
 * If you are using Browser's single-page application (SPA) monitoring, you can use the SPA API to add custom monitoring
 * of specific browser interactions. This is useful for monitoring interactions that aren't recorded automatically by
 * New Relic because they don't result in route changes (for example, a dynamically-updated widget). The API also
 * allows you to turn off default monitoring for interactions that may not be considered important enough to monitor.
 * For more about how New Relic SPA monitoring works, see Understand SPA data collection.
 *
 * newrelic.interaction() returns a new API object that is bound to the current interaction. If it is called when
 * New Relic is not currently monitoring an interaction, a new interaction is created. If called again within the same
 * interaction, a new object referencing the current interaction is created.  SPA API methods can be used on
 * newrelic.interaction(). The methods can also be used on a handle you assign with a variable. For example:
 *
 * ```
 * myInteraction = newrelic.interaction()
 * myInteraction.save()
 * ```
 *
 * The named handle can be saved and used from outside an interaction, but methods will have no effect after
 * the interaction has ended.
 *
 * @returns {BrowserInteraction}
 */
export function interaction() {
  if (!shouldCallNewRelic) {
    return undefined;
  }

  return window.newrelic.interaction();
}

/**
 * A callback function for handling errors.
 * @callback errorHandler
 * @param {any} error - Usually will be an error object, but could be other data types.
 * @returns {Boolean}  true if NewRelic should handle the error, false otherwise.
 */
