import { connect } from 'react-redux';
import {
  handleCallbackLoaded as onLoaded,
  handleCallbackClick as onAccept,
} from '../actions/spotifyActions';
import track from '../metrics/track';
import SprintSpotifyRoadblockPage from '../pages/SprintSpotifyRoadblockPage';
import { getRoadblockText } from '../selectors/spotify';

const mapStateToProps = state => ({
  roadblockText: getRoadblockText(state),
  isAsync: true,
});

export default track(
  connect(mapStateToProps, { onLoaded, onAccept })(SprintSpotifyRoadblockPage)
);
