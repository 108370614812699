import { connect } from 'react-redux';
import ActionError from '../components/ActionError';
import * as routes from '../constants/routes';
import { urlRedirect } from '../actions/routingActions';

function mapStateToProps(state, ownProps) {
  return { ...state, ...ownProps };
}

function mapDispatchToProps(dispatch, ownProps) {
  return { dispatch, ...ownProps };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {
    fieldValue: { errors },
  } = ownProps;
  return {
    ...ownProps,
    action: () => {
      const { dispatch } = dispatchProps;
      const params = stateProps.flow.search;
      dispatch(urlRedirect(routes.GO_SVOD + params));
    },
    hasAction: errors && !!errors['svod-available'],
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ActionError);
