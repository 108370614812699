import { combineReducers } from 'redux';
import { createForms } from 'react-redux-form';
import { HuluLogin } from '@hulu/login-modal';

import startup from './startup';
import config from './config';
import misc from './misc';
import cohorts from './cohorts';
import flow from './flow';
import user from './user';
import payment from './payment';
import modal from './modal';
import warning from './warning';
import siteConfig from './siteConfig';
import partner from './partner';
import addons from './addons';
import ledger from './ledger';
import subscription from './subscription';
import { shouldResetState } from '../utils/reducerUtils';
import bundlePartner from './bundlePartner';
import superbundleEligibility from './superbundleEligibility';
import bundlePartnerSwitchEligibility from './bundlePartnerSwitchEligibility';
import featureFlags from './featureFlags';
import identity from './identity';

// The Login Modal component uses its own key and reducer within the Redux store
const { stateKey: HULU_LOGIN, reducer: huluLoginReducer } = HuluLogin;

const appReducer = combineReducers({
  startup,
  siteConfig,
  config,
  cohorts,
  modal,
  misc,
  flow,
  partner,
  warning,
  addons,
  ledger,
  subscription,
  bundlePartner,
  superbundleEligibility,
  bundlePartnerSwitchEligibility,
  featureFlags,
  identity,
  [HULU_LOGIN]: huluLoginReducer,
  ...createForms({ user, payment }),
});

const rootReducer = (state, action) =>
  appReducer(shouldResetState(action) ? {} : state, action);

export default rootReducer;
