import mapKeys from 'lodash/mapKeys';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import mapValues from 'lodash/mapValues';
import { isValidDateString, formatDateString } from './dateUtils';

// Replaces all strings in a JSON object's values (delimited by %KEY_NAME%)
// with their matching dictionary values.
export const replaceKeys = (jsonObject, dictionary, prefix = '') => {
  if (!jsonObject || !dictionary) {
    return jsonObject;
  }

  const keyMap = mapKeys(
    dictionary,
    (_, key) => `%${prefix}${key.toUpperCase()}%`
  );

  return JSON.parse(
    JSON.stringify(jsonObject).replace(
      new RegExp(Object.keys(keyMap).join('|'), 'g'),
      match => keyMap[match]
    )
  );
};

/**
 * Recursively traverse a node, replace all keys delimited by {{}} with mapping.
 * @param node - node to traverse through
 */
export const recursivelyReplaceReplacementVar = node => {
  if (isObject(node)) {
    if (isArray(node)) {
      return node.map(el => recursivelyReplaceReplacementVar(el));
    }
    return mapValues(node, (value, key, object) => {
      if (isString(value)) {
        return value.replace(/{{(.*?)}}/g, (match, matchKey) => {
          // If mapped key does not exist in the same scope level, do nothing by returning the original string
          if (!object[matchKey]) {
            return match;
          }
          // If the mapped value is a date, format it
          if (isValidDateString(object[matchKey])) {
            return formatDateString(object[matchKey]);
          }
          return object[matchKey];
        });
      }
      if (isArray(value)) {
        return value.map(el => recursivelyReplaceReplacementVar(el));
      }
      if (isObject(value)) {
        return recursivelyReplaceReplacementVar(value);
      }
      return value;
    });
  }
  return node;
};
