import { connect } from 'react-redux';
import { urlRedirect } from '../actions/routingActions';
import MarkdownRoadblock from '../components/MarkdownRoadblock';
import { getRoadblockConfig } from '../selectors/roadblock';
import { getStatus } from '../selectors/superbundleEligibility';
import * as MetricsEvents from '../metrics/metricsEvents';
import track from '../metrics/track';
import { hasSelectedAddon } from '../selectors/addons';
import { getIsSuperbundleFlow } from '../selectors/user';
import { ESPN_PLUS_COMPONENT_ID } from '../constants/plans';

const mapStateToProps = state => ({
  hasEspnAddon: hasSelectedAddon(state, ESPN_PLUS_COMPONENT_ID),
  isSuperbundleFlow: getIsSuperbundleFlow(state),
  ...state,
});

const mergeProps = (
  { hasEspnAddon, ...stateProps },
  { dispatch },
  { trackEvent }
) => {
  const path = ['roadblock', 'eligibility', getStatus(stateProps)];
  const roadblock = getRoadblockConfig(stateProps, path);

  return {
    ...stateProps,
    ...roadblock,
    handleClick: () => dispatch(urlRedirect(roadblock.redirectUrl)),
    onLoaded: () => {
      trackEvent(MetricsEvents.SUBSCRIPTION_STEP_START, {
        title: hasEspnAddon
          ? 'SUF - Existing ESPN+ User'
          : 'SUF - Roadblock Partner Plan Ineligible',
      });

      if (roadblock.metricsData?.eventName) {
        const { eventName, options } = roadblock.metricsData;
        trackEvent(eventName, options);
      }
    },
  };
};

export default track(
  connect(mapStateToProps, null, mergeProps)(MarkdownRoadblock)
);
