import { metricsManager } from '../metrics/MetricsManager';
import * as actionTypes from '../constants/actionTypes';

/*
 * MetricsMiddleware should no have side-effect on the app or state.
 * It should treat app state and behavior as read-only, and only have
 * the effect of emitting an event.
 */
export const metricsMiddleware = () => next => action => {
  const result = next(action);
  const currentActionType = action && action.type;

  // whenever the site config is loaded, initialize metricsManager
  if (currentActionType === actionTypes.GET_SITE_CONFIG.SUCCESS) {
    metricsManager.initialize(action.payload);
  }

  return result;
};
