import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextField from './TextField';
import { getFormFieldValidity } from '../selectors/form';

class NumberField extends Component {
  constructor() {
    super();

    this.onKeyDown = this.onKeyDown.bind(this);
    this.onPaste = this.onPaste.bind(this);
  }

  onKeyDown(e) {
    if (this.props.onFieldChangeStarted) {
      this.props.onFieldChangeStarted();
    }

    if (e.metaKey || e.ctrlKey || (e.which !== 32 && e.which < 47)) {
      return;
    }
    // can't get correct digit in safari if number pad is used.
    const keyCode = e.which >= 96 && e.which <= 105 ? e.which - 48 : e.which;
    const digit = String.fromCharCode(keyCode);
    if (/^\D$/.test(digit)) {
      e.preventDefault();
    }
  }

  onPaste(e) {
    if (this.props.onFieldChangeStarted) {
      this.props.onFieldChangeStarted();
    }
    const target = e.currentTarget;
    setTimeout(() => {
      let value = target.value || '';
      value = value.replace(/\D/g, '');
      target.value = value;
      this.props.onValueChanged(target.name, value);
    });
  }

  render() {
    return (
      <TextField
        onKeyDown={this.onKeyDown}
        onPaste={this.onPaste}
        pattern="[●\d]*"
        type="tel"
        inputMode="numeric"
        {...this.props}
      />
    );
  }
}

NumberField.propTypes = {
  onValueChanged: PropTypes.func.isRequired,
  onFieldChangeStarted: PropTypes.func,
};

const mapStateToProps = (state, props) => ({
  valid: getFormFieldValidity(state, `${props.form}.${props.model}`),
});

export default connect(mapStateToProps)(NumberField);
