import * as types from '../constants/actionTypes';
import { ERROR_CODES } from '../constants/errorCodes';

function warning(state = null, action) {
  switch (action.type) {
    case types.PROCESS_PAYMENT_ERRORS:
      if (action.error) {
        return {
          ...state,
          isIneligiblePromotion:
            state.isIneligiblePromotion ||
            action.error.code === ERROR_CODES.INELIGIBLE_PROMOTION,
          isIneligibleDiscount:
            state.isIneligibleDiscount ||
            action.error.code === ERROR_CODES.INELIGIBLE_DISCOUNT,
        };
      }
      return state;
    case types.SELECT_PLAN:
      if (action.persistWarnings) {
        return state;
      }

      return {
        ...state,
        isIneligiblePromotion: false,
        isIneligibleDiscount: false,
      };
    case types.SET_INELIGIBLE_TRIAL_DISCOUNT:
      return {
        ...state,
        isIneligibleTrialDiscount: action.payload,
      };
    default:
      break;
  }
  return state;
}

export default warning;
