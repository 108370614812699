/* eslint-disable prefer-destructuring */

const cardExpiryVal = function(value) {
  let prefix;
  const ref = (value || '').split(/[\s/]+/, 2);
  let month = ref[0];
  let year = ref[1];
  if ((year == null ? undefined : year.length) === 2 && /^\d+$/.test(year)) {
    prefix = new Date().getFullYear();
    prefix = prefix.toString().slice(0, 2);
    year = prefix + year;
  }
  month = parseInt(month, 10);
  year = parseInt(year, 10);
  return {
    month,
    year,
  };
};

export const validateCardExpiry = function(value) {
  let month;
  let year;

  if (value && value.indexOf('/') > 0) {
    const ref1 = cardExpiryVal(value);
    month = ref1.month;
    year = ref1.year;
  } else {
    return false;
  }

  let ref;
  if (typeof month === 'object' && 'month' in month) {
    ref = month;
    month = ref.month;
    year = ref.year;
  }
  if (!(month && year)) {
    return false;
  }
  if (!/^\d+$/.test(month)) {
    return false;
  }
  if (!/^\d+$/.test(year)) {
    return false;
  }
  if (!(month >= 1 && month <= 12)) {
    return false;
  }
  const expiry = new Date(year, month);
  const currentTime = new Date();
  expiry.setMonth(expiry.getMonth() - 1);
  expiry.setMonth(expiry.getMonth() + 1, 1);
  return expiry > currentTime;
};
