import React from 'react';
import PropTypes from 'prop-types';
import keyBy from 'lodash/keyBy';
import reactStringReplace from 'react-string-replace';
import { formatDateString } from '../utils/dateUtils';

const LedgerTerm = props => {
  const { key } = props;
  const variableMap = keyBy(props.variables, variable => variable.key);

  return (
    <>
      {reactStringReplace(`${props.value} `, /{{(.*?)}}/g, match => {
        const { type, value, href } = variableMap[match];
        switch (type) {
          case 'date':
            return formatDateString(value);
          case 'link':
            return (
              <a
                href={href}
                key={`ledger-term-link-${key}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {value}
              </a>
            );
          case 'string':
          case 'number':
          default:
            return value;
        }
      })}
    </>
  );
};

LedgerTerm.propTypes = {
  value: PropTypes.string.isRequired,
  variables: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['date', 'string', 'number', 'link']).isRequired,
      value: PropTypes.string.isRequired,
      href: PropTypes.string,
    })
  ),
  key: PropTypes.string,
};

export default LedgerTerm;
