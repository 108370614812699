// Utils functions for various accessibility needs
// To be used in conjunction with onMouseDown
// Prevents the blue outline on click
export const preventFocus = event => {
  event.preventDefault();
};

const FOCUSABLE_SELECTOR = 'a[href], area[href], button, details, input, iframe, select, textarea, [tabindex]'
  .split(', ')
  .map(e => `${e}:not([tabindex="-1"]):not([aria-hidden="true"])`)
  .join(',');

/**
 * This sets up a listener that traps focus within an element. Returns a handle that
 * must be removed on componentWillUnmount
 *
 * Not possible to be completely accurate,
 * there's still disabled state and element could have display: none or visibility: hidden set
 */
export const keepFocusWithin = elementRef => {
  const returnFocusTo = document.activeElement;
  const handleKeepFocus = event => {
    const element = elementRef.current;
    if (element && !element.contains(event.target)) {
      event.stopPropagation();
      // focus first focusable element
      element.querySelector(FOCUSABLE_SELECTOR).focus();
    }
  };

  document.addEventListener('focus', handleKeepFocus, true);

  // Put the focus on the containing element, which should have tabindex -1
  if (elementRef.current) elementRef.current.focus();

  return {
    remove() {
      document.removeEventListener('focus', handleKeepFocus, true);
      // We do not need to guard against returnFocusTo being null/undefined as
      // it always appear valid element when read. If it's removed in the mean time
      // focusing the unattached node is a noop
      returnFocusTo.focus();
    },
  };
};
