import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Control, Errors } from 'react-redux-form';
import SelectField from './SelectField';
import { MONTHS as months } from '../utils/dateUtils';
import * as validators from '../constants/validators';
import { ERROR_TEXT } from '../constants/errorText';
import InputError from './InputError';
import InfoTooltip from './InfoTooltip';

class BirthdayField extends Component {
  constructor() {
    super();

    this.days = [];
    for (let i = 1; i <= 31; i++) {
      this.days.push({ value: i.toString(), text: i });
    }

    this.years = [];
    const endYear = 1900;
    for (
      let startYear = new Date().getFullYear();
      startYear >= endYear;
      startYear--
    ) {
      this.years.push({ value: startYear.toString(), text: startYear });
    }

    this.months = [];
    for (let i = 0; i < months.length; i++) {
      this.months.push({ value: (i + 1).toString(), text: months[i] });
    }
  }

  render() {
    const { dispatch, model, tooltip, displayName, disabled } = this.props;
    return (
      <div className="fieldset" data-testid={`${model}FieldSet`}>
        <div className="label">
          <label className="label__input">{displayName}</label>
          <InfoTooltip
            tooltip={tooltip}
            className="birthday"
            id="birthday-tooltip"
          />
        </div>
        <div className="field field__month">
          <Errors
            className="has-error"
            model={`.${model}.month`}
            show={{ touched: true, focus: false }}
            data-testid={`${model}.month.error`}
          />
          <Control.select
            model={`.${model}.month`}
            validators={{ required: validators.REQUIRED }}
            validateOn="blur"
            controlProps={{
              dispatch,
              placeholder: 'Month',
              options: this.months,
              disabled,
            }}
            component={SelectField}
            disabled={disabled}
            mapProps={{
              id: 'birthdayMonth',
            }}
          />
          <Errors
            className="errors"
            model={`.${model}.month`}
            show={{ touched: true, focus: false }}
            messages={{
              required: <InputError message={ERROR_TEXT.REQUIRED} />,
            }}
            component={props => (
              <span id="birthdayMonth-error">{props.children}</span>
            )}
          />
        </div>
        <div className="field field__day">
          <Errors
            className="has-error"
            model={`.${model}.day`}
            show={{ touched: true, focus: false }}
            data-testid={`${model}.day.error`}
          />
          <Control.select
            model={`.${model}.day`}
            validators={{ required: validators.REQUIRED }}
            validateOn="blur"
            controlProps={{
              dispatch,
              placeholder: 'Day',
              options: this.days,
              disabled,
            }}
            component={SelectField}
            mapProps={{
              id: 'birthdayDay',
            }}
          />
          <Errors
            className="errors"
            model={`.${model}.day`}
            show={{ touched: true, focus: false }}
            messages={{
              required: <InputError message={ERROR_TEXT.REQUIRED} />,
            }}
            component={props => (
              <span id="birthdayDay-error">{props.children}</span>
            )}
          />
        </div>
        <div className="field field__year">
          <Errors
            className="has-error"
            model={`.${model}.year`}
            show={{ touched: true, focus: false }}
            data-testid={`${model}.year.error`}
          />
          <Control.select
            model={`.${model}.year`}
            validators={{ required: validators.REQUIRED }}
            validateOn="blur"
            controlProps={{
              dispatch,
              placeholder: 'Year',
              options: this.years,
              disabled,
            }}
            component={SelectField}
            mapProps={{
              id: 'birthdayYear',
            }}
          />
          <Errors
            className="errors"
            model={`.${model}.year`}
            show={{ touched: true, focus: false }}
            messages={{
              required: <InputError message={ERROR_TEXT.REQUIRED} />,
            }}
            component={props => (
              <span id="birthdayYear-error">{props.children}</span>
            )}
          />
        </div>
      </div>
    );
  }
}

BirthdayField.defaultProps = {
  disabled: false,
};

BirthdayField.propTypes = {
  model: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  tooltip: PropTypes.element.isRequired,
  disabled: PropTypes.bool,
};

export default BirthdayField;
