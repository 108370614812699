import get from 'lodash/get';

import * as api from '../api/Identity';
import { getCohorts } from './cohortsActions';
import { urlRedirect, routeReplace } from './routingActions';
import { showStudentModal } from './modalActions';
import * as types from '../constants/actionTypes';
import * as routes from '../constants/routes';
import { STUDENT } from '../constants/cohorts';
import * as env from '../../../config/env';
import { getIdentityVerificationRequest } from '../selectors/identity';
import { getCurrentPlanProgramId } from '../selectors/program';
import { hasExternalIdentityVerification } from '../selectors/flow';
import { getPromotionCode } from '../selectors/config';
import { stringify } from '../utils/querystring';

export const checkIfVerifiedStudent = () => async dispatch => {
  const cohorts = await dispatch(getCohorts([STUDENT]));
  const studentMembership = cohorts.find(
    cohort => cohort.name.toLowerCase() === STUDENT.toLowerCase()
  );
  return studentMembership && studentMembership.result;
};

export const handleIdentityVerification = () => async (dispatch, getState) => {
  dispatch(createIdentityVerificationStart());
  try {
    const isVerifiedStudent = await dispatch(checkIfVerifiedStudent());
    if (isVerifiedStudent) {
      dispatch(createIdentityVerificationSuccess());
      return;
    }
    // Success response indicates successfully verified and added to student cohort.
    await api.postVerification(
      getIdentityVerificationRequest(getState(), STUDENT)
    );
    dispatch(createIdentityVerificationSuccess());
  } catch (error) {
    dispatch(createIdentityVerificationFailure(error));
    throw error;
  }
};

export const createIdentityVerificationStart = () => ({
  type: types.CREATE_IDENTITY_VERIFICATION.START,
});

export const createIdentityVerificationSuccess = () => ({
  type: types.CREATE_IDENTITY_VERIFICATION.SUCCESS,
});

export const createIdentityVerificationFailure = error => ({
  type: types.CREATE_IDENTITY_VERIFICATION.FAILURE,
  payload: error,
});

/**
 * Direct logged in users for plans which require a verified identity.
 */
export const postLoginRoutingForIdentityVerification = () => async (
  dispatch,
  getState
) => {
  // If this user is returning from the identity partner with a verification:
  if (hasExternalIdentityVerification(getState())) {
    // Go to identity route to handle verification.
    return dispatch(routeReplace(routes.IDENTITY_VERIFICATION));
  }
  // If this user is already a student, go to billing route
  const isVerifiedStudent = await dispatch(checkIfVerifiedStudent());
  if (isVerifiedStudent) {
    return dispatch(routeReplace(routes.BILLING_INFO));
  }
  // Redirect users without a subscription to the identity partner.
  return dispatch(showStudentModal());
};

export const redirectToIdentityPartner = () => (dispatch, getState) => {
  const REDIRECT_URL_PARAM = 'redirect_url';
  const params = {
    [REDIRECT_URL_PARAM]: buildReturnUrl(getState()),
  };
  dispatch(urlRedirect(env.url.sheerId, params));
};

const buildReturnUrl = state => {
  const params = {};
  const currentPlanProgramId = getCurrentPlanProgramId(state);
  const promotionCode = getPromotionCode(get(state, 'flow.query', null));

  if (currentPlanProgramId) {
    const PROGRAM_ID_PARAM = 'promo_id';
    params[PROGRAM_ID_PARAM] = currentPlanProgramId;
  }

  if (promotionCode) {
    const PROMOTION_CODE_PARAM = 'promotion_code';
    params[PROMOTION_CODE_PARAM] = promotionCode;
  }

  const host = getCurrentHostUrl();
  const url = `${host}${routes.IDENTITY_VERIFICATION}`;
  return `${url}?${stringify(params)}`;
};

/*
 * Get the current host url such that the 3rd party site can redirect back to the same
 * environment. This also works for variations of localhost.
 *
 * Example hosts:
 *
 * signup.hulu.com
 * localhost.huluqa.com:port
 * localhost-port.huluqa.com:port
 * localhost:port
 */
export const getCurrentHostUrl = () =>
  `${window.location.protocol}//${window.location.host}`;
