export const GENDER_FIELD_OPTIONS = [
  {
    text: 'Female',
    value: 'f',
  },
  {
    text: 'Male',
    value: 'm',
  },
  {
    text: 'Prefer not to say',
    value: 'p',
  },
];

export const UPDATED_GENDER_FIELD_OPTIONS = [
  {
    text: 'Man',
    value: 'm',
  },
  {
    text: 'Woman',
    value: 'f',
  },
  {
    text: 'Non-binary',
    value: 'n',
  },
  {
    text: 'Prefer not to say',
    value: 'p',
  },
];
