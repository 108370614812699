import get from 'lodash/get';

export const getHasCheckedRecordingConsent = state =>
  get(state, 'payment.hasCheckedRecordingConsent', false);

export const getHasCheckedVermontPrepaidConsent = state =>
  get(state, 'payment.hasCheckedVermontPrepaidConsent', false);

export const getHasCheckedIneligibleDiscountConsent = state =>
  get(state, 'payment.hasCheckedIneligibleDiscountConsent', false);
