module.exports = {
  url: {
    yokozuna: '/api/v2/',
    yokozuna_v3: '/api/v3/',
    yokozuna_v4: '/api/v4/',
    hoth: {
      host: 'https://auth.hulu.com',
      login: '/web/login',
    },
    site: 'https://www.hulu.com',
    login: 'https://secure.hulu.com/account',
    changePlan: 'https://secure.hulu.com/account',
    onboardingPage: 'https://www.hulu.com/onboarding',
    redeemGiftCode: 'https://secure.hulu.com/start/gifting_redeem',
    forgotPassword: 'https://secure.hulu.com/users/find_account',
    tosText: 'https://www.hulu.com/terms.txt',
    tosHtml: 'https://www.hulu.com/terms',
    privacyText: 'https://www.hulu.com/privacy.txt',
    privacyHtml: 'https://www.hulu.com/privacy',
    privacyHtmlTWDC:
      'https://privacy.thewaltdisneycompany.com/en/current-privacy-policy/',
    doNotSellMyInfoTWDC: 'https://privacy.thewaltdisneycompany.com/en/dnsmi/',
    support: 'https://help.hulu.com/contact',
    dplus: 'https://www.disneyplus.com',
    activate: 'https://secure.hulu.com/account/activations',
    sheerId: 'https://offers.sheerid.com/hulu/student/',
    websub: 'https://secure.hulu.com/subscription',
    cup: 'https://secure.hulu.com/commerce',
    logoutApi: 'https://secure.hulu.com/api/2.0/logout',
    disneyFOC:
      'https://privacy.thewaltdisneycompany.com/en/definitions/#The-Walt-Disney-Family-of-Companies',
  },
  recaptcha: {
    authentication: {
      publicKey: '6LfrSNkZAAAAAPS1SbxcjZSwSo9zjT1gZoeOuE0P',
    },
    userCreation: {
      publicKey: '6LeOKwAaAAAAACbPYzYOi9kHNNgh8qSYJg1_rv13',
    },
  },
  paypal: {
    environment: 'production',
    merchantId: 'FRKJQBYUTV2P2',
  },
  venmo: {
    testModeAllowed: false,
  },
  spotify: {
    clientId: '9f00faf0e3d74e9d97654a0c4b465ec2',
    testModeAllowed: false,
  },
  cookieDomain: '.hulu.com',
  newRelicConfig: {
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
    licenseKey: '2961bc4e3a',
    applicationID: '55882255',
    sa: 1,
  },
  datadog: {
    applicationId: '566c343c-4daf-4ce7-a1d2-183e719940fc',
    clientToken: 'pub0341bea8c4a116b847a457751bc49b3c',
  },
  tealiumEnv: 'prod',
};
