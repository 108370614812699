import { connect } from 'react-redux';
import { routePush } from '../actions/routingActions';
import { setIsExistingUser } from '../actions/partnerActions';
import { SPRINTV2_CALLBACK, TMOBILE_CALLBACK } from '../constants/routes';
import track from '../metrics/track';
import {
  SUBSCRIPTION_START,
  SUBSCRIPTION_STEP_START,
} from '../metrics/metricsEvents';
import SprintSpotifyLandingPage from '../pages/SprintSpotifyLandingPage';
import {
  getIsTWDCPrivacyPolicyFeatureOn,
  getIsTMobileFeatureOn,
} from '../selectors/featureFlag';
import { getControlText } from '../selectors/siteconfig';
import { getPayload } from '../selectors/sprintV2';

function mapStateToProps(state) {
  const {
    flow: { partnerFlow },
  } = state;
  const {
    title: titleText,
    subtitle: subTitles,
    disclaimer,
    button_text: { new: newUserButtonText, existing: existingUserButtonText },
  } = getControlText(state, 'sprint_landing_page_text');
  return {
    partnerFlow,
    titleText,
    subTitles,
    disclaimer,
    newUserButtonText,
    existingUserButtonText,
    hasPayload: !!getPayload(state),
    isTMobileFeatureOn: getIsTMobileFeatureOn(state),
    isTWDCPrivacyPolicyFeatureOn: getIsTWDCPrivacyPolicyFeatureOn(state),
  };
}

function mergeProps(
  {
    newUserButtonText,
    existingUserButtonText,
    disclaimer,
    hasPayload,
    ...stateProps
  },
  dispatchProps,
  ownProps
) {
  const { dispatch } = dispatchProps;
  const { trackEvent } = ownProps;
  const callbackRoute = stateProps.isTMobileFeatureOn
    ? TMOBILE_CALLBACK
    : SPRINTV2_CALLBACK;

  const onLoaded = () => {
    if (!hasPayload) {
      dispatch(routePush(callbackRoute));
    }

    trackEvent(SUBSCRIPTION_START);
    trackEvent(SUBSCRIPTION_STEP_START, {
      title: 'SUF - Landing Page',
      pageId: 'landing',
    });
  };

  const onClick = isExistingUser => () => {
    dispatch(setIsExistingUser(isExistingUser));
    dispatch(routePush(callbackRoute));
  };

  const buttons = [
    {
      id: 'new-user-button',
      text: newUserButtonText,
      onClick: onClick(false),
    },
    {
      id: 'existing-user-button',
      text: existingUserButtonText,
      onClick: onClick(true),
    },
  ];

  return {
    ...stateProps,
    buttons,
    disclaimer,
    onLoaded,
  };
}

export default track(
  connect(mapStateToProps, null, mergeProps)(SprintSpotifyLandingPage)
);
