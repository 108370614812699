import { connect } from 'react-redux';
import { routeReplace, urlRedirect } from '../actions/routingActions';
import { LOGIN } from '../constants/routes';
import {
  getIsPartnerFlow,
  getIsUnifiedActivationFlow,
} from '../selectors/flow';
import { getPartnerFlowText } from '../selectors/siteconfig';
import { getIsLoggedIn } from '../selectors/user';
import { url } from '../../../config/env';

import PageTopNav from '../components/PageTopNav';

const mapStateToProps = state => ({
  isPartnerFlow: getIsPartnerFlow(state),
  isUnifiedActivationFlow: getIsUnifiedActivationFlow(state),
  isLoggedIn: getIsLoggedIn(state),
  logo: getPartnerFlowText(state, 'data.logo'),
});

const mergeProps = (
  { isPartnerFlow, isUnifiedActivationFlow, isLoggedIn, logo },
  { dispatch }
) => ({
  isLoggedIn,
  logo,
  onLoginButtonClick: () =>
    dispatch(
      isPartnerFlow || isUnifiedActivationFlow
        ? routeReplace(LOGIN)
        : urlRedirect(url.login)
    ),
});

export default connect(mapStateToProps, null, mergeProps)(PageTopNav);
