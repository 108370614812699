import React from 'react';
import PropTypes from 'prop-types';

import LedgerInfo from './LedgerInfo';
import LedgerPricing from './LedgerPricing';
import LedgerBreakdown from './LedgerBreakdown';
import BreakdownItem from './BreakdownItem';
import LedgerActionItems from './LedgerActionItems';
import { Experiment, Treatment } from '../Experiment';
import LedgerInfoWithBadge from '../../experiments/LedgerInfoWithBadge';
import { getLedgerBadgeType } from '../../selectors/ledger';
import Badge from '../Badge';
import PlanOptionsContainer from '../../containers/PlanOptionsContainer';

const LedgerItem = ({
  id,
  type,
  promotions,
  name,
  price,
  description,
  savings,
  breakdown,
  actions,
  announcement,
  promoTreatmentId,
  badges,
  shouldDisplayPlanOptions,
  showDuoBundleDisclaimer,
}) => (
  <li className="ledger__item">
    {savings && <div className="ledger__offer">SPECIAL OFFER</div>}
    <div className="plan__badges">
      {badges.map(badgeObject => (
        <Badge
          key={badgeObject.key}
          badgeType={getLedgerBadgeType(badgeObject.key)}
          description={badgeObject.value}
        />
      ))}
    </div>
    <Experiment
      fallback={
        <LedgerInfo
          name={name}
          description={description}
          showDuoBundleDisclaimer={showDuoBundleDisclaimer}
        />
      }
    >
      <Treatment developerId={promoTreatmentId}>
        <LedgerInfoWithBadge
          id={id}
          type={type}
          name={name}
          promotions={promotions}
          description={description}
        />
      </Treatment>
    </Experiment>
    <LedgerPricing price={price} savings={savings} />
    {announcement && <div className="ledger__announcement">{announcement}</div>}
    {breakdown && <LedgerBreakdown breakdown={breakdown} />}
    {shouldDisplayPlanOptions && <PlanOptionsContainer />}
    {actions.length > 0 && <LedgerActionItems actions={actions} />}
  </li>
);

LedgerItem.propTypes = {
  id: PropTypes.number,
  type: PropTypes.string.isRequired,
  promotions: PropTypes.array,
  name: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  description: PropTypes.string,
  savings: PropTypes.string,
  breakdown: PropTypes.arrayOf(PropTypes.shape(BreakdownItem.PropTypes)),
  actions: LedgerActionItems.propTypes.actions,
  announcement: PropTypes.string,
  promoTreatmentId: PropTypes.string,
  badges: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  shouldDisplayPlanOptions: PropTypes.bool.isRequired,
  showDuoBundleDisclaimer: PropTypes.bool,
};

export default LedgerItem;
