import {
  smallMobileWidth,
  mobileWidth,
  desktopMinWidth,
  tabletMinWidth,
} from '../styles/variables.scss';
import {
  WIDE_DESKTOP,
  TABLET,
  DESKTOP,
  MOBILE,
  SMALL_MOBILE,
} from '../constants/breakpoints';

export const evaluateBreakpoint = windowWidth => {
  if (windowWidth === undefined) return undefined;

  if (windowWidth >= desktopMinWidth) {
    return WIDE_DESKTOP;
  }
  if (windowWidth >= tabletMinWidth) {
    return TABLET;
  }
  if (windowWidth >= mobileWidth) {
    return DESKTOP;
  }
  if (windowWidth >= smallMobileWidth) {
    return MOBILE;
  }

  return SMALL_MOBILE;
};
