import React from 'react';
import PropTypes from 'prop-types';

const LedgerPricing = ({ price, savings }) => {
  return (
    <div className="ledger__pricing">
      <div className="ledger__price">{price}</div>
      {savings && <div className="ledger__savings">({savings})</div>}
    </div>
  );
};

LedgerPricing.propTypes = {
  price: PropTypes.string.isRequired,
  savings: PropTypes.string,
};

export default LedgerPricing;
