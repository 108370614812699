import React from 'react';
import PropTypes from 'prop-types';
import SimpleTooltip from './SimpleTooltip';
import { SPOTIFY } from '../constants/partners';

export default class SpotifyLandingFooter extends React.Component {
  render() {
    const { eligibilityText } = this.props;
    return (
      <div>
        <div className="terms__partner">
          <SimpleTooltip tooltip={eligibilityText}>
            <span id="link--disclaimer">Eligibility Requirements</span>
          </SimpleTooltip>
        </div>
        <div className="terms__partner">
          <a className="link__partner" href={SPOTIFY.HOME_URL}>
            &lt; BACK TO SPOTIFY
          </a>
        </div>
      </div>
    );
  }
}

SpotifyLandingFooter.propTypes = {
  eligibilityText: PropTypes.string,
};
