import { YOKOZUNA_API } from '../constants/apis';
import request from './request';
import { stringify } from '../utils/querystring';

/**
 * Get information about a Sprint Wireless user.
 *
 * @param {String} payload The Sprint encrypted payload.
 */
export function getSprintV2Eligibility(payload) {
  const params = { payload };

  return request(
    `${YOKOZUNA_API}partners/sprint/eligibility?${stringify(params)}`,
    {
      method: 'GET',
      credentials: 'include',
    }
  );
}
