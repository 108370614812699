import {
  CART_ABANDONMENT,
  CART_ABANDONMENT_TTL,
  getCookie,
  removeCookie,
  setCookie,
} from './cookieUtils';
import { COOKIES_DOMAIN } from '../constants/misc';
import { getFirstName, getSelectedPlan } from '../selectors/user';
import { findPlanBySubIdentifier, getPlans } from '../selectors/plans';
import { isStudentPlan } from '../selectors/plan';
import {
  getIsPaypalCallback,
  getPartnerFlow,
  getSearch,
  getStartPathname,
} from '../selectors/flow';
import {
  getSelectedAddonBundleIds,
  getSelectedAddonIds,
} from '../selectors/addons';

export const deleteCartAbandonmentCookie = () =>
  removeCookie(CART_ABANDONMENT, COOKIES_DOMAIN);

/**
 * Sets the Cookie for Cart Abandonment. The Cookie will have different values
 * depending ont he parameter `nameOnly`, see below.
 *
 * @param state The Redux store
 * @param nameOnly {boolean} If true the cookie will only contain the name of
 *                 the currently logged in user, otherwise it will also contain
 *                 properties about the selected plan and the starting flow
 */
export const setCartAbandonmentCookie = (state, nameOnly = false) => {
  const cookieValue = { name: getFirstName(state) };

  if (!nameOnly) {
    cookieValue.plan = getSelectedPlan(state).name;
    cookieValue.subIdentifier = getSelectedPlan(state).subIdentifier;
    cookieValue.search = getSearch(state);
    cookieValue.startPathname = getStartPathname(state);
    cookieValue.partnerFlow = getPartnerFlow(state);
    cookieValue.selectedAddons = getSelectedAddonIds(state);
    cookieValue.selectedAddonBundles = getSelectedAddonBundleIds(state);
  }

  setCookie(
    CART_ABANDONMENT,
    JSON.stringify(cookieValue),
    CART_ABANDONMENT_TTL,
    COOKIES_DOMAIN
  );
};

/**
 * Finds and parses the Cart Abandonment Cookie and returns it. Returns empty
 * object if the Cookie is not found.
 *
 * @returns {Object} Parsed Cart Abandonment Cookie or empty object if not set
 */
export const getCartAbandonmentCookie = () => {
  try {
    return JSON.parse(getCookie(CART_ABANDONMENT, true));
  } catch (e) {
    return {};
  }
};

/**
 * Gets the string value of the previously selected plan in the parsed Cart
 * Abandonment Cookie if it has been set. Returns null if the Cookie or the
 * subidentifier is not set.
 *
 * @param state The redux store
 * @returns {string|null} Subidentifier for the plan stored in the cookie or
 *                        null if not set
 */
export const getPreviouslySelectedPlanInCartAbandonment = state => {
  const cartAbandonmentCookie = getCartAbandonmentCookie();
  const { subIdentifier } = cartAbandonmentCookie;

  return subIdentifier
    ? findPlanBySubIdentifier(subIdentifier, getPlans(state))
    : null;
};

/**
 * For a user navigating to the Plan Select page we want to check if the Cart
 * Abandonment cookie is present with a previously selected plan, and if the
 * selected plan is not a student plan then we can skip to the next page.
 *
 * @param state Redux store
 * @returns {boolean} True if user should be redirected to Account Creation page
 *                    and skip Plan Select, false otherwise
 */
export const shouldSkipPlanSelectForCartAbandonment = state => {
  const selectedPlan = getSelectedPlan(state);

  return (
    selectedPlan &&
    getPreviouslySelectedPlanInCartAbandonment(state) !== null &&
    !isStudentPlan(selectedPlan) &&
    !getIsPaypalCallback(state)
  );
};
