import React from 'react';
import PropTypes from 'prop-types';

import Panel from '../Panel';

const LedgerSummary = ({ name, price, disclaimer }) => (
  <Panel className="large">
    <div className="ledger__summary">
      <div className="summary__name">{name}</div>
      <div className="summary__price">{price}</div>
    </div>
    <div className="ledger__disclaimer">{disclaimer}</div>
  </Panel>
);

LedgerSummary.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  disclaimer: PropTypes.string.isRequired,
};

export default LedgerSummary;
