import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Manager, Reference, Popper } from 'react-popper';
import useToggle from '../hooks/useToggle';

require('../styles/simpleTooltip.scss');

/*
 * This is borrowed from h3o-account, some modifications to use a plain button
 * instead of h3o-account's Button component and added aria-role/label to the
 * tooltips contents
 */
const SimpleTooltip = ({
  tabIndex,
  children,
  tooltip: content,
  tooltipLabel,
  href,
  className,
  id,
  label,
}) => {
  const [isVisible, toggleTooltip] = useToggle();
  const tooltipRef = useRef();

  // close when clicking outside
  const handleClick = useCallback(
    e => {
      // case where there are multiple tooltips in a page, or hitting the tooltip itself
      if (!isVisible || tooltipRef.current.contains(e.target)) {
        return;
      }
      toggleTooltip();
    },
    [isVisible, toggleTooltip]
  );

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [handleClick]);

  return (
    <span className={classNames('SimpleTooltip', className)} ref={tooltipRef}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <button
              type="button"
              className="SimpleTooltip__trigger"
              href={href}
              ref={ref}
              onClick={toggleTooltip}
              tabIndex={tabIndex}
              aria-label={label}
              aria-expanded={isVisible}
              aria-describedby={id}
            >
              {children}
            </button>
          )}
        </Reference>
        <Popper
          placement="top-start"
          modifiers={{
            offset: {
              enabled: false,
            },
            preventOverflow: {
              boundariesElement: 'window',
              enabled: true,
            },
            shift: {
              enabled: false,
            },
          }}
        >
          {({ ref, style, placement, arrowProps }) =>
            isVisible && (
              <div
                className="SimpleTooltip__tooltip"
                ref={ref}
                style={style}
                data-placement={placement}
              >
                <div
                  id={id}
                  className="SimpleTooltip__content"
                  role="region"
                  aria-label={tooltipLabel}
                >
                  {content}
                </div>
                <div
                  className="tooltip-arrow"
                  ref={arrowProps.ref}
                  style={arrowProps.style}
                />
              </div>
            )
          }
        </Popper>
      </Manager>
    </span>
  );
};

SimpleTooltip.propTypes = {
  tabIndex: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  href: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.node,
  tooltipLabel: PropTypes.string,
};

SimpleTooltip.defaultProps = {
  tabIndex: 0,
};

export default SimpleTooltip;
