import braintree from 'braintree-web';

import { getVenmoClientToken } from '../api/ClientTokens';
import { getCookie } from './cookieUtils';
import * as envVars from '../../../config/env';

// Venmo instance singleton
let venmoInstance;

const initializeInstance = () =>
  getVenmoClientToken()
    // Create the Braintree client instance
    .then(clientToken =>
      braintree.client.create({
        authorization: clientToken,
      })
    )
    // Create the Venmo instance
    .then(clientInstance =>
      braintree.venmo.create({
        client: clientInstance,
        allowNewBrowserTab: false,
      })
    );

const initializeMockInstance = nonce => ({
  isBrowserSupported: () => true,
  tokenize: () => Promise.resolve({ nonce }),
});

export function getVenmoInstance() {
  // Check if the instance is already initialized
  if (venmoInstance) {
    return Promise.resolve(venmoInstance);
  }

  // Check if a mock instance should be initialized
  const testCookie = getCookie('_venmo_test_nonce');
  if (envVars.venmo.testModeAllowed && testCookie) {
    venmoInstance = initializeMockInstance(testCookie);
    return Promise.resolve(venmoInstance);
  }

  // Initialize the venmo client instance
  return initializeInstance().then(instance => {
    venmoInstance = instance;
    return Promise.resolve(venmoInstance);
  });
}
