import { createReducer } from 'redux-starter-kit';
import get from 'lodash/get';
import { parse } from 'query-string';

import {
  GET_BUNDLE_PARTNER_PAYLOAD,
  SAVE_INITIAL_LOCATION,
} from '../constants/actionTypes';
import { BUNDLE_PARTNER } from '../constants/partners';

export const initialState = {
  token: null,
  response: {
    payload: null,
    loading: false,
    error: null,
  },
};

export default createReducer(initialState, {
  [SAVE_INITIAL_LOCATION]: (state, action) => {
    const query = parse(get(action, 'location.search'));
    const token = get(query, BUNDLE_PARTNER.TOKEN_PARAM);
    // Only store the token if it's included in the query (this handles the refresh case)
    state.token = token || state.token;
  },
  [GET_BUNDLE_PARTNER_PAYLOAD.START]: state => {
    state.response = {
      payload: null,
      loading: true,
      error: null,
    };
  },
  [GET_BUNDLE_PARTNER_PAYLOAD.SUCCESS]: (state, { payload }) => {
    state.response = {
      payload,
      loading: false,
      error: null,
    };
  },
  [GET_BUNDLE_PARTNER_PAYLOAD.FAILURE]: (state, { payload }) => {
    state.response = {
      payload: null,
      loading: false,
      error: payload,
    };
  },
});
