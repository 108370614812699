import * as types from '../constants/actionTypes';

const initialState = {
  addonsSelected: [],
  shouldSkipPlanSelect: false,
  lockedOut: false,
  reselectingPlan: false,
  loginCsrfTokens: null,
};

function misc(state = initialState, action) {
  switch (action.type) {
    case types.GET_CONFIG.SUCCESS: {
      if (!action.payload) {
        return state;
      }
      const loginCsrfTokens =
        action.payload.loginCsrfTokens || state.loginCsrfTokens;

      return {
        ...state,
        loginCsrfTokens,
      };
    }
    case types.SHOULD_SKIP_PLAN_SELECT:
      return {
        ...state,
        shouldSkipPlanSelect: action.shouldSkip,
      };
    case types.LOCK_USER_OUT:
      return {
        ...state,
        lockedOut: action.lockedOut,
      };
    case types.RESELECT_PLAN:
      return {
        ...state,
        reselectingPlan: true,
      };
    case types.SHOULD_SHOW_PLAN_SELECT_MESSAGE:
      return {
        ...state,
        shouldShowPlanSelectMessage: action.shouldShow,
      };
    default:
      break;
  }
  return state;
}

export default misc;
