import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { PlanPropTypes } from './Plan';
import PlanContainer from '../containers/PlanContainer';
import ScrollingCarousel from './ScrollingCarousel';
import PlanGroupTabs from './PlanGroupTabs';
import { TAB_OPTIONS } from '../constants/plans';
import { getPlanDisplayTrial } from '../selectors/plan';

require('../styles/planGroup.scss');

const PlanGroup = ({
  plans,
  defaultPlan,
  title,
  hideTitle,
  onClick,
  isLiveOnly,
  planTabIndex,
  onTabSelect,
  showBundleTab,
}) => {
  const [showAllPlans, setShowAllPlans] = useState(false);
  const hasPricingDisclaimer =
    plans.find(plan => plan.pricingDisclaimer) !== undefined;

  // If a plan within the group has a discount and a trial, styling
  // must account for the additional text.
  const hasDiscountsAndTrials =
    plans.find(
      plan => plan.displayDiscount && getPlanDisplayTrial(plan, isLiveOnly)
    ) !== undefined;

  const hidePlans = defaultPlan && !showAllPlans;
  const renderPlan = plan => (
    <PlanContainer
      plan={plan}
      onClick={onClick}
      key={plan.identifier}
      hideTitle={hideTitle}
      title={title}
    />
  );

  return (
    <div
      className={classNames(
        'grouped-plan-select',
        (hidePlans || plans.length === 1) && 'highlighted-plan'
      )}
    >
      {showBundleTab && (
        <PlanGroupTabs
          onTabSelect={onTabSelect}
          planTabIndex={planTabIndex}
          tabOptions={TAB_OPTIONS}
        />
      )}
      <div
        className={classNames('plan-group', {
          'plan-group--plans-hidden': hidePlans,
          'plan-group--price-disclaimer': hasPricingDisclaimer,
        })}
      >
        <div
          className={classNames(
            'plan-group__body',
            `plan-group__${planTabIndex}`,
            {
              'plan-group__with-discounts-and-trials': hasDiscountsAndTrials,
            }
          )}
        >
          {hidePlans ? (
            <>
              {renderPlan(defaultPlan)}
              <a
                className="show-all-plans"
                onClick={() => setShowAllPlans(true)}
              >
                View additional plan options
              </a>
            </>
          ) : (
            <ScrollingCarousel>
              {plans.map(plan => renderPlan(plan))}
            </ScrollingCarousel>
          )}
        </div>
      </div>
    </div>
  );
};

PlanGroup.defaultProps = {
  title: 'Hulu',
};

PlanGroup.propTypes = {
  plans: PropTypes.arrayOf(PlanPropTypes).isRequired,
  defaultPlan: PlanPropTypes,
  onClick: PropTypes.func.isRequired,
  isLiveOnly: PropTypes.bool.isRequired,
  title: PropTypes.string,
  hideTitle: PropTypes.bool,
  planTabIndex: PropTypes.string,
  onTabSelect: PropTypes.func,
  showBundleTab: PropTypes.bool,
};

export default PlanGroup;
