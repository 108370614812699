import { useState } from 'react';
import { debounce } from 'lodash';

/**
 * maintain a toggle state (on/off)
 *
 * @param {boolean} [initialState=false] initial toggle state
 * @param {function(boolean)} [onToggleCallback=noop] callback on state change
 * @returns {[boolean, function]} toggle state and state change action
 */
export default function useToggle() {
  const [toggle, setToggle] = useState(false);

  const handleToggle = debounce(
    () => {
      setToggle(!toggle);
    },
    300,
    { leading: true, trailing: false }
  );

  return [toggle, handleToggle];
}
