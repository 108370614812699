import { stringify } from '../utils/querystring';
import { YOKOZUNA_API } from '../constants/apis';
import request from './request';

/**
 * Get a signup configuration object back from Yokozuna.
 *
 * @param {Object} [params] The parameters to pass for a config.
 * @param {String} [params.email] The user's email address.
 * @param {String} [params.from] The /start page the user came from.
 * @param {String} [params.promotionCode] A promotion the user is signing up with.
 * @param {String} [params.referralCode] A referral code from another user.
 * @param {String} [params.giftCode] A gift code that the user is trying to redeem.
 * @param {String} [params.promotionName] The name of the promotion the user wants.
 * @param {Number} [params.programId] The actual identifier for the program the user wants.
 */
export function fetchConfig(params = {}) {
  const query = {
    email: params.email,
    from: params.from,
    'device-code': params.deviceCode,
    'device-id': params.deviceId,
    'promotion-code': params.promotionCode,
    'referral-code': params.referralCode,
    'referral-type': params.referralType,
    'gift-code': params.giftCode,
    'promotion-name': params.promotionName,
    'program-id': params.programId,
    partner: params.partner,
    subpartner: params.subpartner,
    'roku-pac': params.rokuPac,
    'spotify-code': params.spotifyCode,
    'spotify-redirect': params.spotifyRedirect,
    device: params.device,
    'third-party-id': params.thirdPartyId,
    'third-party-product-id': params.thirdPartyProductIds,
  };

  return request(`${YOKOZUNA_API}config?${stringify(query)}`, {
    credentials: 'include',
  });
}
