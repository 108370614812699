import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReactMarkdown from 'react-markdown';

import Loading from '../components/Loading';
import Roadblock from '../components/Roadblock';

class SprintV2RoadblockPage extends Component {
  componentDidMount() {
    const { onLoaded } = this.props;
    if (onLoaded) {
      onLoaded();
    }
  }

  render() {
    if (this.props.isLoading) {
      return <Loading />;
    }

    const {
      text: { title, subtitle, button, link_text: linkText, link_url: linkUrl },
      handleClick,
    } = this.props;

    return (
      <Roadblock>
        <Roadblock.Title>
          <ReactMarkdown source={title} escapeHtml={false} />
          {subtitle &&
            []
              .concat(subtitle)
              .map((text, index) => (
                <Roadblock.Subtitle key={index}>{text}</Roadblock.Subtitle>
              ))}
          {linkText && linkUrl && (
            <Roadblock.Link url={linkUrl}>{linkText}</Roadblock.Link>
          )}
        </Roadblock.Title>
        {button && handleClick && (
          <Roadblock.Button handleClick={handleClick}>
            {button}
          </Roadblock.Button>
        )}
      </Roadblock>
    );
  }
}

SprintV2RoadblockPage.propTypes = {
  isLoading: PropTypes.bool,
  onLoaded: PropTypes.func,
  handleClick: PropTypes.func,
  text: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    button: PropTypes.string,
    link_text: PropTypes.string,
    link_url: PropTypes.string,
  }).isRequired,
};

export default SprintV2RoadblockPage;
