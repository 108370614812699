import { actions } from 'react-redux-form';
import isEmpty from 'lodash/isEmpty';

import { getSuperbundleEligibility } from './superbundleActions';
import { setSelectedTab } from './formActions';
import { getLedger } from './ledgerActions';
import { getPaypalToken, setPaypalRedirectUrls } from './paypalActions';
import { routePush, routeReplace, urlRedirect } from './routingActions';
import {
  login,
  processErrors,
  setPaymentToken,
  clearPrefillBillingInfo,
} from './serverActions';
import { createSubscriber, resetCreateSubscriber } from './subscriberActions';
import { handleVenmoPayment } from './venmoActions';
import { refreshSessionCookie } from '../api/Login';
import { noticeError } from '../api/NewRelic';
import { loadSDK, init, closeFlow, initXO, startFlow } from '../api/Paypal';
import { DEFAULT_ERROR } from '../constants/errorCodes';
import * as paymentMethods from '../constants/paymentMethods';
import * as routes from '../constants/routes';
import { ELIGIBLE } from '../constants/superbundleEligibilityStatuses';
import * as MetricsEvents from '../metrics/metricsEvents';
import { getBillingTabIndex } from '../selectors/billingPage';
import {
  getIs2PBundleFlow,
  getIsHuluSuperbundleFlow,
  getRedirectUrlQueryParam,
  getIsBundlePartnerFlow,
  getIsUnifiedActivationFlow,
} from '../selectors/flow';
import {
  getShouldRedirectToAccount,
  getShouldSkipLedger,
  getVermontPrepaidConsentText,
} from '../selectors/ledger';
import {
  getHasCheckedIneligibleDiscountConsent,
  getHasCheckedRecordingConsent,
  getHasCheckedVermontPrepaidConsent,
} from '../selectors/payment';
import { getPaymentType } from '../selectors/subscription';
import { getStatus } from '../selectors/superbundleEligibility';
import { getSelectedPlan } from '../selectors/user';
import { isAndroidDevice } from '../utils/deviceUtils';
import {
  completeSignupOnPlatform,
  isHuluPlatform,
} from '../utils/huluPlatformUtils';
import { handleSuccessfulSignupRedirect } from '../utils/url';
import { isUserAgeRestricted } from '../utils/ageValidationUtils';
import { isUserDSSSubscribed, isUserDSSRedirect } from '../utils/dssUtils';
import { url } from '../../../config/env';
import { CHANGE_PLAN_URL } from '../constants/misc';
import { handleSubscriptionErrors as handleBundlePartnerErrors } from './bundlePartnerActions';
import { deleteCartAbandonmentCookie } from '../utils/cartAbandonmentUtils';
import {
  getDPlusText,
  getIsHiSwitchFlow,
  getHiSwitchRedirectUrl,
} from '../selectors/siteconfig';
import { isCuriosityIncluded } from '../selectors/plan';
import { getIsIneligibleDiscount } from '../selectors/warning';
import { hasSelectedAddon } from '../selectors/addons';
import { getShouldCreateUser } from '../selectors/accountPage';
import { ESPN_PLUS_COMPONENT_ID } from '../constants/plans';
import { handleRedirectToUnifiedActivation } from './unifiedActions';

export const handleBillingPageLoaded = () => async (
  dispatch,
  getState,
  { trackEvent }
) => {
  if (getIsUnifiedActivationFlow(getState())) {
    // Redirect the user to CUP before any other ledger actions happen
    return dispatch(handleRedirectToUnifiedActivation());
  }

  // Reset to credit card for paypal cancels
  if (getPaymentType(getState()) === paymentMethods.PAYPAL) {
    dispatch(handlePaymentTypeChanged(paymentMethods.CREDITCARD));
  }
  if (getShouldRedirectToAccount(getState())) {
    return dispatch(urlRedirect(CHANGE_PLAN_URL));
  }
  if (!getSelectedPlan(getState())) {
    return dispatch(routeReplace(routes.PLAN_SELECT));
  }
  if (
    getIsHuluSuperbundleFlow(getState()) ||
    hasSelectedAddon(getState(), ESPN_PLUS_COMPONENT_ID) ||
    getIs2PBundleFlow(getState())
  ) {
    await dispatch(getSuperbundleEligibility());
    if (getStatus(getState()) !== ELIGIBLE) {
      trackEvent(MetricsEvents.SUPERBUNDLE_ELIGIBILITY_CHECK, getState());
      return dispatch(routeReplace(routes.SUPERBUNDLE_ROADBLOCK));
    }
  }
  trackEvent(MetricsEvents.SUBSCRIPTION_STEP_START, {
    title: 'SUF - Billing Info',
    pageId: 'billing',
  });
  switch (getPaymentType(getState())) {
    case paymentMethods.PAYPAL:
      dispatch(setSelectedTab(1));
      break;
    case paymentMethods.VENMO:
      dispatch(setSelectedTab(2));
      break;
    case paymentMethods.CREDITCARD:
    default:
      dispatch(setSelectedTab(0));
  }
  dispatch(actions.change('payment.hasCheckedRecordingConsent', true));
  return getShouldSkipLedger(getState())
    ? dispatch(handleBillingPageSubmit())
    : dispatch(getLedger());
};

export const handleBillingPageSubmit = () => (dispatch, getState) => {
  if (getPaymentType(getState()) === paymentMethods.PAYPAL) {
    return dispatch(handlePaypalSubmit());
  }
  return dispatch(handleDefaultSubmit());
};

export const handleDefaultSubmit = () => (
  dispatch,
  getState,
  { trackEvent, flushEvents }
) =>
  dispatch(handleVenmoPayment())
    .then(() => dispatch(createSubscriber()))
    .then(
      response => {
        // track end payment
        trackEvent(MetricsEvents.SUBSCRIPTION_END, { ...response });
        deleteCartAbandonmentCookie();
        if (isHuluPlatform()) {
          const {
            user: { email, password },
          } = getState();
          completeSignupOnPlatform(email, password);
        }
        /*
         * If a user was already created, then refreshing session cookies
         * is required. Otherwise, do a full login.
         */
        let authPromise;
        if (getShouldCreateUser(getState())) {
          authPromise = refreshSessionCookie(true).catch(err => {
            // Hoth session refresh failed. Log the error and proceed with redirect anyways
            noticeError(
              err ||
                'Hoth session refresh failed before finishing the signup flow.'
            );
          });
        } else {
          authPromise = dispatch(login(false)).catch(err => {
            // Hoth login call failed. Log the error and proceed with redirect anyways
            noticeError(
              err || 'Hoth login failed before finishing the signup flow.'
            );
          });
        }
        const metricsPromise = isAndroidDevice()
          ? // Android does not fire pixels, so we can short-circuit the delay
            Promise.resolve()
          : flushEvents();
        return Promise.all([authPromise, metricsPromise]).then(() => {
          trackEvent(MetricsEvents.USER_LOGIN, {
            auth_method: MetricsEvents.AUTH_METHOD.NEW_SUBSCRIPTION,
          });

          if (getRedirectUrlQueryParam(getState())) {
            dispatch(urlRedirect(getRedirectUrlQueryParam(getState())));
          } else {
            const redirectUrl = getIsHiSwitchFlow(getState())
              ? getHiSwitchRedirectUrl(getState())
              : response.redirectionUrl;
            handleSuccessfulSignupRedirect(redirectUrl);
          }
        });
      },
      ({ errors, plan, addons, addonBundles, user: responseUser }) => {
        const error = { ...DEFAULT_ERROR };
        if (!isEmpty(errors)) {
          if (isUserAgeRestricted(errors)) {
            dispatch(routePush(routes.AGE_ROADBLOCK));
            return;
          }
          if (isUserDSSSubscribed(errors) || isUserDSSRedirect(errors)) {
            dispatch(routePush(routes.SUPERBUNDLE_ROADBLOCK));
            return;
          }
          Object.assign(error, errors[0]);
        }
        trackEvent(MetricsEvents.SUBSCRIPTION_PAYMENT_ERROR, { error });

        // Handle DSS subscription errors
        if (getIsBundlePartnerFlow(getState())) {
          const { message, redirectText, redirectRoute } = getDPlusText(
            getState(),
            'subscriptionError'
          );
          dispatch(
            handleBundlePartnerErrors(message, redirectText, redirectRoute)
          );
          return;
        }

        if (getPaymentType(getState()) === paymentMethods.SPOTIFY) {
          dispatch(login(false));
          dispatch(
            processErrors(
              error,
              plan,
              addons,
              addonBundles,
              responseUser,
              routes.SPOTIFY_LANDING
            )
          );
        } else {
          // Optimistically attempt a login even though payment failed
          dispatch(login(true, url.onboardingPage));
          dispatch(
            processErrors(error, plan, addons, addonBundles, responseUser)
          );
        }
        dispatch(getLedger());
        return; // eslint-disable-line
      }
    );

export const handlePaypalSubmit = () => (
  dispatch,
  getState,
  { trackEvent }
) => {
  initXO();
  dispatch(setPaypalRedirectUrls());
  return dispatch(getPaypalToken())
    .then(token => {
      if (!token) {
        throw new Error(`No token returned for type ${paymentMethods.PAYPAL}`);
      }
      dispatch(setPaymentToken(paymentMethods.PAYPAL, token));
      return startFlow(token);
    })
    .catch(({ errors, plan, addons, addonBundles, user: responseUser }) => {
      closeFlow();
      const error = { ...DEFAULT_ERROR };
      if (!isEmpty(errors)) {
        Object.assign(error, errors[0]);
      }
      trackEvent(MetricsEvents.SUBSCRIPTION_PAYMENT_ERROR, { error });
      dispatch(login(true, url.onboardingPage));
      dispatch(processErrors(error, plan, addons, addonBundles, responseUser));
      dispatch(getLedger());
    });
};

export const handlePaymentTypeChanged = paymentType => (dispatch, getState) => {
  if (
    !(
      getPaymentType(getState()) === paymentMethods.USE_CURRENT &&
      paymentType === paymentMethods.CREDITCARD
    )
  ) {
    dispatch(actions.change('payment.paymentType', paymentType));
  }
};

export const handleBillingPageTabSelect = index => (dispatch, getState) => {
  if (getBillingTabIndex(getState()) === index) {
    return;
  }
  switch (index) {
    case 1:
      dispatch(handlePaymentTypeChanged(paymentMethods.PAYPAL));
      if (!document.getElementById('paypal-jssdk')) {
        loadSDK();
      }
      init({});
      break;
    case 2:
      dispatch(handlePaymentTypeChanged(paymentMethods.VENMO));
      break;
    case 0:
    default:
      dispatch(handlePaymentTypeChanged(paymentMethods.CREDITCARD));
  }
  dispatch(setSelectedTab(index));
  dispatch(resetCreateSubscriber());
  dispatch(clearPrefillBillingInfo());
  dispatch(actions.resetValidity('payment'));
  if (getIsIneligibleDiscount(getState())) {
    dispatch(
      actions.setErrors(
        'payment.hasCheckedIneligibleDiscountConsent',
        !getHasCheckedIneligibleDiscountConsent(getState())
      )
    );
  }
  if (isCuriosityIncluded(getSelectedPlan(getState()))) {
    dispatch(
      actions.setErrors(
        'payment.hasCheckedRecordingConsent',
        !getHasCheckedRecordingConsent(getState())
      )
    );
  }
  if (getVermontPrepaidConsentText(getState())) {
    dispatch(
      actions.setErrors(
        'payment.hasCheckedVermontPrepaidConsent',
        !getHasCheckedVermontPrepaidConsent(getState())
      )
    );
  }
};
