import request from './request';
import YOKOZUNA_API from '../constants/apis';
import { stringify } from '../utils/querystring';

/**
 * Endpoint to determine if user is in a list of specific cohorts.
 *
 * @param {Array<String>} [cohorts] The batch of cohorts to check enrollment against.
 */
export function getCohorts(cohorts = []) {
  const query = { cohorts };

  return request(`${YOKOZUNA_API}cohorts?${stringify(query)}`, {
    credentials: 'include',
  });
}

/**
 * Endpoint to enroll a user in a cohort given their identity is proven by a verification id.
 *
 * @param {Object} verificationRequest The request body containing an unverified verification id and requested cohort.
 */
export function postVerification(verificationRequest) {
  return request(`${YOKOZUNA_API}verifications`, {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(verificationRequest),
  });
}
