import { parse, stringify } from 'query-string';

import * as types from '../constants/actionTypes';
import { setCookie } from '../utils/cookieUtils';
import {
  SPOTIFY_LANDING,
  SPOTIFY_CALLBACK,
  SPRINTV2_LANDING,
  PARTNER_LANDING,
  TMOBILE_LANDING,
} from '../constants/routes';
import { SPOTIFY, SPRINTV2, PARTNERSHIP } from '../constants/partners';
import { BASIC_PLANS_TAB } from '../constants/plans';

export const initialState = {
  search: '',
  query: {
    partner: null,
    subpartner: null,
  },
  client: {},
  pathname: '',
  startPathname: '',
  partnerFlow: null,
  spotifyTokens: null,
  selectedPlanTabIndex: BASIC_PLANS_TAB,
  instrumentationFlowName: null,
};

function flow(state = initialState, action) {
  switch (action.type) {
    case types.SAVE_INITIAL_LOCATION: {
      const { location } = action;
      const query = parse(location.search);
      const newState = { ...state, ...location };

      if (newState.startPathname) {
        newState.onFirstLoad = false;
        newState.query = { ...state.query, ...query };
      } else {
        newState.onFirstLoad = true;
        newState.startPathname = newState.pathname;
        newState.query = { ...query };

        // Sets the hsrc cookie if present in query parameters.
        if (newState.query.hsrc) {
          setCookie('_hsrc', newState.query.hsrc, 0);
        }

        // Resets the partner parameters.
        newState.partnerFlow = null;
        let partner = newState.query.partner || 'hulu';
        let subpartner =
          newState.query.subpartner || newState.query.client_type || 'web';

        // For spotify flow initialization, manually set partner and subpartner.
        if (
          newState.pathname === SPOTIFY_LANDING ||
          newState.startPathname === SPOTIFY_LANDING ||
          newState.startPathname === SPOTIFY_CALLBACK
        ) {
          newState.startPathname = SPOTIFY_LANDING;
          newState.partnerFlow = SPOTIFY.FLOW;
          partner = SPOTIFY.PARTNER;
          subpartner = null;

          // For Spotify flow callback, manually set spotify-code and spotify-redirect.
          if (newState.query.code) {
            newState.query[SPOTIFY.PARAM_CODE] = newState.query.code;
            newState.query[SPOTIFY.PARAM_REDIRECT] = `${
              window.location.origin
            }${SPOTIFY.REDIRECT_URL}${
              newState.query.existing_user ? SPOTIFY.EXISTING_USER_PARAM : ''
            }`;
            state.spotifyTokens = null;
            delete newState.query.code;
          }
        }

        // For Sprint v2 flow, manually set partner.
        if (
          newState.pathname === SPRINTV2_LANDING ||
          newState.startPathname === SPRINTV2_LANDING ||
          newState.pathname === TMOBILE_LANDING ||
          newState.startPathname === TMOBILE_LANDING
        ) {
          newState.startPathname = SPRINTV2_LANDING;
          newState.partnerFlow = SPRINTV2.FLOW;
          partner = SPRINTV2.PARTNER;
          subpartner = null;
        }

        if (newState.pathname.includes(PARTNER_LANDING)) {
          newState.partnerFlow = PARTNERSHIP.FLOW;
        }

        newState.client = {
          partner,
          subpartner,
          device: newState.query.device,
        };
      }

      return newState;
    }
    case types.CLEAR_LOCATION_QUERY: {
      const { search, query } = initialState;
      return { ...state, search, query };
    }
    case types.REPLACE_LOCATION_QUERY: {
      return { ...state, query: action.query, search: stringify(action.query) };
    }
    case types.GET_SPOTIFY_ELIGIBILITY.SUCCESS: {
      return {
        ...state,
        spotifyTokens: action.payload.tokens,
        client: {
          partner: SPOTIFY.PARTNER,
          subpartner: action.payload.subpartner,
        },
      };
    }
    case types.GET_SPRINTV2_ELIGIBILITY.SUCCESS: {
      return {
        ...state,
        query: {
          ...state.query,
          subpartner: action.payload.subpartner,
        },
        client: {
          ...state.client,
          subpartner: action.payload.subpartner,
        },
      };
    }
    case types.GET_PARTNER_OFFER_CONTEXT.SUCCESS: {
      return {
        ...state,
        client: {
          ...state.client,
          partner: action.payload.partner,
          subpartner: action.payload.subpartner,
        },
      };
    }
    case types.GET_BUNDLE_PARTNER_PAYLOAD.SUCCESS: {
      return {
        ...state,
        client: {
          ...state.client,
          partner: action.payload.partner,
          subpartner: null,
        },
      };
    }
    case types.PLAN_TAB_SELECTED:
      return {
        ...state,
        selectedPlanTabIndex: action.tabIndex,
      };
    case types.SAVE_INSTRUMENTATION_FLOW_NAME:
      return {
        ...state,
        instrumentationFlowName: action.payload,
      };
    default:
      return state;
  }
}

export default flow;
