import React from 'react';

import Plan from './Plan';
import { BADGE_TYPES } from '../constants/badge';
import { isSashDisneyBundle } from '../selectors/plan';

import '../styles/bundleBadgesPlan.scss';

export const ADS_BUNDLE_BADGE_COPY = 'Best Value • Save 25%';

const BundleBadgesPlan = props => {
  const { plan } = props;
  let { badgeDescription, badgeType } = props;

  if (isSashDisneyBundle(plan.identifier)) {
    badgeDescription = ADS_BUNDLE_BADGE_COPY;
    badgeType = BADGE_TYPES.GRAY;
  }

  return (
    <Plan
      {...props}
      planClass="bundle-badges-plan"
      badgeDescription={badgeDescription}
      badgeType={badgeType}
    />
  );
};

BundleBadgesPlan.propTypes = Plan.propTypes;

export default BundleBadgesPlan;
