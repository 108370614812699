import get from 'lodash/get';
import snakeCase from 'lodash/snakeCase';

import {
  PLAN_TYPES,
  SUBSCRIPTION_ERRORS,
  HIT_PAYMENT_METHODS,
} from '../constants/metrics';
import { ERROR_CODES } from '../constants/errorCodes';
import { ONETRUST_HULU_CONSENT_GROUP } from '../constants/misc';
import * as PAYMENT_METHODS from '../constants/paymentMethods';
import * as routes from '../constants/routes';
import {
  hasSelectedAddon,
  wasOfferedAddonsOrAddonBundles,
} from '../selectors/addons';
import { getIsSashOnly, getIsCuriosityIncluded } from '../selectors/config';
import { getLedger, getLedgerInvoices } from '../selectors/ledger';
import { getPartnerOfferKey } from '../selectors/partners';
import {
  getInsufficientFundsMessage,
  getInvalidZipMessage,
} from '../selectors/siteconfig';
import { getPaymentType } from '../selectors/subscription';
import {
  getIsPartnerFlow,
  getClientSubpartner,
  getStartPathname,
  getIsRokuPacFlow,
  getInstrumentationFlowName,
  getIsBundlePartnerFlow,
  getIsMaxBundleFlow,
} from '../selectors/flow';
import { getCookie, ONETRUST_CONSENT } from './cookieUtils';
import { HEIMDALL_HUB_MAPPINGS } from '../constants/heimdallHubMappings';
import { ESPN_PLUS_COMPONENT_ID } from '../constants/plans';

const getSubpartner = state => {
  if (getIsPartnerFlow(state) || getIsBundlePartnerFlow(state)) {
    return 'web';
  }

  const subpartner = getClientSubpartner(state);
  if (subpartner) {
    return snakeCase(subpartner);
  }

  return '';
};

/**
 * If user came in through a start page, use that to determine planType.
 * Else, check the plans for sash_only or svod or default.
 * @param partner The partner.
 * @param config The config node from the store.
 * @param flow The page from which the user entered the signup flow.
 * @returns string The plan type.
 */
const getPlanType = state => {
  if (getIsRokuPacFlow(state)) {
    return 'pac';
  }

  if (wasOfferedAddonsOrAddonBundles(state)) {
    return PLAN_TYPES.HISUF;
  }

  const startPathname = getStartPathname(state).toLowerCase();

  switch (startPathname) {
    case routes.SPRINT_LANDING:
      return PLAN_TYPES.SPRINT;
    case routes.SPRINTV2_LANDING:
      return PLAN_TYPES.SPRINTV2;
    case routes.SPOTIFY_LANDING:
      return PLAN_TYPES.SPOTIFY;
    case routes.BUNDLE_PARTNER_ACTIVATE:
      return PLAN_TYPES.DISNEY_ACTIVATION;
    case routes.GO_SVOD:
      return PLAN_TYPES.SVOD;
    case routes.GO_ONE_HULU:
      return PLAN_TYPES.ONE_HULU;
    case routes.GO_LIVE_ONLY:
      return PLAN_TYPES.LIVE_ONLY;
    case routes.GO_SASH:
      return PLAN_TYPES.SASH_ONLY;
    case routes.GO_NOAH:
      return PLAN_TYPES.NOAH_ONLY;
    case routes.GO_CURIOSITY:
      return PLAN_TYPES.CURIOSITY;
    default:
      if (startPathname.startsWith(routes.PARTNER_LANDING)) {
        return getPartnerOfferKey(state);
      }

      if (getIsSashOnly(state)) {
        return PLAN_TYPES.SASH_ONLY;
      }

      if (!getIsCuriosityIncluded(state)) {
        return PLAN_TYPES.SVOD;
      }

      return PLAN_TYPES.DEFAULT;
  }
};

export const getTrialDuration = plan => {
  let trialDuration;

  if (plan && plan.trial && Number.isInteger(plan.trial.length)) {
    const trialLength = plan.trial.length;
    const trialUnit = plan.trial.unit;

    switch (trialUnit) {
      case 'day':
        trialDuration = String(trialLength);
        break;
      case 'week':
        trialDuration = String(trialLength * 7);
        break;
      case 'month':
        trialDuration = String(trialLength * 31);
        break;
      default:
        trialDuration = '0';
        break;
    }
  } else {
    trialDuration = '0';
  }

  return trialDuration;
};

export const getTrialPeriod = plan => {
  let trialPeriod;
  if (plan && plan.trial && Number.isInteger(plan.trial.length)) {
    trialPeriod = `${String(plan.trial.length)}-${plan.trial.unit}`;
  } else {
    trialPeriod = 'none';
  }

  return trialPeriod;
};

const USER_STATUSES = {
  newSubscriber: 'new-subscriber',
  existingSubscriber: 'existing-subscriber',
  classicSubscriber: 'classic-subscriber',
  anonymous: 'anonymous',
  available: 'available',
};

export const getCustomerType = (state, isNewSubscriber) => {
  let userStatus;

  if (state.config.user) {
    // But if the user status is not available, should be set to classic or existing.
    if (
      isNewSubscriber &&
      state.config.user.status === USER_STATUSES.available
    ) {
      userStatus = USER_STATUSES.newSubscriber;
    } else {
      switch (state.config.user.status) {
        case 'existing':
          userStatus = USER_STATUSES.classicSubscriber;
          break;
        case 'subscribed':
          userStatus = USER_STATUSES.existingSubscriber;
          break;
        default:
          userStatus = USER_STATUSES.anonymous;
          break;
      }
    }
  } else {
    userStatus = USER_STATUSES.anonymous;
  }

  return userStatus;
};

export const getOrientation = () => {
  const docEl = window.document.documentElement;
  return docEl.clientWidth > docEl.clientHeight ? 'landscape' : 'portrait';
};

export const getFlowName = state =>
  getInstrumentationFlowName(state) ||
  `suf_${getSubpartner(state)}_${getPlanType(state)}`;

export const getDriverPage = state => {
  const queryObj = get(state, 'flow.query', {});
  const queryParamsToFind = ['series', 'from'];
  const key =
    queryParamsToFind.find(queryParam => queryParam in queryObj) || '';

  let driverPageName = get(queryObj, key, document.referrer) || 'other';

  if (key === 'series') {
    driverPageName = `series=${driverPageName}`;
  }

  if (driverPageName.indexOf('?') >= 0) {
    driverPageName = driverPageName.substring(0, driverPageName.indexOf('?'));
  }

  return driverPageName.replace(/https*:\/\//, '');
};

export const getSubscriptionErrorReason = (state, { code, message }) => {
  if (code === ERROR_CODES.INVALID_PAYMENT_INFO) {
    // Look for Litle error messages
    if (getInsufficientFundsMessage(state) === message) {
      return SUBSCRIPTION_ERRORS.INSUFFICIENT_FUNDS;
    }

    if (getInvalidZipMessage(state) === message) {
      return SUBSCRIPTION_ERRORS.INVALID_ZIPCODE;
    }

    // Other payment errors should use payment_failure_bad_details
    return SUBSCRIPTION_ERRORS.PAYMENT_FAILURE_BAD_DETAILS;
  }

  // Default to application_error
  return SUBSCRIPTION_ERRORS.APPLICATION_ERROR;
};

export const getHITPaymentMethod = state => {
  switch (getPaymentType(state)) {
    case PAYMENT_METHODS.CREDITCARD:
    case PAYMENT_METHODS.USE_CURRENT:
      return HIT_PAYMENT_METHODS.CREDIT_CARD;
    case PAYMENT_METHODS.PAYPAL:
      return HIT_PAYMENT_METHODS.PAYPAL;
    case PAYMENT_METHODS.VENMO:
      return HIT_PAYMENT_METHODS.VENMO;
    case PAYMENT_METHODS.RPM:
      return HIT_PAYMENT_METHODS.RPM;
    case PAYMENT_METHODS.GIFT_CODE:
      return HIT_PAYMENT_METHODS.GIFTCARD;
    case PAYMENT_METHODS.SPOTIFY:
      return HIT_PAYMENT_METHODS.SPOTIFY;
    case PAYMENT_METHODS.SPRINTV2:
      return HIT_PAYMENT_METHODS.SPRINT;
    case PAYMENT_METHODS.NONE:
      return HIT_PAYMENT_METHODS.VIP;
    case PAYMENT_METHODS.PARTNER:
      return getPartnerOfferKey(state);
    default:
      // This should never happen as we always set the payment type
      return null;
  }
};

export const getPlanSelectPageId = (plans = []) => {
  const planIdentifiers = plans
    .map(plan => plan.identifier)
    .sort()
    .join('_')
    .toLowerCase();

  return `plans_${planIdentifiers}`;
};

const convertDollarsToCents = dollars => dollars * 100;

export const getLedgerInvoiceTotalAmountInCents = state =>
  convertDollarsToCents(
    parseFloat(get(getLedgerInvoices(state)[0], 'total.amount', 0))
  );

export const getPlanDurationInMonths = planFrequency => {
  const { length, unit } = planFrequency;

  switch (unit) {
    // the length field from Subplatform's response is documented to have Number type,
    // it's not necessarily an integer so we treat it as a float
    case 'year':
      return Math.floor(parseFloat(length) * 12);
    case 'month':
      return Math.floor(parseFloat(length));
    case 'week':
      return Math.floor(parseFloat(length) / 4);
    case 'day':
      return Math.floor(parseFloat(length) / 30);
    default:
      return null;
  }
};

export const getSelectedPlanDurationInMonths = state => {
  const planFrequency = get(
    getLedger(state),
    'subscription.plan.price.frequency',
    null
  );
  if (planFrequency !== null) {
    return getPlanDurationInMonths(planFrequency);
  }
  return null;
};

/**
 * Check if the user has opted into targeted advertising with OneTrust.
 *
 * If not, we should not collect some metrics such as sending Tealium events.
 */
export const isUserOptedIntoOneTrustConsentGroup = () => {
  // first, check the OneTrust active groups window object if available
  if (
    window.OnetrustActiveGroups &&
    typeof window.OnetrustActiveGroups === 'string'
  ) {
    return window.OnetrustActiveGroups.includes(ONETRUST_HULU_CONSENT_GROUP);
  }

  // then, check a field inside the OneTrust consent cookie
  const oneTrustConsentCookieString = getCookie(ONETRUST_CONSENT);

  if (oneTrustConsentCookieString) {
    const consentGroups = oneTrustConsentCookieString
      .split('&groups=')[1]
      .split(';')[0]
      .split(',')
      .map(group => group.split(':'));

    return Boolean(
      consentGroups.find(
        group => group[0] === ONETRUST_HULU_CONSENT_GROUP && group[1] === '1'
      )
    );
  }

  // treat the user as opted-in if neither value is available.
  // for example, when OneTrust is not enabled in the current environment
  return true;
};

export const getCurrPageUri = (state, pathname) => {
  const heimdallHubId = HEIMDALL_HUB_MAPPINGS[pathname];

  if (heimdallHubId) {
    return heimdallHubId;
  }

  const isSuperBundleRoadblock = pathname === routes.SUPERBUNDLE_ROADBLOCK;

  if (isSuperBundleRoadblock) {
    if (getIsMaxBundleFlow(state)) {
      return 'app:superbundle-roadblock:max-redirect';
    }

    if (hasSelectedAddon(state, ESPN_PLUS_COMPONENT_ID)) {
      return 'app:superbundle-roadblock:disney-redirect';
    }

    return 'app:superbundle-roadblock';
  }

  return '';
};
