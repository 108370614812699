import React from 'react';

const SheerIDLogo = () => (
  <img
    className="sheerid-logo"
    src="/static/images/hulu+sheer-dark.svg"
    alt="Hulu and SheerID logos"
  />
);

export default SheerIDLogo;
