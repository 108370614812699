import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Panel from './Panel';

require('../styles/planOptions.scss');

const PlanOption = ({
  planOptionOffer,
  planTrialDuration,
  pricingEvolution,
  savingDetail,
  onClick,
  isSelected,
  id,
  onTermsApplyClick,
}) => {
  const [isHover, setHover] = useState(false);
  const [hoverEnabled, setHoverEnabled] = useState(true);
  const optionClass = classNames('x-large prepaid', {
    selected: isSelected,
    hovered: hoverEnabled && !isSelected && isHover,
  });

  const hoverHandler = isOnHover => {
    if (hoverEnabled) {
      setHover(isOnHover);
    }
  };

  const touchHandler = () => {
    setHoverEnabled(false);
    setHover(false);
  };

  const htmlId = `plan-option-${id}`;
  return (
    <li>
      <input
        type="radio"
        name="prepaid-plan-option"
        value={id}
        id={htmlId}
        onClick={onClick}
        tabIndex="0"
        aria-label={`Select ${planOptionOffer} for ${pricingEvolution}`}
      />
      <label
        htmlFor={htmlId}
        className="prepaid__option"
        onMouseOver={() => hoverHandler(true)}
        onMouseOut={() => hoverHandler(false)}
        onTouchStart={touchHandler}
      >
        <Panel className={optionClass} testid={`prepaid-plan-${id}`}>
          <div className="prepaid__details">
            <div className="prepaid__offer">{planOptionOffer}</div>
            {planTrialDuration && (
              <div className="prepaid__trial">{planTrialDuration}</div>
            )}
            <div className="prepaid__price">{pricingEvolution}</div>
          </div>
          {savingDetail && <div className="prepaid__save">{savingDetail}</div>}
          {onTermsApplyClick && (
            <a
              role="button"
              tabIndex="0"
              className="prepaid__terms"
              onClick={onTermsApplyClick}
            >
              Terms Apply
            </a>
          )}
        </Panel>
      </label>
    </li>
  );
};

PlanOption.propTypes = {
  planOptionOffer: PropTypes.string.isRequired,
  planTrialDuration: PropTypes.string.isRequired,
  pricingEvolution: PropTypes.string.isRequired,
  savingDetail: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  id: PropTypes.string,
  onTermsApplyClick: PropTypes.func,
};

export default PlanOption;
