import get from 'lodash/get';
import { createSelector } from 'reselect';

const getBundlePartner = state => get(state, 'bundlePartner');

export const getToken = createSelector(getBundlePartner, bundlePartner =>
  get(bundlePartner, 'token')
);

export const getPayload = createSelector(getBundlePartner, bundlePartner =>
  get(bundlePartner, 'response.payload')
);

export const getIsLoading = createSelector(
  getBundlePartner,
  bundlePartner => get(bundlePartner, 'response.error.errors[0]') == null
);
