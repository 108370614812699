import * as types from '../constants/actionTypes';
import { ERROR } from '../constants/modals';
import { ERROR_CODES } from '../constants/errorCodes';

const initialState = {};

export default function modal(state = initialState, action) {
  switch (action.type) {
    case types.CHANGE_MODAL:
      return action.modal;
    case types.PROCESS_PAYMENT_ERRORS:
      if (
        action.error.code === ERROR_CODES.INELIGIBLE_PROMOTION ||
        action.error.code === ERROR_CODES.INELIGIBLE_DISCOUNT
      ) {
        return {};
      }
      return {
        type: ERROR,
        preventClose: action.preventClose,
        message: action.error.message,
        redirectText: action.redirectText,
        redirectRoute: action.redirectRoute,
      };
    default:
      return state;
  }
}
