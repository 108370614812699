import get from 'lodash/get';
import { createSelector } from 'reselect';
import { isParentalConsentNeeded } from '../utils/ageValidationUtils';
import { isMaxBundle, isDuoBundle } from './plan';

// takes in separate year, month and day args to output an ISO date string
const formatDate = ({ year, month, day }) => {
  return `${year}-${month < 10 ? `0${month}` : month}-${
    day < 10 ? `0${day}` : day
  }`;
};

export const getUser = state => state.user;
export const getConfigUser = state => state.config.user;
export const getIsUserSashOnly = state => state.user.sashOnly;
export const getIsUserHuluBilled = state => state.user.customerType === 'HULU';
export const getIsUserCancelPending = state => state.user.cancelPending;
export const getSelectedPlan = state => get(state, 'user.selectedPlan');
export const getIsBundleChecked = (state, plan) =>
  get(state, `user.plan-${plan.id}-bundle`, false);
export const getFirstName = state => state.user.firstName;
export const getEmail = state => state.user.email;

export const getIsPrepaidForSelectedPlan = createSelector(
  [getSelectedPlan],
  selectedPlan => get(selectedPlan, 'isPrepaid', false)
);

export const getIsLoggedIn = createSelector([getUser], user =>
  get(user, 'loggedIn', false)
);

export const getIsActiveSubscriber = createSelector(
  [getUser],
  user => get(user, 'status', null) === 'subscribed'
);

export const getIsLoggedInActiveSubscriber = createSelector(
  [getIsLoggedIn, getIsActiveSubscriber],
  (isLoggedIn, isActiveSubscriber) => isLoggedIn && isActiveSubscriber
);

export const getIsExisting = createSelector(
  getUser,
  user => get(user, 'status') === 'existing'
);

// deprecated - use selectors/partners/getPartnerRoadblockEligibility instead
export const getIsSwitchBillingEligible = createSelector(
  [getIsUserSashOnly, getIsUserHuluBilled, getIsUserCancelPending],
  (sashOnly, huluBilled, isCancelPending) =>
    (sashOnly && huluBilled) || isCancelPending
);

/**
 * Check if the user is purchasing or activating a Disney superbundle (which includes Disney+, Hulu and ESPN+).
 *
 * @param {Object} state the redux state tree.
 * @returns {Boolean} whether or not the flow is a superbundle flow.
 */
export const getIsSuperbundleFlow = createSelector(
  getSelectedPlan,
  selectedPlan => get(selectedPlan, 'isDisneySuperBundle', false)
);

/**
 * Checks if plan is Max Bundle
 * @returns {boolean} True if is Max Bundle
 */
export const getIsMaxBundleFlow = createSelector(
  getSelectedPlan,
  selectedPlan => isMaxBundle(get(selectedPlan, 'identifier', []))
);

/**
 * Checks if the plan is a Duo Bundle
 * @returns {boolean} True if it is a Duo Bundle
 */
export const getIsDuoBundleFlow = createSelector(
  getSelectedPlan,
  selectedPlan => isDuoBundle(get(selectedPlan, 'identifier', ''))
);

export const getUserNode = createSelector(
  getUser,
  ({ email, password, firstName, zip, parentalConsent, gender, birthday }) => {
    let birthdayStr;

    if (typeof birthday === 'string') {
      birthdayStr = birthday;
    }

    // If the birthday object exists, convert it to a formatted date string.
    else if (birthday && birthday.year && birthday.month && birthday.day) {
      birthdayStr = formatDate(birthday);
    }

    return {
      email,
      password,
      firstName,
      zip,
      parentalConsent,
      gender,
      birthday: birthdayStr,
    };
  }
);

export const getIsUserAgeRestricted = createSelector(getUser, ({ birthday }) =>
  isParentalConsentNeeded(birthday)
);

export const getUserCreationRecaptchaToken = createSelector(
  getUser,
  ({ userCreationRecaptchaToken }) => userCreationRecaptchaToken
);

/**
 * Get the error message returned from the User Creation APIs if the
 * user password doesn't satisfy the stronger password strength requirements
 * launched with the Unified Identity project.
 */
export const getUserCreationPasswordCheckError = createSelector(
  getUser,
  ({ userCreationPasswordCheckError }) => userCreationPasswordCheckError
);
