import { stringify } from '../utils/querystring';
import { HOTH_API, SITE_API, LOGOUT_API } from '../constants/apis';
import { RECAPTCHA_INVISIBLE } from '../constants/recaptcha';
import request from './request';

export function login(email, password, csrfValue, recaptchaToken) {
  const url = `${HOTH_API}/v2/web/password/authenticate`;
  const recaptchaParams = {
    rrventerprise: recaptchaToken,
    use_enterprise_version: true,
    recaptcha_type: RECAPTCHA_INVISIBLE,
  };
  let query = {
    csrf: csrfValue,
    user_email: email,
    password,
  };
  query = {
    ...query,
    ...(recaptchaToken ? recaptchaParams : { rrventerprise: '' }),
    ...{ scenario: 'signup_login' },
  };
  const headers = new Headers({
    'Content-Type': 'application/x-www-form-urlencoded',
  });

  return request(url, {
    method: 'POST',
    credentials: 'include',
    body: stringify(query),
    headers,
  });
}

/**
 * Makes a call to Web-Utilities' Logout endpoint to clear session cookies.
 * See: https://github.prod.hulu.com/WebTeam/web-utilities
 */
export function logout() {
  return fetch(LOGOUT_API, {
    credentials: 'include',
  });
}

export function refreshSessionCookie(forceRefresh = false) {
  const url = `${HOTH_API}/v2/web/device_token/authenticate`;

  const query = {};
  if (forceRefresh) {
    query.force_refresh = true;
  }

  const headers = new Headers({
    'Content-Type': 'application/x-www-form-urlencoded',
  });
  return request(url, {
    method: 'POST',
    credentials: 'include',
    body: stringify(query),
    headers,
  });
}

export function refreshCookies() {
  const url = `${SITE_API}/api/2.0/refresh_cookies`;
  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  });
}
