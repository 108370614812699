import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loading from '../components/Loading';
import {
  handleBundlePartnerEligibility,
  handleBundlePartnerEligibilityClick,
} from '../actions/bundlePartnerActions';
import MarkdownRoadblock from '../components/MarkdownRoadblock';
import { getIsLoading } from '../selectors/bundlePartner';
import { getDPlusText } from '../selectors/siteconfig';

export const BundlePartnerActivationPage = props => {
  useEffect(() => {
    props.handleBundlePartnerEligibility();
  }, []);

  const {
    isLoading,
    buttonConfig,
    handleBundlePartnerEligibilityClick: handleClick,
  } = props;

  if (isLoading) {
    return <Loading />;
  }

  return <MarkdownRoadblock {...buttonConfig} handleClick={handleClick} />;
};

BundlePartnerActivationPage.propTypes = {
  handleBundlePartnerEligibility: PropTypes.func.isRequired,
  handleBundlePartnerEligibilityClick: PropTypes.func.isRequired,
  buttonConfig: PropTypes.shape({
    markdown: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
  }),
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isLoading: getIsLoading(state),
  buttonConfig: getDPlusText(state, 'roadblock.invalidOffer'),
});

const mapDispatchToProps = {
  handleBundlePartnerEligibility,
  handleBundlePartnerEligibilityClick,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BundlePartnerActivationPage);
