import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { isPrepaid, isCuriosityIncluded } from '../selectors/plan';

/* If state.config.user is equal to parameter "user", we can default the configUser parameter. */
export const getMissingUserFields = (state, updatedUserNode) => {
  /* The account page form updates state.user in live time, so some fields
  should be checked using state.config.user if we want to know whether the field
  was missing initially. Other fields like 'legalZip' only exists in state.user, hence
  the separation between 'fieldsToCheckInUser' and 'fieldsToCheckInConfigUser'. */

  const {
    user: { selectedPlan },
    config,
  } = state;
  const user = updatedUserNode || state.user;
  const configUser = config.user || user;
  const fieldsToCheckInConfigUser = ['firstName', 'birthday', 'gender'];
  const fieldsToCheckInUser = [];

  // TODO: Include billingZip in missing field check for non-Curiosity giftcode/rpm cases.
  if (isCuriosityIncluded(selectedPlan)) {
    fieldsToCheckInUser.push('zip');
  } else if (isPrepaid(selectedPlan)) {
    fieldsToCheckInUser.push('legalZip');
  }

  return fieldsToCheckInConfigUser
    .filter(field => isEmpty(get(configUser, field)))
    .concat(fieldsToCheckInUser.filter(field => isEmpty(get(user, field))));
};
