import { createAction } from 'redux-starter-kit';
import get from 'lodash/get';
import { routeReplace, urlRedirect } from './routingActions';
import { url } from '../../../config/env';
import { GET_BUNDLE_PARTNER_SWITCH_ELIGIBILITY } from '../constants/actionTypes';
import { SWITCH_ELIGIBILITY } from '../constants/bundlePartnerSwitchEligibilityStatuses';
import { getBundlePartnerSwitchEligibilityTextForReason } from '../selectors/bundlePartnerSwitchEligibility';
import { getPayload, getToken } from '../selectors/bundlePartner';
import request from '../api/request';
import { YOKOZUNA_API } from '../constants/apis';
import { stringify } from '../utils/querystring';
import { BILLING_INFO } from '../constants/routes';
import * as MetricsEvents from '../metrics/metricsEvents';

export const getBundlePartnerSwitchEligibilityStart = createAction(
  GET_BUNDLE_PARTNER_SWITCH_ELIGIBILITY.START
);
export const getBundlePartnerSwitchEligibilitySuccess = createAction(
  GET_BUNDLE_PARTNER_SWITCH_ELIGIBILITY.SUCCESS
);
export const getBundlePartnerSwitchEligibilityFailure = createAction(
  GET_BUNDLE_PARTNER_SWITCH_ELIGIBILITY.FAILURE
);

export function handleBundlePartnerSwitchEligibility() {
  return async (dispatch, getState, { trackEvent }) => {
    dispatch(getBundlePartnerSwitchEligibilityStart());
    try {
      const token = getToken(getState());
      const response = await request(
        `${YOKOZUNA_API}bundle-partners/switch?${stringify({ token })}`,
        { credentials: 'include' }
      );
      if (response.status === SWITCH_ELIGIBILITY.SWITCH) {
        dispatch(
          urlRedirect(url.activate, {
            partner: getPayload(getState()).partner,
          })
        );
      } else if (response.status === SWITCH_ELIGIBILITY.MERGE) {
        dispatch(routeReplace(BILLING_INFO));
      } else if (response.status === SWITCH_ELIGIBILITY.ALREADY_ACTIVATED) {
        dispatch(urlRedirect(url.site));
      } else {
        dispatch(getBundlePartnerSwitchEligibilitySuccess(response));
        trackEvent(MetricsEvents.BUNDLE_PARTNER_SWITCH_INELIGIBLE);
      }
    } catch (error) {
      dispatch(getBundlePartnerSwitchEligibilityFailure(error));
    }
  };
}

export const handleBundlePartnerSwitchEligibilityClick = () => {
  return (dispatch, getState) => {
    const redirectUrl = get(
      getBundlePartnerSwitchEligibilityTextForReason(getState()),
      'redirectUrl'
    );
    return dispatch(urlRedirect(redirectUrl));
  };
};
