export const CreateAccountAppRouter = history => ({
  /**
   * Retrieve the pathname based on values obtained from the current location.
   * @returns a string containing the pathname.
   */
  getPathname() {
    return history.location.pathname;
  },

  /**
   * Push a new route to the navigation stack
   * @param {String} path
   */
  pushRoute(path) {
    history.push(path);
  },

  /**
   * Set up a callback function to be executed before the user navigates through session history using the browser's forward or backward buttons.
   * @param {Function} callback
   */
  beforePopRoute(callback) {
    const unblock = history.block((_, action) => {
      // Navigation stays unblocked if the action to be executed is not equal to 'POP'
      if (action !== 'POP') {
        // Unblock the navigation.
        return unblock();
      }

      const response = callback();

      // Navigation remains unblocked if the returned value of this callback is `true`.
      if (response === true) {
        return unblock();
      }

      return false;
    });
  },

  /**
   * Construct the app router used by the Edna Create Account flow for the web-login-ui.
   * @returns a routing object whose format is predefined in the web-login-ui.
   */
  build() {
    return {
      beforePopRoute: this.beforePopRoute,

      getLocalPathname: this.getPathname,

      pushLocalRoute: this.pushRoute,
    };
  },
});

const LOGIN_BASE_PATH = '/login';

export const LoginAppRouter = () => ({
  beforePopRoute: callback => window.addEventListener('popstate', callback),

  getLocalPathname: () => {
    const localPathname = window.location.pathname.split('/').at(-1);

    // If the user is currently on a sub-path in the login flow: Ex. /login/enter-email
    if (localPathname !== LOGIN_BASE_PATH.split('/').at(-1)) {
      return `/${localPathname}`;
    }
    return '/';
  },

  pushLocalRoute: route => {
    // Remove the trailing slash in the route argument if it exists
    const slug = route.startsWith('/') ? route.slice(1) : route;

    const targetRoute = `${LOGIN_BASE_PATH}/${slug}`;

    window.history.pushState(
      {
        url: targetRoute,
        slug,
      },
      '',
      targetRoute
    );
  },

  /**
   * Construct the app router used by the Edna Login flow from the web-login-ui package.
   * @returns a routing object whose format is predefined in the web-login-ui.
   */
  build() {
    return {
      beforePopRoute: this.beforePopRoute,

      getLocalPathname: this.getLocalPathname,

      pushLocalRoute: this.pushLocalRoute,
    };
  },
});
