import React from 'react';
import PropTypes from 'prop-types';

import { ACCOUNT_INFO, BILLING_INFO } from '../constants/routes';
import { FastPassAccount } from './pages/FastPassAccount';
import { FastPassBilling } from './pages/FastPassBilling';

require('./FastPass.scss');

let ChildComponent;

export const FastPass = props => {
  const {
    location: { pathname },
  } = props;

  const isEnabled = ['development', 'staging', 'test'].includes(
    process.env.NODE_ENV
  );

  const renderDashboard = () => (
    <div className="testing-dashboard__container">
      {pathname.startsWith(ACCOUNT_INFO) && <FastPassAccount />}
      {pathname === BILLING_INFO && <FastPassBilling />}
    </div>
  );

  return (
    <>
      {isEnabled && renderDashboard()}
      <ChildComponent {...props} />
    </>
  );
};

FastPass.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

export const withFastPass = Component => {
  ChildComponent = Component;
  return FastPass;
};
