import { YOKOZUNA_API, YOKOZUNA_V3_API } from '../constants/apis';
import request from './request';

import { stringify } from '../utils/querystring';

export default function getAccountStatus(email, isUnifiedIdentityOn) {
  const trimEmail = email.trim();
  const yokozunaApi = isUnifiedIdentityOn ? YOKOZUNA_V3_API : YOKOZUNA_API;
  return trimEmail
    ? request(`${yokozunaApi}accounts/status?${stringify({ email })}`, {
        method: 'GET',
        credentials: 'include',
      })
    : Promise.resolve({ status: 'invalid' });
}
