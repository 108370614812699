import PropTypes from 'prop-types';
import React from 'react';

import * as validators from '../../constants/validators';
import InputError from '../InputError';
import { ERROR_TEXT } from '../../constants/errorText';
import NumberField from '../../containers/NumberFieldContainer';
import HomeZipError from '../../containers/HomeZipErrorWrappers';

const ZipCodeTooltipWrapper = () => (
  <p>
    We use your home zip code to personalize your experience with your local
    programming.
  </p>
);

export const ZipCodeFields = ({
  displayBillingZip,
  displayPlaceHolder,
  includesLive,
  isPrepaid,
}) => {
  const maxLength = '5';

  // The underlying conditions decide whether or not to show each individual zip code input fields.
  const showHomeZipCodeField = includesLive;
  const showBillingZipCodeField = !includesLive && displayBillingZip;
  const showLegalZipCodeField = !includesLive && isPrepaid;

  const zipCodeFields = [
    {
      displayName: 'HOME ZIP CODE',
      errorWrapper: HomeZipError,
      key: 'homezip',
      model: 'zip',
      name: 'homeZipCodeField',
      placeHolder: 'Home Zip Code',
      shouldRender: showHomeZipCodeField,
      tooltip: {
        className: 'homezip',
        wrapper: <ZipCodeTooltipWrapper />,
      },
    },
    {
      displayName: 'ZIP CODE',
      key: 'billingzip',
      model: 'billingZip',
      name: 'billingZipCodeField',
      placeHolder: 'Zip Code',
      shouldRender: showBillingZipCodeField,
    },
    {
      displayName: 'ZIP CODE',
      key: 'legalZip',
      model: 'legalZip',
      name: 'legalZipCodeField',
      placeHolder: 'Zip Code',
      shouldRender: showLegalZipCodeField,
    },
  ];

  const field = zipCodeFields.find(item => item.shouldRender);

  return field ? (
    <NumberField
      displayName={field.displayName}
      placeHolder={displayPlaceHolder ? field.placeHolder : null}
      form="user"
      model={field.model}
      maxLength={maxLength}
      tooltip={field.tooltip?.wrapper}
      tooltipClassName={field.tooltip?.className}
      validators={{ invalid: validators.ZIP }}
      messages={{
        invalid: <InputError message={ERROR_TEXT.VALID_ZIP_CODE} />,
      }}
      errorWrapper={field.errorWrapper}
      key={field.key}
    />
  ) : null;
};

ZipCodeFields.propTypes = {
  displayBillingZip: PropTypes.bool,
  displayPlaceHolder: PropTypes.bool,
  includesLive: PropTypes.bool,
  isPrepaid: PropTypes.bool,
};

ZipCodeFields.defaultProps = {
  displayBillingZip: false,
  displayPlaceHolder: false,
  includesLive: false,
  isPrepaid: false,
};
