import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import metricsManagerShape from './metricsManagerShape';

export const MetricsManagerContext = createContext();

class MetricsManagerProvider extends Component {
  render() {
    const { store, metricsManager, children } = this.props;
    metricsManager.setStore(store);
    const { Provider } = MetricsManagerContext;
    return <Provider value={metricsManager}>{children}</Provider>;
  }
}

MetricsManagerProvider.propTypes = {
  store: PropTypes.object.isRequired,
  metricsManager: metricsManagerShape.isRequired,
  children: PropTypes.element.isRequired,
};

export default MetricsManagerProvider;
