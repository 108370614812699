import * as envVars from '../../../config/env';

export const SPOTIFY = {
  FLOW: 'SPOTIFY',
  PARTNER: 'spotify',
  CLIENT_ID: envVars.spotify.clientId,
  HOME_URL: 'https://www.spotify.com',
  MANAGE_HULU_URL: 'https://www.spotify.com/us/manage-hulu-subscription/',
  API_URL: 'https://accounts.spotify.com',
  AUTH_OPERATION: '/authorize',
  ME_OPERATION: '/me',
  SCOPE: 'user-read-private user-read-email user-read-birthdate',
  REDIRECT_URL: '/spotify-callback',
  EXISTING_USER_PARAM: '?existing_user=true',
  PARAM_CODE: 'spotify_code',
  PARAM_REDIRECT: 'spotify_redirect',
  TEST_MODE_ALLOWED: envVars.spotify.testModeAllowed,
  STATUS: {
    INELIGIBLE: 'INELIGIBLE',
    PENDING_ACTIVE: 'PENDING_ACTIVE',
  },
  RESPONSE_STATUS: {
    SUCCESS: 'success',
    SERVICE_ERROR: 'service_error',
    AUTHENTICATION_FAILURE: 'authentication_failure',
    USER_UNAUTHORIZED: 'user_unauthorized',
    ALREADY_SUBSCRIBED: 'already_subscribed',
    INELIGIBLE_PROMOTION: 'ineligible_promotion',
    CUSTOMER_NOT_FOUND: 'customer_not_found',
  },
};

export const SPRINTV2 = {
  FLOW: 'SPRINTV2',
  PAYLOAD_PARAM: 'request',
  OFFER_TERMS: 'https://www.sprint.com/en/legal/hulu-terms.html',
  FAQ_URL: 'https://www.sprint.com/hulu',
  HULU_HELP: 'https://help.hulu.com/en-us/add-sprint',
  PARTNER: 'sprint',
  SWITCH_BILLING_INELIGIBLE: 'ineligible',
  ELIGIBILITY_STATUS: {
    BAD_REQUEST: 'bad_request',
    ALREADY_SUBSCRIBED: 'already_subscribed',
    SERVICE_ERROR: 'service_error',
    HULU_INELIGIBLE: 'hulu_ineligible',
    THIRD_PARTY_INELIGIBLE: 'third_party_ineligible',
  },
};

export const TMOBILE = {
  FAQ_URL:
    'https://www.t-mobile.com/account/odf/DataService:ALL/Service:ALL/DataPass:ALL',
};

export const AMAZON = {
  PARTNER: 'amazon',
  SUBPARTNER: {
    AMAZON_ECHO_SHOW: 'amazon echo show',
  },
};

export const GOOGLE_ANDROID = {
  PARTNER: 'google',
  SUBPARTNER: 'android',
};

export const PARTNERSHIP = {
  FLOW: 'partnership',
  OFFER_ID_PARAM: 'partner_offer_id',
  OFFER_ERRORS: {
    INVALID_OFFER: 'invalidOffer',
    ALREADY_REDEEMED: 'alreadyRedeemed',
    RETRY: 'retry',
  },
  SWITCH_ELIGIBILITY: {
    HULU_INELIGIBLE: 'huluIneligible',
    THIRD_PARTY_INELIGIBLE: 'thirdPartyIneligible',
  },
};

export const BUNDLE_PARTNER = {
  FLOW: 'bundle-partner',
  TOKEN_PARAM: 'token',
  PARTNER: {
    VERIZON_VIA_DSS: 'verizonviadss',
  },
};
