import { connect } from 'react-redux';
import { urlRedirect } from '../actions/routingActions';
import { SPRINTV2, TMOBILE } from '../constants/partners';
import SprintSpotifyRoadblockPage from '../pages/SprintSpotifyRoadblockPage';
import { getSprintErrorText } from '../selectors/sprintV2';
import { getIsTMobileFeatureOn } from '../selectors/featureFlag';

// TODO: Productionalize t-mobile flag
const mapStateToProps = state => ({
  roadblockText: getSprintErrorText(state),
  isTMobileFeatureOn: getIsTMobileFeatureOn(state),
});

const mapDispatchToProps = dispatch => ({
  onAccept: () => dispatch(urlRedirect(SPRINTV2.FAQ_URL)),
  onAcceptTMobile: () => dispatch(urlRedirect(TMOBILE.FAQ_URL)),
});

const mergeProps = (
  { roadblockText, isTMobileFeatureOn },
  { onAccept, onAcceptTMobile }
) => ({
  roadblockText,
  isTMobileFeatureOn,
  onAccept,
  onAcceptTMobile,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(SprintSpotifyRoadblockPage);
