import * as types from '../constants/actionTypes';

export const initialState = {
  verified: false,
  loading: false,
  error: null,
};

export default function identity(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_IDENTITY_VERIFICATION.START:
      return {
        ...state,
        loading: true,
      };
    case types.CREATE_IDENTITY_VERIFICATION.SUCCESS:
      return {
        ...state,
        loading: false,
        verified: true,
      };
    case types.CREATE_IDENTITY_VERIFICATION.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
