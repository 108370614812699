import get from 'lodash/get';

import * as MetricsEvents from './metricsEvents';
import { getFlowName, getTrialPeriod } from '../utils/metricsUtils';

export class AndroidMetricsTracker {
  constructor() {
    this.conversionEventDelay = 3000;

    this.trackEvent = this.trackEvent.bind(this);
  }

  getEventList() {
    return [
      MetricsEvents.SUBSCRIPTION_START,
      MetricsEvents.SUBSCRIPTION_END,
      MetricsEvents.SUBSCRIPTION_PAYMENT_ERROR,
      MetricsEvents.SUBSCRIPTION_PLAN_SELECT,
      MetricsEvents.SUBSCRIPTION_STEP_START,
    ];
  }

  /**
   * Track an event by sending it to Vortex via the Android platform link.
   *
   * Specs:
   * - https://docs.google.com/document/d/12MygrJE6-Sup1-0IU_AVZX6MpYfEXWCBXijjxsu-fSo
   * - https://wiki.hulu.com/display/RAD/Subscription+Instrumentation
   *
   * @param {string} event The name of the event to track.
   * @param {object} state The redux store's current state.
   * @param {object} options Any extra options provided by the callee.
   */
  trackEvent(eventName, state, options) {
    const properties = {
      flow_name: getFlowName(state),
      hit_version: '1.0.0',
    };

    switch (eventName) {
      case MetricsEvents.SUBSCRIPTION_START:
        break;
      case MetricsEvents.SUBSCRIPTION_STEP_START:
        properties.screen_name = options.title;
        break;
      case MetricsEvents.SUBSCRIPTION_PLAN_SELECT:
        properties.selected_bundle_id = AndroidMetricsTracker.stringifyId(
          get(state, 'user.selectedPlan.id')
        );
        properties.selected_bundle_name = get(
          state,
          'user.selectedPlan.identifier'
        );
        properties.selected_program_id = AndroidMetricsTracker.stringifyId(
          get(state, 'user.selectedPlan.subscription.promotion.programId')
        );
        break;
      case MetricsEvents.SUBSCRIPTION_END:
        properties.outcome = 'new_subscription';
        properties.selected_bundle_id = AndroidMetricsTracker.stringifyId(
          get(state, 'user.selectedPlan.id')
        );
        properties.selected_bundle_name = get(
          state,
          'user.selectedPlan.identifier'
        );
        properties.selected_program_id = AndroidMetricsTracker.stringifyId(
          get(state, 'user.selectedPlan.subscription.promotion.programId')
        );
        properties.selected_product_id = AndroidMetricsTracker.stringifyId(
          options.productId
        );
        properties.trial_period = getTrialPeriod(state.user.selectedPlan);
        properties.account_type = 'hulu';
        properties.is_new_account = state.user.status === 'available';
        if (state.config.errorMessages.length !== 0) {
          properties.last_error_reason = state.config.errorMessages.slice(
            -1
          )[0].code;
        }
        break;
      case MetricsEvents.SUBSCRIPTION_PAYMENT_ERROR:
        properties.reason = options.error;
        break;
      default:
        return;
    }
    this.sendEvent(eventName, properties);
  }

  sendEvent(eventName, properties) {
    window.huluPlatform.trackEvent(eventName, JSON.stringify(properties));
  }

  flush() {
    return new Promise(resolve =>
      setTimeout(resolve, this.conversionEventDelay)
    );
  }

  static stringifyId(id, defaultValue) {
    if (id === null || id === undefined) {
      return defaultValue;
    }

    if (typeof id === 'number') {
      return String(id);
    }

    return id;
  }
}

export function isAndroidMetricsTrackerAvailable() {
  return !!window.huluPlatform && !!window.huluPlatform.trackEvent;
}
