import { getPaypalClientToken } from '../api/ClientTokens';
import * as types from '../constants/actionTypes';
import { PAYPAL_CALLBACK } from '../constants/routes';
import { removeParam, replacePath } from '../utils/url';
import { getPaypalClientTokenRequest } from '../selectors/billingPage';

export const setPaypalRedirectUrls = () => ({
  type: types.SET_PAYPAL_REDIRECT_URLS,
  successRedirectUrl: replacePath(PAYPAL_CALLBACK),
  cancelRedirectUrl: removeParam(window.location.href, 'token'),
});

// Thunks
export const getPaypalToken = () => (dispatch, getState) =>
  getPaypalClientToken(getPaypalClientTokenRequest(getState()));
