import * as types from '../constants/actionTypes';

export const initialState = {
  bootstrap: {
    loaded: false,
    loading: false,
    error: null,
  },
};

function startup(state = initialState, action) {
  switch (action.type) {
    case types.BOOTSTRAP.START: {
      return {
        ...state,
        bootstrap: { error: null, loaded: false, loading: true },
      };
    }
    case types.BOOTSTRAP.SUCCESS: {
      return {
        ...state,
        bootstrap: { error: null, loaded: true, loading: false },
      };
    }
    case types.BOOTSTRAP.FAILURE: {
      return {
        ...state,
        bootstrap: {
          error: { details: action.payload },
          loaded: false,
          loading: false,
        },
      };
    }
    default:
      return state;
  }
}

export default startup;
