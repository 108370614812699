import React from 'react';
import PropTypes from 'prop-types';
import BundleBadgesPlan from './BundleBadgesPlan';
import SASHBundleBadgePlan from './SASHBundleBadgePlan';
import { TabsOnMobileWebPlan } from './TabsOnMobileWebPlan';
import { ValuePropsBulletsPlan } from './ValuePropsBulletsPlan';
import Plan from './Plan';

/**
 * Use this component for dedicated updates to Plan as required by different
 * experiments.
 */
const AlternativePlanWrapper = props => {
  const {
    showBundleBadges,
    showSASHBundleBadge,
    showTabsOnMobileWeb,
    showValuePropBullets,
  } = props;

  // HULU_13873: Tabs On Mobile Web experiment
  if (showTabsOnMobileWeb) {
    return <TabsOnMobileWebPlan {...props} />;
  }

  // HULU_13876: Most Popular Badge for SASH Bundle experiment
  if (showSASHBundleBadge) {
    return <SASHBundleBadgePlan {...props} />;
  }

  // HULU_13632: Bundle Badges experiment
  if (showBundleBadges) {
    return <BundleBadgesPlan {...props} />;
  }

  // HULU_14131 Value Prop Bullets experiment
  if (showValuePropBullets) {
    return <ValuePropsBulletsPlan {...props} />;
  }

  return <Plan {...props} />;
};

AlternativePlanWrapper.propTypes = {
  ...Plan.propTypes,
  showBundleBadges: PropTypes.bool,
  showSASHBundleBadge: PropTypes.bool,
  showTabsOnMobileWeb: PropTypes.bool,
  showValuePropBullets: PropTypes.bool,
};

AlternativePlanWrapper.defaultProps = {
  showBundleBadges: false,
  showSASHBundleBadge: false,
  showTabsOnMobileWeb: false,
  showValuePropBullets: false,
};

export default AlternativePlanWrapper;
