import { actions } from 'react-redux-form';
import get from 'lodash/get';
import * as ENV from '../../../config/env';
import * as api from '../api/User';
import * as routes from '../constants/routes';
import { ACCOUNT_SUBMIT, UPDATE_ASSIGNMENT } from '../constants/actionTypes';
import {
  getUserRequest,
  getShouldCreateUser,
  getIsEmailRegistered,
  getShouldRedirectToWebsubBilling,
  getIsPasswordPolicyCheckFailed,
  getPasswordPolicyCheckFailedReason,
} from '../selectors/accountPage';
import { getSelectedPlan } from '../selectors/user';
import { getFromParam } from '../selectors/flow';
import { setEmailValidity } from './formActions';
import { showStudentModal, showUserEndpointFailureModal } from './modalActions';
import { routePush, urlRedirect } from './routingActions';
import { validateAccount } from './subscriberActions';
import { login } from './serverActions';
import { getLedgerStart, resetLedger } from './ledgerActions';
import { setCartAbandonmentCookie } from '../utils/cartAbandonmentUtils';
import { isStudentPlan } from '../selectors/plan';
import { EXISTING } from '../constants/userStatuses';
import { clearWebSubCookie, didVisitWebSub } from '../utils/cookieUtils';
import { redirectToPlanSelect } from './urlActions';
import { setUserData } from './userActions';

// Create the account if the user email is available and the password
// satisfies the strength requirement.
// Otherwise, dispatches the errors accordingly.
export const createAccount = () => (dispatch, getState) => {
  dispatch(accountSubmitStart());
  return Promise.resolve()
    .then(() => dispatch(validateAccount()))
    .then(() => {
      // Create users at the account step when applicable. See selector for business rules.
      if (getShouldCreateUser(getState())) {
        return dispatch(postUserRequest());
      }
      dispatch(accountSubmitSuccess());
      return dispatch(handleRedirectToBilling());
    })
    .catch(error => {
      // Clear loading spinner on error
      dispatch(resetLedger());

      if (getIsEmailRegistered(error)) {
        dispatch(setEmailValidity('user.email', EXISTING, ['available']));
        dispatch(actions.setPending('user.email', false));
        dispatch(actions.change('user.status', EXISTING));
      } else if (getIsPasswordPolicyCheckFailed(error)) {
        dispatch(
          accountSubmitPasswordCheckFailure(
            getPasswordPolicyCheckFailedReason(error)
          )
        );
        dispatch(
          actions.setValidity('user.password', {
            passesPasswordCheck: false,
          })
        );
      } else {
        dispatch(accountSubmitGeneralFailure(error));
        dispatch(showUserEndpointFailureModal(error));
      }
    });
};

// Handle account creation page submit
export const handleCreateAccountSubmit = () => (dispatch, getState) => {
  return dispatch(createAccount()).then(() => {
    const state = getState();
    if (isStudentPlan(getSelectedPlan(state)) && state.forms.user.$form.valid) {
      dispatch(showStudentModal());
    }
  });
};

/**
 * A handler for processing actions that occur after user account is successfully created.
 * This involves synchronizing the application state, then guides user to subsequent steps.
 *
 * @param {Object} [params] An object containing the arguments to be passed to the handler.
 * @param {Object} [params.formData] Form data collected during create account.
 * @param {Object} [params.response] The response returned after executing the createUser request.
 * In the case where the user is part of the partner flow, the response value would be undefined.
 */

export const postCreateAccount = ({ formData, response }) => async (
  dispatch,
  getState
) => {
  const userData = {
    birthday: formData.birthdate,
    firstName: formData.name,
    gender: formData.gender,
    parentalConsent: formData.parentalConsent,
    password: formData.password,
    zip: formData.zip,
  };

  const { billingZip } = formData;

  if (billingZip) {
    // If the billing zip is present in the formData, update the billingZip within the user node.
    userData.billingZip = billingZip;
  }

  // If the response is defined, update the firstName and email with the values provided in the response.
  if (response) {
    userData.firstName = response.user.firstName;
    userData.email = response.user.email;
  }

  // Update the name, email, password, birthdate, gender and zip within the user node
  dispatch(setUserData(userData));

  // Show loading spinner while preparing for redirection
  dispatch(getLedgerStart());
  dispatch(accountSubmitSuccess());

  // When in Student Discount flow we don't redirect to billing but to
  // verification partner instead (SheerID)
  const shouldRedirectToVerification = isStudentPlan(
    getSelectedPlan(getState())
  );

  // The response is the data sent back by the create account request.
  // If the user is coming from the partner flow, the create account request would not be triggered,
  // which could result in a null response. In this scenario, the subsequent actions would not be executed,
  // the user would then be redirected to the billing.
  if (response) {
    dispatch(updateAssignmentFromUserCreation(response));
    await dispatch(login(true));
  }

  if (shouldRedirectToVerification) {
    // Set only the name for cart abandonment when user is a student
    // We want to skip plan select for these users after they go through identity verification
    setCartAbandonmentCookie(getState(), true);
    dispatch(showStudentModal());
  } else {
    // Set the cart abandonment cookie after login/auth cookies only for non student discount
    setCartAbandonmentCookie(getState());
    dispatch(handleRedirectToBilling());
  }
};

// User Creation API actions
export const postUserRequest = () => {
  return (dispatch, getState) => {
    return api.createUser(getUserRequest(getState())).then(
      async response => {
        // Show loading spinner while preparing for redirection
        dispatch(getLedgerStart());
        dispatch(accountSubmitSuccess(response));
        dispatch(updateAssignmentFromUserCreation(response));

        // When in Student Discount flow we don't redirect to billing but to
        // verification partner instead (SheerID)
        const shouldRedirectToVerification = isStudentPlan(
          getSelectedPlan(getState())
        );

        // Wait to finish logging in actions before redirecting to make sure
        // that the application state is consistent
        await dispatch(login(true));

        if (shouldRedirectToVerification) {
          // Set only the name for cart abandonment when user is a student
          // We want to skip plan select for these users after they go through identity verification
          setCartAbandonmentCookie(getState(), true);
        } else {
          // Set the cart abandonment cookie after login/auth cookies only for non student discount
          setCartAbandonmentCookie(getState());
          dispatch(handleRedirectToBilling());
        }
      },
      error => Promise.reject(error)
    );
  };
};

/**
 * Attempts to redirect to the billing page unless there's a good reason not to.
 * If the user should be redirected to web-sub then we will do so unless they
 * had already visited it, in which case we assume that they want to change
 * their selected plan and arrived here by using the browser back button.
 */
export const handleRedirectToBilling = () => async (dispatch, getState) => {
  const shouldRedirectToWebsub = getShouldRedirectToWebsubBilling(getState());

  if (shouldRedirectToWebsub) {
    if (didVisitWebSub()) {
      // Redirect to Plan Select instead, see JSDoc.
      clearWebSubCookie();
      dispatch(redirectToPlanSelect());
    } else {
      return dispatch(urlRedirect(getWebsubBillingURL(getState())));
    }
  }

  return dispatch(routePush(routes.BILLING_INFO));
};

const getWebsubBillingURL = state => {
  const websubBillingURL = new URL(`${ENV.url.websub}${routes.BILLING_INFO}`);
  websubBillingURL.searchParams.append('from', getFromParam(state));

  const productId = get(getSelectedPlan(state), 'subplatformProductId');
  if (productId) {
    websubBillingURL.searchParams.append('base-plan', productId);
  }

  return websubBillingURL.toString();
};

export const accountSubmitSuccess = payload => {
  return {
    type: ACCOUNT_SUBMIT.SUCCESS,
    payload,
  };
};

export const updateAssignmentFromUserCreation = payload => {
  return {
    type: UPDATE_ASSIGNMENT,
    payload,
  };
};

export const accountSubmitStart = () => {
  return {
    type: ACCOUNT_SUBMIT.START,
  };
};

export const accountSubmitGeneralFailure = error => {
  return {
    type: ACCOUNT_SUBMIT.GENERAL_FAILURE,
    payload: error,
  };
};

export const accountSubmitPasswordCheckFailure = error => {
  return {
    type: ACCOUNT_SUBMIT.PASSWORD_CHECK_FAILURE,
    payload: error,
  };
};
