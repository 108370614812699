import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { showTermsApplyModal } from '../actions/planOptionsActions';
import { getSelectedPlan } from '../selectors/user';
import { routeReplace, routePush } from '../actions/routingActions';
import * as routes from '../constants/routes';
import { getPlanOptions } from '../selectors/plans';
import { TERMS_APPLY } from '../constants/terms';
import { getAnnualTermsApplyBody } from '../selectors/planOptionsPage';
import { hasAssignment } from '../selectors/config';
import { isFeatureEnabled } from '../selectors/featureFlag';
import { CAT_357 } from '../constants/experiments';
import PlanOptionsContainer from '../containers/PlanOptionsContainer';

require('../styles/planOptions.scss');

const PlanOptionsPage = ({ openModal, onLoaded, onSubmit }) => {
  useEffect(onLoaded);

  const handleSubmit = evt => {
    evt.preventDefault();
    onSubmit();
  };

  return (
    <form className="plan__options" onSubmit={handleSubmit}>
      <PlanOptionsContainer />
      <button type="button" className="planoptions__terms" onClick={openModal}>
        Terms Apply
      </button>
      <button
        type="submit"
        className="button button--select"
        data-testid="planoptions--submit"
      >
        Select
      </button>
    </form>
  );
};

PlanOptionsPage.propTypes = {
  openModal: PropTypes.func.isRequired,
  onLoaded: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    planOptions: getPlanOptions(state, getSelectedPlan(state)),
    isPrepaidFeatureOn: isFeatureEnabled(state, 'prepaid'),
    isInPlanOptionTreatment: hasAssignment(state, CAT_357.PLAN_OPTION_PAGE),
  };
};

const mapDispatchToProps = {
  onTermsApply: showTermsApplyModal,
  dispatchRouteReplace: routeReplace,
  dispatchRoutePush: routePush,
};

const mergeProps = (stateProps, dispatchProps) => {
  const {
    planOptions,
    isPrepaidFeatureOn,
    isInPlanOptionTreatment,
  } = stateProps;
  const {
    onTermsApply,
    dispatchRouteReplace,
    dispatchRoutePush,
  } = dispatchProps;
  return {
    openModal: () => {
      onTermsApply(TERMS_APPLY, getAnnualTermsApplyBody(stateProps));
    },
    onLoaded: () => {
      if (
        !isPrepaidFeatureOn ||
        !isInPlanOptionTreatment ||
        planOptions.length <= 1
      ) {
        dispatchRouteReplace(routes.PLAN_SELECT);
      }
    },
    onSubmit: () => dispatchRoutePush(routes.ACCOUNT_INFO),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(PlanOptionsPage);
