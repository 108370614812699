import { createSelector } from 'reselect';
import get from 'lodash/get';

import { SPOTIFY } from '../constants/partners';
import { ERROR_CODES } from '../constants/errorCodes';
import { getSiteConfig } from './siteconfig';

export const getEligibility = state => get(state, 'partner.spotifyEligibility');

export const getResponseStatus = createSelector(getEligibility, eligibility => {
  let spotifyResponseStatus = SPOTIFY.RESPONSE_STATUS.SUCCESS;
  if (eligibility && eligibility.error) {
    spotifyResponseStatus = SPOTIFY.RESPONSE_STATUS.SERVICE_ERROR;
    if (eligibility.error.status === 400) {
      const errorCode = get(eligibility.error, 'errors[0].code');
      if (
        errorCode === ERROR_CODES.AUTHENTICATION_FAILURE ||
        errorCode === ERROR_CODES.UNKNOWN
      ) {
        spotifyResponseStatus = SPOTIFY.RESPONSE_STATUS.AUTHENTICATION_FAILURE;
      } else if (errorCode === ERROR_CODES.USER_UNAUTHORIZED) {
        spotifyResponseStatus = SPOTIFY.RESPONSE_STATUS.USER_UNAUTHORIZED;
      } else if (errorCode === ERROR_CODES.ALREADY_SUBSCRIBED) {
        spotifyResponseStatus = SPOTIFY.RESPONSE_STATUS.ALREADY_SUBSCRIBED;
      } else if (errorCode === ERROR_CODES.INELIGIBLE_PROMOTION) {
        spotifyResponseStatus = SPOTIFY.RESPONSE_STATUS.INELIGIBLE_PROMOTION;
      } else if (errorCode === ERROR_CODES.CUSTOMER_NOT_FOUND) {
        spotifyResponseStatus = SPOTIFY.RESPONSE_STATUS.CUSTOMER_NOT_FOUND;
      }
    }
  }
  return spotifyResponseStatus;
});

export const getRoadblockText = createSelector(
  [getSiteConfig, getResponseStatus],
  (siteConfig, responseStatus) =>
    get(siteConfig, `["spotify_callback_text"]["${responseStatus}"]`, {})
);

export const getIsExistingUser = state =>
  get(state, 'flow.query.spotify_redirect', '').includes(
    SPOTIFY.EXISTING_USER_PARAM
  );
