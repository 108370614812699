import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getControlText } from '../../selectors/siteconfig';
import { showUnifiedIdentityLearnMoreModal } from '../../actions/modalActions';

/**
 * Displays message about only needing one account to access all The Walt Disney
 * Family of Companies and a "Learn more" link that opens up a modal.
 */
const AccountInstructions = ({ copy, learnMore, onOpenLearnMore }) => (
  <p className="account__unified-identity-instructions">
    {copy}{' '}
    <button type="button" onClick={onOpenLearnMore}>
      {learnMore}
    </button>
  </p>
);

AccountInstructions.propTypes = {
  copy: PropTypes.string.isRequired,
  learnMore: PropTypes.string.isRequired,
  onOpenLearnMore: PropTypes.func.isRequired,
};

const UnifiedIdentityAccountInstructions = connect(
  state => ({
    copy: getControlText(state, 'account.instructions_copy'),
    learnMore: getControlText(state, 'account.instructions_learn_more'),
  }),
  dispatch => ({
    onOpenLearnMore: () => dispatch(showUnifiedIdentityLearnMoreModal()),
  })
)(AccountInstructions);

export { UnifiedIdentityAccountInstructions };
