import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from './TextField';
import { validateCardExpiry } from '../utils/paymentUtils';
import { ERROR_TEXT } from '../constants/errorText';
import InputError from './InputError';

class CardExpiryField extends Component {
  constructor() {
    super();

    this.setValue = this.setValue.bind(this);
    this.replaceFullWidthChars = this.replaceFullWidthChars.bind(this);
    this.replaceFullWidthChars = this.replaceFullWidthChars.bind(this);
    this.formatExpiry = this.formatExpiry.bind(this);
    this.reformatExpiry = this.reformatExpiry.bind(this);

    this.onPaste = this.onPaste.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  onPaste(e) {
    if (this.props.onFieldChangeStarted) {
      this.props.onFieldChangeStarted();
    }
    const target = e.currentTarget;
    this.reformatExpiry(target);
  }

  onKeyDown(e) {
    if (this.props.onFieldChangeStarted) {
      this.props.onFieldChangeStarted();
    }
    // restrict numeric
    // can't get correct digit in safari if number pad is used.
    const keyCode = e.which >= 96 && e.which <= 105 ? e.which - 48 : e.which;
    const digit = String.fromCharCode(keyCode);
    if (e.which === 8) {
      // do nothing
    } else if (e.metaKey || e.ctrlKey || e.which === 0 || e.which < 47) {
      return;
    } else if (!/^\d$/.test(digit)) {
      e.preventDefault();
    }

    const target = e.currentTarget;
    const realValue = target.value;

    // if user is appending numbers
    if (
      !(
        target.selectionStart != null &&
        target.selectionStart !== realValue.length
      )
    ) {
      // handle normal digit and delete key
      if (/[\d]/.test(digit)) {
        // restrict card number length
        const value = (target.value + digit).replace(/\D/g, '');
        if (/^\d$/.test(value) && value !== '0' && value !== '1') {
          e.preventDefault();
          setTimeout(() => this.setValue(target, `0${value}`));
        } else if (/^\d\d$/.test(value)) {
          e.preventDefault();
          setTimeout(() => {
            const m1 = parseInt(value[0], 10);
            const m2 = parseInt(value[1], 10);
            if (m2 > 2 && m1 !== 0) {
              this.setValue(target, `0${m1} / ${m2}`);
            } else {
              this.setValue(target, `${value} / `);
            }
          });
        }
      } else if (e.which === '/' || e.which === ' ') {
        const { value } = target;
        if (/^\d$/.test(value) && value !== '0') {
          e.preventDefault();
          this.setValue(target, `0${value} / `);
        }
      } else if (e.which === 8) {
        const { value } = target;
        if (/\d\s\/\s$/.test(value)) {
          e.preventDefault();
          setTimeout(() =>
            this.setValue(target, value.replace(/\d\s\/\s$/, ''))
          );
        }
      }
    }
  }

  onKeyUp(e) {
    const target = e.currentTarget;
    // Reformat card number for such cases
    // - last digital, remove the last blank space
    // - user starts inputting from the middle
    this.reformatExpiry(target);
  }

  setValue(target, value) {
    target.value = value; // eslint-disable-line no-param-reassign
    this.props.onValueChanged(target.name, value);
  }

  setSafeValue(target, value) {
    const last = target.value;
    if (last === value) {
      return;
    }
    let cursor;
    try {
      cursor = target.selectionStart;
    } catch (error) {
      cursor = null;
    }
    this.setValue(target, value);
    if (cursor !== null && target === document.activeElement) {
      if (cursor === last.length) {
        cursor = value.length;
      }
      if (last !== value) {
        const prevPair = last.slice(cursor - 1, +cursor + 1 || 9e9);
        const currPair = value.slice(cursor - 1, +cursor + 1 || 9e9);
        const digit = value[cursor];
        if (
          /\d/.test(digit) &&
          prevPair === `${digit} ` &&
          currPair === ` ${digit}`
        ) {
          cursor += 1;
        }
      }
      target.selectionStart = cursor; // eslint-disable-line no-param-reassign
      target.selectionEnd = cursor; // eslint-disable-line no-param-reassign
    }
  }

  reformatExpiry(target) {
    return setTimeout(() => {
      let { value } = target;
      value = this.replaceFullWidthChars(value);
      value = this.formatExpiry(value);
      this.setSafeValue(target, value);
    });
  }

  formatExpiry(expiry) {
    const parts = expiry.match(/^\D*(\d{1,2})(\D+)?(\d{1,4})?/);
    if (!parts) {
      return '';
    }
    let mon = parts[1] || '';
    let sep = parts[2] || '';
    const year = parts[3] || '';
    if (year.length > 0) {
      sep = ' / ';
    } else if (sep === ' /') {
      mon = mon.substring(0, 1);
      sep = '';
    } else if (mon.length === 2 || sep.length > 0) {
      sep = ' / ';
    } else if (mon.length === 1 && mon !== '0' && mon !== '1') {
      mon = `0${mon}`;
      sep = ' / ';
    }
    return mon + sep + year;
  }

  replaceFullWidthChars(v) {
    let str = v;
    if (str == null) {
      str = '';
    }
    const fullWidth =
      '\uff10\uff11\uff12\uff13\uff14\uff15\uff16\uff17\uff18\uff19';
    const halfWidth = '0123456789';
    let value = '';
    const chars = str.split('');
    for (let i = 0, len = chars.length; i < len; i++) {
      let chr = chars[i];
      const idx = fullWidth.indexOf(chr);
      if (idx > -1) {
        chr = halfWidth[idx];
      }
      value += chr;
    }
    return value;
  }

  render() {
    return (
      <TextField
        onKeyDown={this.onKeyDown}
        onPaste={this.onPaste}
        onKeyUp={this.onKeyUp}
        pattern="[\d\s\/]*"
        type="tel"
        form="payment"
        maxLength="7"
        inputMode="numeric"
        placeHolder="MM / YY"
        validators={{
          invalid: validateCardExpiry,
        }}
        messages={{
          invalid: <InputError message={ERROR_TEXT.VALID_DATE} />,
        }}
        {...this.props}
      />
    );
  }
}

CardExpiryField.propTypes = {
  onValueChanged: PropTypes.func.isRequired,
  onFieldChangeStarted: PropTypes.func,
};

export default CardExpiryField;
