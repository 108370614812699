import { getConfig } from './configActions';
import { routeReplace, routePush, urlRedirect } from './routingActions';
import request from '../api/request';
import {
  SET_IS_EXISTING_USER,
  GET_PARTNER_OFFER_CONTEXT,
} from '../constants/actionTypes';
import { YOKOZUNA_API } from '../constants/apis';
import {
  PLAN_SELECT,
  LOGIN,
  PARTNER_ROADBLOCK,
  PARTNER_ELIGIBILITY,
} from '../constants/routes';
import {
  getPartnerOfferId,
  getIsExistingUser,
  getPartnerOfferError,
} from '../selectors/partners';
import { getPartnerFlowText } from '../selectors/siteconfig';
import { getIsMissingPlans } from '../selectors/config';
import {
  SUBSCRIPTION_START,
  SUBSCRIPTION_STEP_START,
} from '../metrics/metricsEvents';

// Action Creators
export const setIsExistingUser = isExistingUser => ({
  type: SET_IS_EXISTING_USER,
  payload: isExistingUser,
});

export const getPartnerOfferContextStart = () => ({
  type: GET_PARTNER_OFFER_CONTEXT.START,
});

export const getPartnerOfferContextSuccess = response => ({
  type: GET_PARTNER_OFFER_CONTEXT.SUCCESS,
  payload: response,
});

export const getPartnerOfferContextFailure = error => ({
  type: GET_PARTNER_OFFER_CONTEXT.FAILURE,
  payload: error,
});

// Thunks
// Go to error page if no eligible plans are returned.
export const handlePartnerEligibility = () => (dispatch, getState) =>
  dispatch(getPartnerOfferContext())
    .then(() => dispatch(getConfig()))
    .then(() => {
      // Go to login page for existing users.
      if (getIsExistingUser(getState())) {
        return dispatch(routeReplace(LOGIN));
      }

      // Handle post config routing for remaining users.
      return dispatch(postConfigRoutingForPartnership());
    })
    // The offer context call failed. Stay on the eligibility page and show the
    // roadblock. Resolve here so the error doesn't bubble up.
    .catch(() => Promise.resolve());

export const getPartnerOfferContext = () => (dispatch, getState) => {
  dispatch(getPartnerOfferContextStart());
  return request(
    `${YOKOZUNA_API}partner-offers/${getPartnerOfferId(getState())}`,
    {
      method: 'GET',
      credentials: 'include',
    }
  ).then(
    response => dispatch(getPartnerOfferContextSuccess(response)),
    error => {
      dispatch(getPartnerOfferContextFailure(error));
      return Promise.reject(error);
    }
  );
};

export const postConfigRoutingForPartnership = () => (dispatch, getState) =>
  dispatch(
    routeReplace(
      getIsMissingPlans(getState()) ? PARTNER_ROADBLOCK : PLAN_SELECT
    )
  );

export const handlePartnerEligibilityClick = () => (dispatch, getState) => {
  const { redirectUrl } = getPartnerFlowText(getState(), [
    'eligibility',
    getPartnerOfferError(getState()),
  ]);

  // Redirect if a URL is included
  if (redirectUrl) {
    return dispatch(urlRedirect(redirectUrl));
  }

  // Otherwise retry the eligibility call
  return dispatch(handlePartnerEligibility());
};

export const handlePartnerLandingLoaded = () => (
  dispatch,
  getState,
  { trackEvent }
) => {
  // Skip the landing page and and show the invalid offer error screen on the
  // eligibility page if there is no partner offer ID.
  if (getPartnerOfferId(getState())) {
    trackEvent(SUBSCRIPTION_START);
    trackEvent(SUBSCRIPTION_STEP_START, {
      title: 'SUF - Landing Page',
      pageId: 'landing',
    });
  } else {
    dispatch(routeReplace(PARTNER_ELIGIBILITY));
  }
};

export const handlePartnerLandingClick = isExistingUser => dispatch => {
  dispatch(setIsExistingUser(isExistingUser));
  dispatch(routePush(PARTNER_ELIGIBILITY));
};
