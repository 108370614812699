import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { getLiveTvLinkPlan, getShouldShowLiveTvLink } from '../selectors/plans';
import { getLiveTvLinkText } from '../selectors/siteconfig';

import '../styles/liveTvLink.scss';

const LiveTvLink = ({
  linkClass,
  showLiveTvLink,
  handleLinkClick,
  liveTvLinkText,
  liveTvLinkPlan,
}) =>
  showLiveTvLink && liveTvLinkPlan ? (
    <div
      className={classNames(
        'live-tv-link',
        linkClass && `live-tv-link--${linkClass}`
      )}
    >
      <a
        data-testid="live-tv-link"
        data-automationid="live-tv-link"
        onClick={() => handleLinkClick(liveTvLinkPlan)}
      >
        {liveTvLinkText}
      </a>
    </div>
  ) : null;

LiveTvLink.defaultProps = {
  linkClass: '',
  liveTvLinkText: '',
};

LiveTvLink.propTypes = {
  linkClass: PropTypes.string,
  showLiveTvLink: PropTypes.bool,
  liveTvLinkText: PropTypes.string,
  liveTvLinkPlan: PropTypes.object,
  handleLinkClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  showLiveTvLink: getShouldShowLiveTvLink(state),
  liveTvLinkText: getLiveTvLinkText(state),
  liveTvLinkPlan: getLiveTvLinkPlan(state),
});

export default connect(mapStateToProps)(LiveTvLink);
