export const isTabletOrPhone = function() {
  const { userAgent } = navigator;
  if (
    /(itouch|iphone|ipod)/i.test(userAgent) ||
    (/(mobile)/i.test(userAgent) && /Android/i.test(userAgent))
  ) {
    return 'Phone';
  }

  return 'Tablet';
};

const config = [
  {
    matchOn: /(ipad|itouch|iphone|ipod)/i,
    identifier: 'iOS',
    deviceType: isTabletOrPhone,
  },
  {
    matchOn: /Xbox/i,
    identifier: 'xbox',
    deviceType: 'Console',
  }, // Deprecated; Xbox masks itself as an Android device in the user agent, so it has to be first and left here so it
  // doesn't get picked up as a mobile device.
  {
    matchOn: /Android/i,
    identifier: 'android',
    deviceType: isTabletOrPhone,
  },
  {
    matchOn: /Windows Phone/i,
    identifier: 'windowsPhone',
    deviceType: 'Phone',
  },
  {
    matchOn: /GoogleTV/i,
    identifier: 'googleTV',
    deviceType: 'SetTop',
  },
  {
    matchOn: /Boxee/i,
    identifier: 'boxee',
    deviceType: 'SetTop',
  },
  {
    matchOn: /Skyfire/i,
    identifier: 'skyfire',
    deviceType: 'SetTop',
  },
  {
    matchOn: /(Playbook|BlackBerry);/i,
    identifier: 'blackberry',
    deviceType: 'Phone',
  },
  {
    matchOn: /(BB10);/i,
    identifier: 'blackberry10',
    deviceType: 'Phone',
  },
  {
    matchOn: /PlayStation Vita/i,
    identifier: 'psVita',
    deviceType: 'Handheld',
  }, // Do not move below kindle because the word 'silk' is in this user agent too. stupid.
  {
    matchOn: /Silk\/1/i,
    identifier: 'kindle',
    deviceType: isTabletOrPhone,
  },
  {
    matchOn: /Silk\/[2-9]/i,
    identifier: 'kindle',
    deviceType: isTabletOrPhone,
  },
  {
    matchOn: /BNTV250/i,
    identifier: 'nook',
    deviceType: 'Tablet',
  },
  {
    matchOn: /PLAYSTATION 3/i,
    identifier: 'ps3',
    deviceType: 'Console',
  },
  {
    matchOn: /PlayStation 4/i,
    identifier: 'ps4',
    deviceType: 'Console',
  },
  {
    matchOn: /Nintendo WiiU/i,
    identifier: 'wiiU',
    deviceType: 'Console',
  },
  {
    matchOn: /Mobile/i,
    identifier: 'unknown-mobile-browser',
    deviceType: 'Mobile',
  }, // Catches at least FirefoxOS
];

export function isMobile(userAgent = navigator.userAgent) {
  return ['Mobile', 'Tablet', 'Phone', 'Handheld'].includes(
    getDeviceType(userAgent)
  );
}

export function getDevice(userAgent = navigator.userAgent) {
  const device = config.find(d => d.matchOn.test(userAgent));

  return device == null
    ? { identifier: 'unknown', deviceType: 'Desktop' }
    : device;
}

export function getDeviceType(userAgent = navigator.userAgent) {
  const device = getDevice(userAgent);
  return typeof device.deviceType === 'function'
    ? device.deviceType()
    : device.deviceType;
}

export function isAndroidDevice() {
  // TODO: ask for help to implement :)
  return false;
}
