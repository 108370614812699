import React from 'react';

import { useWindowSize } from '../hooks/useWindowSize';

const withWindowSize = WrappedComponent => props => {
  const windowSize = useWindowSize();

  const mergedProps = {
    windowSize,
    ...props,
  };

  return <WrappedComponent {...mergedProps} />;
};

export default withWindowSize;
