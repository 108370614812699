import { setCookie, getCookie, removeCookie } from './cookieUtils';

export default {
  setItem(key, value) {
    setCookie(
      key,
      value,
      0.041667,
      window.location.hostname
    ); /* one hour = 0.041667 day */
  },

  getItem(key) {
    return getCookie(key);
  },

  removeItem(key) {
    removeCookie(key);
  },
};
