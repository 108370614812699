import { compose, createStore, applyMiddleware } from 'redux';
import persistState, { mergePersistedState } from 'redux-localstorage';
import adapter from 'redux-localstorage/lib/adapters/sessionStorage';
import filter from 'redux-localstorage-filter';
import thunk from 'redux-thunk';

import reducers from './reducers';
import { getGuid } from './utils/cookieUtils';
import { metricsMiddleware } from './middleware/MetricsMiddleware';
import { routingMiddleware } from './middleware/RoutingMiddleware';
import { textMiddleware } from './middleware/TextMiddleware';
import { metricsManager } from './metrics/MetricsManager';

import cookieStorage from './utils/cookieStorage';
import sessionStorage from './utils/sessionStorage';

// Go install redux devtools extension, so you can inspector the state in store
// https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd
let composeFunc = compose;
const sessionKey = `sufo_${process.env.commitHash}_${getGuid()}`;

if (
  process.env.NODE_ENV === 'development' ||
  process.env.NODE_ENV === 'staging'
) {
  // eslint-disable-next-line no-underscore-dangle
  composeFunc = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const reducer = compose(mergePersistedState())(reducers);

export function storageAvailable(type) {
  try {
    const storage = window[type];
    const x = '___storage_test___';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

const storageType = storageAvailable('sessionStorage')
  ? sessionStorage
  : cookieStorage;
const storage = compose(
  filter([
    'user',
    'misc',
    'flow',
    'warning',
    'payment.paymentType',
    'addons',
    'partner',
    'bundlePartner',
    'superbundleEligibility',
    'subscription',
  ])
)(adapter(storageType));

const enhancer = composeFunc(
  applyMiddleware(
    thunk.withExtraArgument(metricsManager),
    textMiddleware,
    metricsMiddleware,
    routingMiddleware
  ),
  persistState(storage, sessionKey)
);

const store = createStore(reducer, {}, enhancer);

if (process.env.NODE_ENV === 'development') {
  window.store = store;
}

const clearStorage = () => {
  storageType.removeItem(sessionKey);
};

export { store, clearStorage, enhancer };
