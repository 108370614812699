import { connect } from 'react-redux';

import { handleRoadblockClick as handleClick } from '../actions/sprintV2Actions';
import { getRoadblockText } from '../selectors/sprintV2';
import SprintV2RoadblockPage from '../pages/SprintV2RoadblockPage';

const mapStateToProps = state => ({
  text: getRoadblockText(state),
});

export default connect(mapStateToProps, { handleClick })(SprintV2RoadblockPage);
