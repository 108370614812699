import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from '../components/Loading';

class PaypalCallbackPage extends Component {
  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.props.handleSubscription();
  }

  render() {
    return <Loading />;
  }
}

PaypalCallbackPage.propTypes = {
  handleSubscription: PropTypes.func.isRequired,
};

export default PaypalCallbackPage;
