import { connect } from 'react-redux';
import { getConfigValue, getControlText } from '../selectors/siteconfig';
import {
  MAINTENANCE_PAGE_KEY,
  MAINTENANCE_PAGE_ROOT,
  MAINTENANCE_TEXT_KEY,
} from '../constants/siteConfigKeys';
import ErrorPage from '../pages/ErrorPage';
import { ERROR_SCREEN_DEFAULT_TEXT } from '../constants/errorText';

function mapStateToProps(state) {
  if (getConfigValue(state, MAINTENANCE_PAGE_ROOT, MAINTENANCE_PAGE_KEY)) {
    return {
      errorText: getControlText(
        state,
        MAINTENANCE_TEXT_KEY,
        ERROR_SCREEN_DEFAULT_TEXT
      ),
    };
  }

  return { errorText: ERROR_SCREEN_DEFAULT_TEXT };
}

export default connect(mapStateToProps)(ErrorPage);
