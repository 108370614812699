import get from 'lodash/get';
import find from 'lodash/find';

import { getSelectedAddons, getSelectedAddonBundles } from './addons';
import { getSelectedPlan } from './user';
import { findPlanBySubIdentifier } from './plans';

export const getPlanProgramId = plan =>
  get(plan, 'subscription.promotion.programId');

export const getCurrentPlanProgramId = state =>
  getPlanProgramId(getSelectedPlan(state));

export const getNewPlanProgramId = (state, newConfig) => {
  const currentPlanSubIdentifier = get(getSelectedPlan(state), 'subIdentifier');
  const newPlan = findPlanBySubIdentifier(
    currentPlanSubIdentifier,
    newConfig.plans
  );
  return getPlanProgramId(newPlan);
};

/**
 * Check whether or not the base bundle program changed after the user logs in.
 */
export const didPlanProgramChange = (state, newConfig) =>
  getNewPlanProgramId(state, newConfig) !== getCurrentPlanProgramId(state);

/**
 * Check whether or not any of the selected add-ons changed programs after the
 * user logs in.
 */
export const didAddonProgramChange = (state, newConfig) =>
  getSelectedAddons(state).some(
    addon =>
      addon.programId !==
      get(
        find(
          newConfig.addons,
          newAddon => newAddon.componentId === addon.componentId
        ),
        'programId'
      )
  );

/**
 * Check whether or not any of the selected add-on bundles changed programs
 * after the user logs in.
 */
export const didAddonBundleProgramChange = (state, newConfig) =>
  getSelectedAddonBundles(state).some(
    addonBundle =>
      get(addonBundle, 'requestObject.programId') !==
      get(
        find(
          newConfig.addonBundles,
          newAddonBundle => newAddonBundle.bundleId === addonBundle.bundleId
        ),
        'requestObject.programId'
      )
  );

/**
 * Check whether or not the program changed on the any of the subscription's
 * components after a user logs in. This is used to determine if the user became
 * ineligible for any trials or discounts.
 */
export const didProgramChange = (state, newConfig) => {
  // Check if base bundle program changed
  if (didPlanProgramChange(state, newConfig)) {
    return true;
  }

  // Check if the program changed on any add-ons
  if (didAddonProgramChange(state, newConfig)) {
    return true;
  }

  // Check if the program changed on any add-on bundles
  if (didAddonBundleProgramChange(state, newConfig)) {
    return true;
  }

  // The program didn't change on any subscription components.
  return false;
};
