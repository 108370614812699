import get from 'lodash/get';
import has from 'lodash/has';
import { routeReplace } from './routingActions';
import { getSiteConfiguration } from '../api/SiteConfig';
import * as types from '../constants/actionTypes';
import { ERROR } from '../constants/routes';
import {
  PARTNER_TEXT_KEY_ROOT,
  MAINTENANCE_PAGE_ROOT,
  MAINTENANCE_PAGE_KEY,
} from '../constants/siteConfigKeys';
import { getPartnerOfferKey } from '../selectors/partners';

// Config API actions
export function getSiteConfig() {
  return (dispatch, getState) => {
    dispatch(resetSiteConfig());
    dispatch(getSiteConfigStart());

    const partnerOfferKey = getPartnerOfferKey(getState());

    return getSiteConfiguration(partnerOfferKey).then(
      response => {
        dispatch(getSiteConfigSuccess(response));

        // Checks if there is known maintenance
        if (
          get(response, [MAINTENANCE_PAGE_ROOT, MAINTENANCE_PAGE_KEY], false)
        ) {
          dispatch(routeReplace(ERROR));
          return Promise.reject();
        }

        // Checks if partnership node is disabled
        if (partnerOfferKey && !has(response, PARTNER_TEXT_KEY_ROOT)) {
          dispatch(routeReplace(ERROR));
          return Promise.reject();
        }

        return response;
      },
      error => {
        dispatch(getSiteConfigFailure(error));
        return Promise.reject(error);
      }
    );
  };
}

export function getSiteConfigStart() {
  return {
    type: types.GET_SITE_CONFIG.START,
  };
}

export function getSiteConfigSuccess(response) {
  return {
    type: types.GET_SITE_CONFIG.SUCCESS,
    payload: response,
  };
}

export function getSiteConfigFailure(error) {
  return {
    type: types.GET_SITE_CONFIG.FAILURE,
    payload: error,
  };
}

export function resetSiteConfig() {
  return {
    type: types.GET_SITE_CONFIG.RESET,
  };
}
