export const CREDITCARD = 'creditcard';
export const PAYPAL = 'paypal';
export const VENMO = 'venmo';
export const RPM = 'rpm';
export const USE_CURRENT = 'current';
export const GIFT_CODE = 'giftcode';
export const SPOTIFY = 'spotify';
export const SPRINTV2 = 'sprintv2';
export const PARTNER = 'partner';
export const BUNDLE_PARTNER = 'dss';
export const NONE = 'none';
