import React from 'react';
import PropTypes from 'prop-types';

import { TOOLTIP_TOGGLE_KEYS } from '../../constants/keyboardKeys';

const handleKeyDown = action => e => {
  if (TOOLTIP_TOGGLE_KEYS.includes(e.key)) {
    action();
  }
};

const LedgerActionItem = ({ text, action }) => (
  <a
    className="ledger__action-item"
    tabIndex="0"
    role="button"
    onClick={action}
    onKeyDown={handleKeyDown(action)}
  >
    {text}
  </a>
);

LedgerActionItem.propTypes = {
  text: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
};

export default LedgerActionItem;
