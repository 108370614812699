import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MarkdownRoadblock from '../components/MarkdownRoadblock';
import Loading from '../components/Loading';
import {
  handleBundlePartnerSwitchEligibility,
  handleBundlePartnerSwitchEligibilityClick,
} from '../actions/bundlePartnerSwitchEligibilityActions';
import {
  getIsLoading,
  getBundlePartnerSwitchEligibilityTextForReason,
} from '../selectors/bundlePartnerSwitchEligibility';

export const BundlePartnerSwitchEligibilityPage = props => {
  useEffect(() => {
    props.handleBundlePartnerSwitchEligibility();
  }, []);

  if (props.isLoading) {
    return <Loading />;
  }

  return (
    <MarkdownRoadblock
      {...props.buttonConfig}
      handleClick={props.handleBundlePartnerSwitchEligibilityClick}
    />
  );
};

BundlePartnerSwitchEligibilityPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  buttonConfig: PropTypes.shape({
    markdown: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
  }),
  handleBundlePartnerSwitchEligibility: PropTypes.func.isRequired,
  handleBundlePartnerSwitchEligibilityClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isLoading: getIsLoading(state),
  buttonConfig: getBundlePartnerSwitchEligibilityTextForReason(state),
});

const mapDispatchToProps = {
  handleBundlePartnerSwitchEligibility,
  handleBundlePartnerSwitchEligibilityClick,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BundlePartnerSwitchEligibilityPage);
