import { connect } from 'react-redux';

import { triggerReload } from '../actions/bootstrapActions';
import track from '../metrics/track';
import Page from '../pages/Page';
import { getMessagingForPage } from '../selectors/dynamicMessaging';
import { getIsOneTrustScriptEnabled } from '../selectors/featureFlag';
import {
  getIsRokuPacFlow,
  getPartnerClass,
  getVipClass,
  getFooterClass,
} from '../selectors/flow';
import { getPageTitleClass, getPlanSelectClass } from '../selectors/plans';
import { getIsBootstrapLoading } from '../selectors/startup';
import { getModalIsOpen } from '../selectors/modal';
import { withFastPass } from '../tools/FastPass';
import { getShouldRedirectToAccount } from '../selectors/ledger';
import { getShouldShowEdnaCreateAccount } from '../selectors/accountPage';

const mapStateToProps = (state, ownProps) => {
  const { flow } = state;
  const { location } = ownProps;

  return {
    flow,
    bootstrapLoading: getIsBootstrapLoading(state),
    modalIsOpen: getModalIsOpen(state),
    location,
    pageName: location.pathname.replace(/\//g, ''),
    pageMessaging: getMessagingForPage(state, ownProps),
    isRokuPacFlow: getIsRokuPacFlow(state),
    footerClass: getFooterClass(state),
    partnerClass: getPartnerClass(state, ownProps),
    vipClass: getVipClass(state, ownProps),
    planSelectClass: getPlanSelectClass(state),
    pageTitleClass: getPageTitleClass(state, ownProps),
    shouldEnableOneTrustScript: getIsOneTrustScriptEnabled(state),
    shouldRedirectToAccount: getShouldRedirectToAccount(state),
    isEdnaAccountCreationFeatureEnabled: getShouldShowEdnaCreateAccount(state),
  };
};

const mergeProps = (stateProps, { dispatch }, { children }) => ({
  ...stateProps,
  children,
  dispatch,
  onLoaded: () => dispatch(triggerReload(stateProps.location)),
});

export default withFastPass(
  track(connect(mapStateToProps, null, mergeProps)(Page))
);
