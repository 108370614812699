import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AddonPropTypes } from '../constants/propTypes';
import track from '../metrics/track';

import '../styles/addons.scss';

export class Addon extends Component {
  constructor() {
    super();
    this.state = {
      hoverEnabled: true,
      isHover: false,
    };

    this.handleHover = this.handleHover.bind(this);
    this.handleTouch = this.handleTouch.bind(this);
  }

  handleHover(isHover) {
    if (this.state.hoverEnabled) {
      this.setState({ isHover });
    }
  }

  handleTouch() {
    // Disables mouse-hover animations if a touch event is received.
    if (this.state.hoverEnabled) {
      this.setState({
        hoverEnabled: false,
        isHover: false,
      });
    }
  }

  render() {
    const {
      addon: { name, iconUrl, displayPricing, displayTrial },
      isSelected,
      onClick,
    } = this.props;
    const { isHover } = this.state;

    return (
      <div className="addon__selection">
        <div
          className={`addon__checkbox ${isSelected ? 'checked' : ''} ${
            isHover ? 'hover' : ''
          }`}
          onClick={onClick}
          onMouseOver={() => this.handleHover(true)}
          onMouseOut={() => this.handleHover(false)}
          onTouchStart={() => this.handleTouch()}
          data-testid="addon__checkbox"
        />
        <div className="addon__textcontainer">
          <div className="addon__namecontainer">
            <div className="addon__name">{name}</div>
          </div>
          <div className="addon__imagecontainer">
            <img className="addon__image" src={iconUrl} alt={name} />
          </div>
          <div className="addon__trial">{displayTrial}</div>
          <div className="addon__pricing">{displayPricing[0]}</div>
        </div>
      </div>
    );
  }
}

Addon.propTypes = {
  addon: AddonPropTypes,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default track(Addon);
