import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LedgerTerm from '../LedgerTerm';
import { getLedgerNotices } from '../../selectors/ledger';

export const LedgerNotice = ({ ledgerNotices }) => {
  if (!ledgerNotices || !ledgerNotices.length) {
    return null;
  }
  return (
    <div className="ledger__notice">
      <img alt="Notice Icon" src="/static/images/notice.svg" />
      <div>
        {ledgerNotices.map(notice => (
          <LedgerTerm {...notice} />
        ))}
      </div>
    </div>
  );
};

LedgerNotice.propTypes = {
  ledgerNotices: PropTypes.arrayOf(PropTypes.shape(LedgerTerm.propTypes)),
};

const mapStateToProps = state => ({
  ledgerNotices: getLedgerNotices(state),
});

export default connect(mapStateToProps)(LedgerNotice);
