import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  handlePartnerEligibility,
  handlePartnerEligibilityClick,
} from '../actions/partnerActions';
import Loading from '../components/Loading';
import MarkdownRoadblock from '../components/MarkdownRoadblock';
import { getPartnerFlowText } from '../selectors/siteconfig';
import { getPartnerOfferError } from '../selectors/partners';

export class PartnerEligibilityPage extends Component {
  componentDidMount() {
    this.props.handlePartnerEligibility();
  }

  render() {
    const {
      eligibilityConfig,
      handlePartnerEligibilityClick: handleClick,
    } = this.props;

    if (!eligibilityConfig) {
      return <Loading />;
    }

    return (
      <MarkdownRoadblock {...eligibilityConfig} handleClick={handleClick} />
    );
  }
}

PartnerEligibilityPage.propTypes = {
  handlePartnerEligibility: PropTypes.func.isRequired,
  handlePartnerEligibilityClick: PropTypes.func.isRequired,
  eligibilityConfig: PropTypes.shape({
    markdown: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
  }),
};
const mapStateToProps = state => ({
  eligibilityConfig: getPartnerFlowText(state, [
    'eligibility',
    getPartnerOfferError(state),
  ]),
});

const mapDispatchToProps = {
  handlePartnerEligibility,
  handlePartnerEligibilityClick,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerEligibilityPage);
