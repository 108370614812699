import { createAction } from 'redux-starter-kit';
import noop from 'lodash/noop';

import {
  GET_BUNDLE_PARTNER_PAYLOAD,
  PROCESS_PAYMENT_ERRORS,
} from '../constants/actionTypes';
import request from '../api/request';
import { YOKOZUNA_API } from '../constants/apis';
import { stringify } from '../utils/querystring';
import { getToken } from '../selectors/bundlePartner';
import { getConfig } from './configActions';
import { routeReplace, urlRedirect } from './routingActions';
import { checkEmailStatus } from './formActions';
import { PLAN_SELECT } from '../constants/routes';
import { getDPlusText } from '../selectors/siteconfig';
import { getShouldCheckSwitchEligibility } from '../selectors/bundlePartnerSwitchEligibility';

// Action Creators
export const getBundlePartnerPayloadStart = createAction(
  GET_BUNDLE_PARTNER_PAYLOAD.START
);
export const getBundlePartnerPayloadSuccess = createAction(
  GET_BUNDLE_PARTNER_PAYLOAD.SUCCESS
);
export const getBundlePartnerPayloadFailure = createAction(
  GET_BUNDLE_PARTNER_PAYLOAD.FAILURE
);

/**
 * Create an action to show the error modal for Disney+ subscriptions.
 *
 * @param message The message to be displayed on the error modal.
 * @param redirectText The help/redirect link text.
 * @param redirectRoute The help/redirect link URL.
 * @returns The action
 */
export const handleSubscriptionErrors = (
  message,
  redirectText,
  redirectRoute
) => ({
  type: PROCESS_PAYMENT_ERRORS,
  preventClose: true,
  error: { message },
  redirectText,
  redirectRoute,
});

// Thunks
export function getBundlePartnerPayload() {
  return async (dispatch, getState) => {
    dispatch(getBundlePartnerPayloadStart());
    try {
      const token = getToken(getState());
      const response = await request(
        `${YOKOZUNA_API}bundle-partners/payload?${stringify({ token })}`
      );
      dispatch(getBundlePartnerPayloadSuccess(response));
      return response;
    } catch (error) {
      dispatch(getBundlePartnerPayloadFailure(error));
      throw error;
    }
  };
}

export function handleBundlePartnerEligibility() {
  return async (dispatch, getState) => {
    try {
      await dispatch(getBundlePartnerPayload());
      await dispatch(getConfig());
      if (!getShouldCheckSwitchEligibility(getState())) {
        await dispatch(checkEmailStatus());
        dispatch(routeReplace(PLAN_SELECT));
      }
    } catch (error) {
      // The activation page will render the roadblock, so we don't need to do anything here.
      noop(error);
    }
  };
}

export function handleBundlePartnerEligibilityClick() {
  return (dispatch, getState) => {
    const { redirectUrl } = getDPlusText(getState(), 'roadblock.invalidOffer');
    return dispatch(urlRedirect(redirectUrl));
  };
}
