export const ITEM_TYPES = {
  BASE_BUNDLE: 'BASE_BUNDLE_LEDGER_ITEM',
  ADDON_BUNDLE: 'ADDON_BUNDLE_LEDGER_ITEM',
  ADDON: 'ADDON_LEDGER_ITEM',
};

export const V3_PAYMENT_TYPES = {
  GIFT_CODE: 'giftcode',
  SPRINT: 'sprint',
};

export const BOLD_BILLING_LEGAL_TERMS = ['recurringTotal', 'cancelPrepay'];

/* A map from a Subplatform billing type string to a HITs billing type string */
export const BILLING_TYPES_MAPPING = {
  standard: 'standard',
  standardPrepaid: 'prepaid_standard',
  promotionalPrepaid: 'prepaid_promotional',
  none: 'none',
};
