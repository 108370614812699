/* eslint-disable no-else-return, no-param-reassign */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cardValidator, { creditCardType } from 'card-validator';
import TextField from './TextField';
import { ERROR_TEXT } from '../constants/errorText';
import InputError from './InputError';

class CreditCardField extends Component {
  constructor(props) {
    super(props);

    this.setValue = this.setValue.bind(this);
    this.setSafeValue = this.setSafeValue.bind(this);
    this.setCardType = this.setCardType.bind(this);
    this.reformatCardNumber = this.reformatCardNumber.bind(this);
    this.formatCardNumber = this.formatCardNumber.bind(this);

    this.onPaste = this.onPaste.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  onPaste(e) {
    if (this.props.onFieldChangeStarted) {
      this.props.onFieldChangeStarted();
    }
    this.reformatCardNumber(e);
  }

  onKeyDown(e) {
    if (this.props.onFieldChangeStarted) {
      this.props.onFieldChangeStarted();
    }
    const maxCardNumberLength = 16;

    // restrict numeric
    // can't get correct digit in safari if number pad is used.
    const keyCode = e.which >= 96 && e.which <= 105 ? e.which - 48 : e.which;
    const digit = String.fromCharCode(keyCode);
    if (e.which === 8) {
      // do nothing
    } else if (e.metaKey || e.ctrlKey || e.which === 0 || e.which < 47) {
      return true;
    } else if (!/^\d$/.test(digit)) {
      e.preventDefault();
      return false;
    }

    const target = e.currentTarget;
    const realValue = target.value;
    // if user is appending numbers
    if (
      !(
        target.selectionStart != null &&
        target.selectionStart !== realValue.length
      )
    ) {
      // handle normal digit and delete key
      if (/[\d]/.test(digit)) {
        // restrict card number length
        const value = (target.value + digit).replace(/\D/g, '');
        const { card } = cardValidator.number(value);
        if (card) {
          if (value.length > card.lengths[card.lengths.length - 1]) {
            e.preventDefault();
            return false;
          }
        } else {
          if (value.length > maxCardNumberLength) {
            e.preventDefault();
            return false;
          }
          return true;
        }

        const re = /(?:^|\s)(\d{4})$/;

        if (re.test(realValue)) {
          e.preventDefault();
          this.setValue(target, `${realValue} ${digit}`);
          return false;
        }

        if (re.test(realValue + digit)) {
          e.preventDefault();
          this.setValue(target, `${realValue}${digit} `);
          return false;
        }
      } else if (e.which === 8) {
        const { value } = target;
        if (/\d\s$/.test(value)) {
          e.preventDefault();
          setTimeout(() => this.setValue(target, value.replace(/\d\s$/, '')));
          return false;
        }

        if (/\s\d?$/.test(value)) {
          e.preventDefault();
          setTimeout(() => this.setValue(target, value.replace(/\d$/, '')));
          return false;
        }
      }
    }
    return true;
  }

  onKeyUp(e) {
    // Reformat card number for such cases
    // - last digital, remove the last blank space
    // - user starts inputting from the middle
    return this.reformatCardNumber(e);
  }

  setSafeValue(target, value) {
    const last = target.value;
    if (last === value) {
      return;
    }
    let cursor;
    try {
      cursor = target.selectionStart;
    } catch (error) {
      cursor = null;
    }
    this.setValue(target, value);
    if (cursor !== null && target === document.activeElement) {
      if (cursor === last.length) {
        cursor = value.length;
      }
      if (last !== value) {
        const prevPair = last.slice(cursor - 1, +cursor + 1 || 9e9);
        const currPair = value.slice(cursor - 1, +cursor + 1 || 9e9);
        const digit = value[cursor];
        if (
          /\d/.test(digit) &&
          prevPair === `${digit} ` &&
          currPair === ` ${digit}`
        ) {
          cursor += 1;
        }
      }
      target.selectionStart = cursor;
      target.selectionEnd = cursor;
    }
  }

  setValue(target, value) {
    target.value = value;
    this.props.onValueChanged(target.name, value);
  }

  setCardType(target, card) {
    const cardType = card || 'unknown';
    if (!target.classList.contains(cardType)) {
      Object.values(creditCardType.types).forEach(c =>
        target.classList.remove(c)
      );
      target.classList.remove('unknown');
      target.classList.add(cardType);
    }
  }

  formatCardNumber(value) {
    const num = value.replace(/\D/g, '');
    const cardType = cardValidator.number(num).card?.type;

    // limit to 15 digits for american express and give this format: ####
    // ###### ##### using regex to divide the characters in 3 groups of 4,
    // then 6 and the remaining 5
    if (cardType === 'american-express') {
      return num.slice(0, 15).replace(/^(.{4})(.{6})(.*)$/, '$1 $2 $3');
    }

    // for other card types limit to 16 and use format: #### #### ####
    // #### with regex to divide equal group of 4
    return num.slice(0, 16).replace(/\d{4}(?=.)/g, '$& ');
  }

  reformatCardNumber(e) {
    const target = e.currentTarget;
    setTimeout(() => {
      const { card } = cardValidator.number(target.value);
      // not format unknown card.
      if (!card) {
        return;
      }
      const re = /(?:^|\s)(\d{4})\s?$/;
      let shouldHaveSpaceAtEnd = re.test(target.value);
      const realValue = (target.value || '').replace(/\D/g, '');
      if (realValue.length >= card.lengths[card.lengths.length - 1]) {
        shouldHaveSpaceAtEnd = false;
      }
      let value = this.formatCardNumber(target.value);
      if (shouldHaveSpaceAtEnd) {
        value = `${value} `;
      }

      this.setSafeValue(target, value);
    });
    setTimeout(() => {
      const cardType = cardValidator.number(target.value).card?.type;
      this.setCardType(target, cardType);
    });
  }

  render() {
    const { creditCardNumber } = this.props;

    return (
      <TextField
        onKeyDown={this.onKeyDown}
        onKeyUp={this.onKeyUp}
        onPaste={this.onPaste}
        pattern="[●\d\s]*"
        type="tel"
        form="payment"
        validators={{
          invalid: value =>
            this.props.isPrefilled || cardValidator.number(value).isValid,
        }}
        messages={{
          invalid: <InputError message={ERROR_TEXT.CHECK_CREDITCARD} />,
        }}
        inputMode="numeric"
        {...this.props}
      >
        <CreditCardLogos cardNumber={creditCardNumber} />
      </TextField>
    );
  }
}

const logoTypes = ['visa', 'mastercard', 'discover', 'american-express'];

const CreditCardLogos = ({ cardNumber }) => {
  let logos = [...logoTypes];
  if (cardNumber) {
    const { card } = cardValidator.number(cardNumber);
    logos = card && logoTypes.includes(card.type) ? [card.type] : [];
  }

  return logos.length > 0 ? (
    <div className="credit-card__logo-wrapper">
      {logos.map(type => {
        const niceType = creditCardType.getTypeInfo(type)?.niceType || type;
        return (
          <img
            src={`/static/images/${type}.svg`}
            alt={`${niceType} logo`}
            className="credit-card__logo"
            key={type}
          />
        );
      })}
    </div>
  ) : null;
};

CreditCardLogos.propTypes = {
  cardNumber: PropTypes.string,
};

CreditCardField.defaultProps = {
  isPrefilled: false,
};

CreditCardField.propTypes = {
  onValueChanged: PropTypes.func.isRequired,
  onFieldChangeStarted: PropTypes.func,
  isPrefilled: PropTypes.bool,
  creditCardNumber: PropTypes.string,
};

export default CreditCardField;
