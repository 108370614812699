import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getMatchingAssignments } from '../selectors/config';

/**
 *  This is similar to the Experiment component in h3o-account, but it gets
 *  assignments from redux instead of the cookie.
 */

const mapState = (state, { children }) => {
  const developerIds = React.Children.map(
    children,
    child => child.props.developerId
  );

  return {
    matchingAssignments: getMatchingAssignments(state, developerIds),
  };
};

export const Experiment = connect(mapState)(
  ({ children, fallback, matchingAssignments }) => {
    const childArray = React.Children.toArray(children);

    const childComponent = childArray.find(child =>
      matchingAssignments.includes(child.props.developerId)
    );

    if (!matchingAssignments.length || !childComponent) {
      return fallback || null;
    }

    return childComponent;
  }
);

export class Treatment extends React.Component {
  render() {
    return this.props.children;
  }
}

const treatmentType = PropTypes.shape({
  type: PropTypes.oneOf([Treatment]),
});

Experiment.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(treatmentType),
    treatmentType,
  ]),
  fallback: PropTypes.node, // Component to render if no Treatments are rendered
};

Treatment.propTypes = {
  children: PropTypes.node.isRequired,
  developerId: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
};
