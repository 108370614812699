import * as paymentMethods from '../constants/paymentMethods';

export const translateSavedPayment = function(savedPayment) {
  const expDate = new Date(savedPayment.expiration);
  const expiry = `${expDate.getUTCMonth() + 1} / ${expDate
    .getUTCFullYear()
    .toString()
    .slice(2)}`;
  return {
    paymentType: paymentMethods.USE_CURRENT,
    creditCard: `●●●● ●●●● ●●●● ${savedPayment.mask}`,
    expiry,
    zip: savedPayment.zip,
    cvc: '',
  };
};
