import PropTypes from 'prop-types';

export const LegalTerm = PropTypes.shape({
  text: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      textUrl: PropTypes.string.isRequired,
      htmlUrl: PropTypes.string.isRequired,
      startIndex: PropTypes.number.isRequired,
      length: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired
  ),
});

const AddonRequestObjectPropTypes = PropTypes.shape({
  action: PropTypes.string.isRequired,
  componentId: PropTypes.number.isRequired,
  programId: PropTypes.number.isRequired,
  discountIds: PropTypes.arrayOf(PropTypes.number).isRequired,
});

const ChargeFrequencyPropTypes = PropTypes.shape({
  length: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
});

const TrialPropTypes = ChargeFrequencyPropTypes;

const DisplayPricingPropTypes = PropTypes.arrayOf(PropTypes.string);

export const AddonPropTypes = PropTypes.shape({
  chargeFrequency: ChargeFrequencyPropTypes,
  componentId: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  discount: PropTypes.arrayOf(PropTypes.number),
  displayPricing: DisplayPricingPropTypes.isRequired,
  displayTrial: PropTypes.string.isRequired,
  iconUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  programId: PropTypes.number.isRequired,
  requestObject: AddonRequestObjectPropTypes.isRequired,
  trial: TrialPropTypes.isRequired,
});

export const PageMessagingPropTypes = PropTypes.shape({
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  subheader: PropTypes.string,
  below: PropTypes.string,
  banner: PropTypes.string,
  bannerInstructions: PropTypes.string,
});

export const LocationPropTypes = PropTypes.shape({
  pathname: PropTypes.string,
});
