import { connect } from 'react-redux';
import get from 'lodash/get';

import { showTermsApplyModal } from '../actions/planOptionsActions';
import { updateSelectedPlan } from '../actions/planSelectActions';
import PlanOptions from '../components/PlanOptions';
import { hasAssignment } from '../selectors/config';
import { getPlanOptions } from '../selectors/plans';
import { getSelectedPlan } from '../selectors/user';
import { getIsIneligiblePromotion } from '../selectors/warning';
import { CAT_357 } from '../constants/experiments';

const mapStateToProps = state => ({
  isPrepaidOnBillingTreatment: hasAssignment(state, CAT_357.PREPAID_ON_BILLING),
  planOptions: getIsIneligiblePromotion(state)
    ? [getSelectedPlan(state)]
    : getPlanOptions(state, getSelectedPlan(state)),
  preSelectedSubIdentifier: get(getSelectedPlan(state), 'subIdentifier', null),
  isIneligiblePromotion: getIsIneligiblePromotion(state),
});

const mapDispatchToProps = {
  updateChosenPlan: updateSelectedPlan,
  openTermsApplyModal: showTermsApplyModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanOptions);
