import {
  EnterEmail,
  EnterPassword,
  MissingInfo,
} from '../components/AccountCreation';

export const ACCOUNT_CREATION_ROUTE_EMAIL = 'email';
export const ACCOUNT_CREATION_ROUTE_PASSWORD = 'password';
export const ACCOUNT_CREATION_ROUTE_USER = 'user';

// Here are the sub-routes and their corresponding screens
export const ACCOUNT_CREATION_SCREENS = {
  [ACCOUNT_CREATION_ROUTE_EMAIL]: EnterEmail,
  [ACCOUNT_CREATION_ROUTE_PASSWORD]: EnterPassword,
  [ACCOUNT_CREATION_ROUTE_USER]: MissingInfo,
};
