import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ModalTerms extends Component {
  render() {
    const { message, closeModal, fitContent } = this.props;
    const bodyClass = fitContent ? 'modal-body__fit' : '';
    const onClickFunc = e => {
      e.stopPropagation();
    };

    return (
      <div id="terms-modal" className="modal" onClick={onClickFunc}>
        <div className="modal-header" />
        <div className={`modal-body ${bodyClass}`}>
          <div
            className="modal-content"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </div>
        <div className="modal-footer" />
        <button
          aria-label="Close"
          className="modal-close"
          onClick={closeModal}
          type="button"
        >
          ✕
        </button>
      </div>
    );
  }
}

ModalTerms.propTypes = {
  message: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  fitContent: PropTypes.bool,
};

export default ModalTerms;
