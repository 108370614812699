import * as types from '../constants/actionTypes';

export const initialState = {
  createSubscriber: {
    response: null,
    error: null,
    loading: false,
  },
};

function payment(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_SUBSCRIBER.START:
      return {
        ...state,
        createSubscriber: { ...state.createSubscriber, loading: true },
      };
    case types.CREATE_SUBSCRIBER.SUCCESS:
      return {
        ...state,
        createSubscriber: {
          response: action.payload,
          error: null,
          loading: false,
        },
      };
    case types.CREATE_SUBSCRIBER.FAILURE:
      return {
        ...state,
        createSubscriber: {
          response: null,
          error: action.payload,
          loading: false,
        },
      };
    case types.CREATE_SUBSCRIBER.RESET:
      return {
        ...state,
        createSubscriber: { response: null, error: null, loading: false },
      };
    default:
      break;
  }
  return state;
}

export default payment;
