import { actions } from 'react-redux-form';
import { getAccountStatus } from '../api';
import * as types from '../constants/actionTypes';
import { getIsUnifiedIdentityOn } from '../selectors/login';

const EMAIL_STATUSES = [
  'available',
  'invalid',
  'existing',
  'subscribed',
  'serviceUnavailable',
];
export function setEmailValidity(model, actualStatus, validStatuses) {
  const validity = {
    error: !!actualStatus,
  };
  EMAIL_STATUSES.forEach(status => {
    validity[status] =
      validStatuses.indexOf(status) >= 0 || actualStatus !== status;
  });
  return actions.setValidity(model, validity);
}

export function setSelectedTab(tabIndex) {
  return {
    type: types.PAYMENT_TAB_SELECTED,
    tabIndex,
  };
}

export function lockUserOut() {
  return {
    type: types.LOCK_USER_OUT,
    lockedOut: true,
  };
}

export function checkEmailStatus(emailOverride) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      user: { email },
    } = state;
    dispatch(actions.setPending('user.email', true));
    return getAccountStatus(
      emailOverride || email,
      getIsUnifiedIdentityOn(state)
    ).then(response => {
      const status = response ? response.status : undefined;
      dispatch(actions.setPending('user.email', false));
      dispatch(actions.change('user.status', status));
      return status;
    });
  };
}
