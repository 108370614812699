import React from 'react';

import NumberField from '../containers/NumberFieldContainer';
import { ZIP as validateZip } from '../constants/validators';
import { ERROR_TEXT } from '../constants/errorText';
import InputError from './InputError/InputError';

const PaymentZipCode = () => (
  <NumberField
    displayName="ZIP CODE"
    form="payment"
    model="zip"
    maxLength="5"
    validators={{
      invalid: validateZip,
    }}
    messages={{
      invalid: <InputError message={ERROR_TEXT.VALID_ZIP} />,
    }}
    className="field__payment_zip"
  />
);

export default PaymentZipCode;
