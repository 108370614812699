import React, { createRef, Component } from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { preventFocus, keepFocusWithin } from '../../utils/a11y';

class ModalDefault extends Component {
  constructor() {
    super();
    this.modalRef = createRef();
    this.onEscapeClose = this.onEscapeClose.bind(this);
  }

  componentDidMount() {
    document.body.classList.add('modal-open');
    document.addEventListener('keydown', this.onEscapeClose);
    this.keepFocusWithin = keepFocusWithin(this.modalRef);
  }

  componentWillUnmount() {
    this.keepFocusWithin.remove();
    document.removeEventListener('keydown', this.onEscapeClose);
    document.body.classList.remove('modal-open');
  }

  onEscapeClose(e) {
    if (e.which === 27) {
      this.props.closeModal();
    }
  }

  render() {
    const { className, closeModal, footer, message, title } = this.props;

    const header = title && (
      <div className="modal-header" id="default-modal-header">
        {title}
      </div>
    );
    const body = message.map((paragraph, index) => (
      <div key={index} className="modal-content">
        <ReactMarkdown
          source={paragraph}
          className="modal-markdown"
          linkTarget="_blank"
        />
      </div>
    ));

    return (
      <div
        ref={this.modalRef}
        className="modal-padding"
        role="dialog"
        aria-modal="true"
        aria-labelledby="default-modal-header"
      >
        <div
          id="default-modal"
          data-testid="default-modal"
          className={cx('modal', className)}
          onClick={e => e.stopPropagation()}
        >
          {header}
          <div className="modal-body">{body}</div>
          <button
            aria-label="Close"
            className="modal-close"
            onClick={closeModal}
            onMouseDown={preventFocus}
            type="button"
          >
            <img
              className="modal-close__icon"
              alt="Close"
              src="/static/images/close-icon.svg"
            />
          </button>
          {footer}
        </div>
      </div>
    );
  }
}

ModalDefault.propTypes = {
  className: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  footer: PropTypes.node,
  message: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string,
};

export default ModalDefault;
