export function getOS() {
  let OSName = 'Unknown OS';
  if (global.window) {
    const navigatorAppVer = navigator.appVersion;
    if (navigatorAppVer.indexOf('Win') > -1) {
      OSName = 'Windows';
    } else if (navigatorAppVer.indexOf('Mac') > -1) {
      OSName = 'Mac';
    } else if (navigatorAppVer.indexOf('X11') > -1) {
      OSName = 'UNIX';
    } else if (navigatorAppVer.indexOf('Linux') > -1) {
      OSName = 'Linux';
    }
  }

  return OSName;
}

export function getBrowserName() {
  if (global.window) {
    const navigatorAgent = navigator.userAgent;
    let browserName = navigator.appName;

    if (navigatorAgent.indexOf('Opera') !== -1) {
      browserName = 'Opera';
    } else if (navigatorAgent.indexOf('MSIE') !== -1) {
      browserName = 'Microsoft Internet Explorer';
    } else if (navigatorAgent.indexOf('Chrome') !== -1) {
      browserName = 'Chrome';
    } else if (navigatorAgent.indexOf('Safari') !== -1) {
      browserName = 'Safari';
    } else if (navigatorAgent.indexOf('Firefox') !== -1) {
      browserName = 'Firefox';
    }

    return browserName;
  }

  return '';
}

export function getOSVersion(str) {
  let ver = '';

  if (global.window) {
    if (/^Mozilla\/\d+\.\d+ \(([^)]+)\)/.test(str)) {
      const osStr = RegExp.$1;
      if (/Windows NT ([^;]+)/.test(osStr)) {
        ver = RegExp.$1;
      } else if (/Mac OS X ([^;]+)/.test(osStr)) {
        ver = RegExp.$1;
      } else {
        ver = osStr;
      }
    }
  }

  return ver;
}

export function isLocalStorageNameSupported() {
  try {
    // in safari, if cookies blocked (Settings > Safari > Block Cookies > Always),
    // accessing sessionStorage will result in SecurityError: Dom Exception 18

    // same in chrome, Settings > Privacy > Content settings, will result in error
    // SecurityError: Failed to read the 'sessionStorage' property from 'Window': Access is denied for this document.
    if (!('sessionStorage' in window) || !window.sessionStorage) {
      return false;
    }

    const testKey = 'hulu_storage_test';
    const storage = window.sessionStorage;
    storage.setItem(testKey, '1');
    storage.removeItem(testKey);
    return true;
  } catch (error) {
    return false;
  }
}

function s4() {
  // eslint-disable-next-line no-bitwise
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}

export function getNewGuid() {
  let guid = '';
  for (let i = 0; i < 8; i++) {
    guid += s4();
  }

  return guid.toUpperCase();
}
