import { connect } from 'react-redux';

import { toggleAddon } from '../actions/addonActions';
import { routePush } from '../actions/routingActions';
import { getAddons, getSelectedAddonIds } from '../selectors/addons';
import { getMessagingForPage } from '../selectors/dynamicMessaging';
import * as routes from '../constants/routes';
import AddonSelectPage from '../pages/AddonSelectPage';

function mapStateToProps(state, ownProps) {
  const { banner, bannerInstructions } = getMessagingForPage(state, ownProps);
  return {
    addons: getAddons(state),
    selectedAddons: getSelectedAddonIds(state),
    canSubmit: true,
    sectionTitle: banner,
    sectionSubtitle: bannerInstructions,
  };
}

function mergeProps(stateProps, dispatchProps) {
  const { dispatch } = dispatchProps;

  const onClick = componentId => dispatch(toggleAddon(componentId));
  const onSubmit = () => dispatch(routePush(routes.BILLING_INFO));

  return {
    ...stateProps,
    onClick,
    onSubmit,
  };
}

export default connect(mapStateToProps, null, mergeProps)(AddonSelectPage);
