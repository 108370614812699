import { createReducer } from 'redux-starter-kit';
import { GET_BUNDLE_PARTNER_SWITCH_ELIGIBILITY } from '../constants/actionTypes';

export const initialState = {
  response: {
    payload: null,
    loading: false,
    error: null,
  },
};

export default createReducer(initialState, {
  [GET_BUNDLE_PARTNER_SWITCH_ELIGIBILITY.START]: state => {
    state.response = { payload: null, loading: true, error: null };
  },
  [GET_BUNDLE_PARTNER_SWITCH_ELIGIBILITY.SUCCESS]: (state, { payload }) => {
    state.response = {
      payload,
      loading: false,
      error: null,
    };
  },
  [GET_BUNDLE_PARTNER_SWITCH_ELIGIBILITY.FAILURE]: (state, { payload }) => {
    state.response = {
      payload: null,
      loading: false,
      error: payload,
    };
  },
});
