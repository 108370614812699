import find from 'lodash/find';
import get from 'lodash/get';
import { createSelector } from 'reselect';
import { ERROR_CODES } from '../constants/errorCodes';
import {
  isFeatureEnabled,
  DISABLE_ACCOUNT_STATUS_CHECK_FLAG,
  getIsEdnaPartnerFlowEnabled,
} from './featureFlag';
import {
  getClient,
  getIsBundlePartnerFlow,
  getIsPartnerFlow,
  getPartnerFlow,
} from './flow';
import {
  getUserNode,
  getUserCreationRecaptchaToken,
  getIsLoggedIn,
} from './user';
import { getIsRedirectToWebsubFlow } from './siteconfig';

export const getIsRokuWeb = state => !!state.config.rpm;
export const getIsUserRequestLoading = state => state.user.userCreateLoading;

/**
 * Determines whether the current flow should redirect the user to web-sub
 * for billing or not. We'll only redirect if the usercreation flag is on,
 * since web-sub needs the user to be logged in to work.
 * The flow will redirect to web-sub depending on from parameters configured on
 * site-config.
 *
 * @param state - the redux store
 * @returns {boolean} - True if the user should be redirected to web-sub
 */
export const getShouldRedirectToWebsubBilling = state => {
  const isUserCreateFeature = getUserCreateFeature(state);
  const isAlwaysFlow = getIsRedirectToWebsubFlow(state);

  return isUserCreateFeature && isAlwaysFlow;
};

export const getDisableEmail = createSelector(
  [getIsRokuWeb, getIsBundlePartnerFlow],
  (isRokuWeb, isBundlePartnerFlow) => isRokuWeb || isBundlePartnerFlow
);

export const getUserRequest = createSelector(
  [getUserNode, getClient, getUserCreationRecaptchaToken],
  (user, client, recaptchaToken) => ({
    user: { ...user, recaptchaToken },
    client,
  })
);

export const getUserCreateFeature = state =>
  isFeatureEnabled(state, 'usercreation');

/*
 * Determine whether or not to do user creation with the Yokozuna /users endpoint.
 *
 * Users should be created when:
 *   - The flag is enabled, AND
 *   - The user is not in a partner flow. Partner flows do not currently handle cases
 *     for users created without a subscription. Until then, users must be created via
 *     the Yokozuna /subscriptions call when their user/subscription will be linked to
 *     the 3rd party partner in Jackman.
 */
export const getShouldCreateUser = createSelector(
  [getUserCreateFeature, getPartnerFlow],
  (userCreateFeature, partnerFlow) => {
    const isNotPartnerFlow = partnerFlow === null;
    return userCreateFeature && isNotPartnerFlow;
  }
);

export const getIsEmailRegistered = errorResponse =>
  find(errorResponse.errors, { code: ERROR_CODES.USER_EMAIL_REGISTERED });

/**
 * This "password policy check failed" error will be returned if the user's
 * password doesn't satisfy the stronger password strength requirement launched
 * with the Unified Identity project.
 */
export const getIsPasswordPolicyCheckFailed = errorResponse =>
  find(errorResponse.errors, {
    code: ERROR_CODES.USER_PASSWORD_POLICY_CHECK_FAILED,
  });

export const getPasswordPolicyCheckFailedReason = errorResponse =>
  get(getIsPasswordPolicyCheckFailed(errorResponse), 'message');

export const getShouldDisableAccountStatusCheck = state =>
  isFeatureEnabled(state, DISABLE_ACCOUNT_STATUS_CHECK_FLAG);

export const getShouldShowEdnaCreateAccount = createSelector(
  [getIsEdnaPartnerFlowEnabled, getIsPartnerFlow, getIsLoggedIn],
  (isEdnaPartnerFlowEnabled, isPartnerFlow, isLoggedIn) => {
    // Return false when the user is already logged in.
    if (isLoggedIn) return false;

    // TODO WEB-20236: Remove the Edna Partner Flow Feature Flag
    const isPartnerFlowAddon = isPartnerFlow ? isEdnaPartnerFlowEnabled : true;

    return isPartnerFlowAddon;
  }
);
