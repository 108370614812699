import React from 'react';
import SimpleTooltip from './SimpleTooltip';

const InfoTooltip = props => (
  <SimpleTooltip {...props}>
    <img
      className="label__input-icon"
      alt="More Information Icon"
      src="/static/images/more-info.svg"
    />
  </SimpleTooltip>
);

export default InfoTooltip;
