// HUWEB-4137: move to pages/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import Panel from '../components/Panel';
import Loading from '../components/Loading';

require('../styles/form.scss');
require('../styles/roadblock.scss');

class SprintSpotifyRoadblockPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };

    this.onClickAccept = this.onClickAccept.bind(this);
    this.onClickDecline = this.onClickDecline.bind(this);
    this.handleAction = this.handleAction.bind(this);
  }

  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    if (this.props.onLoaded) {
      this.handleAction(this.props.onLoaded);
    }
  }

  // TODO: Productionalize t-mobile flag
  onClickAccept() {
    if (this.props.isTMobileFeatureOn && this.props.onAcceptTMobile) {
      this.handleAction(this.props.onAcceptTMobile);
    } else if (this.props.onAccept) {
      this.handleAction(this.props.onAccept);
    }
  }

  onClickDecline() {
    if (this.props.onDecline) {
      this.handleAction(this.props.onDecline);
    }
  }

  /**
   * For async action, the spinner is shown before the action is called. If the action is successful,
   * the spinner remains, and if the action fails, the spinner is hidden. This essentially gives
   * actions the ability to show/hide the spinner.
   *
   * @param {function} action The action that will be called. An async action must return a Promise.
   */
  handleAction(action) {
    if (this.props.isAsync) {
      this.setState({ isLoading: true }, () => {
        action().then(
          () => {
            // Action succeeded, keep the spinner
            this.setState({ isLoading: true });
          },
          () => {
            // Action failed, hide the spinner
            this.setState({ isLoading: false });
          }
        );
      });
    } else {
      action();
    }
  }

  insertLinksToText(textData) {
    if (textData && typeof textData === 'object') {
      const { text } = textData;
      const children = [];
      let lastIndex = 0;
      textData.links.forEach((link, i) => {
        const startIndex = text.indexOf(link.id);
        const linkName = `cta-${link.id.toLowerCase().replace('_', '-')}-${i}`;
        children.push(text.substring(lastIndex, startIndex));
        children.push(
          <a key={linkName} className={linkName} href={link.url}>
            {link.text}
          </a>
        );
        lastIndex = startIndex + link.id.length;
      });
      children.push(text.substring(lastIndex));

      return children;
    }

    return <ReactMarkdown source={textData} escapeHtml={false} />;
  }

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    const {
      roadblockText: { header, subheader, accept, decline, footer },
    } = this.props;

    const roadblockSubheader = subheader && (
      <div className="roadblock__subtitle">
        {[].concat(subheader).map((text, index) => (
          <p className={`subtitle-${index}`} key={`subtitle-${index}`}>
            {this.insertLinksToText(text)}
          </p>
        ))}
      </div>
    );
    const acceptButton = accept && (
      <button
        className="button roadblock__button"
        type="button"
        onClick={this.onClickAccept}
      >
        {accept}
      </button>
    );
    const declineButton = decline && (
      <button
        className="button button--inverse roadblock__decline__button"
        type="button"
        onClick={this.onClickDecline}
      >
        {decline}
      </button>
    );

    return (
      <Panel className="large equal-padding">
        <div className="roadblock">
          <div className="roadblock__title">
            {header}
            {roadblockSubheader}
          </div>
          {footer}
          {acceptButton}
          {declineButton}
        </div>
      </Panel>
    );
  }
}

SprintSpotifyRoadblockPage.defaultProps = {
  roadblockText: {},
};

const TextWithLinks = PropTypes.shape({
  text: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired
  ),
});

SprintSpotifyRoadblockPage.propTypes = {
  onLoaded: PropTypes.func,
  onDecline: PropTypes.func,
  onAccept: PropTypes.func,
  onAcceptTMobile: PropTypes.func,
  roadblockText: PropTypes.shape({
    accept: PropTypes.string,
    header: PropTypes.string,
    subheader: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      TextWithLinks,
    ]),
    footer: PropTypes.element,
    decline: PropTypes.string,
  }),
  isAsync: PropTypes.bool,
  isTMobileFeatureOn: PropTypes.bool,
};

export default SprintSpotifyRoadblockPage;
