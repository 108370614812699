// For plans that offers limited time discount. Example output: $4.99/month then 5.99/month
import get from 'lodash/get';

export const getPricingEvolution = displayPricing => {
  return displayPricing
    .join(' then ')
    .replace(' every 3 months', '/3 mo')
    .replace('month', 'mo')
    .replace('year', 'yr');
};

export const getAnnualTermsApplyBody = planOption => {
  const annualPlan = planOption.planOptions.find(
    plan =>
      get(plan, 'pricing[0].frequency.length', 0) === 1 &&
      get(plan, 'pricing[0].frequency.unit', '') === 'year'
  );
  return annualPlan?.optionOfferAdditionalTerms || [];
};
