import { connect } from 'react-redux';
import ActivatePage from '../pages/UnifiedActivatePage';
import { handleStartUnifiedActivation } from '../actions/unifiedActions';

const mapDispatchToProps = dispatch => ({
  onLoaded: () => dispatch(handleStartUnifiedActivation()),
});

const ConnectedUnifiedActivatePage = connect(
  null,
  mapDispatchToProps
)(ActivatePage);

export default ConnectedUnifiedActivatePage;
