import React from 'react';
import PropTypes from 'prop-types';

import LedgerError from './LedgerError';
import LedgerItem from './LedgerItem';
import LedgerNotice from './LedgerNotice';
import LedgerSummary from './LedgerSummary';
import Panel from '../Panel';

require('../../styles/ledger.scss');

const Ledger = ({
  items,
  summary,
  ledgerTitle,
  ineligibleErrorText: { header, subheader },
  promoTreatmentId,
  shouldDisplayPlanOptions,
  isIneligiblePromotion,
}) => (
  <div className="ledger">
    <Panel className="large">
      <div className="ledger__title">{ledgerTitle}</div>
      <LedgerError header={header} subheader={subheader} />
      <LedgerNotice />
      <ul className="ledger__content">
        {items.map((item, index) => {
          const isPrepaidIneligiblePromotion =
            index === 0 && shouldDisplayPlanOptions && isIneligiblePromotion;

          const { name, description, badges, price, savings, actions } = item;
          let hideItems = {
            name,
            description,
            badges,
            price,
            savings,
            actions,
          };

          if (isPrepaidIneligiblePromotion) {
            hideItems = {
              name: null,
              description: null,
              badges: [],
              price: null,
              savings: null,
              actions: [],
            };
          }

          return (
            <LedgerItem
              key={index}
              {...item}
              {...hideItems}
              promoTreatmentId={promoTreatmentId}
              shouldDisplayPlanOptions={index === 0 && shouldDisplayPlanOptions}
            />
          );
        })}
      </ul>
    </Panel>
    {summary && (
      <LedgerSummary
        name={summary.name}
        price={summary.price}
        disclaimer={summary.disclaimer}
      />
    )}
  </div>
);

Ledger.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(LedgerItem.PropTypes)).isRequired,
  summary: PropTypes.shape({
    name: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    disclaimer: PropTypes.string.isRequired,
  }),
  ineligibleErrorText: PropTypes.shape({
    header: PropTypes.string,
    subheader: PropTypes.string,
  }).isRequired,
  ledgerTitle: PropTypes.string.isRequired,
  promoTreatmentId: PropTypes.string,
  shouldDisplayPlanOptions: PropTypes.bool.isRequired,
  isIneligiblePromotion: PropTypes.bool,
};

export default Ledger;
