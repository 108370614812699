import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-redux-form';

import Addon from '../components/Addon';
import Panel from '../components/Panel';
import { AddonPropTypes } from '../constants/propTypes';

import '../styles/addons.scss';

class AddonSelectPage extends React.Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleClick(componentId) {
    this.props.onClick(componentId);
  }

  handleSubmit() {
    if (this.props.canSubmit) {
      this.props.onSubmit();
    }
  }

  render() {
    const {
      canSubmit,
      addons,
      selectedAddons,
      sectionTitle,
      sectionSubtitle,
    } = this.props;

    return (
      <div>
        <Panel className="addons add-ons-page-panel">
          <div className="addons__sectiontitle">{sectionTitle}</div>
          <div className="addons__sectionsubtitle">{sectionSubtitle}</div>
          <Form
            model="addons"
            onSubmit={() => this.handleSubmit()}
            className="form addons"
            method="POST"
          >
            <div className="addons__selectionlist">
              {addons.map(addon => (
                <Addon
                  key={addon.componentId}
                  addon={addon}
                  isSelected={selectedAddons.indexOf(addon.componentId) !== -1}
                  onClick={() => this.handleClick(addon.componentId)}
                />
              ))}
            </div>
            <button className="button" aria-disabled={!canSubmit} type="submit">
              Continue
            </button>
          </Form>
        </Panel>
      </div>
    );
  }
}

AddonSelectPage.propTypes = {
  addons: PropTypes.arrayOf(AddonPropTypes).isRequired,
  selectedAddons: PropTypes.arrayOf(PropTypes.number).isRequired,
  canSubmit: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  sectionTitle: PropTypes.string,
  sectionSubtitle: PropTypes.string,
};

export default AddonSelectPage;
