export const DPLUS_TEXT_KEY_ROOT = 'sufo_dplus';
export const DPLUS_PPV_TEXT_KEY_ROOT = 'sufo_dplus_ppv';
export const EPLUS_TEXT_KEY_ROOT = 'sufo_eplus';
export const FRONT_END_PAYMENTS_KEY_ROOT = 'sufo_front_end_payments';
export const LINE_BREAK_KEY = 'BR';
export const LINE_BREAK_HTML_KEY = 'BR_HTML';
export const MAINTENANCE_PAGE_KEY = 'maintenance';
export const MAINTENANCE_PAGE_ROOT = 'signup_maintenance';
export const MAINTENANCE_TEXT_KEY = 'maintenance_message';
export const MAX_BUNDLE_TEXT_KEY_ROOT = 'sufo_max';
export const PARTNER_DATA_KEY = 'data';
export const PARTNER_KEY_PREFIX = 'PARTNER_';
export const PARTNER_TEXT_KEY_ROOT = 'sufo_partner';
export const REDIRECTS_ROOT = 'sufo_redirects';
export const TEXT_CONTROLS_KEY = 'sufo_controls.text';
export const URL_KEY_PREFIX = 'URL_';
export const VERIZON_VIA_DSS_TEXT_KEY_ROOT = 'sufo_verizon_via_dss';
