import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import TextField from '../TextField';
import * as validators from '../../constants/validators';
import { ERROR_TEXT } from '../../constants/errorText';
import InputError from '../InputError';

/**
 * Account Creation - Enter User's Password
 *
 * @param props.displayPlaceHolder - A boolean variable controls the visibility of the placeholder, it's set to false by default.
 * @param props.displayExtendedLabel - A boolean variable controls whether to show extended label text, and display 'CREATE PASSWORD' instead of just 'PASSWORD' based on a boolean variable's value.
 */
export const EnterPassword = ({ displayPlaceHolder, displayExtendedLabel }) => {
  const passwordTitle = useMemo(() => {
    return displayExtendedLabel ? 'CREATE PASSWORD' : 'PASSWORD';
  }, [displayExtendedLabel]);

  const placeholder = useMemo(() => {
    if (displayPlaceHolder) {
      return startCase(passwordTitle.toLowerCase());
    }
    return null;
  }, [displayPlaceHolder, passwordTitle]);

  return (
    <TextField
      className="fieldset__password"
      displayName={passwordTitle}
      form="user"
      key="passwordfield"
      messages={{
        invalid: <InputError message={ERROR_TEXT.VALID_PASSWORD} />,
      }}
      model="password"
      placeHolder={placeholder}
      type="password"
      validators={{
        invalid: validators.PASSWORD,
      }}
    />
  );
};

EnterPassword.propTypes = {
  displayPlaceHolder: PropTypes.bool,
  displayExtendedLabel: PropTypes.bool,
};

EnterPassword.defaultProps = {
  displayPlaceHolder: false,
  displayExtendedLabel: false,
};
