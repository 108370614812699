import React from 'react';
import PropTypes from 'prop-types';
import { Form, actions } from 'react-redux-form';
import startCase from 'lodash/startCase';
import ReCAPTCHA from '@hulu/web-google-recaptcha';
import debounce from 'lodash/debounce';

import env from '../../../config/env';

import * as formActions from '../actions/formActions';
import * as modalActions from '../actions/modalActions';
import {
  shouldLockOut,
  isParentalConsentNeeded,
} from '../utils/ageValidationUtils';
import { getAccountStatus, validateZip } from '../api';
import { SPOTIFY } from '../constants/partners';
import { CreateAccountEmailError } from '../containers/EmailErrorWrappers';
import HomeZipError from '../containers/HomeZipErrorWrappers';
import SuperBundleLogo from '../components/SuperBundleLogo';
import BirthdayField from '../components/BirthdayField';
import Loading from '../components/Loading';
import Panel from '../components/Panel';
import TextField from '../components/TextField';
import NumberField from '../containers/NumberFieldContainer';
import CheckBoxField from '../components/CheckBoxField';
import GenderField from '../components/GenderField';
import AccountInfoTerms from '../components/AccountInfoTerms';
import InputError from '../components/InputError';
import {
  UnifiedIdentityAccountInstructions,
  UnifiedIdentityEmailWarning,
} from '../components/UnifiedIdentity';
import { isValidDate } from '../utils/dateUtils';
import * as validators from '../constants/validators';
import { ERROR_TEXT } from '../constants/errorText';
import { setUserCreationRecaptchaToken } from '../actions/userActions';
import { ACCOUNT_INFO } from '../constants/routes';
import EdnaCreateAccountContainer from '../containers/EdnaCreateAccountContainer';

require('../styles/account.scss');

class CreateAccountForm extends React.Component {
  constructor(props) {
    super(props);

    this.checkEmail = this.checkEmail.bind(this);
    this.dispatchEmailStatus = this.dispatchEmailStatus.bind(this);
    this.validateZip = this.validateZip.bind(this);
    this.onChangeAsyncField = this.onChangeAsyncField.bind(this);
    this.sortForm = this.sortForm.bind(this);
    this.onEmailChanged = this.onEmailChanged.bind(this);
    this.onPasswordChanged = this.onPasswordChanged.bind(this);
    this.onHomeZipChanged = debounce(this.onHomeZipChanged.bind(this), 500);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.userCreationRecaptchaRef = React.createRef();
  }

  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.props.onLoaded();
  }

  componentDidMount() {
    const { dispatch, user } = this.props;
    if (user && user.email && !user.loggedIn) {
      const mockEvent = { target: { value: user.email } };
      dispatch(actions.setTouched('user.email'));
      this.checkEmail(mockEvent);
    }
  }

  onChangeAsyncField(field) {
    const { dispatch } = this.props;
    dispatch(actions.setPending(field, true));

    // Clears error when the user changes the field
    dispatch(actions.setValidity(field, { invalid: false }));
  }

  onEmailChanged() {
    const { dispatch } = this.props;
    this.onChangeAsyncField('user.email');

    // Clear account status error when the user changes the email address
    dispatch(actions.setValidity('user.email', { subscribed: true }));
  }

  onPasswordChanged() {
    const { dispatch } = this.props;

    // Clears server-side validation error that checks password strength
    // when the user changes the field
    dispatch(
      actions.setValidity('user.password', { passesPasswordCheck: true })
    );
    dispatch(actions.setPending('user', false));
  }

  onHomeZipChanged(event) {
    this.validateZip(event.target.value, 'user.zip');
  }

  async handleSubmit(user) {
    const {
      canSubmit,
      dispatch,
      lockedOut,
      isCuriosityIncluded,
      isPrepaidForSelectedPlan,
      shouldDisplayLegalZip,
      isGrecaptchaFeatureOn,
    } = this.props;

    if (!canSubmit) {
      return;
    }

    if (isGrecaptchaFeatureOn) {
      const recaptchaUserCreationToken = await this.userCreationRecaptchaRef.current.executeAsync();
      dispatch(setUserCreationRecaptchaToken(recaptchaUserCreationToken));
    }

    if (lockedOut || (user && user.birthday && shouldLockOut(user.birthday))) {
      dispatch(formActions.lockUserOut());
      dispatch(modalActions.showLockoutModal());
    } else if (
      shouldDisplayLegalZip ||
      isCuriosityIncluded ||
      isPrepaidForSelectedPlan
    ) {
      this.isSubmittingZip = true;
      this.validateZip(
        user.zip || user.legalZip,
        isCuriosityIncluded ? 'user.zip' : 'user.legalZip'
      ).then(res => {
        if (res) {
          this.props.onSubmit();
          this.isSubmittingZip = false;
        }
      });
    } else {
      this.props.onSubmit();
    }
  }

  checkEmail(event) {
    const {
      dispatch,
      shouldDisableAccountStatusCheck,
      showUnifiedIdentity,
    } = this.props;
    if (shouldDisableAccountStatusCheck) {
      this.dispatchEmailStatus('available');
      return;
    }
    dispatch(actions.setPending('user.email', true));
    getAccountStatus(event.target.value, showUnifiedIdentity).then(
      response => {
        this.dispatchEmailStatus(response ? response.status : undefined);
      },
      () => {
        this.dispatchEmailStatus('serviceUnavailable');
      }
    );
  }

  dispatchEmailStatus(status) {
    const { dispatch } = this.props;
    dispatch(formActions.setEmailValidity('user.email', status, ['available']));
    dispatch(actions.setPending('user.email', false));
    dispatch(actions.change('user.status', status));
  }

  validateZip(value, zipModel, setPending = true) {
    const { dispatch } = this.props;

    if (setPending) {
      dispatch(actions.setPending(zipModel, true));
    }

    return validateZip(value).then(
      res => {
        const status = res.availability || 'invalid';
        const validity = {};
        validity[status] = res.valid && !res.availability;
        dispatch(actions.setValidity(zipModel, validity));
        dispatch(actions.setPending(zipModel, false));
        return Promise.resolve(res.valid);
      },
      () => {
        dispatch(actions.setValidity(zipModel, { invalid: false }));
        dispatch(actions.setPending(zipModel, false));
      }
    );
  }

  sortForm(
    emailField,
    passwordField,
    nameField,
    birthdayField,
    homeZipCodeField,
    billingZipCodeField,
    legalZipCodeField,
    genderField
  ) {
    return [
      emailField,
      passwordField,
      nameField,
      birthdayField,
      homeZipCodeField,
      billingZipCodeField,
      legalZipCodeField,
      genderField,
    ];
  }

  render() {
    const {
      accountValidated,
      path,
      user,
      missingUserFields,
      isCuriosityIncluded,
      isLoading,
      displayBillingZip,
      isPrepaidForSelectedPlan,
      displayPlaceHolder,
      partnerFlow,
      shouldDisplayLegalZip,
      showUnifiedIdentity,
      shouldShowEdnaCreateAccount,
      isTWDCPrivacyPolicyFeatureOn,
    } = this.props;

    if (isLoading) {
      return <Loading />;
    }

    const showEdnaExperiment =
      path !== ACCOUNT_INFO || shouldShowEdnaCreateAccount;

    if (showEdnaExperiment) {
      return (
        <EdnaCreateAccountContainer
          isDisplayBillingZip={displayBillingZip}
          isDisplayLegalZip={shouldDisplayLegalZip}
          isLiveTVSelected={isCuriosityIncluded}
          isPrepaidForSelectedPlan={isPrepaidForSelectedPlan}
        />
      );
    }

    const needConsent = user.birthday
      ? isParentalConsentNeeded(user.birthday)
      : false;
    let passwordTitle;
    let passwordDescription;
    let buttonText = 'CONTINUE';

    if (partnerFlow === SPOTIFY.FLOW) {
      passwordTitle = 'CREATE PASSWORD';
      buttonText = 'Create Account';
    } else {
      passwordTitle = 'PASSWORD';
    }

    const homeZipCodeField = isCuriosityIncluded ? (
      <NumberField
        displayName="HOME ZIP CODE"
        placeHolder={displayPlaceHolder ? 'Home Zip Code' : null}
        form="user"
        model="zip"
        maxLength="5"
        tooltip={
          <p>
            We use your home zip code to personalize your experience with your
            local programming.
          </p>
        }
        tooltipClassName="homezip"
        validators={{ invalid: validators.ZIP }}
        messages={{
          invalid: <InputError message={ERROR_TEXT.VALID_ZIP_CODE} />,
          'svod-available': (
            <InputError message={ERROR_TEXT.LIVETV_UNAVAILABLE} />
          ),
        }}
        onChange={event => {
          /**
           * We are validating zip onChange instead of just onBlur so if a user
           * enters a valid zip while the rest of the form is complete and
           * valid, they would see an enabled submit button without having to
           * trigger onBlur.
           */
          this.onChangeAsyncField('user.zip');
          this.onHomeZipChanged(event);
        }}
        /**
         * handleOnBlur in the TextField class always gets triggered on blur.
         * handleOnBlur sets the field validity to the result of a
         * validators.ZIP call, which is the first of the two usual steps of a
         * comprehensive zip validation. We need to complete the second step,
         * which is calling validateZip, in order to display the right
         * validity. That is why we are calling validateZip onBlur even when
         * we already call validateZip onChange.
         */
        onBlur={event => {
          this.validateZip(
            event.target.value,
            'user.zip',
            /**
             * We do not set the form to pending onBlur if the last onChange
             * event already performed a full validation that returned valid.
             * Because otherwise, if the user tries hitting the submit button
             * (which should be enabled) while this field is focused, the button
             * would quickly turn to disabled.
             *
             * We set the form to pending onBlur if either we are not sure yet
             * if the zip is valid or we know it's invalid. This is because
             * handleOnBlur in TextField might set the field to valid during the
             * time validateZip is still waiting for a response from backend,
             * potentially showing an enabled button when the zip is in fact
             * invalid.
             */
            !this.props.canSubmit
          );
        }}
        errorWrapper={HomeZipError}
        key="homezip"
      />
    ) : null;

    const billingZipCodeField =
      !isCuriosityIncluded && displayBillingZip ? (
        <NumberField
          displayName="ZIP CODE"
          placeHolder={displayPlaceHolder ? 'Zip Code' : null}
          model="billingZip"
          maxLength="5"
          validators={{ invalid: validators.ZIP }}
          messages={{
            invalid: <InputError message={ERROR_TEXT.VALID_ZIP} />,
          }}
          key="billingzip"
        />
      ) : null;

    const legalZipCodeField =
      !isCuriosityIncluded &&
      (isPrepaidForSelectedPlan || shouldDisplayLegalZip) ? (
        <NumberField
          displayName="ZIP CODE"
          placeHolder={displayPlaceHolder ? 'Zip Code' : null}
          form="user"
          model="legalZip"
          maxLength="5"
          validators={{ invalid: validators.ZIP }}
          messages={{
            invalid: <InputError message={ERROR_TEXT.VALID_ZIP_CODE} />,
          }}
          onBlur={event =>
            this.validateZip(event.target.value, 'user.legalZip')
          }
          onChange={() => this.onChangeAsyncField('user.legalZip')}
          key="legalZip"
        />
      ) : null;

    const ExistingAccountError = showUnifiedIdentity ? (
      <UnifiedIdentityEmailWarning />
    ) : (
      <InputError message={ERROR_TEXT.ACCOUNT_EXISTS} />
    );

    const emailField = accountValidated ? null : (
      <TextField
        displayName="EMAIL"
        placeHolder={displayPlaceHolder ? 'Email' : null}
        form="user"
        model="email"
        type="email"
        className="fieldset__email"
        validators={{ invalid: validators.EMAIL }}
        maxLength={String(validators.MAX_RESTAPI_FIELD_LENGTH)}
        messages={{
          serviceUnavailable: (
            <InputError message={ERROR_TEXT.CANNOT_VERIFY_EMAIL} />
          ),
          invalid: <InputError message={ERROR_TEXT.VALID_EMAIL} />,
          existing: ExistingAccountError,
          subscribed: ExistingAccountError,
        }}
        onChange={this.onEmailChanged}
        onBlur={this.checkEmail}
        errorWrapper={CreateAccountEmailError}
        disabled={this.props.disableEmail}
        key="emailfield"
      />
    );

    const passwordField = accountValidated ? null : (
      <TextField
        displayName={passwordTitle}
        placeHolder={
          displayPlaceHolder ? startCase(passwordTitle.toLowerCase()) : null
        }
        form="user"
        model="password"
        type="password"
        className="fieldset__password"
        description={passwordDescription}
        validators={{
          invalid: validators.PASSWORD,
        }}
        messages={{
          invalid: <InputError message={ERROR_TEXT.VALID_PASSWORD} />,
          passesPasswordCheck: (
            <InputError message={this.props.userCreationPasswordCheckError} />
          ),
        }}
        onChange={this.onPasswordChanged}
        key="passwordfield"
      />
    );

    const nameField =
      accountValidated && !missingUserFields.includes('firstName') ? null : (
        <TextField
          displayName="NAME"
          placeHolder={displayPlaceHolder ? 'Name' : null}
          form="user"
          model="firstName"
          validators={{
            invalid: validators.NAME,
          }}
          messages={{
            invalid: <InputError message={ERROR_TEXT.REQUIRED_NAME} />,
          }}
          disabled={this.props.isRokuWeb}
          maxLength={String(validators.MAX_RESTAPI_FIELD_LENGTH)}
          key="namefield"
        />
      );

    const prefilledBirthdate =
      accountValidated && !missingUserFields.includes('birthday');
    const birthdayField = prefilledBirthdate ? null : (
      <BirthdayField
        displayName="BIRTHDATE"
        model="birthday"
        tooltip={
          <p>
            We&#8217;ll use this information to create a more personalized Hulu
            experience for you based on your age.
          </p>
        }
        dispatch={this.props.dispatch}
        validators={{
          required: validators.REQUIRED,
        }}
        key="birthdayfield"
      />
    );

    // This is in place temporarily while we transition to updated gender options.
    // showUpdatedGenderOptions is pulled from updated-gender-options feature flag in launch darkly
    const genderFieldOptions = this.props.showUpdatedGenderOptions
      ? [
          {
            text: 'Man',
            value: 'm',
          },
          {
            text: 'Woman',
            value: 'f',
          },
          {
            text: 'Non-binary',
            value: 'n',
          },
          {
            text: 'Prefer not to say',
            value: 'p',
          },
        ]
      : [
          {
            text: 'Female',
            value: 'f',
          },
          {
            text: 'Male',
            value: 'm',
          },
          {
            text: 'Prefer not to say',
            value: 'p',
          },
        ];

    const genderField =
      accountValidated && !missingUserFields.includes('gender') ? null : (
        <GenderField
          displayName="GENDER"
          model="gender"
          placeholder="Select"
          options={genderFieldOptions}
          tooltip={
            <p>
              We&#8217;ll use this information to create a more personalized
              Hulu experience for you based on your gender.
            </p>
          }
          dispatch={this.props.dispatch}
          validators={{
            required: validators.REQUIRED,
          }}
          messages={{
            required: <InputError message={ERROR_TEXT.REQUIRED_GENDER} />,
          }}
          key="genderfield"
        />
      );

    const spinnerHtml = <span className="spinner" data-testid="spinner" />;
    const { canSubmit } = this.props;
    const formInputs = this.sortForm(
      emailField,
      passwordField,
      nameField,
      birthdayField,
      homeZipCodeField,
      billingZipCodeField,
      legalZipCodeField,
      genderField
    );
    return (
      <div className="form account">
        <SuperBundleLogo />
        <Form
          model="user"
          onSubmit={model => this.handleSubmit(model)}
          validators={{
            birthday: dateObj => prefilledBirthdate || isValidDate(dateObj),
          }}
          method="POST"
        >
          {/* Recaptcha component for Yokozuna /users */}
          <ReCAPTCHA
            ref={this.userCreationRecaptchaRef}
            size="invisible"
            sitekey={env.recaptcha.userCreation.publicKey}
          />
          <Panel className="account__panel large">
            {showUnifiedIdentity && <UnifiedIdentityAccountInstructions />}

            {formInputs}

            {needConsent && (
              <CheckBoxField
                model="parentalConsent"
                text="I have obtained consent from my parent/guardian to register with Hulu."
                validators={{ required: validators.CHECKBOX }}
              />
            )}
          </Panel>

          <AccountInfoTerms
            ctaText={buttonText}
            shouldShowTwdcLegalTerms={showUnifiedIdentity}
            isTWDCPrivacyPolicyFeatureOn={isTWDCPrivacyPolicyFeatureOn}
          />
          <button
            className={`button button--continue ${canSubmit ? '' : 'disabled'}`}
            disabled={!canSubmit}
            aria-disabled={!canSubmit}
            aria-describedby={validators.USER_FIELDS_ERRORS.join(' ')}
            type="submit"
          >
            {this.isSubmittingZip ? spinnerHtml : buttonText}
          </button>
        </Form>
      </div>
    );
  }
}

CreateAccountForm.propTypes = {
  accountValidated: PropTypes.bool,
  missingUserFields: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  canSubmit: PropTypes.bool.isRequired,
  onLoaded: PropTypes.func.isRequired,
  lockedOut: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  user: PropTypes.object,
  isLoading: PropTypes.bool,
  isRokuWeb: PropTypes.bool,
  disableEmail: PropTypes.bool,
  isCuriosityIncluded: PropTypes.bool,
  partnerFlow: PropTypes.string,
  displayBillingZip: PropTypes.bool,
  isPrepaidForSelectedPlan: PropTypes.bool,
  displayPlaceHolder: PropTypes.bool,
  shouldDisplayLegalZip: PropTypes.bool,
  isGrecaptchaFeatureOn: PropTypes.bool,
  shouldDisableAccountStatusCheck: PropTypes.bool,
  showUnifiedIdentity: PropTypes.bool,
  showUpdatedGenderOptions: PropTypes.bool,
  shouldShowEdnaCreateAccount: PropTypes.bool,
  isTWDCPrivacyPolicyFeatureOn: PropTypes.bool,
  userCreationPasswordCheckError: PropTypes.string,
};

export default CreateAccountForm;
